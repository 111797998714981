import type {
  LibraryItemLogSerializer,
  LibraryItemStatusSerializerMap,
  LibraryItemStatusTypes,
} from '../CommonStatus.types';
import { ProfileStatusSerializers } from '../Profiles/serializers';

const LibraryItemLogSerializers: Record<
  string,
  LibraryItemStatusSerializerMap
> = {
  ...ProfileStatusSerializers,
};

export function getStatusSerializer(
  type: LibraryItemStatusTypes,
  version: number,
): LibraryItemLogSerializer | null {
  const serializers = LibraryItemLogSerializers[type];

  if (serializers == null) return null;

  const serializer = serializers[version] ?? serializers.Default;

  return serializer;
}
