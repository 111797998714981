import { i18n } from 'i18n';

/**
 * List of FE implemented category URIs.
 * Currently:
 * - device_information,
 * - filevault,
 * - startup_settings,
 * - launch_agents_and_daemons,
 * - activation_lock
 * - system_extensions
 * - gatekeeper_and_xprotect
 * - transparency_database
 * - kernel_extensions
 * - local_users
 * - installed_profiles
 * - application_firewall
 * - apps
 */
export const IMPLEMENTED_URIS = [
  'device_information',
  'filevault',
  'startup_settings',
  'launch_agents_and_daemons',
  'activation_lock',
  'system_extensions',
  'gatekeeper_and_xprotect',
  'transparency_database',
  'kernel_extensions',
  'local_users',
  'installed_profiles',
  'application_firewall',
  'apps',
  'desktop_and_screensaver',
  'certificates',
];

const DISPLAY_NAMES = {
  device_information: 'Devices',
  filevault: 'FileVault',
  startup_settings: 'Startup Settings',
  launch_agents_and_daemons: 'Launch Agents and Daemons',
  activation_lock: 'Activation Lock',
  system_extensions: 'System Extensions',
  gatekeeper_and_xprotect: 'Gatekeeper and XProtect',
  transparency_database: 'Transparency Database',
  kernel_extensions: 'Kernel Extensions',
  local_users: 'Local Users',
  application_firewall: 'Application Firewall',
  apps: 'Apps',
  installed_profiles: 'Installed Profiles',
  desktop_and_screensaver: 'Desktop & Screensaver',
  certificates: 'Certificates',
};

export const getDisplayName = (uri: string): string => {
  if (uri in DISPLAY_NAMES) {
    return i18n.t(DISPLAY_NAMES[uri]);
  }
  return uri;
};
