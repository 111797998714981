/* istanbul ignore file */

import { i18n } from 'i18n';
import React from 'react';

const entry = ({ details }, { connectorList }) => {
  const pendingMessage = `${i18n.t('Connector details are pending')}...`;
  const { connector } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector) || {};

  return {
    icon: 'wifi',
    iconText: i18n.t('Connector online'),
    text: i18n.t('Connector online'),
    description: name || bound_domain || pendingMessage,
  };
};

const detail = ({ action_type, details }, { connectorList }) => {
  const pendingMessage = `${i18n.t('Connector details are pending')}...`;
  const { connector, time } = details;

  const { name, bound_domain } =
    connectorList?.find(({ id }) => id === connector) || {};

  return (
    <div className={`activity-entry-details ${action_type}`}>
      <p>
        {i18n.t('Online on:')} {i18n.format.datetime(time)}
      </p>
      <p>
        {i18n.t('Connector:')} {name || bound_domain || pendingMessage}
      </p>
    </div>
  );
};

export default { entry, detail };
