/* istanbul ignore file */
import { Box, Flex, Hint, styled } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import { i18n } from 'i18n';
import { useCallback, useState } from 'react';
import KandjiSupportLink from 'src/app/components/common/KandjiSupportLink';
import { LockScreenLexical } from './LockScreenLexical';
import OrientationSwitcher from './OrientationSwitcher';
import './MessageConfig.css';
import type { LockScreenMessageModel } from '../lock-screen-message.types';

const UpperText = styled(Flex, {
  marginTop: '20px',
  justifyContent: 'space-between',
});

const HelpMessage = styled(Box, {
  width: '40%',
  float: 'right',
  marginTop: '14px',
});

const HintContainer = styled(Box, {
  marginTop: '5px',
});

const DeviceTypes = {
  IPAD_PORTRAIT: 'IPAD_PORTRAIT',
  IPAD_LANDSCAPE: 'IPAD_LANDSCAPE',
  IPHONE: 'IPHONE',
};

const DeviceNames = {
  IPAD_PORTRAIT: 'iPad',
  IPAD_LANDSCAPE: 'iPad',
  IPHONE: 'iPhone',
};

const deviceClassMap = {
  [DeviceTypes.IPHONE]: 'iphone-frame',
  [DeviceTypes.IPAD_PORTRAIT]: 'ipad-portrait-frame',
  [DeviceTypes.IPAD_LANDSCAPE]: 'ipad-landscape-frame',
};

const MessageConfig = ({ isDisabled, update, lockScreenFootnote }) => {
  const [previewFootnote, setPreviewFootnote] = useState(
    lockScreenFootnote || 'Your message here',
  );

  const [orientation, setOrientation] = useState(DeviceTypes.IPHONE);
  const charLimit = orientation === DeviceTypes.IPHONE ? 60 : 160;
  const charCount = lockScreenFootnote ? lockScreenFootnote.length : 0;

  const handleUpdate = useCallback(
    (field: keyof LockScreenMessageModel, value: any) => {
      if (field === 'lockScreenFootnote') {
        value = String(value); // Ensure the value is a string
      }
      update(value);
    },
    [update],
  );

  const handleEditorChange = (newEditorState: string) => {
    if (newEditorState.length < 200) {
      handleUpdate('lockScreenFootnote', newEditorState);
      setPreviewFootnote(newEditorState);
    }
  };

  const formattedText =
    previewFootnote.length > charLimit
      ? {
          placeholder: false,
          text: `${previewFootnote.substring(0, charLimit)}...`,
        }
      : {
          placeholder: previewFootnote.length === 0,
          text:
            previewFootnote.length === 0
              ? 'Your message here'
              : previewFootnote,
        };

  const hintLabel =
    previewFootnote.length > charLimit
      ? i18n.t('This message may get truncated on ') + DeviceNames[orientation]
      : '';

  const supportLink = (
    <KandjiSupportLink
      href="https://support.kandji.io/support/solutions/articles/72000636327"
      text={i18n.t('Learn more')}
      className="b-alink"
    />
  );

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('General')}</h3>
      </Setting.Header>
      <Setting.Rows style={{ borderBottom: 'solid 1px #efefef' }}>
        <p className="b-txt the-recommended">
          {i18n.t(
            'Enter a custom message that will appear on the Lock Screen of iPhone and iPad (and on the login screen of Shared iPad). You can use Kandji Global Variables by typing a dollar sign ($) to make the message dynamic and adapted to each device.',
          )}{' '}
          {supportLink}
        </p>
      </Setting.Rows>

      <Box
        id="Settings"
        style={{ padding: 'var(--b-gap2)', marginTop: '-20px' }}
      >
        <UpperText>
          <Box style={{ width: '50%' }}>
            <Box className="input-label">{i18n.t('Lock Screen message')}</Box>
            <LockScreenLexical
              handleEditorChange={handleEditorChange}
              lockScreenFootnote={lockScreenFootnote}
              isDisabled={isDisabled}
            />
            <HintContainer>
              <Hint
                label={hintLabel}
                variant={charCount < charLimit ? 'default' : 'warning'}
                charCounter={{ count: charCount, max: charLimit }}
              />
            </HintContainer>
          </Box>
          <HelpMessage>
            {i18n.t(
              'This message will truncate, beginning around 60 characters for iPhone and around 160 characters for iPad. Use the preview below to estimate how your message may appear on a device.',
            )}
          </HelpMessage>
        </UpperText>

        <Box id="Settings" className="settings-box">
          <Box>
            <br />
            <Box className="outer-preview-box">
              <Box className="inner-preview-box">
                <Box className="preview-text">{i18n.t('Preview')}</Box>
                <Box className="switcher-container">
                  <OrientationSwitcher
                    disabled={isDisabled}
                    orientation={orientation}
                    setOrientation={setOrientation}
                  />
                </Box>
                <Box className="device-container-lsm">
                  <Box className="device-wrapper">
                    <Box className="device-frame-container">
                      <Box className={deviceClassMap[orientation]}>
                        <Box className="lockscreen-text">
                          {i18n.t('Swipe up to open')}
                        </Box>
                        <Box className="lockscreen-message">
                          {formattedText.text}
                        </Box>
                        <Box className="swipe-bar" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Setting.Card>
  );
};

export default MessageConfig;
