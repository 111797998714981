import { Button, Flex, Separator, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'src/i18n';

type ThreatListTableBulkActionProps = Readonly<{
  dataCount: number | undefined;
  selectedCount?: number;
  isLoadingForSelecting: boolean;
  isExporting: boolean;
  isAnyRowSelected: boolean;
  onClearAllSelected: () => void;
  onAllSelected: () => void;
  onExport: () => void;
}>;

const ThreatListTableBulkAction = (props: ThreatListTableBulkActionProps) => {
  const {
    dataCount,
    selectedCount = 0,
    isLoadingForSelecting,
    isExporting,
    isAnyRowSelected,
    onClearAllSelected,
    onAllSelected,
    onExport,
  } = props;

  const isAllRowsSelected = selectedCount === dataCount;

  if (!isAnyRowSelected) {
    return null;
  }

  return (
    <Flex>
      <Flex gap="sm" alignItems="center">
        <Button
          compact
          variant="default"
          onClick={onExport}
          disabled={isLoadingForSelecting}
          loading={isExporting}
        >
          {i18n.t('Export CSV')}
        </Button>
      </Flex>
      <Separator
        orientation="vertical"
        css={{
          margin: '0 18px',
          background: '$neutral20',
        }}
      />
      <Flex gap="sm" alignItems="center">
        <Text size="2" variant="description">
          {i18n.t('{selectedCount} selected', { selectedCount })}
        </Text>
        <Button
          compact
          variant="subtle"
          css={{ color: '$blue50' }}
          onClick={onClearAllSelected}
          disabled={isLoadingForSelecting}
        >
          {i18n.t('Clear')}
        </Button>
        {!isAllRowsSelected && (
          <>
            <Separator
              orientation="vertical"
              css={{
                background: '$neutral20',
              }}
            />
            <Button
              compact
              variant="subtle"
              css={{ color: '$blue50' }}
              onClick={onAllSelected}
              loading={isLoadingForSelecting}
              disabled={isExporting}
            >
              {isLoadingForSelecting
                ? i18n.t('Selecting...')
                : i18n.t('Select all {dataCount}', { dataCount })}
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default ThreatListTableBulkAction;
