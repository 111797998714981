import {
  Box,
  Card,
  Chip,
  Flex,
  Paragraph,
  Text,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'src/i18n';
import ThreatEmptyIcon from '../../assets/threat-empty-v3.svg';
import { constants } from '../../common';
import ThreatListLoader from '../../common/components/ThreatListLoader';
import type { ThreatStatus } from '../../threat.types';
import ThreatListPieChart from './ThreatListPieChart';

const getStatusLabel = (status: ThreatStatus) =>
  constants.THREAT_STATUS_DISPLAY_NAME_MAP(status);

const getStatusColor = (status: ThreatStatus) =>
  constants.THREAT_STATUS_COLOR_MAP[status];

const getPieColor = (status: ThreatStatus) =>
  `var(--colors-${getStatusColor(status)}50)`;

const ThreatTileCard = styled(Card, {
  display: 'flex',
  flex: 1,
  minHeight: 172,
});

const StatusListItemBullet = styled(Box, {
  width: '$3',
  height: '$3',
  borderRadius: '50%',
});

const TileHeader = styled(Text, {
  color: '$neutral70',
  fontSize: '$3',
});

const chipStyledCss = {
  padding: '0 5px',
  border: '1px solid $neutral30',
  fontWeight: '$regular',
  color: '$neutral80',
  backgroundColor: '$neutral0',
};

type ThreatListTileCardProps = {
  title: string;
  dateRangeLabel: string | undefined;
  totalCount: number;
  counts: Record<ThreatStatus, number>;
  isLoading: boolean;
  displayDetectionPeriod: string;
};

const StatusListItem = (props: {
  label: string;
  count: number;
  bulletColor: string;
}) => {
  const { label, count, bulletColor } = props;

  return (
    <Flex gap="sm" alignItems="center">
      <StatusListItemBullet css={{ backgroundColor: bulletColor }} />
      <Text css={{ color: '$neutral70' }}>
        {label} - {count}
      </Text>
    </Flex>
  );
};

const ThreatListTileCard = (props: ThreatListTileCardProps) => {
  const {
    title,
    dateRangeLabel,
    totalCount,
    counts,
    isLoading,
    displayDetectionPeriod,
  } = props;

  const isEmpty = totalCount === 0;

  const preparedData = Object.entries(counts).map(([key, value]) => ({
    color: getPieColor(key as ThreatStatus),
    value,
  }));

  const loader = (
    <Box mb6>
      <ThreatListLoader />
    </Box>
  );

  const emptyState = (
    <Flex
      flow="column"
      alignItems="center"
      justifyContent="space-between"
      flex={1}
      pt6
      css={{ height: '100%' }}
    >
      <img
        src={ThreatEmptyIcon}
        alt={i18n.t('No results')}
        style={{ width: 153 }}
      />
      <Paragraph css={{ textAlign: 'center' }}>
        {displayDetectionPeriod}
      </Paragraph>
    </Flex>
  );

  const charts = (
    <>
      <Flex flow="column" justifyContent="center">
        <ThreatListPieChart
          data={preparedData}
          label={totalCount.toLocaleString()}
        />
      </Flex>
      <Flex
        flow="column"
        gap="sm"
        justifyContent="center"
        css={{ minWidth: 190 }}
      >
        {Object.entries(counts).map(([key, value]) => (
          <StatusListItem
            key={key}
            label={getStatusLabel(key as ThreatStatus)}
            count={value}
            bulletColor={`$${getStatusColor(key as ThreatStatus)}50`}
          />
        ))}
      </Flex>
    </>
  );

  return (
    <ThreatTileCard>
      <Flex
        flex={1}
        flow="column"
        gap="lg"
        justifyContent="space-between"
        data-testid="chart-tile"
      >
        <Flex flow="row" justifyContent="space-between" gap="lg">
          <TileHeader css={{ fontWeight: '$medium', lineHeight: '$3' }}>
            {title}
          </TileHeader>
          {dateRangeLabel && (
            <Chip label={dateRangeLabel} css={chipStyledCss} />
          )}
        </Flex>
        <Flex
          flex={1}
          alignItems="center"
          style={{ justifyContent: 'space-evenly' }}
        >
          {isLoading ? loader : isEmpty ? emptyState : charts}
        </Flex>
      </Flex>
    </ThreatTileCard>
  );
};

export default ThreatListTileCard;
