import {
  Box,
  Button,
  Dialog,
  Flex,
  Label,
  Paragraph,
  Text,
  TextArea,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { i18n } from 'src/i18n';
import useGetReleaseDetails from '../../common/hooks/use-get-release-details';
import { ThreatService } from '../../data-service';
import ThreatListLoader from './ThreatListLoader';

const FieldGroup = styled(Flex, {
  gap: '$2',
  flexDirection: 'column',
  mb: '$2',
});

const SeparatorLine = styled(Box, {
  height: 1,
  backgroundColor: '$neutral20',
  width: '100%',
});

const LoaderWrapper = styled(Flex, {
  position: 'absolute',
  inset: '60px 0px 0px 0px',
  backgroundColor: '$neutral0',
});

type EventFormModel = {
  itemName: string;
  note?: string;
  consent?: string;
};

type ThreatListReleaseModalProps = {
  name: string;
  fileHash: string;
  deviceId: string;
  libraryItemId: string | undefined;
  onClose: () => void;
  onRelease: (deviceCount: number) => void;
  onError: (type: string) => void;
};

const ThreatListReleaseModal = (props: ThreatListReleaseModalProps) => {
  const {
    name,
    fileHash,
    deviceId,
    libraryItemId,
    onClose,
    onRelease,
    onError,
  } = props;

  const {
    data: dataThreatDetails,
    isLoading: isLoadingDetails,
    isError: isErrorFetchingDetails,
  } = useGetReleaseDetails(deviceId, fileHash);

  if (isErrorFetchingDetails) {
    onError('release-details-fetch-error');
    return null;
  }

  const libraryItemName = dataThreatDetails?.library_item_name;
  const isAlreadyAllowed = dataThreatDetails?.library_item_is_threat_allowed;
  const devicesToBeReleased = dataThreatDetails?.device_ids;
  const deviceCount = dataThreatDetails?.device_count || 0;

  const threatService = useMemo(() => new ThreatService(), []);
  const [isReleasing, setIsReleasing] = useState<boolean>(false);

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
  } = useForm<EventFormModel>({
    mode: 'onChange',
  });

  const handleRelease = () => {
    setIsReleasing(true);

    if (!fileHash || !devicesToBeReleased) {
      onError('release-error');
      return;
    }

    handleSubmit((model) => {
      threatService
        .createThreatRelease({
          is_adding_to_allow_list: !isAlreadyAllowed,
          descriptive_name: model.itemName || '',
          file_hash: fileHash,
          device_ids: devicesToBeReleased,
          release_note: model.note || '',
        })
        .then(() => {
          onRelease(deviceCount);
        })
        .catch(() => onError('release-error'))
        .finally(() => {
          setIsReleasing(false);
        });
    })();
  };

  if (!name || !fileHash) {
    return null;
  }

  const headerDescription = isAlreadyAllowed
    ? i18n.t('This threat has already been added to the Allow list.')
    : i18n.t(
        'To release this threat from quarantine, you will need to add it to your Allow list.',
      );

  const footerDescription = isAlreadyAllowed
    ? i18n.t(
        'I understand this threat will be released from quarantine on all devices assigned to Blueprints containing the Avert Library Item titled {libraryItemName}.',
        { libraryItemName },
      )
    : i18n.t(
        'I understand this file hash will be added to the Allow list in the Avert Library Item titled {libraryItemName} and released from quarantine.',
        { libraryItemName },
      );

  const content = (
    <Flex flow="column" gap="lg" mt3>
      <Paragraph css={{ mb: '$1' }}>{headerDescription}</Paragraph>
      <FieldGroup>
        <Label above>{i18n.t('Threat name')}</Label>
        <Text>{name}</Text>
      </FieldGroup>
      <FieldGroup>
        <Label above>{i18n.t('File hash')}</Label>
        <Text>{fileHash}</Text>
      </FieldGroup>
      <FieldGroup>
        <Label above>{i18n.t('Avert Library Item')}</Label>
        <Text>
          <Link
            to={`/library/avert/${libraryItemId}`}
            target="_blank"
            style={{ color: 'var(--colors-blue50)' }}
          >
            {libraryItemName}
          </Link>{' '}
          {i18n.t('{deviceCount} {count} impacted', {
            deviceCount,
            count: i18n.t('{count, plural, one {device} other {devices}}', {
              count: deviceCount,
            }),
          })}
        </Text>
      </FieldGroup>
      {!isAlreadyAllowed && (
        <TextField
          {...register('itemName', {
            shouldUnregister: true,
            required: i18n.t('Required'),
          })}
          autoFocus
          label={i18n.t('Item name')}
          placeholder={i18n.t('Enter a descriptive name for the item.')}
          maxLength={64}
          state={errors?.itemName?.message ? 'error' : 'default'}
          hint={{
            ...(errors?.itemName?.message && {
              label: errors.itemName.message,
            }),
          }}
          data-testid="item-name-field"
        />
      )}
      <TextArea
        {...register('note', {
          shouldUnregister: true,
          required: i18n.t('Required'),
        })}
        label={i18n.t('Note')}
        placeholder={i18n.t(
          'Describe why the file is being released. For internal use only.',
        )}
        maxLength={256}
        state={errors?.note?.message ? 'error' : 'default'}
        hint={{
          ...(errors?.note?.message && { label: errors.note.message }),
        }}
        data-testid="note-field"
      />
      <SeparatorLine />
      <TextField
        {...register('consent', {
          shouldUnregister: true,
          required: i18n.t('Required'),
          validate: (value: string | undefined) =>
            value === 'RELEASE' || i18n.t('Invalid value'),
        })}
        label={i18n.t('Type {release} to release this threat', {
          release: 'RELEASE',
        })}
        placeholder="RELEASE"
        maxLength={16}
        state={errors?.consent?.message ? 'error' : 'default'}
        hint={{
          ...(errors?.consent?.message && { label: errors.consent.message }),
        }}
        data-testid="consent-field"
      />
      <Paragraph variant="subtle">{footerDescription}</Paragraph>
      {isLoadingDetails && (
        <LoaderWrapper justifyContent="center" alignItems="center">
          <ThreatListLoader label={i18n.t('Loading threat details')} />
        </LoaderWrapper>
      )}
    </Flex>
  );

  const footer = (
    <Flex justifyContent="end" mx3>
      <Flex gap="sm">
        <Button onClick={onClose} variant="subtle">
          {i18n.t('Cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={handleRelease}
          disabled={!isValid || isReleasing}
        >
          {!isReleasing
            ? isAlreadyAllowed
              ? i18n.t('Release')
              : i18n.t('Add and Release')
            : i18n.t('Releasing...')}
        </Button>
      </Flex>
    </Flex>
  );

  return (
    <Dialog
      isOpen
      onOpenChange={onClose}
      css={{ width: 592 }}
      title={i18n.t('Release threat')}
      content={content}
      footer={footer}
    />
  );
};

export default ThreatListReleaseModal;
