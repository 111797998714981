/* istanbul ignore file */
import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'features/library-items/data-service/library-item/devices';
import configs from 'features/library-items/library/library-item-configurations/items/profile';
//import { api } from 'src/app/api/base';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import type {
  LockScreenMessageApiData,
  LockScreenMessageModel,
} from '../lock-screen-message.types';

type SendToApi = {
  name: string;
  active: boolean;
  blueprints: string[];
  is_all_blueprints: boolean;
  type?: string;
  identifier?: string;
  skip_blueprint_conflict: boolean;
  reassign_lib_item_to_bp: boolean;
  rules: object | null;
  data?: {
    LockScreenFootnote: string;
  };
};

async function transformFromApi(apiData: LockScreenMessageApiData) {
  const allBlueprintOptions = await getAllBlueprintOptions(
    apiData.blueprints.concat(apiData.excluded_blueprints || []),
  );

  const selectedBlueprints = allBlueprintOptions.filter(
    (option: { value: string }) => apiData.blueprints.includes(option.value),
  );

  const excludedBlueprints = allBlueprintOptions.filter(
    (option: { value: string }) =>
      apiData.excluded_blueprints?.includes(option.value),
  );

  const result = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    devices: getSelectedDevicesFromRunsOn(apiData),
    isActive: apiData.active,
    rules: apiData.rules,
    lockScreenFootnote: apiData.data.LockScreenFootnote,
  };

  return {
    ...apiData,
    data: result,
  };
}

async function transformToApi(model: LockScreenMessageModel) {
  const runsOn = getRunsOnFromModel(model);

  const toSend: SendToApi = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b: { value: string }) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    skip_blueprint_conflict: model.skip_blueprint_conflict,
    reassign_lib_item_to_bp: true,
    data: {
      LockScreenFootnote: model.lockScreenFootnote,
    },
    rules: model.rules || null,
  };

  // Populate `runs_on_*` fields
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  if (!model.id) {
    const itemConfig = configs.LockScreenMessage;
    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }
  return toSend;
}

export { transformFromApi, transformToApi };
