import { Button, ButtonGroup, setClass } from '@kandji-inc/bumblebee';
import { KandjiSetupCtx } from 'features/library-items/items/kandji-setup/kandji-setup.context';
import { useContext, useState } from 'react';

import { Flex, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'src/i18n';
import { Screen } from '../components/mac-screen';
import CustomizePanel from '../customize-panel';
import useCompanyLogo from '../hooks/use-company-logo.hook';
import setPreviewImage, { previewPaths } from '../set-preview-image';
import ToggleButton from '../toggle-button';
import { AppList, AppListFooter } from './app-list';

const InstallScreen = (props) => {
  const { isDarkMode, setIsDarkMode, isDisabled, setGlobalValidations } =
    useContext(KandjiSetupCtx);
  const { model, setModel, initialStateCopy, beforeEditModel } = props;

  const form = {
    key: 'installScreen',
    title: i18n.t('Customize Install screen'),
    header: {
      label: i18n.t('Header'),
      maxLength: 35,
    },
    subHeader: {
      label: i18n.t('Subheader'),
      maxLength: 205,
    },
  };

  const [isInstallConfig, setIsInstallConfig] = useState(true);

  const onValidations = (v) => {
    setGlobalValidations((p) => ({ ...p, [v.name]: v.isInvalid }));
  };

  const companyLogo = useCompanyLogo({ model, isDarkMode });

  return (
    <div className="b-library__setting-card">
      <h3 className="b-h3 b-library__setting-card--title">
        {i18n.t(`Install screen`)}
      </h3>
      <p className="b-txt b-library__setting-card--subtitle">
        {i18n.t(`Customize the header and subheader text on the Install screen. Installed
        items shown in the preview are for reference only.`)}
      </p>
      <div className="b-library__setting-rows">
        <div className="b-library__setting-row--single-col">
          <div className="b-flex-vc b-flex-btw">
            <div className="b-flex-g b-flex-vc">
              <CustomizePanel
                form={form}
                model={model}
                setModel={setModel}
                beforeEditModel={beforeEditModel}
                initialStateCopy={initialStateCopy}
                disabled={isDisabled}
                multiLineTextAllowed={false}
                onValidations={onValidations}
                autoSubheader
              />
              <Button
                onClick={() => setIsInstallConfig((p) => !p)}
                icon={isInstallConfig ? 'angle-up' : 'angle-down'}
                kind="link"
                size="small"
                data-testid="install-screen:configbutton"
              >
                {isInstallConfig
                  ? i18n.t('Collapse preview')
                  : i18n.t('Expand preview')}
              </Button>
            </div>
            <div
              className={setClass(
                'k-ks-toggle',
                !isInstallConfig && '--hidden',
              )}
            >
              <ButtonGroup
                initialSelected={isDarkMode ? 1 : 0}
                activeWatch={isDarkMode ? 1 : 0}
                onToggle={(index) => setIsDarkMode(index !== 0)}
              >
                <ToggleButton
                  className="b-txt k-ks-dark-blue"
                  onClick={() => setIsDarkMode(false)}
                >
                  {i18n.t(`Light Mode`)}
                </ToggleButton>
                <ToggleButton
                  className="b-txt k-ks-dark-blue"
                  onClick={() => setIsDarkMode(true)}
                >
                  {i18n.t(`Dark Mode`)}
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div>
          <div
            className={setClass([
              'k-library-preview',
              !isInstallConfig ? 'k-library-preview--collapsed' : '',
            ])}
          >
            <div className="k-library-preview__bg">
              <img
                draggable="false"
                src={setPreviewImage({
                  isDarkMode,
                  displayMode: model.general.displayMode,
                })}
                alt={i18n.t('Preview')}
              />
              <Screen.Root
                model={model}
                companyLogo={companyLogo}
                isDarkMode={isDarkMode}
              >
                <Screen.Header>
                  <img
                    draggable="false"
                    src={companyLogo.src}
                    alt={i18n.t('Company Logo')}
                  />
                </Screen.Header>
                <Screen.Body>
                  {({ isDarkMode }) => {
                    return (
                      <Flex flow="column">
                        <Text
                          css={{
                            fontFamily: 'SF Pro Display',
                            fontWeight: 700,
                            fontSize: '26px',
                            lineHeight: '32px',
                            color: isDarkMode
                              ? 'rgba(255,255,255, 0.85)'
                              : 'rgba(0, 0, 0, 0.85)',
                          }}
                        >
                          {model.installScreen.header}
                        </Text>
                        <Flex
                          css={{
                            justifyContent: 'center',
                            paddingBottom: '14px',
                          }}
                        >
                          <Text
                            css={{
                              padding: '0 165px',
                              fontSize: '13px',
                              lineHeight: '16px',
                              fontFamily: 'SF Pro Text',
                              color: isDarkMode
                                ? 'rgba(255,255,255, 0.85)'
                                : 'rgba(0, 0, 0, 0.85)',
                            }}
                          >
                            {model.installScreen.subHeader}
                          </Text>
                        </Flex>
                        <AppList isDarkMode={isDarkMode} />
                      </Flex>
                    );
                  }}
                </Screen.Body>
                <Screen.Footer>
                  {({ isDarkMode }) => {
                    return <AppListFooter isDarkMode={isDarkMode} />;
                  }}
                </Screen.Footer>
              </Screen.Root>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallScreen;
