import { type LocaleLanguageOptionList, i18n } from 'i18n';

const findOption = (value, options) => options.find((v) => v.value === value);

const optionsCountRange = [
  { value: '110', label: '1-10' },
  { value: '1150', label: '11-50' },
  { value: '51100', label: '51-100' },
  { value: '101200', label: '101-200' },
  { value: '201500', label: '201-500' },
  { value: '5001000', label: '501-1,000' },
  { value: '10015000', label: '1,001-5,000' },
  { value: '500110000', label: '5,001-10,000' },
  { value: '1000110000', label: '10,001-20,000' },
  { value: '20000', label: '20,000+' },
];

const optionsIndustryFactory = () => [
  {
    value: 'advertisingmarketingservices',
    label: i18n.t('Advertising & Marketing Services'),
  },
  { value: 'agriculture', label: i18n.t('Agriculture') },
  {
    value: 'biotechpharmaceuticals',
    label: i18n.t('Biotech & Pharmaceuticals'),
  },
  { value: 'computeritservices', label: i18n.t('Computer & IT Services') },
  {
    value: 'constructioncontracting',
    label: i18n.t('Construction & Contracting'),
  },
  { value: 'consultingservices', label: i18n.t('Consulting Services') },
  { value: 'distributionwholesale', label: i18n.t('Distribution & Wholesale') },
  { value: 'education', label: i18n.t('Education') },
  { value: 'energyutilities', label: i18n.t('Energy/Utilities') },
  {
    value: 'engineeringarchitecturedesign',
    label: i18n.t('Engineering, Architecture & Design'),
  },
  { value: 'financeaccounting', label: i18n.t('Finance & Accounting') },
  { value: 'government', label: i18n.t('Government') },
  { value: 'healthcareservices', label: i18n.t('Healthcare Services') },
  { value: 'insurance', label: i18n.t('Insurance') },
  {
    value: 'logistictransportationservices',
    label: i18n.t('Logistic & Transportation Services'),
  },
  { value: 'manufacturing', label: i18n.t('Manufacturing') },
  { value: 'mediapublishing', label: i18n.t('Media/Publishing') },
  { value: 'nonprofit', label: i18n.t('Nonprofit') },
  { value: 'other', label: i18n.t('Other') },
  { value: 'professionalservices', label: i18n.t('Professional Services') },
  { value: 'realestate', label: i18n.t('Real Estate') },
  { value: 'restauranthospitality', label: i18n.t('Restaurant & Hospitality') },
  { value: 'retail', label: i18n.t('Retail') },
  {
    value: 'staffingfirmrecruitingagency',
    label: i18n.t('Staffing Firm/Recruiting Agency'),
  },
  { value: 'techsoftware', label: i18n.t('Tech/Software') },
  { value: 'telecommunications', label: i18n.t('Telecommunications') },
];

const optionsLocaleLanguages = Object.entries(
  i18n.LOCALE_LANGUAGE_DESCRIPTORS,
).map(([key, label]) => ({
  value: key,
  label,
})) as unknown as LocaleLanguageOptionList;

const localizedUserProfileUpdateMessage = {
  [i18n.LOCALES.en_US]: 'User was updated',
  [i18n.LOCALES.en_GB]: 'User was updated',
  [i18n.LOCALES.es_419]: 'El usuario fue actualizado',
  [i18n.LOCALES.de]: 'Benutzer wurde aktualisiert',
  [i18n.LOCALES.ja_JP]: 'ユーザーが更新されました',
};

export {
  findOption,
  optionsCountRange,
  optionsIndustryFactory,
  optionsLocaleLanguages,
  localizedUserProfileUpdateMessage,
};
