import type { CSS } from '@kandji-inc/nectar-ui';
import {
  Box,
  Chip,
  Flex,
  Label,
  Tooltip,
  TooltipProvider,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';
import { MAX_DEVICE_FAMILIES } from 'src/features/visibility/prism/utils/constants';

type FilterChipsProps = {
  label: string;
  chipLabel: string;
  chipCss?: CSS;
  filterKey: 'device_families' | 'blueprints';
  selectedFilters:
    | {
        id: string;
        name: string;
      }[]
    | undefined;
};

const MAX_DISPLAYED_FILTERS = 6;

const StyledList = styled('ul', {
  padding: '0 6px',
  display: 'flex',
  flexDirection: 'column',
  gap: '$sm',
});

const FilterChips = ({
  label,
  chipLabel,
  chipCss = {},
  selectedFilters = [],
  filterKey,
}: FilterChipsProps) => {
  const [shouldTruncate, setShouldTruncate] = React.useState(false);
  const displayCount = MAX_DISPLAYED_FILTERS;

  const innerChipCss = {
    border: 'none',
    boxShadow: '$colors$neutral20 0px 0px 0px 1px inset',
    backgroundColor: '$neutral0',
    ...chipCss,
  };

  React.useEffect(() => {
    if (selectedFilters.length > MAX_DISPLAYED_FILTERS) {
      setShouldTruncate(true);
    } else {
      setShouldTruncate(false);
    }
  }, [selectedFilters]);

  return (
    <TooltipProvider>
      <Flex flow="column" gap="sm">
        <Label variant="description">{label}</Label>
        <Flex gap="sm" wrap="wrap" id={`chips-${filterKey}`}>
          {(filterKey === 'device_families' &&
            selectedFilters.length === MAX_DEVICE_FAMILIES) ||
          selectedFilters.length === 0 ? (
            <Chip
              label={i18n.t('All {chipLabel}', { chipLabel })}
              iconLeft={{ icon: getIcon(filterKey), size: 'sm' }}
              color="default"
              css={innerChipCss}
            />
          ) : (
            selectedFilters.slice(0, displayCount).map((filter) => (
              <Chip
                key={filter.id}
                label={filter.name || filter.id}
                iconLeft={{
                  icon: getIcon(filterKey, filter.name || filter.id),
                  size: 'sm',
                }}
                color="default"
                css={innerChipCss}
              />
            ))
          )}
          {shouldTruncate && (
            <Tooltip
              css={{
                zIndex: 5,
              }}
              // @ts-expect-error -- TODO fix this
              content={
                <Box
                  css={{
                    padding: '0 8px',
                  }}
                >
                  <StyledList css={{ mb: 0 }}>
                    {selectedFilters.slice(displayCount).map((filter) => (
                      <li key={filter.id}>{filter.name || filter.id}</li>
                    ))}
                  </StyledList>
                </Box>
              }
              side="bottom"
            >
              <Chip
                label={`+${selectedFilters.length - displayCount}`}
                color="default"
                aria-describedby="tooltip"
                css={innerChipCss}
              />
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </TooltipProvider>
  );
};

export default FilterChips;

export const getIcon = (filterKey: string, filterName?: string) => {
  if (!filterName || filterKey === 'blueprints') {
    switch (filterKey) {
      case 'device_families':
        return 'laptop-mobile';
      case 'blueprints':
        return 'kandji-blueprint';
      default:
        return 'laptop-mobile';
    }
  } else {
    switch (filterName) {
      case 'Mac':
        return 'laptop-mobile';
      case 'iPhone':
        return 'sf-iphone';
      case 'iPad':
        return 'sf-ipad-landscape';
      case 'Apple TV':
        return 'sf-apple-tv';
      default:
        return 'laptop-mobile';
    }
  }
};
