import { datadogLogs } from '@datadog/browser-logs';
/* istanbul ignore file */
import { useMutation } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';
import type { ExportAllVulnerabilitiesRequestSchema } from '../vulnerability.types';

const useCreateVulnerabilitiesExportMutation = () => {
  return useMutation({
    mutationFn: exportVulnerabilities,
  });
};

const exportVulnerabilities = async (
  body: ExportAllVulnerabilitiesRequestSchema,
) => {
  const client = vulnerabilityApi?.('v1/export');

  if (!client) {
    throw new Error('Vulnerability API not found');
  }

  const res = await client.post({ view: 'vulnerabilities', ...body });

  try {
    return { ...res.data, id: res.data.export_id };
  } catch (e) {
    datadogLogs.logger.error('Error exporting Vulnerabilities', {
      error: e,
    });
  }
};

export { useCreateVulnerabilitiesExportMutation };
