/* istanbul ignore file */
import {
  $t,
  ProviderNames,
  getI18nJSLocale,
  getI18nLanguage,
  getI18nLocale,
  getI18nShouldUseRelativeDates,
  getI18nTimeZone,
  render,
  setI18nLocale,
  setI18nProvider,
  setI18nShouldUseRelativeDates,
  setI18nTimeZone,
  t,
  ut,
} from '@kandji-inc/nectar-i18n';
import { getEnv } from 'src/util';
import { common } from './common';
import { format } from './format';

type SupportedLocales = readonly ['en_US', 'en_GB', 'es_419', 'de', 'ja_JP'];

type SupportedLocaleRecord = {
  [LocaleKey in SupportedLocales[number]]: LocaleKey;
};

export type LocaleLanguageOption<
  LocaleKey extends
    keyof typeof LOCALE_LANGUAGE_DESCRIPTORS = keyof typeof LOCALE_LANGUAGE_DESCRIPTORS,
> = {
  value: LocaleKey;
  label: (typeof LOCALE_LANGUAGE_DESCRIPTORS)[LocaleKey];
};

export type LocaleLanguageOptionList = {
  [LocaleKey in Exclude<
    keyof SupportedLocales,
    keyof string[]
  >]: LocaleLanguageOption<SupportedLocales[LocaleKey]>;
} & Array<LocaleLanguageOption<SupportedLocales[number]>>;

const LOCALES = {
  en_US: 'en_US',
  en_GB: 'en_GB',
  es_419: 'es_419',
  de: 'de',
  ja_JP: 'ja_JP',
} as const satisfies SupportedLocaleRecord;

const DEFAULT_LOCALE = LOCALES.en_US;

/**
 * Human-readable locale names, used for displaying locale names in the
 * user preferences language selection and should not be translated to show
 * recognizable language choices to the user.
 */
const LOCALE_LANGUAGE_DESCRIPTORS = {
  en_US: 'English (US)',
  en_GB: 'English (UK)',
  es_419: 'Español (Latinoamérica)',
  de: 'Deutsch',
  ja_JP: '日本語 (日本)',
} as const;

const createMap =
  <TMap extends Record<string, () => string>>(map: TMap) =>
  (variable: keyof TMap) => {
    if (variable in map) {
      const translationFn = map[variable];
      if (translationFn) {
        return translationFn();
      }
    }

    const isDev = getEnv() === 'dev';
    if (isDev) {
      throw new Error(`Missing translation for variable: ${String(variable)}`);
    }
    console.error('Missing translation for variable: ', variable);

    return variable;
  };

let i18nEnabled = false;

// temporary until we enable i18n globally
const setProvider = (providerName: string) => {
  if ([ProviderNames.qa, ProviderNames.transifex].includes(providerName)) {
    i18nEnabled = true;
  }
  setI18nProvider(providerName);
};

export const i18n = {
  // temporary until we enable i18n globally
  isEnabled: () => i18nEnabled,
  // wrapped to consolidate imports
  setProvider,
  setLocale: setI18nLocale,
  getLocale: getI18nLocale,
  setTimeZone: setI18nTimeZone,
  getTimeZone: getI18nTimeZone,
  setShouldUseRelativeDates: setI18nShouldUseRelativeDates,
  getShouldUseRelativeDates: getI18nShouldUseRelativeDates,
  getJSLocale: getI18nJSLocale,
  getLanguage: getI18nLanguage,
  render,
  t,
  ut,
  $t,
  // extra helpers
  createMap,
  common,
  format,
  // env
  ProviderNames,
  LOCALES,
  DEFAULT_LOCALE,
  LOCALE_LANGUAGE_DESCRIPTORS,
};
