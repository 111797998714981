/* istanbul ignore file */
import { i18n } from 'i18n';
import { useCreateVulnerabilitiesExportMutation } from 'src/features/edr/vulnerability/hooks/use-create-vulnerabilities-export-mutation';
import { useVulnerabilitiesExportStatusQuery } from 'src/features/edr/vulnerability/hooks/use-vulnerability-export-status-query';
import type { ExportAllVulnerabilitiesRequestSchema } from 'src/features/edr/vulnerability/vulnerability.types';
import {
  useCreatePrismExport,
  usePrismExportQuery,
} from 'src/features/visibility/prism/hooks';
import { useCreateViewsExportMutation } from 'src/features/visibility/prism/hooks/use-create-views-export-mutation';
import { useViewsExportStatusQuery } from 'src/features/visibility/prism/hooks/use-views-export-status-query';
import { getDisplayName } from 'src/features/visibility/prism/implemented-category-uris';
import type {
  ExportPrismCategoryRequestSchema,
  ExportPrismViewRequestSchema,
} from 'src/features/visibility/prism/types/prism.types';
import {
  useCreatePulseExportMutation,
  usePulseExportQuery,
} from 'src/features/visibility/pulse/hooks';
import type { PulseExportRequestSchema } from 'src/features/visibility/pulse/types/pulse.types';

type ExportDataConfig<T> = {
  schemaType: T;
  getToastTitle: (variables: T) => string;
  getExportPayload: (variables: T) => T;
  useCreateExportMutation: () => any;
  useExportQuery: (exportId: string) => any;
};

const visibilityConfigs = {
  prism: {
    schemaType: {} as ExportPrismCategoryRequestSchema,
    getToastTitle: (variables: ExportPrismCategoryRequestSchema) =>
      getDisplayName(
        (variables as ExportPrismCategoryRequestSchema).category ??
          'device_information',
      ),
    getExportPayload: (variables: ExportPrismCategoryRequestSchema) => {
      const prismVariables = variables as ExportPrismCategoryRequestSchema;
      return {
        blueprint_ids: prismVariables.blueprint_ids ?? [],
        device_families: prismVariables.device_families ?? [],
        category: prismVariables.category ?? 'device_information',
        filter: prismVariables.filter,
        sort_by: prismVariables.sort_by,
      };
    },
    useCreateExportMutation: useCreatePrismExport,
    useExportQuery: (exportId: string) => usePrismExportQuery({ exportId }),
  },
  pulse: {
    schemaType: {} as PulseExportRequestSchema,
    getToastTitle: (variables: PulseExportRequestSchema) =>
      (variables as PulseExportRequestSchema).report_name,
    getExportPayload: (variables: PulseExportRequestSchema) => {
      const pulseVariables = variables as PulseExportRequestSchema;
      return {
        pulse_job_id: pulseVariables.pulse_job_id,
        with_results_only: pulseVariables.with_results_only,
      };
    },
    useCreateExportMutation: useCreatePulseExportMutation,
    useExportQuery: (exportId: string) => usePulseExportQuery({ exportId }),
  },
  views: {
    schemaType: {} as ExportPrismViewRequestSchema,
    getToastTitle: (variables: ExportPrismCategoryRequestSchema) =>
      (variables as ExportPrismViewRequestSchema).view_name ??
      i18n.t('Devices'),
    getExportPayload: (variables: ExportPrismViewRequestSchema) => {
      const viewVariables = variables as ExportPrismViewRequestSchema;
      return {
        view_name: viewVariables.view_name,
        blueprint_ids: viewVariables.blueprint_ids ?? [],
        device_families: viewVariables.device_families ?? [],
        filter: viewVariables.filter,
        columns: viewVariables.columns,
      };
    },
    useCreateExportMutation: useCreateViewsExportMutation,
    useExportQuery: (exportId: string) =>
      useViewsExportStatusQuery({ exportId }),
  },
};

export const exportDataConfigs: { [type: string]: ExportDataConfig<any> } = {
  ...visibilityConfigs,
  vulnerabilities: {
    schemaType: {} as ExportAllVulnerabilitiesRequestSchema,
    getToastTitle: () => i18n.t('Vulnerabilities'),
    getExportPayload: (variables: ExportAllVulnerabilitiesRequestSchema) => {
      const vulnerabilitiesVariables = variables;
      return {
        filter: vulnerabilitiesVariables.filter,
      };
    },
    useCreateExportMutation: useCreateVulnerabilitiesExportMutation,
    useExportQuery: (exportId: string) =>
      useVulnerabilitiesExportStatusQuery({ exportId }),
  },
};
