/* istanbul ignore file */
import { Button, Flex, Heading, Paragraph } from '@kandji-inc/nectar-ui';
import React from 'react';
import { noParameters } from 'src/features/blueprint-flow/assets';
import { ParameterImportButton } from 'src/features/blueprint-flow/pages/parameters/components';
import { i18n } from 'src/i18n';

const ImportParameter = ({ blueprints, onAddSettings, onImport }) => {
  return (
    <Flex flow="column" alignItems="center" justifyContent="center" hFull wFull>
      <Flex flow="column" alignItems="center" wrap="wrap" wFull>
        {noParameters}

        <Flex flow="column" alignItems="center" pt2 pb2 wFull>
          <Heading
            as="h4"
            size="4"
            css={{
              color: '$neutral90',
              fontWeight: '$medium',
              textAlign: 'center',
              lineHeight: 'var(--lineHeights-3)',
            }}
          >
            {i18n.t('Import from Blueprint')}
          </Heading>

          <Paragraph
            css={{
              color: '$neutral90',
              whiteSpace: 'normal',
              textAlign: 'center',
            }}
          >
            {i18n.t(
              'Optionally import existing App Blocking Parameter settings from a Blueprint.',
            )}
          </Paragraph>

          <Flex css={{ padding: '14px 0' }}>
            <Flex gap="sm">
              <Button variant="default" onClick={onAddSettings}>
                {i18n.t('Add Settings')}
              </Button>
              <ParameterImportButton
                blueprints={blueprints}
                disabled={false}
                onImport={onImport}
                label={i18n.t('Import from Blueprint')}
                helperText={i18n.t('Select a Blueprint to import from')}
                importText={i18n.t('Import Parameter')}
                showParameterCount={false}
                triggerCss={{ backgroundColor: 'inherit' }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ImportParameter;
