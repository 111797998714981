/* istanbul ignore file */
import { i18n } from 'i18n';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import FeatureFlags from '../config/feature-flags';

const mapStateToProps = ({ auth0, account }) => ({
  configLocale: auth0?.locale,
  tenantLocale: account?.company?.locale,
  userLocale: account?.user?.locale,
  userTimeZone: account?.user?.settings?.timezone,
  userDisplayRelativeDates: account?.user?.settings?.disable_relative_dates, // var name is opposite UI :/
  userPreferredDateFormat: account?.user?.settings?.preferred_date_format,
});

function withLocale(BaseComponent) {
  function LocaleWrapper({
    configLocale,
    tenantLocale,
    userLocale,
    userTimeZone,
    userDisplayRelativeDates,
    userPreferredDateFormat,
  }) {
    // set provider
    useEffect(() => {
      const i18nProvider =
        localStorage.getItem('i18n-provider') ||
        FeatureFlags.getFlag('i18n-provider', i18n.ProviderNames.noop);
      i18n.setProvider(i18nProvider);
    }, []);

    // set locale
    useEffect(() => {
      const initializeI18nLocale = async () => {
        let i18nLocale;
        // standard locale resolution
        if (i18n.isEnabled()) {
          i18nLocale =
            localStorage.getItem('i18n-locale') ||
            userLocale ||
            tenantLocale ||
            configLocale;
        }
        // infer english locale from datetime format
        else {
          i18nLocale =
            userPreferredDateFormat === 'DD/MM/YYYY' ? 'en_GB' : 'en_US';
        }

        await i18n.setLocale(i18nLocale);
        document.documentElement.lang = i18n.getJSLocale();
      };

      // we only update locale if we find an inconsistency or change
      if (configLocale === tenantLocale && configLocale === userLocale) {
        return;
      }

      initializeI18nLocale();
    }, [configLocale, tenantLocale, userLocale, userPreferredDateFormat]);

    // formatter settings
    useEffect(() => {
      const shouldUseRelativeDates = userDisplayRelativeDates === 'enable';
      i18n.setTimeZone(userTimeZone);
      i18n.setShouldUseRelativeDates(shouldUseRelativeDates);
    }, [userTimeZone, userDisplayRelativeDates]);

    return <BaseComponent />;
  }
  return connect(mapStateToProps)(LocaleWrapper);
}

export default withLocale;
