/* istanbul ignore file */
import React, { useContext, useCallback } from 'react';

import { updateSetting as update } from '@kandji-inc/bumblebee';
import ActivityTab from 'features/library-items/common/activity-tab';
import NewStatusTab from 'features/library-items/common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from 'features/library-items/common/new-status-tab/util';
import useUniqValue from 'src/components/common/hooks/use-uniq-value/use-uniq-value';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import LibraryContext from '../../routes/library.context';
import type { AppBlockingProps } from './app-blocking.types';
import initial from './initial-state';
import General from './sections/General';
import { appBlockingService } from './service/app-blocking-service';
import { transformFromApi, transformToApi } from './service/transformers';
import useAppBlockingService from './service/use-app-blocking-service';

const AppBlockingPage = (props: AppBlockingProps) => {
  const { model, setModel, pageState } = props;
  const [, triggerValidation] = useUniqValue();

  const generalUpdate = useCallback(update('general', setModel), []);

  const { itemConfig } = useContext(LibraryContext);

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      crumb={model.name || itemConfig.name}
      summaryInfoProps={{
        name: itemConfig.name,
        description: itemConfig.description,
        publisher: itemConfig.publisher,
        devices: itemConfig.devices,
        requirements: itemConfig.osRequirements,
      }}
      triggerValidation={triggerValidation}
      transformToApi={transformToApi}
      transformFromApi={transformFromApi}
      service={appBlockingService}
      defaultIcon={itemConfig.icon}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      supportsDuplication
    >
      <General
        settings={model.general}
        isDisabled={pageState.isDisabled}
        isSubmitted={pageState.isSubmitted}
        isAdding={pageState.isAdding}
        update={generalUpdate}
      />
    </LibraryItemPage>
  );
};

const AppBlocking = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initial,
    useService: useAppBlockingService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  /* istanbul ignore next */
  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <AppBlockingPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="app-blocking-v2"
    />
  );
};

export default AppBlocking;
