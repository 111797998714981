import featureFlags from 'src/config/feature-flags';
import type { Status } from '../../device-status-tab.types';
import { formatStatusContent } from './formatStatusContent';

export function formatStatusLogs(
  status: Status,
  isEnabled = featureFlags.getFlag(
    'core_10282024_library_item_status_store_structured_log_content',
  ),
): Status {
  if (!isEnabled) {
    return status;
  }

  const newStatus = { ...status };
  const { last_audit_log, last_audit_log_content, log, log_content } =
    newStatus;

  newStatus.last_audit_log = formatStatusContent(
    last_audit_log_content,
    last_audit_log,
  );

  newStatus.log = formatStatusContent(log_content, log);

  return newStatus;
}
