import { Card, Flex, Separator, Text } from '@kandji-inc/nectar-ui';
import { createContext, useContext } from 'react';
import KandjiLogo from '../kandji-logo';

const ScreenContext = createContext(undefined);

const ScreenRoot = ({ model, companyLogo, isDarkMode = false, children }) => {
  return (
    <ScreenContext.Provider value={{ isDarkMode, companyLogo, model }}>
      <Card
        css={{
          width: '800px',
          height: '600px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(calc(-50% + 0px), calc(-50% + 0px))',
          backgroundColor: isDarkMode ? '#262126' : '#ececec',
          borderRadius: '12px',
          border: isDarkMode ? '1px #414141 solid' : '0.50px #969696 solid',
          boxShadow:
            ' 0px 20px 50px rgba(0, 0, 0, 0.15), 5px 10px 60px rgba(0, 0, 0, 0.15), -5px 10px 60px rgba(0, 0, 0, 0.15), 0px 30px 100px rgba(0, 0, 0, 0.3);',
          padding: '20px 0',
        }}
      >
        {children}
      </Card>
    </ScreenContext.Provider>
  );
};

const ScreenHeader = ({ children }) => {
  const { isDarkMode } = useContext(ScreenContext);
  return (
    <Flex
      css={{
        justifyContent: 'flex-start',
        height: '50px',
        paddingLeft: '20px',
      }}
    >
      {typeof children === 'function' ? children({ isDarkMode }) : children}
      <KandjiLogo isDarkMode={isDarkMode} />
    </Flex>
  );
};

const ScreenBody = ({ children }) => {
  const { isDarkMode, model, companyLogo } = useContext(ScreenContext);
  return (
    <Flex
      css={{
        minHeight: '450px',
        maxHeight: '450px',
        width: '100%',
        padding: '0 20px',
      }}
    >
      {typeof children === 'function'
        ? children({ isDarkMode, model, companyLogo })
        : children}
    </Flex>
  );
};

const ScreenFooterButton = ({ children }) => {
  const { isDarkMode } = useContext(ScreenContext);
  return (
    <Flex>
      <Flex
        css={{
          width: '100%',
          height: '100%',
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 4,
          paddingBottom: 4,
          background: isDarkMode ? '#636366' : 'white',
          boxShadow: '0px 0.5px 1px rgba(0, 0, 0, 0.10)',
          borderRadius: 6,
          overflow: 'hidden',
          border: '0.50px rgba(0, 0, 0, 0.12) solid',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 10,
          display: 'inline-flex',
        }}
      >
        <Text
          css={{
            fontFamily: 'SF Pro Text',
            fontWeight: '500',
            mixBlendMode: 'luminosity',
            fontSize: '13px',
            color: isDarkMode ? '#DFDEDF' : '#232526',
          }}
        >
          {children}
        </Text>
      </Flex>
    </Flex>
  );
};

const ScreenFooter = ({ children }) => {
  const { isDarkMode } = useContext(ScreenContext);
  return (
    <>
      <Separator
        css={{
          backgroundColor: isDarkMode ? '#414141' : '#C6C6C6',
          transitionProperty: 'background-color',
          transitionDuration: '0.5s',
          marginTop: 20,
        }}
      />

      <Flex css={{ padding: '20px 20px' }}>
        {typeof children === 'function' ? children({ isDarkMode }) : children}
      </Flex>
    </>
  );
};

export const Screen = {
  Root: ScreenRoot,
  Header: ScreenHeader,
  Body: ScreenBody,
  Footer: ScreenFooter,
  FooterButton: ScreenFooterButton,
};
