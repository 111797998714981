import { NFSelectV2 } from '@kandji-inc/nectar-form';
import { Box, Chip, Flex } from '@kandji-inc/nectar-ui';
import type { FacetData } from 'features/rules-modal/types';
import { getDeviceFamilyIcon } from 'features/rules/builder/icons/helpers';
import HoverTippy from 'features/util/components/hover-tippy';
import type React from 'react';
import './LibraryItemOSVersionRule.scss';
import { RuleValue } from 'features/rules-modal/components/RuleValue';
import { LI_OS_VERSION_DEFAULT_OPERATOR } from 'features/rules/constants';
import { i18n } from 'src/i18n';

export const LibraryItemOSVersionRule = ({
  operatorFieldName,
  valueFieldName,
  facetData,
  disabled,
}: {
  operatorFieldName: string;
  valueFieldName: string;
  facetData: FacetData;
  disabled: boolean;
}): React.ReactElement => {
  // get family info
  const family = facetData.device_families[0];
  const facetLabel = facetData.name.split(' ')[0];
  const facetIcon = getDeviceFamilyIcon(family);

  // add "is any" operator to the list of operators
  const operators = [
    {
      label: i18n.t('is any'),
      value: LI_OS_VERSION_DEFAULT_OPERATOR,
    },
    ...facetData.supported_operators,
  ];

  return (
    <>
      <HoverTippy
        text={i18n.t(
          'This rule will no longer be applied based on the device platforms chosen.',
        )}
        maxWidth="none"
        disabled={!disabled}
      >
        <Flex flow="row" gap="sm" alignItems="start">
          <Chip
            className="os-version-chip"
            color="unfilled"
            label={
              <>
                {facetLabel}
                {facetIcon}
              </>
            }
            css={{
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '92px', // leaves enough room for all device families, but keeps width consistent
              height: '38px', // match height of form fields
              paddingLeft: '$2',
              paddingRight: '$2',
            }}
          />
          <Box css={{ width: '240px' }}>
            <NFSelectV2
              name={operatorFieldName}
              options={operators}
              disabled={disabled}
              aria-label={i18n.t('Operator')}
            />
          </Box>
          <Box css={{ width: '420px' }}>
            <RuleValue
              operatorFieldName={operatorFieldName}
              valueFieldPrefix={valueFieldName}
              facetData={facetData}
              disabled={disabled}
              aria-label={i18n.t('Value')}
            />
          </Box>
        </Flex>
      </HoverTippy>
    </>
  );
};
