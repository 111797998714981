import { isEmpty } from 'lodash';
import { i18n } from 'src/i18n';

const replaceBooleanField = (key, value) => {
  const yesNoList = [
    'IsSupervised',
    'AwaitingConfiguration',
    'IsActivationLockSupported',
    'IsMDMLostModeEnabled',
    'IsMultiUser',
    'PersonalHotspotEnabled',
    'IsRoaming',
    'DataRoamingEnabled',
    'VoiceRoamingEnabled',
    'IsNetworkTethered',
    'AppAnalyticsEnabled',
    'DiagnosticSubmissionEnabled',
    'IsCloudBackupEnabled',
    'IsDeviceLocatorServiceEnabled',
    'IsDoNotDisturbInEffect',
    'PasscodePresent',
    'PasscodeCompliant',
    'PasscodeCompliantWithProfiles',
    'PINRequiredForDeviceLock',
    'PINRequiredForEraseDevice',
  ];
  const activeDisabledList = [];
  if (yesNoList.includes(key)) {
    return value === true ? i18n.t('Yes') : i18n.t('No');
  }
  if (activeDisabledList.includes(key)) {
    return value === true ? i18n.t('Active') : i18n.t('Disabled');
  }
  return value === true ? i18n.t('True') : i18n.t('False');
};

export const checkField = (key, value, type = 'default') => {
  let checkedValue = value;
  switch (typeof checkedValue) {
    case 'boolean':
      checkedValue = replaceBooleanField(key, value);
      break;
    case 'string':
      if (type === 'date') {
        checkedValue = i18n.format.datetime(value);
      }
      if (type === 'uppercase') {
        checkedValue = value.toUpperCase();
      }
      break;
    case 'number':
      if (type === 'encryptChoice') {
        if (value === 1) {
          checkedValue = i18n.t('Block-level encryption');
        } else if (value === 2) {
          checkedValue = i18n.t('File-level encryption');
        } else if (value === 3) {
          checkedValue = i18n.t('Block-level and file-level encryption');
        }
      }
      if (type === 'seconds') {
        checkedValue = `${value} ${i18n.t('seconds')}`;
      }
      if (type === 'gsmChoice') {
        checkedValue = 'None';
        if (value === 1) {
          checkedValue = i18n.t('GSM');
        } else if (value === 2) {
          checkedValue = i18n.t('GSM');
        } else if (value === 3) {
          checkedValue = i18n.t('GSM and CDMA');
        }
      }
      if (type === 'percentage') {
        checkedValue = `${(value * 100).toFixed()}%`;
      }
      if (type === 'gigabyte') {
        checkedValue = `${value.toFixed(2)} GB`;
      }
      break;
    default:
      checkedValue = i18n.t('No info');
      break;
  }
  return checkedValue;
};

export const getSystemVersion = (
  computer,
  showFamily = true,
  showVersion = true,
  showVersionExtra = true,
  showBuild = true,
  noInfoText = '',
) => {
  const computerInfo = { ...computer.info } || {};
  const { os_version } = computer;
  // Use supplemental_build_version if we have it, otherwise fallback to build_version
  const build_version =
    computer.supplemental_build_version || computer.build_version;

  let text = '';
  switch (computer.device_family) {
    case 'iPhone':
    case 'iPod':
      text += showFamily ? 'iOS ' : '';
      break;
    case 'iPad':
      text += showFamily
        ? `${computer.os_version < '13.0' ? 'iOS ' : 'iPadOS '}`
        : '';
      break;
    case 'Mac':
      text += showFamily
        ? `${computer.os_version < '10.12' ? 'OS X ' : 'macOS '}`
        : '';
      break;
    case 'AppleTV':
      text += showFamily ? 'tvOS ' : '';
      break;
    case 'RealityDevice':
      text += showFamily ? 'visionOS ' : '';
      break;

    default:
      break;
  }
  text += showVersion && os_version ? os_version : '';
  text +=
    showVersionExtra && computer.supplemental_os_version_extra
      ? ` ${computer.supplemental_os_version_extra}`
      : '';
  text += showBuild && build_version ? ` (${build_version})` : '';
  return text || noInfoText;
};

export const getDeviceModel = (computer) => {
  const computerInfo = { ...computer.info } || {};
  const model = computer.model || computerInfo.Model;
  const modelID = computerInfo['Model Identifier'];

  if (modelID && modelID.includes('VMware', 'Parallels', 'VirtualBox')) {
    return i18n.t('Virtual Machine');
  }

  return model;
};

export const getMDMEnabledUsers = (computer) => {
  const { device_users_list: deviceUsersList } = computer;

  if (!isEmpty(deviceUsersList)) {
    const formattedDeviceUsersList = [];

    // List names in one of the following formats, depending on what is available:
    // long_name (short_name) OR long_name OR short_name
    deviceUsersList.forEach((user) => {
      const { full_name, user_name } = user;
      if (full_name && user_name) {
        formattedDeviceUsersList.push(`${full_name} (${user_name})`);
      } else if (full_name) {
        formattedDeviceUsersList.push(full_name);
      } else if (user_name) {
        formattedDeviceUsersList.push(user_name);
      }
    });

    return formattedDeviceUsersList.join(', ');
  }
  return i18n.t('No info');
};

export const getAgentLastCheckIn = (computer) =>
  [
    computer.last_checkins['agent-library'],
    computer.last_checkins['agent-report'],
  ]
    .filter((el) => el != null)
    .sort()
    .reverse()[0];

export const iOSDeviceInformationMapping = () => ({
  [i18n.t('General')]: {
    DeviceName: {
      title: i18n.t('Device Name'),
      weight: 1,
      type: 'default',
    },
    OSVersion: {
      title: i18n.t('OS Version'),
      weight: 2,
      type: 'default',
    },
    BuildVersion: {
      title: i18n.t('OS Build'),
      weight: 3,
      type: 'default',
    },
    ModelName: {
      title: i18n.t('Model Name'),
      weight: 4,
      type: 'default',
    },
    ProductName: {
      title: i18n.t('Model Identifier'),
      weight: 5,
      type: 'default',
    },
    Model: {
      title: i18n.t('Model Number'),
      weight: 6,
      type: 'default',
    },
    SerialNumber: {
      title: i18n.t('Serial Number'),
      weight: 7,
      type: 'default',
    },
    UDID: {
      title: i18n.t('UDID'),
      weight: 8,
      type: 'default',
    },
    EASDeviceIdentifier: {
      title: i18n.t('EAS Device Identifier'),
      weight: 9,
      type: 'default',
    },
    TimeZone: {
      title: i18n.t('TimeZone'),
      weight: 10,
      type: 'default',
    },
  },
  [i18n.t('Hardware')]: {
    BatteryLevel: {
      title: i18n.t('Battery Level'),
      weight: 1,
      type: 'percentage',
    },
    BatteryHealth: {
      title: i18n.t('Battery Health'),
      weight: 2,
      type: 'default',
    },
    AvailableDeviceCapacity: {
      title: i18n.t('Available Device Capacity'),
      weight: 3,
      type: 'gigabyte',
    },
    DeviceCapacity: {
      title: i18n.t('Device Capacity'),
      weight: 4,
      type: 'gigabyte',
    },
    WiFiMAC: {
      title: i18n.t('Wi-Fi Address'),
      weight: 5,
      type: 'uppercase',
    },
    BluetoothMAC: {
      title: i18n.t('Bluetooth Address'),
      weight: 6,
      type: 'uppercase',
    },
    CellularTechnology: {
      title: i18n.t('Cellular Technology'),
      weight: 7,
      type: 'gsmChoice',
    },
    ModemFirmwareVersion: {
      title: i18n.t('Modem Firmware'),
      dependency: 'CellularTechnology',
      wrongValueDependency: [0],
      weight: 8,
      type: 'default',
    },
  },
  [i18n.t('Management')]: {
    IsSupervised: {
      title: i18n.t('Supervised'),
      weight: 1,
      type: 'default',
    },
    AwaitingConfiguration: {
      title: i18n.t('Awaiting Configuration'),
      weight: 2,
      type: 'default',
    },
    IsMDMLostModeEnabled: {
      title: i18n.t('MDM Lost Mode Enabled'),
      weight: 5,
      type: 'default',
    },
    PINRequiredForDeviceLock: {
      title: i18n.t('PIN Required For Device Lock'),
      weight: 7,
      type: 'default',
    },
    PINRequiredForEraseDevice: {
      title: i18n.t('PIN Required For Erase Device'),
      weight: 8,
      type: 'default',
    },
  },
  [i18n.t('Cellular')]: {
    PhoneNumber: {
      title: i18n.t('Phone Number'),
      weight: 1,
      type: 'default',
    },
    IMEI: {
      title: i18n.t('IMEI'),
      weight: 2,
      type: 'default',
    },
    ICCID: {
      title: i18n.t('ICCID'),
      weight: 3,
      type: 'default',
    },
    MEID: {
      title: i18n.t('MEID'),
      weight: 4,
      type: 'default',
    },
    PersonalHotspotEnabled: {
      title: i18n.t('Personal Hotspot Enabled'),
      weight: 5,
      type: 'default',
    },
    IsRoaming: {
      title: i18n.t('Roaming Enabled'),
      weight: 6,
      type: 'default',
    },
    DataRoamingEnabled: {
      title: i18n.t('Data Roaming Enabled'),
      weight: 7,
      type: 'default',
    },
    VoiceRoamingEnabled: {
      title: i18n.t('Voice Roaming Enabled'),
      weight: 8,
      type: 'default',
    },
    CurrentCarrierNetwork: {
      title: i18n.t('Current Carrier Network'),
      weight: 9,
      type: 'default',
    },
    CarrierSettingsVersion: {
      title: i18n.t('Carrier Settings Version'),
      weight: 10,
      type: 'default',
    },
    CurrentMCC: {
      title: i18n.t('Current MCC'),
      weight: 11,
      type: 'default',
    },
    CurrentMNC: {
      title: i18n.t('Current MNC'),
      weight: 12,
      type: 'default',
    },
    SubscriberCarrierNetwork: {
      title: i18n.t('Subscriber Carrier Network'),
      weight: 13,
      type: 'default',
    },
    SubscriberMCC: {
      title: i18n.t('Subscriber MCC'),
      weight: 14,
      type: 'default',
    },
    SubscriberMNC: {
      title: i18n.t('Subscriber MNC'),
      weight: 15,
      type: 'default',
    },
    SIMCarrierNetwork: {
      title: i18n.t('SIM Carrier Network'),
      weight: 16,
      type: 'default',
    },
    SIMMCC: {
      title: i18n.t('SIM MCC'),
      weight: 17,
      type: 'default',
    },
    SIMMNC: {
      title: i18n.t('SIM MNC'),
      weight: 18,
      type: 'default',
    },
    ServiceSubscriptions: {
      title: i18n.t('Service Subscriptions'),
      weight: 19,
      type: 'multiLineListOutput',
      list: {
        CarrierSettingsVersion: i18n.t('Carrier Settings Version'),
        CurrentCarrierNetwork: i18n.t('Current Carrier Network'),
        CurrentMCC: i18n.t('Current MCC'),
        CurrentMNC: i18n.t('Current MNC'),
        IMEI: i18n.t('IMEI'),
        ICCID: i18n.t('ICCID'),
        IsDataPreferred: i18n.t('Data Preferred'),
        IsRoaming: i18n.t('Roaming'),
        IsVoicePreferred: i18n.t('Voice Preferred'),
        Label: i18n.t('Label'),
        LabelID: i18n.t('Label ID'),
        MEID: i18n.t('MEID'),
        PhoneNumber: i18n.t('Phone Number'),
        Slot: i18n.t('Slot'),
        EID: i18n.t('eSIM ID'),
      },
    },
  },
  [i18n.t('Additional')]: {
    IsNetworkTethered: {
      title: i18n.t('Network Tethered'),
      weight: 1,
      type: 'default',
    },
    AppAnalyticsEnabled: {
      title: i18n.t('App Analytics Enabled'),
      weight: 2,
      type: 'default',
    },
    DiagnosticSubmissionEnabled: {
      title: i18n.t('Diagnostic Submission Enabled'),
      weight: 3,
      type: 'default',
    },
    IsCloudBackupEnabled: {
      title: i18n.t('iCloud Backup Enabled'),
      weight: 4,
      type: 'default',
    },
    LastCloudBackupDate: {
      title: i18n.t('Last iCloud Backup Date'),
      weight: 5,
      type: 'default',
    },
    IsDeviceLocatorServiceEnabled: {
      title: i18n.t('Location Services Enabled'),
      weight: 6,
      type: 'default',
    },
    IsDoNotDisturbInEffect: {
      title: i18n.t('Do Not Disturb In Effect'),
      weight: 7,
      type: 'default',
    },
    iTunesStoreAccountIsActive: {
      title: i18n.t('iTunes Store Account Active'),
      weight: 8,
      type: 'default',
    },
  },
  [i18n.t('Shared iPad')]: {
    IsMultiUser: {
      title: i18n.t('Shared iPad'),
      weight: 1,
      type: 'default',
    },
    setResidentUsers: {
      title: i18n.t('Maximum Number of Shared iPad Users'),
      weight: 2,
      type: 'default',
    },
    EstimatedResidentUsers: {
      title: i18n.t('Estimated Resident Users'),
      weight: 3,
      type: 'default',
    },
  },
});

export const tvOSDeviceInformationMapping = () => ({
  [i18n.t('General')]: {
    DeviceName: {
      title: i18n.t('Device Name'),
      weight: 1,
      type: 'default',
    },
    OSVersion: {
      title: i18n.t('OS Version'),
      weight: 2,
      type: 'default',
    },
    BuildVersion: {
      title: i18n.t('OS Build'),
      weight: 3,
      type: 'default',
    },
    ModelName: {
      title: i18n.t('Model Name'),
      weight: 4,
      type: 'default',
    },
    ProductName: {
      title: i18n.t('Model Identifier'),
      weight: 5,
      type: 'default',
    },
    Model: {
      title: i18n.t('Model Number'),
      weight: 6,
      type: 'default',
    },
    SerialNumber: {
      title: i18n.t('Serial Number'),
      weight: 7,
      type: 'default',
    },
    UDID: {
      title: i18n.t('UDID'),
      weight: 8,
      type: 'default',
    },
    DeviceID: {
      title: i18n.t('Device Identifier'),
      weight: 9,
      type: 'default',
    },
  },
  [i18n.t('Hardware')]: {
    WiFiMAC: {
      title: i18n.t('Wi-Fi Address'),
      weight: 1,
      type: 'uppercase',
    },
    BluetoothMAC: {
      title: i18n.t('Bluetooth Address'),
      weight: 2,
      type: 'uppercase',
    },
  },
  [i18n.t('Management')]: {
    IsSupervised: {
      title: i18n.t('Supervised'),
      weight: 1,
      type: 'default',
    },
    AwaitingConfiguration: {
      title: i18n.t('Awaiting Configuration'),
      weight: 2,
      type: 'default',
    },
  },
  [i18n.t('Additional')]: {
    iTunesStoreAccountIsActive: {
      title: i18n.t('iTunes Store Account Active'),
      weight: 1,
      type: 'default',
    },
  },
});

export const SecurityMapping = () => ({
  HardwareEncryptionCaps: {
    title: i18n.t('Hardware Encryption Capabilities'),
    weight: 1,
    type: 'encryptChoice',
  },
  PasscodePresent: {
    title: i18n.t('Passcode Present'),
    weight: 2,
    type: 'default',
  },
  PasscodeCompliant: {
    title: i18n.t('Passcode Compliant'),
    weight: 3,
    type: 'default',
  },
  PasscodeCompliantWithProfiles: {
    title: i18n.t('Passcode Compliant With Installed Profiles'),
    weight: 4,
    type: 'default',
  },
  PasscodeLockGracePeriodEnforced: {
    title: i18n.t('Passcode Lock Grace Period (Enforced)'),
    weight: 5,
    type: 'seconds',
  },
  PasscodeLockGracePeriod: {
    title: i18n.t('Passcode Lock Grace Period (User-Defined)'),
    weight: 6,
    type: 'seconds',
  },
});

export const ABMMapping = () => ({
  color: {
    title: i18n.t('Color'),
    weight: 2,
    type: 'default',
  },
  description: {
    title: i18n.t('Description'),
    weight: 3,
    type: 'default',
  },
  device_assigned_by: {
    title: i18n.t('Device Assigned By'),
    weight: 8,
    type: 'default',
  },
  device_assigned_date: {
    title: i18n.t('Device Assigned Date'),
    weight: 7,
    type: 'date',
  },
  device_family: {
    title: i18n.t('Device Family'),
    weight: 5,
    type: 'default',
  },
  model: {
    title: i18n.t('Model'),
    weight: 1,
    type: 'default',
  },
  os: {
    title: i18n.t('OS'),
    weight: 6,
    type: 'default',
  },
  serial_number: {
    title: i18n.t('Serial Number'),
    weight: 4,
    type: 'default',
  },
});

export const getDeviceGeneralInfo = (computer) => {
  let info = {};
  switch (computer.device_family) {
    case 'iPhone':
    case 'iPod':
    case 'iPad':
    case 'AppleTV':
      break;
    case 'Mac':
      const computerInfo = { ...computer.info } || {};
      info = {
        ...info,
        ...{
          [i18n.t('Device Name')]:
            computerInfo['Computer Name'] || computer.name,
          [i18n.t('Device ID')]: computer.id,
          [i18n.t('Last Enrollment')]: computer.lastEnrollmentNotFormatted,
          [i18n.t('First Enrollment')]: computer.firstEnrollmentNotFormatted,
          [i18n.t('Model')]: getDeviceModel(computer),
          [i18n.t('OS Version')]: getSystemVersion(
            computer,
            false,
            true,
            true,
            false,
          ),
          [i18n.t('System Version')]: getSystemVersion(computer),
          [i18n.t('Boot Volume')]: computerInfo['Boot Volume'],
          [i18n.t('Time Since Boot')]: computerInfo['Time since boot'],
          [i18n.t('Last User')]: computerInfo['User Name'],
        },
      };
      break;
    default:
      info = {};
      break;
  }
  return info;
};

export const getDeviceProfileInfo = (computer) => {
  const info = [];
  let value;
  const mdmProfileList = {
    IsManaged: i18n.t('Managed'),
    IsEncrypted: i18n.t('Encrypted'),
    PayloadUUID: i18n.t('Payload UUID'),
    // this is a magic key that controls rendering logic in ComputerTechSpecsTab.tsx so we will translate it there are to not disrupt the magic
    PayloadContent: 'Payload Content',
    PayloadVersion: i18n.t('Payload Version'),
    PayloadIdentifier: i18n.t('Payload Identifier'),
    HasRemovalPasscode: i18n.t('Has Removal Passcode'),
    PayloadDescription: i18n.t('Payload Description'),
    // this is a magic key that controls rendering logic in ComputerTechSpecsTab.tsx so we will translate it there are to not disrupt the magic
    PayloadDisplayName: 'Payload Display Name',
    SignerCertificates: i18n.t('Signer Certificates'),
    PayloadOrganization: i18n.t('Payload Organization'),
    PayloadRemovalDisallowed: i18n.t('Payload Removal Disallowed'),
  };

  const PayloadContent = {
    PayloadType: i18n.t('Payload Type'),
    PayloadUUID: i18n.t('Payload UUID'),
    PayloadVersion: i18n.t('Payload Version'),
    PayloadIdentifier: i18n.t('Payload Identifier'),
    PayloadDescription: i18n.t('Payload Description'),
    PayloadDisplayName: 'Payload Display Name',
    PayloadOrganization: i18n.t('Payload Organization'),
  };

  switch (computer.device_family) {
    case 'iPhone':
    case 'iPod':
    case 'iPad':
    case 'AppleTV':
      computer.mdm_profile_list.map((profMdm, index) => {
        info.push({});
        Object.keys(computer.mdm_profile_list[index])
          .filter((profileKey) =>
            Object.keys(mdmProfileList).includes(profileKey),
          )
          .map((profileKey) => {
            if (profileKey === 'PayloadContent') {
              info[index][mdmProfileList[profileKey]] = [];
              if (computer.mdm_profile_list[index][profileKey] !== null) {
                computer.mdm_profile_list[index][profileKey].map(
                  (profContent, contentIndex) => {
                    info[index][mdmProfileList[profileKey]].push({});
                    Object.keys(
                      computer.mdm_profile_list[index][profileKey][
                        contentIndex
                      ],
                    )
                      .filter((contentKey) =>
                        Object.keys(PayloadContent).includes(contentKey),
                      )
                      .map((contentKey) => {
                        value = checkField(
                          contentKey,
                          computer.mdm_profile_list[index][profileKey][
                            contentIndex
                          ][contentKey],
                        );
                        info[index][mdmProfileList[profileKey]][contentIndex][
                          PayloadContent[contentKey]
                        ] = value;
                      });
                  },
                );
              }
            } else {
              value = checkField(
                profileKey,
                computer.mdm_profile_list[index][profileKey],
              );
              info[index][mdmProfileList[profileKey]] = value;
            }
          });
      });
      break;
    case 'Mac':
      break;
    default:
      break;
  }
  return info;
};

export const getDeviceMdmCertificateInfo = (computer) => {
  const info = [];
  const certificate = {
    CommonName: i18n.t('Common Name'),
    IsIdentity: i18n.t('Identity'),
  };
  switch (computer.device_family) {
    case 'iPhone':
    case 'iPod':
    case 'iPad':
    case 'AppleTV':
      computer.mdm_certificate_list.map((_, index) => {
        info.push({});
        Object.keys(computer.mdm_certificate_list[index])
          .filter((profileKey) => Object.keys(certificate).includes(profileKey))
          .map((profileKey) => {
            info[index][certificate[profileKey]] = checkField(
              profileKey,
              computer.mdm_certificate_list[index][profileKey],
            );
          });
      });
      break;
    case 'Mac':
      break;
    default:
      break;
  }
  return info;
};
