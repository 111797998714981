import type { FIXME_Any } from '@kandji-inc/nectar-ui';
import type { UseQueryResult } from '@tanstack/react-query';
import type {
  OnChangeFn,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  useBlueprints,
  useGlobalFilters,
  usePagination,
  usePrismSchemas,
} from 'src/features/visibility/prism/hooks';
import type {
  Blueprint,
  DeviceFamily,
  GlobalFilters,
} from 'src/features/visibility/prism/hooks';
import { getColumnsForPrismCategory } from 'src/features/visibility/prism/utils/column-defs';
import { useComputerCount } from '../hooks';
import type {
  PrismCategorySchema,
  PrismCategoryUri,
} from '../types/prism.types';

export type Option = {
  id: string;
  name: string;
};

export type PrismContextType = {
  columns: FIXME_Any;
  prismCategories: PrismCategorySchema[] | undefined;
  selectedPrismCategory: PrismCategorySchema | undefined;
  setSelectedPrismCategory: (attribute: PrismCategorySchema) => void;
  isLoading: boolean;
  pagination: PaginationState;
  setPagination: OnChangeFn<PaginationState>;
  sorting: SortingState;
  setSorting: OnChangeFn<SortingState>;
  globalFilters: GlobalFilters;
  updateDeviceFamilyFilter: (deviceFamilies: DeviceFamily[]) => void;
  updateBlueprintFilter: (blueprints: Blueprint[]) => void;
  resetAllFilters: () => void;
  blueprintsQuery: typeof useBlueprints extends () => infer T ? T : never;
  computerCountQuery: typeof useComputerCount extends () => infer T ? T : never;
  prismNavOpen: boolean;
  setPrismNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  globalFilterModalOpen: boolean;
  setGlobalFilterModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const PrismContext = React.createContext<PrismContextType>({
  columns: [],
  prismCategories: [],
  selectedPrismCategory: undefined,
  setSelectedPrismCategory: {} as React.Dispatch<
    React.SetStateAction<PrismCategorySchema>
  >,
  isLoading: false,
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
  setPagination: {} as OnChangeFn<PaginationState>,
  sorting: [] as SortingState,
  setSorting: {} as OnChangeFn<SortingState>,
  globalFilters: {} as GlobalFilters,
  updateDeviceFamilyFilter: {} as (deviceFamilies: DeviceFamily[]) => void,
  updateBlueprintFilter: {} as (blueprints: Blueprint[]) => void,
  resetAllFilters: {} as () => void,
  blueprintsQuery: {} as UseQueryResult,
  computerCountQuery: {} as UseQueryResult,
  prismNavOpen: true,
  setPrismNavOpen: {} as React.Dispatch<React.SetStateAction<boolean>>,
  globalFilterModalOpen: false,
  setGlobalFilterModalOpen: {} as React.Dispatch<React.SetStateAction<boolean>>,
});

const PrismProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const prismSchemas = usePrismSchemas('v3');
  const blueprintsQuery = useBlueprints();
  const computerCountQuery = useComputerCount();
  const history = useHistory();
  const location = useLocation();
  const [prismNavOpen, setPrismNavOpen] = React.useState(true);

  const [globalFilterModalOpen, setGlobalFilterModalOpen] =
    React.useState(false);

  const routeParams = useParams<{ prismCategory: PrismCategoryUri }>();

  const [selectedPrismCategory, setSelectedPrismCategory] =
    React.useState<PrismCategorySchema>();

  const [prismCategorySchemas, setPrismCategorySchemas] = React.useState([]);

  const [sorting, setSorting] = React.useState<SortingState>([]);

  React.useEffect(() => {
    // istanbul ignore next -- todo: figure out how to test this
    if (prismSchemas.data && location.pathname.includes('prism')) {
      if (
        selectedPrismCategory?.uri !== routeParams.prismCategory ||
        !routeParams.prismCategory
      ) {
        const prismCategory = routeParams.prismCategory || 'device_information';
        const schema = prismSchemas.data.find(
          (schema) => schema.uri === prismCategory,
        );
        if (!routeParams.prismCategory) {
          history.push(`/devices/prism/${schema.uri}`);
        }
        return setSelectedPrismCategory(schema);
      }
    }

    return undefined;
  }, [
    history,
    location.pathname,
    prismSchemas.data,
    routeParams.prismCategory,
    selectedPrismCategory,
  ]);

  React.useEffect(() => {
    if (prismSchemas.data) {
      setPrismCategorySchemas(prismSchemas.data);
    }
  }, [prismSchemas.data]);

  const {
    paginationState: { pageIndex, pageSize },
    setPagination,
  } = usePagination();

  const [globalFiltersState, setGlobalFiltersState] =
    React.useState<GlobalFilters>({
      deviceFamilies: [],
      blueprints: [],
    });

  const {
    globalFilters,
    updateDeviceFamilies,
    updateBlueprints,
    resetAllFilters,
  } = useGlobalFilters(globalFiltersState);

  React.useEffect(() => {
    setGlobalFiltersState(globalFilters);
  }, [globalFilters]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const columns = React.useMemo(
    () =>
      getColumnsForPrismCategory(
        selectedPrismCategory?.uri || routeParams.prismCategory,
        prismCategorySchemas,
      ),
    [
      selectedPrismCategory?.uri,
      routeParams.prismCategory,
      prismCategorySchemas,
    ],
  );

  return (
    <PrismContext.Provider
      value={{
        columns,
        prismCategories: prismSchemas.data,
        isLoading: !selectedPrismCategory,
        selectedPrismCategory,
        setSelectedPrismCategory,
        pagination,
        setPagination,
        sorting,
        setSorting,
        updateDeviceFamilyFilter: updateDeviceFamilies,
        updateBlueprintFilter: updateBlueprints,
        resetAllFilters,
        globalFilters,
        blueprintsQuery,
        computerCountQuery,
        prismNavOpen,
        setPrismNavOpen,
        globalFilterModalOpen,
        setGlobalFilterModalOpen,
      }}
    >
      {children}
    </PrismContext.Provider>
  );
};

const usePrismContext = () => {
  const context = React.useContext(PrismContext);

  return context;
};

export { PrismProvider, usePrismContext, PrismContext };
