import { ButtonGroup } from '@kandji-inc/nectar-ui';

const DeviceTypes = {
  IPAD_PORTRAIT: 'IPAD_PORTRAIT',
  IPAD_LANDSCAPE: 'IPAD_LANDSCAPE',
  IPHONE: 'IPHONE',
};

const OrientationSwitcher = ({ orientation, setOrientation, disabled }) => (
  <ButtonGroup
    variant="inverse"
    buttons={[
      {
        label: '',
        onClick: () => {
          setOrientation(DeviceTypes.IPHONE);
        },
        icon: {
          name: 'sf-iphone',
        },
        selected: orientation === DeviceTypes.IPHONE,
        'aria-label': 'iPhone',
      },
      {
        label: '',
        onClick: () => {
          setOrientation(DeviceTypes.IPAD_LANDSCAPE);
        },
        icon: {
          name: 'sf-ipad-landscape',
        },
        selected: orientation === DeviceTypes.IPAD_LANDSCAPE,
        'aria-label': 'iPad-landscape',
      },
      {
        label: '',
        onClick: () => {
          setOrientation(DeviceTypes.IPAD_PORTRAIT);
        },
        icon: {
          name: 'sf-ipad-portrait',
        },
        selected: orientation === DeviceTypes.IPAD_PORTRAIT,
        //disabled: disabled,
        'aria-label': 'iPad-portrait',
      },
    ]}
  />
);

export default OrientationSwitcher;
