/* istanbul ignore file */
import {
  getRunsOnFromModel,
  getSelectedDevicesFromRunsOn,
} from 'features/library-items/data-service/library-item/devices';
import { webclipService } from 'features/library-items/items/webclip/service/webclip-service';
import configs from 'features/library-items/library/library-item-configurations/items/profile';
import type { GeneralWebClip, WebClipApiData } from 'src/types/common.types';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';

type SendToApi = {
  name: string;
  active: string;
  blueprints: string[];
  is_all_blueprints: boolean;
  type?: string;
  identifier?: string;
  skip_blueprint_conflict: boolean;
  reassign_lib_item_to_bp: boolean;
  data: {
    Label: string;
    URL: string;
    webClipImgS3Key: string;
    IsRemovable: boolean;
    FullScreen: boolean;
    IgnoreManifestScope: boolean;
    TargetApplicationBundleIdentifier: string;
    Precomposed: boolean;
  };
  rules: object;
};

function transformSettings(settings: GeneralWebClip) {
  return {
    general: {
      FullScreen: settings.FullScreen,
      IgnoreManifestScope: settings.IgnoreManifestScope,
      IsRemovable: settings.IsRemovable,
      Label: settings.Label,
      Precomposed: settings.Precomposed,
      URL: settings.URL,
      webClipImgUrl: settings.app_icon,
      webClipImgS3Key: settings.webClipImgS3Key,
    },
  };
}

async function transformFromApi(apiData: WebClipApiData) {
  const allBlueprintOptions = await getAllBlueprintOptions([
    ...apiData.blueprints,
    ...apiData.excluded_blueprints,
  ]);
  const selectedBlueprints = allBlueprintOptions.filter(
    (option: { value: string }) => apiData.blueprints.includes(option.value),
  );

  const excludedBlueprints = allBlueprintOptions.filter(
    (option: { value: string }) =>
      apiData.excluded_blueprints.includes(option.value),
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
    devices: getSelectedDevicesFromRunsOn(apiData),
  };

  const result = {
    ...commonData,
    isActive: apiData.active,
    rules: apiData.rules,
    ...transformSettings(apiData.data),
  };

  return {
    ...apiData,
    data: result,
  };
}

async function transformToApi(model) {
  const runsOn = getRunsOnFromModel(model);

  const { webClipImgUrl, webClipImg } = model.general;
  if (webClipImg) {
    try {
      const res = await webclipService.uploadWebClipImage(
        webClipImgUrl,
        webClipImg,
      );
      model.general.webClipImgS3Key = res.fields.key;
    } catch (error) {
      console.error('Error uploading web clip image:', error);
      throw error; // Re-throw the error if needed
    }
  }

  const toSend: SendToApi = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    skip_blueprint_conflict: model.skip_blueprint_conflict,
    reassign_lib_item_to_bp: true,
    data: {
      Label: model.general.Label,
      URL: model.general.URL,
      webClipImgS3Key: model.general.webClipImgS3Key,
      IsRemovable: model.general.IsRemovable,
      FullScreen: model.general.FullScreen,
      IgnoreManifestScope: model.general.IgnoreManifestScope,
      TargetApplicationBundleIdentifier:
        model.general.TargetApplicationBundleIdentifier,
      Precomposed: model.general.precomposed,
    },
    rules: model.rules || null,
  };

  // Populate `runs_on_*` fields
  Object.keys(runsOn).forEach((runsOnKey) => {
    toSend[runsOnKey] = runsOn[runsOnKey];
  });

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  if (model.reassign_lib_item_to_bp) {
    toSend.reassign_lib_item_to_bp = model.reassign_lib_item_to_bp;
  }

  if (!model.id) {
    const itemConfig = configs.WebClip;
    toSend.type = itemConfig.type;
    toSend.identifier = itemConfig.identifier;
  }

  return toSend;
}

export { transformFromApi, transformToApi };
