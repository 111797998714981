import type { MouseEvent } from 'react';
import React, { Fragment, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import type {
  ColumnDef,
  ExpandedState,
  Row,
  SortingState,
} from '@tanstack/react-table';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import {
  Box,
  Button,
  DropdownMenu,
  Flex,
  Icon,
  TablePrimitives,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  TooltipProvider,
  Tr,
} from '@kandji-inc/nectar-ui';

import { getItemConfig } from 'features/library-items/library/api/transformer';
import IconRules from 'features/library-items/library/library-list/assets/icon_rules.svg';
import HoverTippy from 'features/util/components/hover-tippy';
import { AccountContext } from 'src/contexts/account';
import { EnvironmentContext } from 'src/contexts/environment';

import type {
  ComputerRecord,
  Status,
  StatusRow,
  StatusTypeKind,
} from './device-status-tab.types';

import { i18n } from 'i18n';
import { displayLibraryCategory } from '../library-items/library/common';
import { PENDING_STATUSES, STATUS_TYPES } from './constants';
import Empty from './empty';
import {
  dateColumnSort,
  formatStatus,
  getExpandedDetails,
  groupStatusesByType,
  nameColumnSort,
  statusColumnSort,
  translateDateColumnName,
} from './helpers';
import { noSearchData } from './images';
import StatusBadge from './status-badge';

const { Table } = TablePrimitives;

const noIconOffset = '28px';

// istanbul ignore next
const noop = () => {};

const ArrowIcon = (props: {
  kind: string;
  getCanExpand: () => boolean;
  getIsExpanded: () => boolean;
  toggleExpanded: (expanded?: boolean) => void;
}) => {
  const { kind, getCanExpand, getIsExpanded, toggleExpanded } = props;

  return (
    <Box
      css={{
        marginTop: '-2px',
        minWidth: '$4',
        cursor: getCanExpand() ? 'pointer' : 'default',
      }}
      data-testid={getCanExpand() ? `${kind}-caret` : 'spacer'}
      onClick={getCanExpand() ? () => toggleExpanded(!getIsExpanded()) : noop}
    >
      {getCanExpand() && (
        <Icon
          size="sm"
          color="var(--color-neutral-90)"
          name={`fa-caret-${getIsExpanded() ? 'down' : 'right'}-solid-small`}
        />
      )}
    </Box>
  );
};

const NameCell = (props: { row: Row<StatusRow> }) => {
  const { row } = props;

  const { icon, instanceName, name, rulesPresent, url, computer } =
    row.original;

  const isComputerBlueprintClassic = computer.blueprint.type === 'form';

  const hoverStyles = {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  };

  return (
    <Flex>
      <Flex flow="row" alignItems="start">
        <Flex flow="row" gap="sm" alignItems="center">
          {icon && (
            <img
              src={icon}
              style={{
                height: 'var(--sizes-5)',
                width: 'var(--sizes-5)',
                alignSelf: 'start',
                userSelect: 'none',
              }}
              alt="Library Item Icon"
            />
          )}

          <Flex
            flow="column"
            gap="xs"
            css={{ paddingLeft: icon ? '0' : noIconOffset }}
          >
            <Flex flow="row" gap="sm">
              {/* If the item has a URL (i.e., not a Parameter), make the name clickable and
                open the Library Item in a new tab on click. Otherwise, just display the name. */}
              {url ? (
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={
                    // istanbul ignore next - browser functions
                    (e) => e.stopPropagation()
                  }
                >
                  <Text css={{ fontWeight: '$medium', hoverStyles }}>
                    {name}
                  </Text>
                </a>
              ) : (
                <Text css={{ fontWeight: '$medium' }}>{name}</Text>
              )}

              {isComputerBlueprintClassic && rulesPresent && (
                <HoverTippy
                  text={i18n.t('Assignment Rules')}
                  icon={IconRules}
                />
              )}
            </Flex>

            {instanceName && <Text>{instanceName}</Text>}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const StatusCell = (props: { row: Row<StatusRow> }) => (
  <StatusBadge status={props.row.original.status} />
);

const DateCell = (props: { row: Row<StatusRow> }) => {
  const { date, status } = props.row.original;
  const isPending = PENDING_STATUSES.includes(formatStatus(status));

  if (date && !isPending) {
    return (
      <TooltipProvider>
        <Tooltip content={i18n.format.datetime(date)} side="top">
          <Text css={{ width: 'fit-content' }}>
            {i18n.format.datetime(date, { relative: true })}
          </Text>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return null;
};

const ActionsCell = (props: { row: Row<StatusRow> }) => {
  const { actions } = props.row.original;

  if (actions?.length) {
    return (
      <Flex>
        <DropdownMenu
          withArrow={false}
          contentProps={{ align: 'end' }}
          options={actions}
        >
          <Button
            id="actions"
            title={i18n.t('Actions')}
            variant="subtle"
            icon={{ name: 'ellipsis' }}
            onClick={
              // istanbul ignore next - browser functions
              (e) => {
                e.stopPropagation();
                e.preventDefault();
              }
            }
            compact
            css={{ margin: '-$1 0 -$1 -$2' }}
          />
        </DropdownMenu>
      </Flex>
    );
  }

  return null;
};

const StatusTable = (props: {
  tableName: string;
  dateColumnName: string;
  data: any;
}) => {
  const { tableName, dateColumnName, data } = props;

  const [expanded, setExpanded] = useState<ExpandedState>({});

  // The 'Name' column should sort asc by default
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'name', desc: false },
  ]);

  const columnHelper = createColumnHelper<StatusRow>();

  const columns: ColumnDef<StatusRow>[] = [
    columnHelper.accessor('id', {
      header: ({ table }) => (
        <ArrowIcon
          kind="table"
          getCanExpand={table.getCanSomeRowsExpand}
          getIsExpanded={table.getIsAllRowsExpanded}
          toggleExpanded={table.toggleAllRowsExpanded}
        />
      ),
      cell: ({ row }) => (
        <ArrowIcon
          kind="row"
          getCanExpand={row.getCanExpand}
          getIsExpanded={row.getIsExpanded}
          toggleExpanded={row.toggleExpanded}
        />
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('name', {
      header: () => (
        <Box css={{ paddingLeft: noIconOffset }}>{i18n.t('Name')}</Box>
      ),
      cell: (info) => <NameCell {...info} />,
      sortingFn: nameColumnSort,
    }),
    columnHelper.accessor('status', {
      header: i18n.t('Status'),
      cell: (info) => <StatusCell {...info} />,
      sortingFn: statusColumnSort,
    }),
    columnHelper.accessor('date', {
      header: dateColumnName,
      cell: (info) => <DateCell {...info} />,
      sortingFn: dateColumnSort,
      sortDescFirst: false,
    }),
    columnHelper.accessor('actions', {
      header: null,
      cell: (info) => <ActionsCell {...info} />,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: { expanded, sorting },
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand: (row) => !!row.original.expandedContent,
    enableSortingRemoval: false, // Forces sorting to go 'none' -> 'asc' -> 'desc' -> 'asc' -> 'desc' -> ...
  });

  const getColumnWidth = (rowCells, idx) => {
    // Expanding arrow icon column
    if (idx === 0) {
      return '$8';
    }

    // Name column
    if (idx === 1) {
      return '100%';
    }

    // Actions column
    if (idx === rowCells.length - 1) {
      return '$8';
    }

    // Default
    return '$13';
  };

  return (
    <Flex flow="column" gap="lg">
      <Text size="3" css={{ fontWeight: '$medium' }}>
        {tableName}
      </Text>

      <Table>
        <Box
          css={{
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            borderRadius: '$2',
            border: '1px solid $neutral20',
            pointerEvents: 'none',
          }}
        />
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header, idx) => (
                <Th
                  key={header.id}
                  title={header.id}
                  scope="col"
                  sort={
                    header.column.getCanSort() && {
                      state: header.column.getIsSorted() || 'none',
                      onSort: header.column.getToggleSortingHandler(),
                    }
                  }
                  css={{
                    width: getColumnWidth(headerGroup.headers, idx),
                    borderBottom: '1px solid $neutral30',
                    boxShadow: 'unset',
                  }}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <Tr
                hoverBg={row.getCanExpand() ? 'row' : 'none'}
                hoverAnchorUnderline={false}
                css={{
                  cursor: row.getCanExpand() ? 'pointer' : 'default',
                }}
                onClick={
                  row.getCanExpand()
                    ? () => row.toggleExpanded(!row.getIsExpanded())
                    : noop
                }
              >
                {row.getVisibleCells().map((cell, idx, rowCells) => (
                  <Td
                    title={String(cell.getValue())}
                    key={cell.id}
                    valign="top"
                    css={{
                      width: getColumnWidth(rowCells, idx),
                      $$tdActiveBg: row.getCanExpand()
                        ? 'transparent'
                        : 'var(--colors-neutral0)',
                      boxShadow:
                        row.getCanExpand() && row.getIsExpanded()
                          ? 'none'
                          : 'var(--shadows-inset_border_bottom_1) var(--colors-neutral20)',
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>

              {row.getCanExpand() && row.getIsExpanded() && (
                <Tr
                  hoverBg="none"
                  aria-label="expanded-content"
                  hoverAnchorUnderline={false}
                >
                  <Td
                    title="Expand"
                    colSpan={1}
                    css={{
                      width: '$8',
                      $$tdActiveBg: 'var(--colors-neutral0)',
                    }}
                  >
                    {null}
                  </Td>

                  <Td
                    title="Expanded content"
                    valign="top"
                    colSpan={1}
                    css={{ $$tdActiveBg: 'var(--colors-neutral0)' }}
                  >
                    <Box
                      css={{
                        paddingLeft: noIconOffset,
                        whiteSpace: 'normal',
                      }}
                    >
                      {row.original.expandedContent}
                    </Box>
                  </Td>

                  <Td
                    title=""
                    valign="top"
                    colSpan={row.getVisibleCells().length - 1}
                    css={{ $$tdActiveBg: 'var(--colors-neutral0)' }}
                  >
                    {null}
                  </Td>
                </Tr>
              )}
            </Fragment>
          ))}
        </Tbody>
      </Table>
    </Flex>
  );
};

export const StatusTables = (props: {
  computer: ComputerRecord;
  filteredStatuses: Array<Status>;
}) => {
  const { computer, filteredStatuses } = props;

  const history = useHistory();

  // `account` and `environment` are necessary to generate a Library Item's URL
  const account = useContext(AccountContext);
  const environment = useContext(EnvironmentContext);

  const groupedStatuses = groupStatusesByType(filteredStatuses);

  const hasStatusesToShow = filteredStatuses?.length;

  // istanbul ignore next - browser functions
  const handleViewParameterHistoryClick = (parameterId) => {
    localStorage.setItem('activeComputerParameter', parameterId);

    history.push(
      `/devices/${computer.id}/paramHistoryStatus?parameter=${parameterId}&period=30`,
    );
  };

  return hasStatusesToShow ? (
    <Flex flow="column" gap="xl">
      {Object.keys(STATUS_TYPES).map((type: StatusTypeKind) => {
        const statuses = groupedStatuses[type];
        if (statuses) {
          const { dateColumnName } = STATUS_TYPES[type];
          const tableName = displayLibraryCategory(STATUS_TYPES[type].name);
          const translatedDateColumnName =
            translateDateColumnName(dateColumnName);

          // Retrieve the necessary data for each status row in the table
          const rowData: StatusRow[] = statuses.map((s: Status) => ({
            id: s.library_item_id || s.parameter_id,
            name: s.name || s.nameVerbose,
            instanceName: s.instance_name,
            icon: s.icon || getItemConfig(s)?.icon,
            rulesPresent: s.rules_present,
            status: s.status,
            date: s.last_audit_run || s.date || s.run,
            actions: [
              ...(s.parameter_id
                ? [
                    {
                      label: i18n.t('View Parameter history'),
                      onClick:
                        // istanbul ignore next - browser functions
                        (e: MouseEvent) => {
                          e.stopPropagation();
                          handleViewParameterHistoryClick(s.parameter_id);
                        },
                    },
                  ]
                : []),
            ],
            expandedContent: getExpandedDetails(s, type),
            url: getItemConfig(s)?.getUrl({
              id: s.library_item_id,
              account,
              environment,
            }),
            computer,
          }));

          return (
            <StatusTable
              key={tableName}
              data={rowData}
              dateColumnName={translatedDateColumnName}
              tableName={tableName}
            />
          );
        }

        return null;
      })}
    </Flex>
  ) : (
    <Empty
      image={noSearchData}
      title={i18n.t('No results found')}
      message={i18n.t(
        "We couldn't find a match. Try changing your filter parameters, or search with different keywords.",
      )}
    />
  );
};
