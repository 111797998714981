import { createContext, useContext, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UserRoles } from 'src/app/common/constants';

import { api } from 'app/api/base';
import { URL_AUTH_CONNECTOR } from 'app/api/urls';

import { i18n } from 'i18n';
import featureFlags from 'src/config/feature-flags';

import {
  fetchAccount as callFetchAccount,
  updateUser as callUpdateUser,
} from '../app/_actions/account'; // TODO: remove this import after refactoring the routing to have Public routes inside Main and Switch
import { InterfaceContext } from './interface';

export interface UserSettings {
  timezone: string | null;
  preferred_date_format: string;
  disable_relative_dates: string | boolean;
  send_weekly_emails: boolean;
}

export interface AccountContextType {
  auth0UserId: undefined | string | null;
  isEmailPasswordAuth0: undefined | boolean;
  isSocial: undefined | boolean;
  isStandardAuth: undefined | boolean;
  userId: undefined | string;
  userEmail: undefined | string;
  userFirstName: undefined | string;
  userLastName: undefined | string;
  userRole: undefined | string;
  userLocale: undefined | string;
  userSettings: undefined | UserSettings;
  userType?: undefined | string;
  currentCompany: undefined | Record<string, unknown>;
  company: undefined | Record<string, unknown>;
  listAuthConnector: undefined | string[];
  tenantOverLicenseLimit: boolean;
  accountReady: boolean;
  timezone: string | null;
  disableRelativeDates: boolean;
  auth0: undefined;
  fetchAccount: () => Promise<void>;
  updateUser: () => void;
  fetchListAuthConnector: () => void;
  setListAuthConnector: () => void;
}

const defaultValues: AccountContextType = {
  auth0UserId: undefined,
  isEmailPasswordAuth0: undefined,
  isSocial: undefined,
  isStandardAuth: undefined,
  userId: undefined,
  userEmail: undefined,
  userFirstName: undefined,
  userLastName: undefined,
  userRole: undefined,
  userLocale: undefined,
  userSettings: undefined,
  currentCompany: undefined,
  company: undefined,
  listAuthConnector: undefined,
  tenantOverLicenseLimit: false,
  accountReady: false,
  timezone: null,
  disableRelativeDates: false,
  auth0: undefined,
  fetchAccount: async () => {},
  updateUser: () => {},
  fetchListAuthConnector: () => {},
  setListAuthConnector: () => {},
};

const AccountContext = createContext(defaultValues);

// TODO: move hasAuthToken logic back into this file after refactoring the routing to have Public routes inside Main and Switch

const AccountProviderConnect = ({
  auth0UserId,
  userId,
  userEmail,
  userFirstName,
  userLastName,
  userRole,
  userSettings,
  userType,
  userLocale,
  currentCompany,
  company,
  tenantOverLicenseLimit,
  accountReady,
  fetchAccount,
  updateUser,
  auth0,
  children,
  value,
}) => {
  const { setSnackbar } = useContext(InterfaceContext);

  const dispatch = useDispatch();

  const [listAuthConnector, setListAuthConnector] = useState([]);

  const fetchListAuthConnector = async () => {
    try {
      const { data: connData } = await api(URL_AUTH_CONNECTOR).get({});
      const { connections } = connData;

      setListAuthConnector(connections);
    } catch (error) {
      dispatch(setSnackbar({ message: i18n.common.error() }));
    }
  };

  // Whether or not the user is logged in via Standard Authentication
  // For all Valid Strategy names, reference: https://auth0.com/docs/api/management/v2#!/Connections/post_connections
  const isEmailPasswordAuth0 = auth0UserId?.startsWith('auth0');
  const isSocial =
    auth0UserId?.startsWith('google-oauth2') ||
    auth0UserId?.startsWith('office365');
  const isStandardAuth = isEmailPasswordAuth0 || isSocial;

  return (
    <AccountContext.Provider
      value={{
        ...defaultValues,
        auth0UserId,
        isEmailPasswordAuth0,
        isSocial,
        isStandardAuth,
        userId,
        userEmail,
        userFirstName,
        userLastName,
        userRole,
        userType,
        userLocale,
        userSettings,
        currentCompany,
        company,
        listAuthConnector,
        tenantOverLicenseLimit,
        accountReady,
        fetchAccount,
        updateUser,
        auth0,
        fetchListAuthConnector,
        setListAuthConnector,
        ...value,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

const mapStateToProps = ({ account, auth0, company }) => ({
  auth0UserId: auth0.userId,
  userId: account.user.id,
  userEmail: account.user.email,
  userFirstName: account.user.first_name || account.user.userFirstName,
  userLastName: account.user.last_name || account.user.userLastName,
  userRole: account.user.role,
  userType: account.user.type,
  userLocale: account.user.locale,
  currentCompany: account.company,
  tenantOverLicenseLimit: account.tenantOverLicenseLimit,
  userSettings: account.user.settings,
  accountReady: account.accountReady,
  auth0,
  company,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAccount: callFetchAccount,
      updateUser: callUpdateUser,
    },
    dispatch,
  );

const AccountProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountProviderConnect);

const useAccount = () => useContext(AccountContext);

const usePermissions = () => {
  const { userRole } = useAccount();
  const canManageBlueprints = [UserRoles.admin, UserRoles.standard].includes(
    userRole,
  );
  const canManageDevices = [
    UserRoles.admin,
    UserRoles.standard,
    UserRoles.helpdesk,
  ].includes(userRole);
  const canManageSettings = [UserRoles.admin].includes(userRole);
  const canViewPulse = [UserRoles.admin, UserRoles.standard].includes(userRole);
  const canAssignADEUser = [
    UserRoles.admin,
    UserRoles.standard,
    UserRoles.helpdesk,
  ].includes(userRole);

  const isSecretsAuditorRoleEnabled = featureFlags.getFlag(
    'secrets-auditor-role',
  );
  const canViewSecrets = [
    UserRoles.admin,
    UserRoles.standard,
    UserRoles.helpdesk,
    ...(isSecretsAuditorRoleEnabled ? [UserRoles.secrets_auditor] : []),
  ].includes(userRole);

  return {
    canManageBlueprints,
    canManageDevices,
    canManageSettings,
    canViewPulse,
    canAssignADEUser,
    canViewSecrets,
  };
};

const withPermissions = (Component) => (props) => (
  <Component {...props} permissions={usePermissions()} />
);

export default useAccount;
export { AccountContext, AccountProvider, usePermissions, withPermissions };
