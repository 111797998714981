import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Box, Code, Dialog, Flex, Icon, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import React, { useEffect, useState } from 'react';

const BypassModal = (props) => {
  const {
    computerId,
    bypassCode,
    bypassCodeFailed,
    getComputerBypassCode,
    onHide,
  } = props;

  const {
    user_based_albc: userBasedALBC = '',
    device_based_albc: deviceBasedALBC = '',
  } = bypassCode || {};
  const noBypassCodes = !userBasedALBC && !deviceBasedALBC;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getComputerBypassCode(computerId).finally(() => setIsLoading(false));
  }, []);

  const handleOnCopy = (value) => {
    navigator.clipboard.writeText(value);
    toaster(i18n.t('Copied to clipboard.'));
  };

  const content = !isLoading && (
    <Flex flow="column" css={{ minWidth: '$15' }}>
      {noBypassCodes && bypassCodeFailed && (
        <Text>
          {i18n.t(
            'The Activation Lock Bypass code was expired prior to enrollment into Kandji.',
          )}{' '}
          <a href="https://support.kandji.io/support/solutions/articles/72000558685">
            <Text
              variant="primary"
              css={{
                display: 'inline-block',
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              {i18n.t('Learn more...')}
            </Text>
          </a>
        </Text>
      )}

      {noBypassCodes && !bypassCodeFailed && (
        <Text>{i18n.t('There is no bypass code for this device.')}</Text>
      )}

      {(userBasedALBC || deviceBasedALBC) && (
        <Flex flow="column" gap="lg">
          <Flex flow="column" gap="sm">
            <Text size="2" css={{ fontWeight: '$medium' }}>
              {i18n.t('User-based bypass code:')}
            </Text>
            {userBasedALBC ? (
              <Flex flow="row" gap="sm">
                <Code>{userBasedALBC}</Code>
                {userBasedALBC && (
                  <Box
                    css={{ '&:hover': { cursor: 'pointer' } }}
                    onClick={() => handleOnCopy(userBasedALBC)}
                    data-testid="copy-user-based-albc"
                  >
                    <Icon
                      name="copy"
                      color="var(--colors-neutral50)"
                      size="sm"
                    />
                  </Box>
                )}
              </Flex>
            ) : (
              <Text>{i18n.t('Not available')}</Text>
            )}
          </Flex>

          <Flex flow="column" gap="sm">
            <Text size="2" css={{ fontWeight: '$medium' }}>
              {i18n.t('Device-based bypass code:')}
            </Text>
            {deviceBasedALBC ? (
              <Flex flow="row" gap="sm">
                <Code>{deviceBasedALBC}</Code>
                <Box
                  css={{ '&:hover': { cursor: 'pointer' } }}
                  onClick={() => handleOnCopy(deviceBasedALBC)}
                  data-testid="copy-device-based-albc"
                >
                  <Icon name="copy" color="var(--colors-neutral50)" size="sm" />
                </Box>
              </Flex>
            ) : (
              <Text>{i18n.t('Not available')}</Text>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );

  return (
    <Dialog
      isOpen
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={onHide}
      title="Bypass Code"
      content={content}
      css={{ zIndex: 2000 }}
    />
  );
};

export default BypassModal;
