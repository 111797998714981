/* istanbul ignore file */
import { datadogLogs } from '@datadog/browser-logs';
import { useQuery } from '@tanstack/react-query';
import { vulnerabilityApi } from 'src/app/api/base';

const useVulnerabilitiesExportStatusQuery = ({
  exportId,
}: {
  exportId: string | undefined;
}) => {
  return useQuery({
    queryKey: ['vulnerabilities-export-status', exportId],
    queryFn: () => getVulnerabilitiesExportStatus(exportId),
    enabled: !!exportId,
    refetchInterval: ({ state }) => {
      const status = state?.data?.status;
      const signedUrl = state?.data?.signed_url;
      if (status === 'pending' || (!signedUrl && status !== 'failed')) {
        return 1000;
      }
      return false;
    },
  });
};

const getVulnerabilitiesExportStatus = async (id: string | undefined) => {
  const client = vulnerabilityApi?.(`v1/export/${id}`);

  if (!client) {
    throw new Error('Vulnerability API not found');
  }

  const res = await client.get({});

  try {
    return res.data;
  } catch (e) {
    datadogLogs.logger.error('Error getting vulnerabilities export status', {
      error: e,
    });
    return res.data;
  }
};

export { useVulnerabilitiesExportStatusQuery };
