import {
  NFSelectV2,
  ValidationTemplates,
  registerField,
} from '@kandji-inc/nectar-form';
import {
  Box,
  Button,
  ConditionalTooltip,
  Flex,
  Heading,
} from '@kandji-inc/nectar-ui';
import { AssignmentMapOSVersion } from 'features/rules-modal/components/AssignmentMapOSVersion';
import { LibraryItemOSVersion } from 'features/rules-modal/components/LibraryItemOSVersion';
import { RuleValue } from 'features/rules-modal/components/RuleValue';
import type { FacetMap } from 'features/rules-modal/types';
import { getAllInputOptions } from 'features/rules/builder/helpers';
import {
  AM_OS_VERSION_PARENT_KEY,
  OS_VERSION_PARENT_KEY,
} from 'features/rules/constants';
import React from 'react';
import { i18n } from 'src/i18n';
import { FacetCustomSelect } from './FacetCustomSelect';

export const Rule = ({
  facetMap,
  supportedDeviceFamilies,
  installOnDeviceFamilies,
  index,
  fieldPrefix,
  currentlySelectedInputs,
  canRemoveRule,
  removeRule,
  hasUserDirectoryIntegration,
}: {
  facetMap: FacetMap;
  supportedDeviceFamilies: string[];
  installOnDeviceFamilies: string[];
  index: number;
  fieldPrefix: string;
  currentlySelectedInputs: string[];
  canRemoveRule: boolean;
  hasUserDirectoryIntegration: boolean;
  removeRule: () => void;
}) => {
  const requiredInput = ValidationTemplates.required(
    i18n.t('Input is required'),
  );
  const inputField = registerField(`${fieldPrefix}.input`);
  const currentInput = inputField.getValue();
  const isOSVersionInput =
    currentInput === OS_VERSION_PARENT_KEY ||
    currentInput === AM_OS_VERSION_PARENT_KEY;

  const facetOptions = [
    ...getAllInputOptions(facetMap)
      .filter((option) =>
        option.deviceFamilies.some((family) =>
          supportedDeviceFamilies.includes(family),
        ),
      )
      .filter(
        (option) =>
          option.value === currentInput ||
          !currentlySelectedInputs.includes(option.value),
      )
      // disable options based on user directory integration or supported device families:
      .map((option) => {
        const isDeviceFamilyUnsupported = !option.deviceFamilies.some(
          (family) => supportedDeviceFamilies.includes(family),
        );
        const isUserDirectoryIntegrationDisabled =
          option.subject === 'user_directory_integration' &&
          !hasUserDirectoryIntegration;

        let disabledHint;
        /* istanbul ignore next */
        if (isDeviceFamilyUnsupported) {
          disabledHint = i18n.t(
            'This option is not supported by the library item.',
          );
        } else if (isUserDirectoryIntegrationDisabled) {
          disabledHint = i18n.t(
            'A supported directory integration is required.',
          );
        }

        return {
          ...option,
          disabled:
            isDeviceFamilyUnsupported || isUserDirectoryIntegrationDisabled,
          hint: disabledHint,
        };
      }),
  ];

  // check eligibility of the rule based on the device platforms chosen
  let isApplicable = true;
  if (
    currentInput &&
    currentInput !== OS_VERSION_PARENT_KEY &&
    currentInput !== AM_OS_VERSION_PARENT_KEY
  ) {
    isApplicable = facetMap[currentInput].device_families.some((family) =>
      installOnDeviceFamilies.includes(family),
    );
  }

  return (
    <Flex
      flow="row"
      gap="lg"
      css={{
        paddingBottom: '$4',
        borderBottom: '1px solid $neutral20',
        '&:not(:first-child)': {
          paddingTop: '$4',
        },
      }}
      data-testid={`rule-${index}`}
    >
      <Heading
        size="5"
        css={{
          display: 'flex',
          height: '38px',
          width: '21px', // 21px is the width of 2-digit numbers
          alignItems: 'center',
          fontWeight: '$medium',
        }}
      >
        {index + 1}.
      </Heading>
      <ConditionalTooltip
        content={
          isApplicable
            ? undefined
            : i18n.t(
                'This rule will no longer be applied based on the device platforms chosen.',
              )
        }
        css={{ zIndex: 9999 }}
        side="top"
      >
        <Flex justifyContent="space-between" css={{ flexGrow: 1 }}>
          <Flex
            flow={currentInput === AM_OS_VERSION_PARENT_KEY ? 'column' : 'row'}
            gap="sm"
            alignItems="start"
            css={{ width: '100%' }}
          >
            <Box
              css={{
                width:
                  isOSVersionInput && currentInput !== AM_OS_VERSION_PARENT_KEY
                    ? '140px' // makes room for the device family field
                    : '240px',
              }}
            >
              <FacetCustomSelect
                fieldName={inputField.getName()}
                options={facetOptions}
                disabled={!isApplicable}
                validation={requiredInput}
                placeholder={i18n.t('Select condition')}
                ariaLabel="Input"
              />
            </Box>
            <React.Fragment key={currentInput}>
              {currentInput === AM_OS_VERSION_PARENT_KEY && (
                <AssignmentMapOSVersion
                  arrayName={`${fieldPrefix}.${currentInput}.children`}
                  facetMap={facetMap}
                />
              )}
              {currentInput === OS_VERSION_PARENT_KEY && (
                <LibraryItemOSVersion
                  fieldPrefix={`${fieldPrefix}.${currentInput}`}
                  facetMap={facetMap}
                  installOnDeviceFamilies={installOnDeviceFamilies}
                />
              )}
              {currentInput &&
                currentInput !== AM_OS_VERSION_PARENT_KEY &&
                currentInput !== OS_VERSION_PARENT_KEY && (
                  <>
                    <Box css={{ width: '240px' }}>
                      <NFSelectV2
                        name={`${fieldPrefix}.${currentInput}.operator`}
                        options={facetMap[currentInput].supported_operators}
                        disabled={
                          !isApplicable ||
                          facetMap[currentInput].supported_operators.length ===
                            1
                        }
                        readOnly={
                          !isApplicable ||
                          facetMap[currentInput].supported_operators.length ===
                            1
                        }
                        aria-label="Operator"
                      />
                    </Box>
                    <Box css={{ width: '420px' }}>
                      <RuleValue
                        operatorFieldName={`${fieldPrefix}.${currentInput}.operator`}
                        valueFieldPrefix={`${fieldPrefix}.${currentInput}`}
                        facetData={facetMap[currentInput]}
                        disabled={!isApplicable}
                        aria-label={i18n.t('Value')}
                      />
                    </Box>
                  </>
                )}
            </React.Fragment>
          </Flex>
          {canRemoveRule && currentInput !== AM_OS_VERSION_PARENT_KEY && (
            <Box>
              <Button
                variant="subtleDanger"
                icon={{ name: 'trash-can' }}
                onClick={removeRule}
                data-testid="remove-rule"
              />
            </Box>
          )}
        </Flex>
      </ConditionalTooltip>
    </Flex>
  );
};
