import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import type { VulnerabilityState } from '../../store';
import type {
  AllVulnerabilitiesRequestSchema,
  DeviceVulnerabilitiesRequestSchema,
  VulnerableApplicationsRequestSchema,
  VulnerableDevicesRequestSchema,
} from '../../vulnerability.types';

const addDay = (dateString: string) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  return date.toISOString();
};

export const transformDetectionDateFilterToApi = (
  dateFilter: DetectionDateFilterFields,
) => {
  if (!dateFilter) {
    return null;
  }

  const { value, operator, dateRangeFrom, dateRangeTo, selectedDate } =
    dateFilter;

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!value || value === 'all_time') {
    return null;
  }

  /* istanbul ignore if */
  if (operator) {
    if (operator === 'ib') {
      return {
        gte: dateRangeFrom,
        lte: dateRangeTo,
      };
    }

    if (operator === 'eq') {
      return {
        gte: selectedDate,
        lt: addDay(selectedDate),
      };
    }

    return { [operator]: selectedDate };
  }

  return { relative: { value, timezone } };
};

export const transformAllVulnerabilitiesFilterToApi = (filter: {
  severity: VulnerabilityState['allVulnerabilitiesFilter']['severity'];
  latestDetected: VulnerabilityState['allVulnerabilitiesFilter']['latestDetected'];
}): AllVulnerabilitiesRequestSchema['filter'] => {
  const { severity, latestDetected } = filter;

  return {
    severity: { in: severity?.length ? severity : null },
    latest_detection_date: transformDetectionDateFilterToApi(latestDetected),
  };
};

export const transformDeviceVulnerabilitiesFilterToApi = (
  filter: VulnerabilityState['deviceVulnerabilitiesFilter'],
): DeviceVulnerabilitiesRequestSchema['filter'] => {
  const { severity, detectionDate } = filter;

  return {
    severity: { in: severity.length ? severity : null },
    detection_datetime: transformDetectionDateFilterToApi(detectionDate),
  };
};

export const transformVulnerableDevicesFilterToApi =
  /* istanbul ignore next */ (filter: {
    detectionDate: DetectionDateFilterFields;
    blueprints: string[];
  }): VulnerableDevicesRequestSchema['filter'] => {
    const { detectionDate, blueprints } = filter;

    return {
      detection_datetime: transformDetectionDateFilterToApi(detectionDate),
      blueprint_id: { in: blueprints.length ? blueprints : null },
    };
  };

export const transformVulnerableApplicationsFilterToApi =
  /* istanbul ignore next */ (filter: {
    latestDetectionDate: DetectionDateFilterFields;
    blueprints: string[];
  }): VulnerableApplicationsRequestSchema['filter'] => {
    const { latestDetectionDate, blueprints } = filter;

    return {
      latest_detection_date:
        transformDetectionDateFilterToApi(latestDetectionDate),
      blueprint_id: { in: blueprints.length ? blueprints : null },
    };
  };
