/* istanbul ignore file */
import { Button, Icon } from '@kandji-inc/bumblebee';
import classNames from 'classnames';
import { InterfaceContext } from 'contexts/interface';
import { i18n } from 'i18n';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getTopOffset } from '../../common/helpers';

const colorsMap = {
  danger: '#EA3939',
  warning: '#F56F15',
  info: '#4F5360',
};

const Wrapper = styled('div')`
  margin-left: ${(props) =>
    props.sidebarDocked && props.sidebarOpened ? 256 : 77}px;
  position: relative;
  min-height: 57px;
  color: white;
  background: ${(props) => props.color};

  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 16px 100px;
  }
`;

const Content = styled('div')`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  font-size: 20px;
  margin-right: 10px;
`;

const Text = styled('div')`
  font-family: var(--font-body-s-family);
  font-style: normal;
  font-size: var(--font-body-s-size);
  line-height: var(--font-body-s-line-height);
  font-weight: var(--font-body-s-weight);
  letter-spacing: var(--font-body-s-letter-spacing);
  display: flex;
  align-items: center;
`;

const FirstButton = styled(Button)`
  margin-left: 30px;

  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  &:hover {
    &:not(:disabled) {
      color: #fff;
      background: transparent;
      transform: none;
    }
  }
`;

const SecondButton = styled(FirstButton)`
  margin-left: 14px;
  border: none;
  &:hover {
    &:not(:disabled) {
      border-bottom: none;
    }
  }
`;
const CloseIcon = styled(Icon)`
  position: absolute;
  top: 27px;
  transform: translateY(-50%);
  right: 27px;
  cursor: pointer;
`;

const UniversalAlert = ({
  type = 'info',
  closable = false,
  icon = 'octagon-exclamation',
  text = i18n.t('Learn More'),
  button = null,
  secondButton = null,
  sidebarDocked,
  sidebarOpened,
}) => {
  const [close, setClose] = useState(false);
  const onCloseClick = useCallback(() => setClose(true), []);
  const { setBannerTopOffset } = useContext(InterfaceContext);

  useEffect(() => {
    setBannerTopOffset();
  }, [close]);

  return (
    <Wrapper
      color={colorsMap[type]}
      sidebarDocked={sidebarDocked}
      sidebarOpened={sidebarOpened}
      className={classNames({ 'd-none': close })}
      data-testid="banner-wrapper"
    >
      <Content>
        <StyledIcon name={icon} />
        <Text>{text}</Text>
        {button && (
          <FirstButton
            className="pendo-configure-apns"
            onClick={button.onClick}
          >
            {button.text}
          </FirstButton>
        )}
        {secondButton && (
          <SecondButton
            onClick={
              secondButton.closeOnClick ? onCloseClick : secondButton.onClick
            }
          >
            {secondButton.text}
          </SecondButton>
        )}
      </Content>
      {closable && <CloseIcon onClick={onCloseClick} name="xmark" />}
    </Wrapper>
  );
};

UniversalAlert.propTypes = {
  type: PropTypes.oneOf(['danger', 'warning', 'info']),
  closable: PropTypes.bool,
  icon: PropTypes.string,
  text: PropTypes.string,
  button: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  secondButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
    closeOnClick: PropTypes.bool,
  }),
  sidebarDocked: PropTypes.bool.isRequired,
  sidebarOpened: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  sidebarDocked: state.ui.sidebar.docked,
  sidebarOpened: state.ui.sidebar.opened,
});

export default connect(mapStateToProps)(UniversalAlert);

const BannerWrapper = styled('div').attrs(() => ({
  id: 'UniversalAlertsLayer',
}))`
  position: sticky;
  top: ${getTopOffset(true)}px;
  z-index: 1999;
  display: flex;
  flex-direction: column;
`;

export { BannerWrapper };
