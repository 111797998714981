/* istanbul ignore file */
import { Button, Icon } from '@kandji-inc/bumblebee';
import { getComputer, startGetComputers } from 'app/_actions/computer';
import { queryTimeline } from 'app/_actions/timeline';
import { setSnackbar } from 'app/_actions/ui';
import {
  EnrollmentType,
  MDMCommandStatus,
  ParameterType,
  activityPeriodList,
  activityTypeLists,
  colors,
  defaultActivityFilters,
  i18nMDMCommandStatus,
  links,
  parameterDetails,
  settingNameText,
} from 'app/common/constants';
import classNames from 'classnames';
import { usePermissions } from 'contexts/account';
import { InterfaceContext } from 'contexts/interface';
import DOMPurify from 'dompurify';
import camelCase from 'lodash/camelCase';
import clone from 'lodash/clone';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './ActivityTabNew.styles.scss';

import { paths } from 'src/features/blueprints/common';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import history from '../../router/history';
import AwesomeTable from '../common/AwesomeTable';
import BootstrapTable from '../common/BootstrapTable/BootstrapTable';
import TableHeaderColumn from '../common/BootstrapTable/TableHeaderColumn';
import TableNoDataHelper from '../common/TableNoDataHelper';
import { getStatusIconClass } from '../common/helpers';
import AwesomeTableToolBarNew from '../interface/AwesomeTableToolBarNew';
import { HoveredSpan } from '../interface/Base';
import FilterSelect from '../interface/FilterSelect';
import { LineLoader } from '../interface/LineLoader';
import { H2 } from '../interface/Typography';
import {
  cancelAllPendingCommand,
  cancelPendingCommand,
  getPendingCommands,
  retryFailedCommand,
} from './api';

// This will be refactored to using the Nectar IconNames type when we nectarize
import type { IconNames } from '@kandji-inc/bumblebee/lib/atoms/icon/IconNames';
import { i18n } from 'i18n';
import { InfiniteScrollProvider } from '../common/AwesomeTable/InfiniteScrollProvider';
import {
  ADCSCertificateFailure,
  ADCSCertificateIssued,
  ADCSConnectorAdded,
  ADCSConnectorDeleted,
  ADCSConnectorOffline,
  ADCSConnectorOnline,
  ADCSServerAdded,
  ADCSServerDeleted,
  ADCSServerUpdated,
} from './cases';

const queryString = require('query-string');

const notExpandableRows = [
  'blueprint_created',
  'decrypt_file_vault_prk',
  'receive_file_vault_prk',
  'computer_unlocked',
  'computer_mdm_removed',
  'agent_is_missing',
  'mdm_profile_renewed',
  'device_unlock_pin_viewed',
  'activation_lock_bypass_code_viewed',
];

const ActivityTabNew = (props) => {
  const permissions = usePermissions();
  const activeTableFilters = queryString.parse(props.location.search, {
    arrayFormat: 'bracket',
  });
  const initType = activeTableFilters.type || defaultActivityFilters.type;
  const initPeriod = activeTableFilters.period || defaultActivityFilters.period;
  const initOrdering =
    activeTableFilters.ordering || defaultActivityFilters.ordering;

  const [needFetching, setNeedFetching] = useState(true);
  const [timeline, setTimeline] = useState([]);
  const [next, setNext] = useState(null);
  const [stopNext, setStopNext] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedType, setSelectedType] = useState(initType);
  const [selectedPeriod, setSelectedPeriod] = useState(initPeriod);
  const [ordering, setOrdering] = useState(initOrdering);
  const [search, setSearch] = useState('');

  const [commandList, setCommandList] = useState([]);
  const [commandListLoading, setCommandListLoading] = useState(true);

  const { bannerTopOffset } = useContext(InterfaceContext);

  const HelperText = styled('div')`
    margin-left: -70px;
    margin-bottom: 10px;
  `;

  const HelperTextRow = styled('div')`
    padding: 5px;
  `;

  const HelperTextBold = styled('div')`
    font-weight: 500;
  `;

  const TableHeader = styled('div')`
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.2em;
    margin-bottom: 10px;
    margin-top: 20px;
  `;

  const getRequestQuery = () => {
    const urlParams = new URLSearchParams(props.location.search);
    return urlParams.toString();
  };

  const cancelAllPendingCommands = () => {
    cancelAllPendingCommand(props.computerId)
      .then(() =>
        getPendingCommands(props.computerId)({ status: [1, 2, 5, 6] })
          .then((data) => setCommandList(data.results))
          .then(() => props.getComputer(props.computerId))
          .finally(() => setCommandListLoading(false)),
      )
      .catch(() => props.setSnackbar(i18n.common.error()));
  };

  useEffect(() => {
    const {
      activityTabType,
      computer,
      computerId,
      startGetComputers: callStartGetComputers,
    } = props;
    callStartGetComputers();
    if (
      activityTabType === 'computer' &&
      computer.is_mdm &&
      computer.erase_status !== 'yes'
    ) {
      getPendingCommands(computerId)({ status: [1, 2, 5, 6] })
        .then((data) => setCommandList(data.results))
        .finally(() => setCommandListLoading(false));
    }
  }, []);

  useEffect(() => {
    setTimeline([]);
    setNext(null);
    setNeedFetching(true);
    // }, [selectedType, selectedPeriod, ordering, search]);
  }, [selectedType, selectedPeriod, ordering]);

  useEffect(() => {
    const fetchTimeline = (
      activityTabType,
      computerId,
      blueprintId,
      libraryItemId,
    ) => {
      setIsLoading(true);
      const requestQuery = getRequestQuery();
      props
        .queryTimeline(
          activityTabType,
          computerId,
          blueprintId,
          libraryItemId,
          next,
          requestQuery,
        )
        .then((res) => {
          setTimeline([
            ...timeline,
            ...res.results.map((result) => {
              const { text, description } = getActionData(result);
              return { ...result, textDescription: `${text}: ${description}` }; // it needs for correctly search]
            }),
          ]);
          setNext(res.next);
          if (!res.next) {
            setStopNext(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
          setNeedFetching(false);
        });
    };
    if (needFetching && !stopNext) {
      fetchTimeline(
        props.activityTabType,
        props.computerId,
        props.blueprintId,
        props.libraryItemId,
      );
    }
  }, [needFetching, stopNext]);

  const getMDMCommandName = (command) => {
    const commandName = get(command, 'request_type', i18n.t('Not found'));
    if (commandName === 'Settings' && command.metadata.SettingName) {
      return `${commandName}.${command.metadata.SettingName}`;
    }
    return commandName;
  };

  const MDMCommandRequestTypeFormat = (cell, row) => {
    const commandName = cell;
    if (commandName === 'Settings' && row.metadata.SettingName) {
      return `${commandName}.${row.metadata.SettingName}`;
    }
    return commandName;
  };

  interface ActionData {
    icon: IconNames;
    text: string;
    description?: JSX.Element | string;
  }

  const getActionData = (row): ActionData => {
    let computerName;
    switch (row.action_type) {
      case 'blueprint_created':
        return {
          icon: 'circle-plus',
          text: i18n.t('Blueprint Created'),
          description: row.details.name,
        };
      case 'blueprint_created_from_template':
        return {
          icon: 'circle-plus',
          text: i18n.t('Blueprint Created'),
          description: row.details.new_blueprint_name,
        };
      case 'blueprint_created_from_source':
        return {
          icon: 'circle-plus',
          text: i18n.t('Blueprint Created'),
          description: row.details.new_blueprint_name,
        };
      case 'blueprint_deleted':
        return {
          icon: 'trash-can',
          text: i18n.t('Blueprint Deleted'),
          description: row.details.name,
        };
      case 'blueprint_name_changed':
        return {
          icon: 'scroll-old',
          text: i18n.t('Blueprint Modified'),
          description: get(row, 'blueprint.name', i18n.t('Not found')),
        };
      case 'parameters_changed':
        return {
          icon: 'scroll-old',
          text: i18n.t('Blueprint Modified'),
          description: get(row, 'blueprint.name', i18n.t('Not found')),
        };
      case 'blueprint_desc_changed':
        return {
          icon: 'circle-info',
          text: i18n.t('Blueprint Description'),
          description: 'Modified',
        };
      case 'library_item_deleted':
        return {
          icon: 'trash-can',
          text: i18n.t('Library Item Deleted'),
          description: row.details.name,
        };
      case 'library_item_created':
        return {
          icon: 'grid-2',
          text: i18n.t('Library Item Created'),
          description: row.details.name,
        };
      case 'library_item_duplicated':
        return {
          icon: 'copy',
          text: i18n.t('Library Item Duplicated'),
          description: row.details.name,
        };
      case 'library_item_edited':
        return {
          icon: 'grid-2',
          text: i18n.t('Library Item Edited'),
          description: row.details.name,
        };
      case 'library_item_assignment_changed':
        return {
          icon: 'grid-2',
          text: i18n.t('Library Item Assignment Changed'),
          description: row.details.name,
        };
      case 'computer_into_blueprint':
      case 'computer_out_of_blueprint':
      case 'computer_deletion': {
        const computersCount = row.details.computers_count;
        let description = i18n.t('Not found');
        if (computersCount > 0) {
          description = i18n.t(
            '{computers, plural, one {# Device} other {# Devices}}',
            { computers: computersCount },
          );
        }
        if (row.action_type === 'computer_into_blueprint') {
          return {
            icon: 'arrow-right-arrow-left',
            text: i18n.t('Moved In'),
            description,
          };
        }
        if (row.action_type === 'computer_out_of_blueprint') {
          return {
            icon: 'arrow-right-arrow-left',
            text: i18n.t('Moved Out'),
            description,
          };
        }
        return {
          icon: 'trash-can',
          text: i18n.t('Deleted'),
          description,
        };
      }
      case 'blueprint_duplicated':
        return {
          icon: 'copy',
          text: i18n.t('Blueprint'),
          description: i18n.t('Duplicated'),
        };
      case 'blueprint_notes_created':
        return {
          icon: 'pencil',
          text: i18n.t('Note'),
          description: i18n.t('Created'),
        };
      case 'blueprint_notes_edited':
        return {
          icon: 'pencil',
          text: i18n.t('Note'),
          description: i18n.t('Edited'),
        };
      case 'blueprint_notes_deleted':
        return {
          icon: 'pencil',
          text: i18n.t('Note'),
          description: i18n.t('Deleted'),
        };
      case 'computer_enrollment':
        return {
          icon: 'laptop-mobile',
          text: i18n.t('Device Enrolled'),
          description: '',
        };
      case 'mdm_profile_renewed':
        return {
          icon: 'laptop-mobile',
          text: i18n.t('MDM Profile Renewal'),
          description: '',
        };
      case 'runs_after_enrollment':
        return {
          icon: 'kandji-bee',
          text: i18n.t('Parameters First Run'),
          description: '',
        };
      case 'agent_upgrades':
        return {
          icon: 'file-arrow-up',
          text: i18n.t('Agent Version Changed'),
          description: row.details.to,
        };
      case 'os_upgrades':
        return {
          icon: 'arrow-up',
          text: i18n.t('macOS Version Changed'),
          description: row.details.to,
        };
      case 'deletion_of_param_results':
        return {
          icon: 'circle-xmark',
          text: i18n.t('Parameters Deleted'),
          description: row.details.length,
        };
      case 'computer_notes_created':
        return {
          icon: 'pencil',
          text: i18n.t('Note Created'),
          description: '',
        };
      case 'computer_notes_edited':
        return {
          icon: 'pencil',
          text: i18n.t('Note Edited'),
          description: '',
        };
      case 'computer_notes_deleted':
        return {
          icon: 'pencil',
          text: i18n.t('Note Deleted'),
          description: '',
        };
      case 'device_unlock_pin_viewed':
        return {
          icon: 'lock-open',
          text: i18n.t('Viewed Device Unlock Pin'),
          description: '',
        };
      case 'activation_lock_bypass_code_viewed':
        return {
          icon: 'lock-open',
          text: i18n.t('Viewed Activation Lock Bypass Code'),
          description: '',
        };
      case 'enrollment':
        return {
          icon: 'laptop-mobile',
          text: i18n.t('Device Enrolled'),
          description: '',
        };
      case 'enrollment_details':
        return {
          icon: 'circle-info',
          text: i18n.t('Enrollment Details'),
          description: '',
        };
      case 'device_based_activation_lock_enabled':
        return {
          icon: 'desktop',
          text: i18n.t('Device Based Activation Lock Enabled'),
          description: '',
        };
      case 'username_changed':
        return {
          icon: 'user',
          text: i18n.t('User Updated'),
          description: row.details.to,
        };
      case 'asset_tag_changed':
        return {
          icon: 'note-sticky',
          text: i18n.t('Asset Tag Updated'),
          description: row.details.to,
        };
      case 'computer_tags_changed':
        return {
          icon: 'tag',
          text: i18n.t('Tags updated'),
          description: '',
        };
      case 'tag_changed':
        return {
          icon: 'tag',
          text: i18n.t('Tag renamed'),
          description: '',
        };
      case 'tag_deleted':
        return {
          icon: 'tag',
          text: i18n.t('Tag deleted'),
          description: '',
        };
      case 'tag_created':
        return {
          icon: 'tag',
          text: i18n.t('Tag created'),
          description: '',
        };
      case 'hardware_uuid_changed':
        return {
          icon: 'wrench',
          text: i18n.t('Hardware UUID Changed'),
          description: row.details.to,
        };
      case 'serial_number_changed':
        return {
          icon: 'wrench',
          text: i18n.t('Serial Number Changed'),
          description: row.details.to,
        };
      // *** do not delete ***
      // case 'runs':
      //   return {icon: 'fas fa-dot-circle', iconText: 'Runs', text: 'Checked In',
      //     description: row.details.blueprint_name};
      case 'local_user_added':
        return {
          icon: 'user-group',
          text: i18n.t('Local User'),
          description: i18n.t('Added'),
        };
      case 'local_user_deleted':
        return {
          icon: 'user-group',
          text: i18n.t('Local User'),
          description: i18n.t('Deleted'),
        };
      case 'local_user_changed':
        return {
          icon: 'user-group',
          text: i18n.t('Local Users'),
          description: i18n.t('Changes Detected'),
        };
      case 'name_changed':
        return {
          icon: 'user',
          text: i18n.t('Device Name Updated'),
          description: row.details.to,
        };
      case 'decrypt_file_vault_prk':
        return {
          icon: 'lock-open',
          text: i18n.t('Viewed FileVault Recovery Key'),
        };
      case 'receive_file_vault_prk':
        return {
          icon: 'lock-open',
          text: i18n.t('Received FileVault Recovery Key'),
        };
      case 'move_between_blueprints':
        return {
          icon: 'scroll-old',
          text: i18n.t('Blueprint Changed'),
          description: row.details.to,
        };
      case 'application_blocked':
        return {
          icon: 'ban',
          text: i18n.t('Application Blocked'),
          description: row.details.name,
        };
      case 'remediation_obtained':
        return {
          icon: 'wrench',
          text: i18n.t('Remediation'),
          description: row.details.parameter_name,
        };
      case 'first_run_remediation':
        return {
          icon: 'wrench',
          text: i18n.t('First Run Remediation'),
          description: row.details.parameter_name,
        };
      case 'computer_gone_missing':
        computerName =
          Object.keys(row.details).indexOf('computer_name') > -1
            ? row.details.computer_name
            : i18n.t('Not found');
        return {
          icon: 'desktop',
          text: i18n.t('Device Offline'),
          description: computerName,
        };
      case 'computer_gone_active':
        computerName =
          Object.keys(row.details).indexOf('computer_name') > -1
            ? row.details.computer_name
            : i18n.t('Not found');
        return {
          icon: 'desktop',
          text: i18n.t('Device Active'),
          description: computerName,
        };
      case 'mdm_command_completed': {
        const commandName = getMDMCommandName(row.details);
        return {
          icon: 'desktop',
          text: i18n.t('MDM Command Completed'),
          description: commandName,
        };
      }
      case 'mdm_command_failed_resend':
      case 'mdm_command_failed': {
        const commandName = getMDMCommandName(row.details);
        const onClick = (e) => {
          e.stopPropagation();
          retryFailedCommand(props.computerId)(row.details.uuid)
            .then(() =>
              props.setSnackbar(i18n.t('Command initiated for retry')),
            )
            .then(() =>
              getPendingCommands(props.computerId)({ status: [1, 2, 5, 6] })
                .then((data) => setCommandList(data.results))
                .finally(() => setCommandListLoading(false)),
            )
            .catch(() =>
              props.setSnackbar(
                i18n.t(
                  'Cannot retry command, device no longer assigned the Library Item.',
                ),
              ),
            );
        };
        return {
          icon: 'desktop',
          text: i18n.t('MDM Command Failed'),
          description: (
            <>
              {`${commandName}   `}
              {props.activityTabType === 'computer' && (
                <Button theme="dark" kind="link" onClick={onClick}>
                  {i18n.t('Resend')}
                </Button>
              )}
            </>
          ),
        };
      }
      case 'computer_unlocked': {
        return {
          icon: 'desktop',
          text: i18n.t('Device Unlocked'),
          description: '',
        };
      }
      case 'computer_mdm_removed': {
        return {
          icon: 'desktop',
          text: i18n.t('MDM Profile Removed'),
          description: '',
        };
      }
      case 'agent_is_missing': {
        return {
          icon: 'desktop',
          text: i18n.t('Agent Is Missing'),
          description: '',
        };
      }
      case 'vpp_asset_salable_detected': {
        return {
          icon: 'app-store-ios',
          text: i18n.t('App added to App Store'),
          description: row.details.app_name,
        };
      }
      case 'vpp_asset_non_salable_detected': {
        return {
          icon: 'app-store-ios',
          text: i18n.t('App removed from App Store'),
          description: row.details.app_name,
        };
      }

      /* istanbul ignore next */
      case 'adcs_connector_online': {
        return ADCSConnectorOnline.entry(row, props) as ActionData;
      }
      /* istanbul ignore next */
      case 'adcs_connector_offline': {
        return ADCSConnectorOffline.entry(row, props) as ActionData;
      }
      /* istanbul ignore next */
      case 'adcs_certificate_issued': {
        return ADCSCertificateIssued.entry(row) as ActionData;
      }
      /* istanbul ignore next */
      case 'adcs_certificate_issued_failure': {
        return ADCSCertificateFailure.entry(row) as ActionData;
      }
      /* istanbul ignore next */
      case 'adcs_certificate_renewed': {
        return {
          icon: 'check',
          text: i18n.t('Certificate issuance renewed'),
          description: row?.details?.event,
        };
      }

      /* istanbul ignore next */
      case 'adcs_connector_added': {
        return ADCSConnectorAdded.entry(row, props) as ActionData;
      }
      /* istanbul ignore next */
      case 'adcs_connector_deleted': {
        return ADCSConnectorDeleted.entry(row, props) as ActionData;
      }
      /* istanbul ignore next */
      case 'adcs_server_added': {
        return ADCSServerAdded.entry(row) as ActionData;
      }
      /* istanbul ignore next */
      case 'adcs_server_updated': {
        return ADCSServerUpdated.entry(row) as ActionData;
      }
      /* istanbul ignore next */
      case 'adcs_server_deleted': {
        return ADCSServerDeleted.entry(row) as ActionData;
      }
      /* istanbul ignore next */
      case 'recovery_password_viewed': {
        return {
          icon: 'lock-open',
          text: i18n.t('Recovery Password Viewed'),
          description: '',
        };
      }
      case 'company_owner_changed': {
        return {
          icon: 'circle-info',
          text: i18n.t('Company Owner Changed'),
          description: '',
        };
      }

      default:
        return {
          icon: 'question-circle',
          text: i18n.t('Not Found'),
          description: '',
        };
    }
  };

  const expandColumnComponent = (isExpanded, setIsExpanded, isExpandable) => {
    let content = '';
    if (isExpandable) {
      content = isExpanded ? (
        <Icon name="angle-down" size="xs" />
      ) : (
        <Icon name="angle-right" size="xs" />
      );
    } else {
      content = ' ';
    }
    return <div className="expand-wrapper">{content}</div>;
  };

  const formatDescriptionRow = (cell, row, isExpanded, setIsExpanded) => {
    // return <i className={`${icon} fa-lg`} title={iconText} />;
    const isExpandable = isExpandableRow(row);
    const expandComponent = expandColumnComponent(
      isExpanded,
      setIsExpanded,
      isExpandable,
    );
    const { icon, text, description } = getActionData(row);
    const iconTag = <Icon name={icon} size="sm" />;
    if (
      row.action_type === 'computer_notes_created' ||
      row.action_type === 'computer_notes_edited' ||
      row.action_type === 'computer_notes_deleted' ||
      !description
    ) {
      return (
        <div className="activity-tab-new-description">
          <div>{expandComponent}</div>
          <div className="icon-tag-wrapper">{iconTag}</div>
          <div className="description-text-wrapper">
            <span>{text}</span>
          </div>
        </div>
      );
    }
    return (
      <div className="activity-tab-new-description">
        <div>{expandComponent}</div>
        <div className="icon-tag-wrapper">{iconTag}</div>
        <div className="description-text-wrapper">
          <span className="title">{`${text}: `}</span>
          <span className="description" title={description as string}>
            {description}
          </span>
        </div>
      </div>
    );
  };

  const isExpandableRow = useCallback((row) => {
    if (notExpandableRows.includes(row.action_type)) {
      return !!(
        row.action_type === 'blueprint_created' &&
        (row.details.from_blueprint_name || row.details.library_items)
      );
    }
    return true;
  }, []);

  const formatUserRow = (cell, row) => <span title={row.user}>{row.user}</span>;

  const formatComputerRow = (cell, row) => {
    if (!isEmpty(row.computer)) {
      return (
        <span
          className={classNames({ 'table-link': !row.computer.is_removed })}
          role="presentation"
          onClick={() => {
            if (!row.computer.is_removed) {
              history.push(`${links.devices}/${row.computer.id}`);
            }
          }}
          title={row.computer.name}
        >
          {row.computer.name}
        </span>
      );
    }
  };

  const formatBlueprintRow = (cell, row) => {
    if (!isEmpty(row.blueprint)) {
      const rowBlueprint = props.blueprints.find(
        (bp) => bp.id === row.blueprint.id,
      );
      return (
        <span
          className={classNames({ 'table-link': !row.blueprint.is_removed })}
          role="presentation"
          onClick={() => {
            if (!row.blueprint.is_removed) {
              if (rowBlueprint) {
                history.push(paths.getBlueprintRouteByType(rowBlueprint));
              } else {
                history.push(`/blueprints/${row.blueprint.id}`);
              }
            }
          }}
          title={row.blueprint.name}
        >
          {row.blueprint.name}
        </span>
      );
    }
  };

  const formatCreatedAtRow = (cell, row) => (
    <HoveredSpan
      hoveredText={i18n.format.datetime(row.created_at)}
      text={i18n.format.datetime(row.created_at, { relative: true })}
    />
  );

  const prepareChangedValues = (el) => {
    const { parameterMeta } = props;
    if (parameterMeta[el[0]]) {
      const paramInputType = parameterMeta[el[0]].input
        ? parameterMeta[el[0]].input.type
        : null;
      const diff = { ...el[1].diff };
      const result = [];
      switch (paramInputType) {
        case 'disable_with_restrictions':
          result.push(
            i18n.t('Value: {value}', {
              value: parameterDetails(el[0], !isEmpty(diff.details)),
            }),
          );
          if (!isEmpty(get(diff.details, 'users', []))) {
            result.push(
              i18n.t('Users: {users}', {
                users: diff.details.users.join(', '),
              }),
            );
          }
          if (!isEmpty(get(diff.details, 'groups', []))) {
            result.push(
              i18n.t('Groups: {groups}', {
                groups: diff.details.groups.join(', '),
              }),
            );
          }
          return result;
        case 'application_blacklisting':
          Object.entries(diff.details).forEach((entry) => {
            result.push(parameterDetails(el[0], entry[0]));
            result.push('-----');
            if (entry[0] === 'message_customization') {
              Object.entries(entry[1]).forEach((mEl) => {
                result.push(`-- ${parameterDetails(el[0], mEl[0])}: ${mEl[1]}`);
              });
              result.push('-----');
            } else {
              entry[1].forEach((l) => {
                Object.entries(l).forEach((ll) => {
                  const fieldsName = [
                    'by_developer_id',
                    'by_bundle_id',
                    'by_path',
                    'by_process',
                  ];
                  if (fieldsName.includes(entry[0])) {
                    if (ll[0] !== 'type') {
                      result.push(
                        `-- ${parameterDetails(el[0], ll[0])}: ${ll[1]}`,
                      );
                    } else {
                      result.push(
                        `- ${i18n.t('Match type: {match_type}', {
                          match_type: parameterDetails(el[0], ll[1]),
                        })}`,
                      );
                    }
                  }
                });
                result.push('-----');
              });
            }
          });
          return result;
        case 'text_or_file':
          if (diff.details && diff.details.type === 'text') {
            result.push(
              i18n.t('Value: {value}', {
                value: diff.details.value,
              }),
            );
          } else if (diff.details && diff.details.type === 'file') {
            result.push(
              i18n.t('Value: {value}', {
                value: `${diff.details.filename}.${diff.details.extension}`,
              }),
            );
          }
          return result;
        case 'hot_corner':
          if (!isEmpty(diff) && !diff.details) {
            return result;
          }
          if (typeof diff.details === 'string') {
            result.push(i18n.t('Corner: {corner}', { corner: diff.details }));
            return result;
          }
          Object.keys(diff.details).map((key) =>
            result.push(
              `${key.capitalize()}: ${parameterDetails(
                el[0],
                diff.details[key],
              )}`,
            ),
          );
          return result;
        case 'secure_wifi_settings':
          Object.keys(diff.details).forEach((key) =>
            result.push(
              `${parameterDetails(el[0], key)}: ${diff.details[key]}`,
            ),
          );
          return result;
        case 'security_auditing_flags':
          if (isEmpty(diff.details)) {
            return null;
          }
          diff.details.forEach((item, index) => {
            result.push(
              `-- ${i18n.t('Flag: {flag}', {
                flag: parameterDetails(el[0], item.flag),
              })}`,
            );
            result.push(
              `-- ${i18n.t('Prefix: {prefix}', {
                prefix: parameterDetails(el[0], item.prefix),
              })}`,
            );
            if (index !== diff.details.length - 1) {
              result.push('-----');
            }
          });
          return result;
        case ParameterType.set_computer_name:
          if (diff.details.pattern) {
            result.push(
              `-- ${i18n.t('Pattern: {pattern}', {
                pattern: diff.details.pattern
                  .map((d) =>
                    d.startsWith('custom_text')
                      ? parameterDetails(el[0], 'custom_text')
                      : parameterDetails(el[0], d),
                  )
                  .join(' - '),
              })}`,
            );
          }
          if (!isEmpty(diff.details.custom_text)) {
            result.push(
              `-- ${i18n.t('Custom Text: {custom_text}', {
                custom_text: Object.values(diff.details.custom_text).join(', '),
              })}`,
            );
          }
          return result;
        case 'path': {
          const prevValues = el[1].from.details ? el[1].from.details : [];
          const newValues = el[1].to.details;
          const removed = prevValues
            ? prevValues.filter((prevVal) => !newValues.includes(prevVal))
            : [];
          const added = prevValues
            ? newValues.filter((prevVal) => !prevValues.includes(prevVal))
            : newValues;
          if (added.length > 0) {
            result.push(i18n.t('Added: {added}', { added }));
          }
          if (removed.length > 0) {
            result.push(i18n.t('Removed: {removed}', { removed }));
          }
          delete diff.details;
          Object.keys(diff).forEach((key) => {
            result.push(`${key.toString()}: ${diff[key].toString()}`);
          });
          return result;
        }
        case 'multi_value':
          Object.keys(diff.details).map((key) =>
            result.push(`${key.capitalize()}: ${diff.details[key]}`),
          );
          return result;
        case 'multi_array': {
          const getNames = () => {
            const names = [];
            const items = parameterMeta[el[0]].input.fields.map((field) => [
              field.key,
              field.weight,
            ]);
            items.sort((first, second) => first[1] - second[1]);
            items.map((item) => {
              if (item[0] !== 'password' && item[0] !== 'password2') {
                names.push(item[0]);
              }
            });
            return names;
          };
          diff.details.map((detail, index) => {
            result.push(i18n.t('Account # {index + 1}', { index: index + 1 }));
            const namesOfFields = getNames();
            namesOfFields.map((key) => {
              const fieldData = parameterMeta[el[0]].input.fields.find(
                (field) => field.key === key,
              );
              return result.push(
                `${fieldData.text.capitalize()} ${detail[key]}`,
              );
            });
            if (diff.details.length > 1 && index !== diff.details.length - 1) {
              result.push(
                <div
                  style={{
                    borderTop: '1px solid #e3eaf2',
                    width: 150,
                  }}
                />,
              );
            }
          });
          return result;
        }
        default: {
          if (!(diff.details === undefined || diff.details === null)) {
            const value =
              parameterDetails(el[0], diff.details) || diff.details.toString();
            result.push(i18n.t('Value: {value}', { value }));
            delete diff.details;
          }
          Object.keys(diff).forEach((key) => {
            result.push(`${key.toString()}: ${diff[key].toString()}`);
          });
          return result;
        }
      }
    }
  };

  const prepareTurnedOnValues = (el) => {
    const { parameterMeta } = props;
    if (parameterMeta[el[0]]) {
      let value;
      const result = [];
      const { details } = el[1];
      let paramInputType = null;
      if (!has(parameterMeta[el[0]], 'input')) {
        paramInputType = 'deleted';
      } else if (parameterMeta[el[0]].input) {
        paramInputType = parameterMeta[el[0]].input.type;
      }
      if (paramInputType === 'multi_array') {
        const getNames = () => {
          const names = [];
          const items = parameterMeta[el[0]].input.fields.map((field) => [
            field.key,
            field.weight,
          ]);
          items.sort((first, second) => first[1] - second[1]);
          items.map((item) => {
            if (item[0] !== 'password' && item[0] !== 'password2') {
              names.push(item[0]);
            }
          });
          return names;
        };
        details.map((detail, index) => {
          result.push(i18n.t('Account # {index + 1}', { index: index + 1 }));
          const namesOfFields = getNames();
          namesOfFields.map((key) =>
            result.push(`${key.capitalize()}: ${detail[key]}`),
          );
          if (details.length > 1 && index !== details.length - 1) {
            result.push(
              <div style={{ borderTop: '1px solid #e3eaf2', width: 150 }} />,
            );
          }
        });
        return result;
      }

      if (paramInputType === 'disk_media_accesses') {
        const getNames = () => {
          const names = [];
          const items = Object.keys(parameterMeta[el[0]].input.options).map(
            (field) => [
              field,
              parameterMeta[el[0]].input.options[field].weight,
            ],
          );
          items.sort((first, second) => first[1] - second[1]);
          items.map((item) => names.push(item[0]));
          return names;
        };
        const namesOfFields = getNames();
        namesOfFields.map((key) =>
          result.push(
            `${parameterMeta[el[0]].input.options[key].title}: ${
              details[key].allow
            }`,
          ),
        );
        return result;
      }
      if (paramInputType === 'disable_with_restrictions') {
        result.push(
          i18n.t('Value: {value}', {
            value: parameterDetails(el[0], !isEmpty(details)),
          }),
        );
        if (!isEmpty(get(details, 'users', []))) {
          result.push(
            i18n.t('Users: {users}', {
              users: details.users.join(', '),
            }),
          );
        }
        if (!isEmpty(get(details, 'groups', []))) {
          result.push(
            i18n.t('Groups: {groups}', {
              groups: details.groups.join(', '),
            }),
          );
        }
        return result;
      }
      if (paramInputType === 'application_blacklisting') {
        Object.entries(details).forEach((entry) => {
          result.push(parameterDetails(el[0], entry[0]));
          result.push('-----');
          if (entry[0] === 'message_customization') {
            Object.entries(entry[1]).forEach((mEl) => {
              result.push(`-- ${parameterDetails(el[0], mEl[0])}: ${mEl[1]}`);
            });
            result.push('-----');
          } else {
            entry[1].forEach((l) => {
              Object.entries(l).forEach((ll) => {
                const fieldsName = [
                  'by_developer_id',
                  'by_bundle_id',
                  'by_path',
                  'by_process',
                ];
                if (fieldsName.includes(entry[0])) {
                  if (ll[0] !== 'type') {
                    result.push(
                      `-- ${parameterDetails(el[0], ll[0])}: ${ll[1]}`,
                    );
                  } else {
                    result.push(
                      `- ${i18n.t('Match type: {match_type}', {
                        match_type: parameterDetails(el[0], ll[1]),
                      })}`,
                    );
                  }
                }
              });
              result.push('-----');
            });
          }
        });
        return result;
      }
      if (paramInputType === 'hot_corner') {
        if (typeof details === 'string') {
          result.push(i18n.t('Corner: {corner}', { corner: details }));
          return result;
        }
        Object.keys(details).forEach((key) =>
          result.push(
            `${key.capitalize()}: ${parameterDetails(el[0], details[key])}`,
          ),
        );
        return result;
      }
      if (paramInputType === 'secure_wifi_settings') {
        Object.keys(details).forEach((key) =>
          result.push(`${parameterDetails(el[0], key)}: ${details[key]}`),
        );
        return result;
      }
      if (paramInputType === 'security_auditing_flags') {
        if (isEmpty(details)) {
          return null;
        }
        details.forEach((item, index) => {
          result.push(
            i18n.t('Flag: {flag}', {
              flag: parameterDetails(el[0], item.flag),
            }),
          );
          result.push(
            i18n.t('Prefix: {prefix}', {
              prefix: parameterDetails(el[0], item.prefix),
            }),
          );
          if (index !== details.length - 1) {
            result.push('-----');
          }
        });
        return result;
      }
      if (paramInputType === ParameterType.set_computer_name) {
        if (isEmpty(details)) {
          return null;
        }
        if (details.pattern) {
          result.push(
            `-- ${i18n.t('Pattern: {pattern}', {
              pattern: details.pattern
                .map((d) =>
                  d.startsWith('custom_text')
                    ? parameterDetails(el[0], 'custom_text')
                    : parameterDetails(el[0], d),
                )
                .join(' - '),
            })}`,
          );
        }
        if (details.custom_text) {
          result.push(
            `-- ${i18n.t('Custom Text: {custom_text}', {
              custom_text: Object.values(details.custom_text).join(', '),
            })}`,
          );
        }
        return result;
      }
      if (Array.isArray(details) && details.length > 0) {
        details.map((detailVal) => result.push(`Value: ${detailVal}`));
      } else if (typeof details === 'object') {
        if (isEmpty(details)) {
          return null;
        }
        if (details && details.type === 'file') {
          result.push(
            i18n.t('Value: {value}', {
              value: `${details.filename}.${details.extension}`,
            }),
          );
        } else {
          Object.keys(details).map((key) =>
            result.push(`${key.capitalize()}: ${details[key]}`),
          );
        }
      } else if (details && typeof details !== 'object') {
        value =
          parameterDetails(el[0], el[1].details) || el[1].details.toString();
        result.push(i18n.t('Value: {value}', { value }));
      }
      return result;
    }
  };

  const expandComponent = useCallback(
    (row) => {
      const { parameterMeta } = props;

      switch (row.action_type) {
        case 'os_upgrades':
        case 'asset_tag_changed':
        case 'username_changed':
        case 'move_between_blueprints':
        case 'hardware_uuid_changed':
        case 'serial_number_changed':
        case 'name_changed':
          return (
            <div className="parameter-details param-history-details">
              <p className="param-description">
                {row.details.from}
                {!row.details.from && (
                  <i className="far fa-times history-upgrade-icon" />
                )}
                <i className="fas fa-long-arrow-alt-right history-upgrade-icon" />
                {row.details.to}
              </p>
            </div>
          );
        case 'computer_tags_changed':
          return (
            <div className="parameter-details param-history-details">
              {row.details.added_tags.length > 0 && (
                <p className="param-description">
                  <span style={{ fontWeight: '500' }}>
                    {i18n.t('Tags added:')}
                  </span>{' '}
                  {row.details.added_tags.map(({ name }) => name).join(', ')}
                </p>
              )}
              {row.details.removed_tags.length > 0 && (
                <p className="param-description">
                  <span style={{ fontWeight: '500' }}>
                    {i18n.t('Tags removed:')}
                  </span>{' '}
                  {row.details.removed_tags.map(({ name }) => name).join(', ')}
                </p>
              )}
            </div>
          );
        case 'tag_created':
          return (
            <div className="parameter-details param-history-details">
              <p className="param-description">{row.details.name}</p>
            </div>
          );
        case 'tag_deleted':
          return (
            <div className="parameter-details param-history-details">
              <p className="param-description">{row.details.name}</p>
            </div>
          );
        case 'tag_changed':
          return (
            <div className="parameter-details param-history-details">
              <p className="param-description">
                {row.details.old_name}
                <i className="fas fa-long-arrow-alt-right history-upgrade-icon" />
                {row.details.new_name}
              </p>
            </div>
          );
        case 'agent_upgrades':
          return (
            <div className="parameter-details param-history-details">
              <p className="param-description">
                {row.details.from}
                {!row.details.from && (
                  <i className="far fa-times history-upgrade-icon" />
                )}
                <i className="fas fa-long-arrow-alt-right history-upgrade-icon" />
                {row.details.to}
              </p>
            </div>
          );
        case 'blueprint_created_from_template':
          return (
            <div className="parameter-details param-history-details">
              <p>
                {i18n.t(
                  'Created a new blueprint {newBpName} from a template {currentBpName}',
                  {
                    newBpName: row.details.new_blueprint_name,
                    currentBpName: row.details.current_blueprint_name,
                  },
                )}
              </p>

              {row.details?.library_items?.added_from_all_blueprint?.length >
                0 && (
                <div>
                  <p>
                    {i18n.t('Included All Classic Blueprints Library Items:')}
                  </p>
                  <ul>
                    {row.details.library_items.added_from_all_blueprint.map(
                      (name) => (
                        <li key={name}>{name}</li>
                      ),
                    )}
                  </ul>
                </div>
              )}

              {row.details?.library_items?.added_from_template?.length > 0 && (
                <div>
                  <p>
                    {i18n.t(
                      'Library Items created and assigned from template {bpName}:',
                      { bpName: row.details?.current_blueprint_name },
                    )}
                  </p>
                  <ul>
                    {row.details.library_items.added_from_template.map(
                      (name) => (
                        <li key={name}>{name}</li>
                      ),
                    )}
                  </ul>
                </div>
              )}
            </div>
          );
        case 'blueprint_created_from_source':
          return (
            <div className="parameter-details param-history-details">
              <p>
                {i18n.t('Duplicated from {sourceBpName} ID: {bpID}', {
                  sourceBpName: row.details?.source_blueprint_name,
                  bpID: row.details?.source_blueprint_id,
                })}
              </p>

              {row.details?.library_items?.added_from_all_blueprint?.length >
                0 && (
                <div>
                  {i18n.t('Included All Classic Blueprints Library Items:')}
                  <ul>
                    {row.details.library_items.added_from_all_blueprint.map(
                      (name) => (
                        <li key={name}>{name}</li>
                      ),
                    )}
                  </ul>
                </div>
              )}

              {row.details?.library_items?.added_from_source_blueprint?.length >
                0 && (
                <div>
                  <p>{i18n.t('Library Items assigned:')}</p>
                  <ul>
                    {row.details.library_items.added_from_source_blueprint.map(
                      (name) => (
                        <li key={name}>{name}</li>
                      ),
                    )}
                  </ul>
                </div>
              )}
            </div>
          );
        case 'blueprint_desc_changed':
          return (
            <div className="parameter-details param-history-details">
              <p>{row.details.description}</p>
            </div>
          );
        case 'enrollment_details':
          return (
            <div className="parameter-details param-history-details">
              {row.details.name && (
                <p className="param-description">
                  {i18n.t('Device Name: {name}', { name: row.details.name })}
                </p>
              )}
              {row.details.serial_number && (
                <p className="param-description">
                  {i18n.t('Serial Number: {serialNumber}', {
                    serialNumber: row.details.serial_number,
                  })}
                </p>
              )}
              {row.details.hardware_uuid && (
                <p className="param-description">
                  {i18n.t('Hardware UUID: {hardwareUuid}', {
                    hardwareUuid: row.details.hardware_uuid,
                  })}
                </p>
              )}
              {row.details.local_user && (
                <p className="param-description">
                  {i18n.t('Local Users: {localUser}', {
                    localUser: row.details.local_user,
                  })}
                </p>
              )}
              {row.details.agent_version && (
                <p className="param-description">
                  {i18n.t('Agent Version: {agentVersion}', {
                    agentVersion: row.details.agent_version,
                  })}
                </p>
              )}
              {row.details.os_version && (
                <p className="param-description">
                  {i18n.t('OS Version: {osVersion}', {
                    osVersion: row.details.os_version,
                  })}
                </p>
              )}
            </div>
          );
        case 'deletion_of_param_results':
          return (
            <div className="parameter-details param-history-details">
              {Object.entries(row.details).map((el) => {
                const paramName = parameterMeta[el[1].parameter].name;
                const timestamp = el[1].time_run;
                const statusIcon = getStatusIconClass(
                  el[1].status,
                  el[1].is_mute,
                );
                const time = i18n.format.datetime(timestamp, {
                  relative: true,
                });
                return (
                  <div key={uuidv4()}>
                    <p>
                      <i
                        className={`history-status-icon ${statusIcon}`}
                        title={el[1].status}
                      />
                      <span>{paramName}</span>
                    </p>
                    <p className="history-param-value">
                      v{el[1].agent_version} - {time}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        case 'enrollment':
          return (
            <div className="parameter-details param-history-details">
              <p className="param-description">
                {i18n.t('Enrollment Type: {enrollmentType}', {
                  enrollmentType:
                    EnrollmentType[
                      get(row, 'details.enrollment_type', 'NOT_FOUND')
                    ],
                })}
              </p>
              <p className="param-description">
                {i18n.t('Enrollment Date: {createdAt}', {
                  createdAt: get(row, 'created_at')
                    ? i18n.format.datetime(get(row, 'created_at'))
                    : i18n.t('Not Found'),
                })}
              </p>
              <p className="param-description">
                {i18n.t('Device Name: {deviceName}', {
                  deviceName: get(row, 'computer.name', i18n.t('Not found')),
                })}
              </p>
              <p className="param-description">
                {i18n.t('Blueprint: {blueprint}', {
                  blueprint: get(row, 'blueprint.name', i18n.t('Not found')),
                })}
              </p>
            </div>
          );
        case 'local_user_added':
        case 'local_user_deleted':
          return (
            <div className="parameter-details param-history-details">
              <p className="param-description">
                {row.details.name || i18n.t('Not Found')}
              </p>
            </div>
          );
        case 'local_user_changed':
          return (
            <div className="parameter-details param-history-details">
              {row.details.added && (
                <p className="param-description">
                  {i18n.t('Added:')}
                  {row.details.added || i18n.t('Not Found')}
                </p>
              )}
              {row.details.deleted && (
                <p className="param-description">
                  {i18n.t(' Deleted:')}
                  {row.details.deleted || i18n.t('Not Found')}
                </p>
              )}
            </div>
          );
        case 'parameters_changed':
          return (
            <div className="parameter-details param-history-details">
              {Object.entries(row.details).map((el) => {
                const paramName = parameterMeta[el[0]]
                  ? parameterMeta[el[0]].name
                  : el[1].name;
                if (el[1].type_of_event === 'value_changed') {
                  const paramChangedValues = prepareChangedValues(el);
                  return (
                    <div key={uuidv4()}>
                      <p className="history-details-p" key={uuidv4()}>
                        <i className="far fa-toggle-on history-details-icon" />
                        <span>
                          {i18n.t('Parameter Enabled - {paramName}', {
                            paramName,
                          })}
                        </span>
                      </p>
                      {paramChangedValues &&
                        paramChangedValues.map((paramVal) => (
                          <p key={uuidv4()} className="history-param-value">
                            {paramVal}
                          </p>
                        ))}
                    </div>
                  );
                }
                if (el[1].type_of_event === 'turned_on') {
                  const paramTurnedOnValues = prepareTurnedOnValues(el);
                  return (
                    <div key={uuidv4()}>
                      <p className="history-details-p" key={uuidv4()}>
                        <i className="far fa-toggle-on history-details-icon" />
                        <span>
                          {i18n.t('Parameter Enabled - {paramName}', {
                            paramName,
                          })}
                        </span>
                      </p>
                      {paramTurnedOnValues &&
                        paramTurnedOnValues.length > 0 &&
                        paramTurnedOnValues.map((paramVal) => (
                          <p key={uuidv4()} className="history-param-value">
                            {paramVal}
                          </p>
                        ))}
                    </div>
                  );
                }
                if (el[1].type_of_event === 'turned_off') {
                  return (
                    <p className="history-details-p" key={uuidv4()}>
                      <i className="far fa-toggle-off history-details-icon" />
                      <span>
                        {i18n.t('Parameter Deleted - {paramName}', {
                          paramName,
                        })}
                      </span>
                    </p>
                  );
                }
              })}
            </div>
          );
        case 'computer_enrollment':
          return (
            <div className="parameter-details param-history-details">
              <p className="param-description">
                {i18n.t('Enrollment Type: {enrollmentType}', {
                  enrollmentType:
                    EnrollmentType[
                      get(row, 'details.enrollment_type', 'NOT_FOUND')
                    ],
                })}
              </p>
              <p className="param-description">
                {i18n.t('Enrollment Date: {enrollmentDate}', {
                  enrollmentDate: get(row, 'created_at')
                    ? i18n.format.datetime(get(row, 'created_at'))
                    : i18n.t('Not Found'),
                })}
              </p>
              <p className="param-description">
                {i18n.t('Device Name: {computerName}', {
                  computerName: get(row, 'computer.name', i18n.t('Not Found')),
                })}
              </p>
              <p className="param-description">
                {i18n.t('Blueprint: {blueprintName}', {
                  blueprintName: get(
                    row,
                    'blueprint.name',
                    i18n.t('Not Found'),
                  ),
                })}
              </p>
            </div>
          );
        case 'computer_into_blueprint':
        case 'computer_out_of_blueprint':
        case 'computer_deletion': {
          const computersCount = row.details.computers_count;
          const from = row.details.old_blueprint_name || i18n.t('Not found');
          const to = row.details.new_blueprint_name || i18n.t('Not found');
          let msg = i18n.t('Not found');
          if (computersCount > 0) {
            if (row.action_type === 'computer_deletion') {
              const blueprintName =
                row.details.blueprint_name || i18n.t('Not found');

              msg = i18n.t(
                '{computersCount, plural, one {# Device} other {# Devices}} Deleted from {blueprintName}',
                { computersCount, blueprintName },
              );
            } else {
              msg = i18n.t(
                '{computersCount, plural, one {# Device} other {# Devices}} Moved from {from} to {to}',
                { computersCount, from, to },
              );
            }
          }
          return (
            <div className="parameter-details param-history-details">
              <p>{msg}</p>
              {computersCount && (
                <ul>
                  {row.details.computer_names.map((name) => (
                    <li key={uuidv4()}>{name}</li>
                  ))}
                </ul>
              )}
            </div>
          );
        }
        case 'blueprint_duplicated':
          return (
            <div className="parameter-details param-history-details">
              <p>
                {i18n.t('{currentBpName} Duplicated to {newBpName}', {
                  currentBpName: row.details.current_blueprint_name,
                  newBpName: row.details.new_blueprint_name,
                })}
              </p>
            </div>
          );
        case 'blueprint_deleted': {
          const deviceNames = get(row.details, 'device_names', []);
          return (
            <div className="parameter-details param-history-details">
              <p>
                {i18n.t(
                  '{deviceLength, plural, one {# Device} other {# Devices}} deleted as part of {name} deletion',
                  { deviceLength: deviceNames.length, name: row.details.name },
                )}
              </p>
              {!!deviceNames.length && (
                <ul>
                  {deviceNames.map((name) => (
                    <li key={uuidv4()}>{name}</li>
                  ))}
                </ul>
              )}
            </div>
          );
        }
        case 'blueprint_name_changed':
          return (
            <div className="parameter-details param-history-details">
              <p>
                {i18n.t('Changed From {oldName} to {newName}', {
                  oldName: row.details.old_name || i18n.t('Not found'),
                  newName: row.details.new_name || i18n.t('Not found'),
                })}
              </p>
            </div>
          );
        case 'blueprint_notes_created':
        case 'blueprint_notes_edited':
        case 'blueprint_notes_deleted':
        case 'computer_notes_created':
        case 'computer_notes_edited':
        case 'computer_notes_deleted':
          return (
            <div className="parameter-details param-history-details">
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(row.details.content),
                }}
              />
            </div>
          );
        case 'runs_after_enrollment': {
          const passedCount = row.details.first_runs.counts.passed || 0;
          const remediatedCount = row.details.first_runs.counts.remediated || 0;
          const alertsCount = row.details.first_runs.counts.alerts || 0;
          const mutedCount = row.details.first_runs.counts.muted || 0;
          const incompatibleCount =
            row.details.first_runs.counts.incompatible || 0;

          return (
            <div className="parameter-details param-history-details">
              <p>
                {i18n.t('Device Name: {deviceName}', {
                  deviceName: row.details.computer_name,
                })}
              </p>
              <p>
                {i18n.t('Blueprint Name: {bpName}', {
                  bpName: row.details.blueprint_name,
                })}
              </p>
              <p>
                {i18n.t('Date: {paramDate}', {
                  paramDate: row.details.first_runs.task_datetime,
                })}
              </p>
              <ul>
                {!!passedCount && (
                  <li>{i18n.t('{passedCount} Passed', { passedCount })}</li>
                )}
                {!!remediatedCount && (
                  <li>
                    {i18n.t('{remediatedCount} Remediated', {
                      remediatedCount,
                    })}
                  </li>
                )}
                {!!alertsCount && (
                  <li>{i18n.t('{alertsCount} Alerts', { alertsCount })}</li>
                )}
                {!!mutedCount && (
                  <li>{i18n.t('{mutedCount} Muted Alerts', { mutedCount })}</li>
                )}
                {!!incompatibleCount && (
                  <li>
                    {i18n.t('{incompatibleCount} Incompatible', {
                      incompatibleCount,
                    })}
                  </li>
                )}
              </ul>

              {!!passedCount && (
                <span>
                  <p>{i18n.t('PASSED')}</p>
                  <ul>
                    {row.details.first_runs.parameter_names.passed.map(
                      (parameterName) => (
                        <li>{parameterName}</li>
                      ),
                    )}
                  </ul>
                </span>
              )}

              {!!remediatedCount && (
                <span>
                  <p>{i18n.t('REMEDIATED')}</p>
                  <ul>
                    {row.details.first_runs.parameter_names.remediated.map(
                      (parameterName) => (
                        <li>{parameterName}</li>
                      ),
                    )}
                  </ul>
                </span>
              )}

              {!!alertsCount && (
                <span>
                  <p>{i18n.t('ALERTS')}</p>
                  <ul>
                    {row.details.first_runs.parameter_names.alerts.map(
                      (parameterName) => (
                        <li>{parameterName}</li>
                      ),
                    )}
                  </ul>
                </span>
              )}

              {!!mutedCount && (
                <span>
                  <p>{i18n.t('MUTED')}</p>
                  <ul>
                    {row.details.first_runs.parameter_names.muted.map(
                      (parameterName) => (
                        <li>{parameterName}</li>
                      ),
                    )}
                  </ul>
                </span>
              )}

              {!!incompatibleCount && (
                <span>
                  <p>{i18n.t('INCOMPATIBLE')}</p>
                  <ul>
                    {row.details.first_runs.parameter_names.incompatible.map(
                      (parameterName) => (
                        <li>{parameterName}</li>
                      ),
                    )}
                  </ul>
                </span>
              )}
            </div>
          );
        }
        case 'remediation_obtained':
        case 'first_run_remediation':
          return (
            <div className="parameter-details param-history-details">
              {row.details && (
                <div>
                  {row.details.parameter_name && (
                    <p>
                      <span
                        className="sharp-sans"
                        style={{
                          color: colors['grey-500'],
                          fontSize: 12,
                          textTransform: 'uppercase',
                          letterSpacing: '0.1rem',
                          fontWeight: 600,
                        }}
                      >
                        {i18n.t('Parameter:')}
                      </span>
                      {` ${row.details.parameter_name}`}
                    </p>
                  )}
                  {(row.details.run_details || []).map((detail, index) => (
                    <p key={index} style={{ color: colors['grey-400'] }}>
                      {detail}
                    </p>
                  ))}
                  {row.details.run_time && (
                    <p>
                      <span style={{ fontWeight: 600, color: colors.grey500 }}>
                        {i18n.t('Remediation complete:')}
                      </span>
                      {` ${i18n.format.datetime(row.details.run_time)}`}
                    </p>
                  )}
                </div>
              )}
            </div>
          );
        case 'computer_gone_missing':
          return (
            <div className="parameter-details param-history-details">
              <p className="param-description">
                {i18n.t(
                  '{computerName} has gone offineline. Last Check-In was {date}.',
                  {
                    computerName: row.details.computer_name,
                    date: i18n.format.datetime(row.details.last_report),
                  },
                )}
              </p>
            </div>
          );
        case 'computer_gone_active':
          return (
            <div className="parameter-details param-history-details">
              <p className="param-description">
                {i18n.t(
                  '{computerName} has checked back in and is no longer offline.',
                  { computerName: row.details.computer_name },
                )}
              </p>
            </div>
          );
        case 'blueprint_created':
          if (row.details.from_blueprint_name) {
            return (
              <div className="parameter-details param-history-details">
                <p>
                  <span>
                    {i18n.t('Blueprint Duplicated from {fromBpName}', {
                      fromBpName: row.details.from_blueprint_name,
                    })}
                  </span>
                </p>
                {!!row.details.enforsed_parameters.length && (
                  <p>{i18n.t('Parameters Enforced')}</p>
                )}
                {row.details.enforsed_parameters.map((param) => (
                  <div key={uuidv4()}>
                    <p className="history-details-p" key={uuidv4()}>
                      <i className="far fa-toggle-on history-details-icon" />
                      <span>
                        {i18n.t('Enabled - {paramName}', {
                          paramName: param.name,
                        })}
                      </span>
                    </p>
                    {Object.keys(param.variable).length > 0 &&
                      Object.keys(param.variable).map((el) => (
                        <p key={uuidv4()} className="history-param-value">
                          {`${el} - ${param.variable[el].toString()}`}
                        </p>
                      ))}
                  </div>
                ))}
              </div>
            );
          }
          if (row.details.library_items) {
            return (
              <div className="parameter-details param-history-details">
                {row.details?.library_items?.added_from_all_blueprint?.length >
                  0 && (
                  <div>
                    {i18n.t('Included All Classic Blueprints Library Items:')}
                    <ul>
                      {row.details.library_items.added_from_all_blueprint.map(
                        (name) => (
                          <li key={name}>{name}</li>
                        ),
                      )}
                    </ul>
                  </div>
                )}
              </div>
            );
          }
          break;
        case 'application_blocked': {
          const names = {
            name: 'Name',
            path: 'Path',
            developer: 'Developer',
            local_user: 'Local User',
            date_blocked: 'Local Time',
          };
          const keyToString = (k) => (names[k] ? names[k] : k.toUpperCase());
          return Object.entries(row.details).map((d, index) => (
            <div
              className="parameter-details param-history-details"
              key={camelCase(`applicationBlocked${index}`)}
            >
              <p>
                <span
                  style={{
                    fontWeight: 600,
                    color: colors.grey500,
                  }}
                >
                  {`${keyToString(d[0])}: `}
                </span>
                {d[0] === 'date_blocked' ? i18n.format.datetime(d[1]) : d[1]}
              </p>
            </div>
          ));
        }
        case 'library_item_deleted':
        case 'library_item_created':
        case 'library_item_assignment_changed':
        case 'library_item_duplicated':
        case 'library_item_edited': {
          const changedFieldRows = get(row, 'details.changed_fields', []);

          const actionName = {
            library_item_deleted: i18n.t('Deleted'),
            library_item_edited: i18n.t('Edited'),
            library_item_created: i18n.t('Created'),
            library_item_assignment_changed: i18n.t('Edited'),
            library_item_duplicated: i18n.t('Duplicated'),
          }[row.action_type];

          return (
            <div className="parameter-details param-history-details">
              {row.user && (
                <p className="param-description">
                  {i18n.t('{actionName} By: {user}', {
                    actionName,
                    user: row.user,
                  })}
                </p>
              )}

              {row.details.old_blueprints && row.details.new_blueprints && (
                <p className="param-description">
                  {row.details.old_blueprints.join(', ')}
                  {' → '}
                  {row.details.new_blueprints.join(', ')}
                </p>
              )}

              {changedFieldRows.map((text) => (
                <p className="param-description">{text}</p>
              ))}
              <p className="param-description">
                {`${actionName}: `}
                {i18n.format.datetime(row.created_at)}
              </p>

              {row.details.source_library_item_name &&
                row.details.source_library_item_name && (
                  <p className="param-description">
                    {i18n.t(
                      'Duplicated from {sourceLibraryItemName} ID: {sourceLibraryItemId}',
                      {
                        sourceLibraryItemName:
                          row.details.source_library_item_name,
                        sourceLibraryItemId: row.details.source_library_item_id,
                      },
                    )}
                  </p>
                )}

              {row.details.duplicate_library_item_id &&
                row.details.duplicate_library_item_name && (
                  <p className="param-description">
                    {i18n.t(
                      'Duplicated to {duplicateLibraryItemName} ID: {duplicateLibraryItemId}',
                      {
                        duplicateLibraryItemName:
                          row.details.duplicate_library_item_name,
                        duplicateLibraryItemId:
                          row.details.duplicate_library_item_id,
                      },
                    )}
                  </p>
                )}
            </div>
          );
        }
        case 'mdm_command_failed_resend':
        case 'mdm_command_failed':
        case 'mdm_command_completed': {
          const dateCompleted = get(row, 'details.date_completed');
          const dateRequested = get(row, 'details.date_requested');
          const requestType = get(row, 'details.request_type');
          const userName = get(row, 'details.username');
          const profileName = get(row, 'details.profile_name');
          const profileUUID = get(row, 'details.profile_uuid');
          const payloadIdentifier = get(row, 'details.metadata.identifier');
          const payloadUUID = get(row, 'details.metadata.uuid');
          const errorChain = get(row, 'details.error_chain', []);
          const isError83 =
            requestType === 'ScheduleOSUpdate' &&
            errorChain.some((err) => err.code === 83);
          const isCustomErrorDetails = isError83; // use || for the future
          const settingName = get(row, 'details.metadata.SettingName');
          const installDueToRules = get(row, 'details.metadata.rule_install');
          const removalDueToRules = get(row, 'details.metadata.rule_remove');
          const settingValue = settingName
            ? row.details.metadata[settingName]
            : null;
          const commandStatusText = i18n.t('Command {command}', {
            command:
              row.action_type === 'mdm_command_completed'
                ? i18n.t('Completed')
                : i18n.t('Failed'),
          });
          return (
            <div className="parameter-details param-history-details">
              {[
                'InstallEnterpriseApplication',
                'InstallApplication',
                'RemoveApplication',
              ].includes(requestType) && (
                <>
                  <p className="param-description">
                    <span>
                      {i18n.t('Application: {appName}', {
                        appName: get(
                          row,
                          'details.metadata.name',
                          i18n.t('Kandji Agent'),
                        ),
                      })}
                    </span>
                  </p>
                  {get(row, 'details.metadata.version') && (
                    <p className="param-description">
                      <span>
                        {i18n.t('Version: {version}', {
                          version: get(row, 'details.metadata.version'),
                        })}
                      </span>
                    </p>
                  )}
                  <p className="param-description">
                    <span>
                      {i18n.t('Developer: {developer}', {
                        developer: get(
                          row,
                          'details.metadata.author',
                          i18n.t('Kandji, Inc.'),
                        ),
                      })}
                    </span>
                  </p>
                  {get(row, 'details.metadata.bundle_id') && (
                    <p className="param-description">
                      <span>
                        {i18n.t('Bundle ID: {bundleId}', {
                          bundleId: get(row, 'details.metadata.bundle_id'),
                        })}
                      </span>
                    </p>
                  )}
                </>
              )}
              {['InstallProfile'].includes(requestType) &&
                installDueToRules && (
                  <p className="param-description">
                    {i18n.t(
                      'Installation triggered by Assignment Rule evaluating to true',
                    )}
                  </p>
                )}
              {['RemoveProfile'].includes(requestType) && removalDueToRules && (
                <p className="param-description">
                  {i18n.t(
                    'Removal triggered by Assignment Rule evaluating to false',
                  )}
                </p>
              )}
              {['InstallProfile'].includes(requestType) && profileName && (
                <p className="param-description">
                  <span>
                    {i18n.t('Profile Name: {profileName}', {
                      profileName,
                    })}
                  </span>
                </p>
              )}
              {['RemoveProfile'].includes(requestType) && profileName && (
                <p className="param-description">
                  <span>
                    {i18n.t('Profile Name: {profileName}', {
                      profileName,
                    })}
                  </span>
                </p>
              )}
              {['InstallProfile'].includes(requestType) && profileUUID && (
                <p className="param-description">
                  <span>
                    {i18n.t('Profile UUID: {profileUUID}', { profileUUID })}
                  </span>
                </p>
              )}
              {['InstallProfile'].includes(requestType) &&
                payloadIdentifier && (
                  <p className="param-description">
                    <span>
                      {i18n.t('Payload Identifier: {payloadIdentifier}', {
                        payloadIdentifier,
                      })}
                    </span>
                  </p>
                )}
              {['InstallProfile'].includes(requestType) && payloadUUID && (
                <p className="param-description">
                  <span>
                    {i18n.t('Payload UUID: {payloadUUID}', { payloadUUID })}
                  </span>
                </p>
              )}
              {requestType === 'UnlockUserAccount' && userName && (
                <p className="param-description">
                  <span>{i18n.t('User: {userName}', { userName })}</span>
                </p>
              )}
              {requestType === 'DeleteUserAccount' && userName && (
                <p className="param-description">
                  <span>{i18n.t('User: {userName}', { userName })}</span>
                </p>
              )}
              {requestType === 'Settings' && settingName && (
                <p className="param-description">
                  <span>{`${get(
                    settingNameText,
                    settingName,
                    i18n.t('Setting Value'),
                  )}: ${settingValue}`}</span>
                </p>
              )}
              {requestType === 'EraseDevice' &&
                get(row, 'details.metadata.return_to_service.enabled') && (
                  <p className="param-description">
                    <span>{i18n.t('Return to Service')}</span>
                    {get(row, 'details.metadata.return_to_service.profile') && (
                      <>
                        <div>
                          {i18n.t('Wi-Fi Profile: {wifiProfile}', {
                            wifiProfile: (
                              <span>
                                {i18n.t('User: {userName}', { userName })}
                              </span>
                            ),
                          })}
                        </div>
                        <div>
                          {i18n.t('Wi-Fi UUID: {wifiUUID}', {
                            wifiUUID: get(
                              row,
                              'details.metadata.return_to_service.profile.uuid',
                            ),
                          })}
                        </div>
                      </>
                    )}
                    {get(row, 'details.metadata.return_to_service.error') && (
                      <div>
                        {get(row, 'details.metadata.return_to_service.error')}
                      </div>
                    )}
                  </p>
                )}
              <p className="param-description">
                <span>
                  {i18n.t('Command Issued: {issuedDate}', {
                    issuedDate: i18n.format.datetime(dateRequested),
                  })}
                </span>
              </p>
              <p className="param-description">
                <span>{`${commandStatusText}: ${i18n.format.datetime(
                  dateCompleted,
                )}`}</span>
              </p>
              {isError83 && (
                <p className="param-description">
                  <span>{i18n.t('Details:')}</span>
                  <div>
                    {i18n.t(
                      'Download failed because device requires Wi-Fi to download the update.',
                    )}
                  </div>
                </p>
              )}
              {!isEmpty(errorChain) && !isCustomErrorDetails && (
                <p className="param-description">
                  <span>{i18n.t('Details:')}</span>
                  <div>
                    {errorChain.map((val) => (
                      <div>
                        {val.us_english_description ||
                          val.localized_description}
                        &nbsp;({val.domain}: &nbsp;{val.code})
                      </div>
                    ))}
                  </div>
                </p>
              )}
            </div>
          );
        }
        case 'device_based_activation_lock_enabled': {
          return (
            <div className="parameter-details param-history-details">
              <p className="param-description">
                {i18n.t('Enabled at: {date}', {
                  date: i18n.format.datetime(row.details.enabled_at),
                })}
              </p>
            </div>
          );
        }
        case 'vpp_asset_salable_detected':
        case 'vpp_asset_non_salable_detected': {
          const appName = get(row, 'details.app_name');
          const salable_description = () => ({
            vpp_asset_salable_detected: i18n.t(
              'This app was added to the App Store and can now be installed on devices.',
            ),
            vpp_asset_non_salable_detected: i18n.t(
              'This app was removed from the App Store and is no longer able to be installed on devices.',
            ),
          });
          return (
            <div className="parameter-details param-history-details">
              {appName && (
                <p className="param-description">
                  {i18n.t('App Name: {appName}', { appName })}
                </p>
              )}
              <p className="param-description">{`${
                salable_description()[row.action_type]
              }`}</p>
              <p className="param-description">
                {i18n.t('Detected at: {detectedAt}', {
                  detectedAt: i18n.format.datetime(row.details.detected_at),
                })}
              </p>
            </div>
          );
        }

        /* istanbul ignore next */
        case 'adcs_connector_added': {
          return ADCSConnectorAdded.detail(row, props);
        }
        /* istanbul ignore next */
        case 'adcs_connector_deleted': {
          return ADCSConnectorDeleted.detail(row, props);
        }
        /* istanbul ignore next */
        case 'adcs_server_added': {
          return ADCSServerAdded.detail(row, props);
        }
        /* istanbul ignore next */
        case 'adcs_server_updated': {
          return ADCSServerUpdated.detail(row, props);
        }
        /* istanbul ignore next */
        case 'adcs_server_deleted': {
          return ADCSServerDeleted.detail(row, props);
        }
        /* istanbul ignore next */
        case 'adcs_certificate_issued_failure': {
          return ADCSCertificateFailure.detail(row);
        }
        /* istanbul ignore next */
        case 'adcs_certificate_issued': {
          return ADCSCertificateIssued.detail(row);
        }
        /* istanbul ignore next */
        case 'adcs_connector_online': {
          return ADCSConnectorOnline.detail(row, props);
        }
        /* istanbul ignore next */
        case 'adcs_connector_offline': {
          return ADCSConnectorOffline.detail(row, props);
        }
        case 'company_owner_changed': {
          return (
            <div className="parameter-details param-history-details">
              <p className="param-description">{row.details.justification}</p>
            </div>
          );
        }
      }
    },
    [props.parameterMeta],
  );

  const onTypeChange = (newSelectedType) => {
    let selected = clone(newSelectedType);
    const { location, match } = props;
    if (isEqual(selectedType, selected)) {
      return null;
    }
    if (!selected) {
      selected = defaultActivityFilters.type;
    }
    const activeFilters = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    const newFilters = queryString.stringify(
      { ...activeFilters, type: selected },
      { arrayFormat: 'bracket' },
    );
    history.push(`${match.url}?${newFilters}`);
    setSelectedType(selected);
    return null;
  };

  const onPeriodChange = (newSelectedPeriod) => {
    let selected = clone(newSelectedPeriod);
    const { location, match } = props;
    if (isEqual(selectedPeriod, selected)) {
      return null;
    }
    if (!selected) {
      selected = defaultActivityFilters.period;
    }
    const activeFilters = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    });
    const newFilters = queryString.stringify(
      { ...activeFilters, period: selected },
      { arrayFormat: 'bracket' },
    );
    history.push(`${match.url}?${newFilters}`);
    setSelectedPeriod(selected);
    return null;
  };

  const onOrderingChange = useCallback(
    (fieldName) => {
      const { location, match } = props;
      const newValue = ordering[0] === '-' ? fieldName : `-${fieldName}`;
      const activeFilters = queryString.parse(location.search, {
        arrayFormat: 'bracket',
      });
      const newFilters = queryString.stringify(
        { ...activeFilters, ordering: newValue },
        { arrayFormat: 'bracket' },
      );
      history.push(`${match.url}?${newFilters}`);
      setOrdering(newValue);
      return null;
    },
    [props.match, props.location],
  );

  const filterTimeline = (searchString) => {
    const preparedSearch = searchString.toLowerCase();
    const preparedSearchArray = preparedSearch.split(' ');

    const checkDescription = (description) => {
      let hasMatch = true;
      preparedSearchArray.forEach((word) => {
        hasMatch = hasMatch && description.indexOf(word) > -1;
      });
      return hasMatch;
    };

    return timeline.filter((item) => {
      const descriptionMatch =
        get(item, 'textDescription') &&
        checkDescription(item.textDescription.toLowerCase());
      const computerNameMatch =
        get(item, 'computer.name') &&
        item.computer.name.toLowerCase().indexOf(preparedSearch) > -1;
      const blueprintNameMatch =
        get(item, 'blueprint.name') &&
        item.blueprint.name.toLowerCase().indexOf(preparedSearch) > -1;
      const userNameMatch =
        get(item, 'user') &&
        item.user.toLowerCase().indexOf(preparedSearch) > -1;
      return (
        descriptionMatch ||
        computerNameMatch ||
        blueprintNameMatch ||
        userNameMatch
      );
    });
  };

  const setSearchString = (searchString) => setSearch(searchString);

  const renderTitle = () => {
    const { activityTabType } = props;
    if (activityTabType !== 'global') {
      return null;
    }
    return (
      <div className="mr-auto d-flex flex-dir-row align-items-center">
        <H2>{i18n.t('Activity')}</H2>
      </div>
    );
  };

  const renderActionButtons = () => {
    const { filters, activityTabType } = props;
    return (
      <div className="activity-tab-btn-group">
        {filters.indexOf('type') >= 0 && (
          <div>
            <FilterSelect
              value={selectedType}
              onChange={onTypeChange}
              multi
              options={activityTypeLists[activityTabType]}
              // filterSelected
              label={i18n.t('Activity Type')}
            />
          </div>
        )}
        {filters.indexOf('period') >= 0 && (
          <div>
            <FilterSelect
              value={selectedPeriod}
              onChange={onPeriodChange}
              options={activityPeriodList}
              label={i18n.t('Date Range')}
              hasCustomRange
            />
          </div>
        )}
      </div>
    );
  };

  const renderLoadingDataView = () => (
    <div className="activity-tab-no-data-view">
      {isLoading && !timeline.length > 0 && (
        /* istanbul ignore next */
        <LineLoader isDelayed height="1100px" />
      )}
    </div>
  );

  const renderNoDataView = () => (
    <TableNoDataHelper
      key="##table-empty##"
      message={i18n.t('NO DATA TO DISPLAY')}
    />
  );
  const filteredTimeline = filterTimeline(search);

  const HEADERS_MAP = {
    DESCRIPTION: 'description',
    BLUEPRINT: 'blueprint',
    DEVICE: 'device',
    KANDJI_USER: 'kandji_user',
    DATE: 'date',
  };

  const i18nHeadersMap = i18n.createMap({
    [HEADERS_MAP.DESCRIPTION]: () => i18n.t('Description'),
    [HEADERS_MAP.BLUEPRINT]: () => i18n.t('Blueprint'),
    [HEADERS_MAP.DEVICE]: () => i18n.t('Device'),
    [HEADERS_MAP.KANDJI_USER]: () => i18n.t('Kandji User'),
    [HEADERS_MAP.DATE]: () => i18n.t('Date'),
  });

  const globalHeaders = [
    {
      name: 'description',
      label: i18nHeadersMap(HEADERS_MAP.DESCRIPTION),
      width: '40%',
      pinned: true,
      formatRow: formatDescriptionRow,
      sortable: false,
    },
    {
      name: 'blueprint__name',
      label: i18nHeadersMap(HEADERS_MAP.BLUEPRINT),
      width: '15%',
      pinned: false,
      formatRow: formatBlueprintRow,
      sortable: true,
    },
    {
      name: 'computer__name',
      label: i18nHeadersMap(HEADERS_MAP.DEVICE),
      width: '15%',
      pinned: false,
      formatRow: formatComputerRow,
      sortable: true,
    },
    {
      name: 'user__last_name',
      label: i18nHeadersMap(HEADERS_MAP.KANDJI_USER),
      width: '15%',
      pinned: false,
      formatRow: formatUserRow,
      sortable: true,
    },
    {
      name: 'created_at',
      label: i18nHeadersMap(HEADERS_MAP.DATE),
      width: '15%',
      pinned: false,
      formatRow: formatCreatedAtRow,
      sortable: true,
    },
  ];

  const blueprintHeaders = [
    {
      name: 'description',
      label: i18nHeadersMap(HEADERS_MAP.DESCRIPTION),
      width: '40%',
      pinned: true,
      formatRow: formatDescriptionRow,
      sortable: false,
    },
    {
      name: 'computer__name',
      label: i18nHeadersMap(HEADERS_MAP.DEVICE),
      width: '20%',
      pinned: false,
      formatRow: formatComputerRow,
      sortable: true,
    },
    {
      name: 'user__last_name',
      label: i18nHeadersMap(HEADERS_MAP.KANDJI_USER),
      width: '20%',
      pinned: false,
      formatRow: formatUserRow,
      sortable: true,
    },
    {
      name: 'created_at',
      label: i18nHeadersMap(HEADERS_MAP.DATE),
      width: '20%',
      pinned: false,
      formatRow: formatCreatedAtRow,
      sortable: true,
    },
  ];

  const computerHeaders = [
    {
      name: 'description',
      label: i18nHeadersMap(HEADERS_MAP.DESCRIPTION),
      width: '40%',
      pinned: true,
      formatRow: formatDescriptionRow,
      sortable: false,
    },
    {
      name: 'blueprint__name',
      label: i18nHeadersMap(HEADERS_MAP.BLUEPRINT),
      width: '20%',
      pinned: false,
      formatRow: formatBlueprintRow,
      sortable: true,
    },
    {
      name: 'user__last_name',
      label: i18nHeadersMap(HEADERS_MAP.KANDJI_USER),
      width: '20%',
      pinned: false,
      formatRow: formatUserRow,
      sortable: true,
    },
    {
      name: 'created_at',
      label: i18nHeadersMap(HEADERS_MAP.DATE),
      width: '20%',
      pinned: false,
      formatRow: formatCreatedAtRow,
      sortable: true,
    },
  ];

  const authHeaders = useCallback(() => {
    if (props.activityTabType === 'blueprint') {
      return blueprintHeaders;
    }
    if (props.activityTabType === 'computer') {
      return computerHeaders;
    }
    return globalHeaders;
  }, [props.activityTabType]);

  const renderCancelCommand = (cell, row) => {
    if (row.status !== 1) {
      return;
    }
    const onClick = () =>
      cancelPendingCommand(props.computerId)(cell)
        .then(() =>
          getPendingCommands(props.computerId)({ status: [1, 2, 5, 6] })
            .then((data) => setCommandList(data.results))
            .then(() => props.getComputer(props.computerId))
            .finally(() => setCommandListLoading(false)),
        )
        .catch(() => props.setSnackbar(i18n.common.error()));
    return (
      <Button
        theme="dark"
        kind="link"
        onClick={onClick}
        size="small"
        className="activity-tab-cancel-command-btn"
      >
        {i18n.t('Cancel')}
      </Button>
    );
  };

  const formatTimeRow = (cell) => {
    if (cell) {
      return (
        <HoveredSpan
          hoveredText={i18n.format.datetime(cell)}
          text={i18n.format.datetime(cell, { relative: true })}
        />
      );
    }
    return <span>{i18n.t('Not yet pushed')}</span>;
  };

  const commandExpandComponent = (row) => (
    <>
      <HelperText>
        <HelperTextRow>
          <HelperTextBold>
            {i18n.t('{commandName} is currently Pending', {
              commandName: getMDMCommandName(row),
            })}
          </HelperTextBold>
        </HelperTextRow>
        {row.username && (
          <HelperTextRow>
            {i18n.t('User: {username}', {
              username: row.username,
            })}
          </HelperTextRow>
        )}

        <HelperTextRow>
          {i18n.t('Command Issued: {dateRequested}', {
            dateRequested: formatTimeRow(row.date_requested),
          })}
        </HelperTextRow>
        <HelperTextRow>
          {i18n.t('Last Push: {lastPush}', {
            lastPush: formatTimeRow(row.last_pushed),
          })}
        </HelperTextRow>
        {[
          'InstallEnterpriseApplication',
          'InstallApplication',
          'RemoveApplication',
        ].includes(row.request_type) && (
          <>
            <HelperTextRow>
              {i18n.t('Application: {appName}', {
                appName: get(row, 'metadata.name', i18n.t('Kandji Agent')),
              })}
            </HelperTextRow>
            {get(row, 'metadata.version') && (
              <HelperTextRow>
                {i18n.t('Version: {version}', {
                  version: get(row, 'metadata.version'),
                })}
              </HelperTextRow>
            )}
            <HelperTextRow>
              {i18n.t('Developer: {author}', {
                author: get(row, 'metadata.author', 'Kandji, Inc.'),
              })}
            </HelperTextRow>
            {get(row, 'metadata.bundle_id') && (
              <HelperTextRow>
                {i18n.t('Bundle ID: {bundleId}', {
                  bundleId: get(row, 'metadata.bundle_id'),
                })}
              </HelperTextRow>
            )}
          </>
        )}
        {row.request_type === 'Settings' && row.metadata.SettingName && (
          <HelperTextRow>
            <span>{`${get(
              settingNameText,
              row.metadata.SettingName,
              i18n.t('Setting Value'),
            )}: ${row.metadata[row.metadata.SettingName]}`}</span>
          </HelperTextRow>
        )}
        {row.profile_name && (
          <HelperTextRow>
            {i18n.t('Profile Name: {profileName}', {
              profileName: get(row, 'metadata.bundle_id'),
            })}
          </HelperTextRow>
        )}
        {row.profile_uuid && (
          <HelperTextRow>
            {i18n.t('Profile UUID: {profileUUID}', {
              profileUUID: row.profile_uuid,
            })}
          </HelperTextRow>
        )}
        {row.metadata.identifier && (
          <HelperTextRow>
            {i18n.t('Payload Identifier: {identifier}', {
              identifier: row.metadata.identifier,
            })}
          </HelperTextRow>
        )}
        {row.metadata.uuid && (
          <HelperTextRow>
            {i18n.t('Payload UUID: {payloadUUID}', {
              payloadUUID: row.metadata.uuid,
            })}
          </HelperTextRow>
        )}
        {row.request_type === 'EraseDevice' &&
          row.metadata.return_to_service?.enabled && (
            <HelperTextRow>{i18n.t('Return to Service')}</HelperTextRow>
          )}
      </HelperText>
    </>
  );

  const commandExpandColumnComponent = ({
    isExpandableRow: canExpand,
    isExpanded,
  }) => {
    let content = '';
    if (canExpand) {
      content = isExpanded ? (
        <Icon name="angle-down" size="xs" />
      ) : (
        <Icon name="angle-right" size="xs" />
      );
    } else {
      content = ' ';
    }
    return content;
  };

  /* istanbul ignore next */
  return (
    <>
      <AwesomeTableToolBarNew
        title={renderTitle()}
        btnGroup={renderActionButtons()}
        searchLabel={i18n.t('Search activity')}
        searchFunc={setSearchString}
        searchIconPosition="right"
        sticky
        blueprintTabType={props.activityTabType === 'blueprint'}
        top={bannerTopOffset}
        rightBtnGroup
        bgWhite={props.activityTabType === 'computer'}
      />
      {props.activityTabType === 'computer' &&
        props.computer.erase_status !== 'yes' &&
        props.computer.is_mdm &&
        commandList.length > 0 && (
          <div style={{ gridArea: 'table', marginBottom: 16 }}>
            <TableHeader>{i18n.t('Pending')}</TableHeader>
            <BootstrapTable
              data={commandList}
              options={{
                sortName: 'last_push',
                expandBy: 'column',
              }}
              version="4"
              tableContainerClass="mini"
              containerClass="bst-borderless old-table"
              expandableRow={(row) => [1, 2, 5].includes(row.status)}
              expandComponent={commandExpandComponent}
              expandColumnOptions={{
                expandColumnVisible: true,
                expandColumnComponent: commandExpandColumnComponent,
                columnWidth: 36,
              }}
            >
              <TableHeaderColumn
                dataField="request_type"
                className="header-border-right"
                dataFormat={MDMCommandRequestTypeFormat}
              >
                {i18n.t('Command')}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="status"
                className="header-border-right"
                dataFormat={(cell) => i18nMDMCommandStatus(cell)}
              >
                {i18n.t('Status')}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="date_requested"
                className="header-border-right"
                dataFormat={formatTimeRow}
              >
                {i18n.t('Date Issued')}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="last_pushed"
                className="header-border-right"
                dataFormat={formatTimeRow}
              >
                {i18n.t('Date of last push')}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="user"
                className="header-border-right"
              >
                {i18n.t('Kandji User')}
              </TableHeaderColumn>
              {permissions.canManageDevices && (
                <TableHeaderColumn
                  dataField="uuid"
                  tdStyle={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 50,
                  }}
                  thStyle={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 50,
                  }}
                  expandable={false}
                  className="header-border-right"
                  dataFormat={renderCancelCommand}
                >
                  {commandList.filter((item) => item.status === 1).length >
                    1 && (
                    <Button
                      theme="dark"
                      kind="link"
                      size="small"
                      onClick={cancelAllPendingCommands}
                    >
                      {i18n.t('CANCEL ALL')}
                    </Button>
                  )}
                </TableHeaderColumn>
              )}
              <TableHeaderColumn
                dataField="uuid"
                isKey
                hidden
                className="header-border-right"
              />
            </BootstrapTable>
            {commandListLoading && <LineLoader isDelayed />}
          </div>
        )}
      {props.activityTabType === 'computer' &&
        props.computer.erase_status !== 'yes' &&
        props.computer.is_mdm && (
          <TableHeader>{i18n.t('Activity Stream')}</TableHeader>
        )}
      <div
        style={{ gridArea: 'table' }}
        className={classNames('tab-history', {
          'ml-4 mr-4': props.activityTabType === 'blueprint',
        })}
        id="history-table"
      >
        <InfiniteScrollProvider scrollCallback={() => setNeedFetching(true)}>
          <AwesomeTable
            headers={authHeaders()}
            data={filteredTimeline}
            expandComponent={expandComponent}
            sortFunc={onOrderingChange}
            ordering={ordering}
            loadingDataView={renderLoadingDataView()}
            noDataView={renderNoDataView()}
            isLoading={isLoading}
            clickableCellsToExpand={['description']}
            isExpandableRow={isExpandableRow}
          />
        </InfiniteScrollProvider>
        {isLoading && timeline.length > 0 && <LineLoader isDelayed />}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  computer: state.computerRecord,
  parameterMeta: state.data.parameterMeta,
  blueprints: state.data.blueprints,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      queryTimeline,
      startGetComputers,
      setSnackbar,
      getComputer,
    },
    dispatch,
  );

ActivityTabNew.propTypes = {
  activityTabType: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.string),
  queryTimeline: PropTypes.func.isRequired,
  computerId: PropTypes.string,
  blueprintId: PropTypes.string,
  libraryItemId: PropTypes.string,
  computer: PropTypes.objectOf(PropTypes.shape),
};

ActivityTabNew.defaultProps = {
  activityTabType: 'global',
  filters: ['type', 'period'],
  computerId: null,
  blueprintId: null,
  libraryItemId: null,
  computer: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTabNew);
