import './styles.css';

import {
  Banner,
  Select,
  SelectSearch,
  TextInput,
  useInvalidations,
} from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import isEqual from 'lodash/isEqual';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { updateUser } from 'app/_actions/users';
import { timezones, userTypes } from 'app/common/constants';
import { AccountContext } from 'contexts/account';
import {
  localizedUserProfileUpdateMessage,
  optionsLocaleLanguages,
} from 'features/company-settings/options';
import { Setting } from 'features/library-items/template';
import {
  displayWeeklyEmailOptions,
  i18nDateFormatOptionMap,
  i18nRelativeDatesOptionMap,
  i18nWeeklyEmailOptionMap,
} from 'features/user-profile/select-options';

import { displayTimezone } from 'src/app/common/helpers';
import Actions from './actions';
import {
  initialPasswords,
  useInitialForm,
  useInitialPageState,
} from './initial-state';
import {
  dateFormatOptions as _dateFormatOptions,
  displayRelativeDatesOptions,
} from './select-options';
import {
  newPassword,
  passwordsMustMatch,
  requiredAndWithinCharLimit,
} from './validation';

interface UserSelectorState {
  account: {
    user: {
      locale?: string | undefined;
    };
  };
}

const UserProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector<
    UserSelectorState,
    UserSelectorState['account']['user']
  >((state) => state.account.user);

  const { isEmailPasswordAuth0, userType } = useContext(AccountContext);

  const [initialForm, setInitialForm] = useState(useInitialForm());
  const [form, setForm] = useState(initialForm);
  const [page, setPage] = useState(useInitialPageState());

  // Note that password state is stored separately from the general form state because unlike
  // the general form, password field storage structure and payload structure differ.
  const [passwords, setPasswords] = useState(initialPasswords);
  const { invalidations, onInvalidate } = useInvalidations({ inputs: 4 });

  const showUserLocale = i18n.isEnabled();

  const timezoneOptions = timezones.map((option) => ({
    ...option,
    label: displayTimezone(option.value),
  }));

  const dateFormatOptions = _dateFormatOptions.map((option) => ({
    ...option,
    label: i18nDateFormatOptionMap(option.label),
  }));

  const relativeDatesOptions = displayRelativeDatesOptions.map((option) => ({
    ...option,
    label: i18nRelativeDatesOptionMap(option.label),
  }));

  const weeklyEmailOptions = displayWeeklyEmailOptions.map((option) => ({
    ...option,
    label: i18nWeeklyEmailOptionMap(option.label),
  }));

  const isDisabled =
    !page.isEditing || page.isSaving || userType === userTypes.super;

  const updatePageState = (k: string, v: boolean) =>
    setPage((prevState) => ({ ...prevState, [k]: v }));

  const updateForm = (k: string, v: unknown, isDateTimePref = false) => {
    if (isDateTimePref) {
      setForm((prevState) => ({
        ...prevState,
        settings: { ...prevState.settings, [k]: v },
      }));
    } else {
      setForm((prevState) => ({ ...prevState, [k]: v }));
    }
  };

  const updatePassword = (k: string, v: unknown) =>
    setPasswords((p) => ({ ...p, [k]: v }));

  const onBack = () => {
    history.push('/devices');
  };

  const onCancel = () => {
    updatePageState('isEditing', false);
    setPasswords(initialPasswords);
    setForm(initialForm);
  };

  const onSave = () => {
    // Force validate that passwords match
    if (passwords.new !== passwords.confirmNew) {
      document.getElementById('new-password-confirm').focus();
    } else {
      // Add passwords to payload if they have been edited
      const payload = form;
      if (!isEqual(passwords, initialPasswords)) {
        payload.old_password = passwords.current;
        payload.password = passwords.new;
      }

      updatePageState('isSaving', true);
      updatePageState('isEditing', false);

      const hasUpdatedLocaleLanguage = form.locale !== user.locale;
      const isSaveLocaleLanguage = showUserLocale && hasUpdatedLocaleLanguage;
      /* istanbul ignore next */
      const userLocaleSaveOptions = isSaveLocaleLanguage
        ? {
            successMessage:
              localizedUserProfileUpdateMessage[form.locale] || null,
            onSuccess: () => {
              const LOCALE_UPDATE_PAGE_RELOAD_DELAY_MS = 3500;
              setTimeout(() => {
                window.location.reload();
              }, LOCALE_UPDATE_PAGE_RELOAD_DELAY_MS);
            },
          }
        : null;

      dispatch(updateUser(payload, true, true, userLocaleSaveOptions));

      // Update/reset form
      setInitialForm(form);
      setPasswords(initialPasswords);
      updatePageState('isSaving', false);
    }
  };

  // Enable/Disable the `Save` button
  useEffect(() => {
    // Either no passwords have been entered or all have been edited
    const passwordsAreValid =
      isEqual(initialPasswords, passwords) ||
      Object.values(passwords).every((v) => v.length);
    const formHasChanged =
      !isEqual(initialForm, form) || !isEqual(initialPasswords, passwords);

    updatePageState(
      'isValid',
      formHasChanged && passwordsAreValid && !invalidations.some(Boolean),
    );
  }, [initialForm, form, invalidations, passwords]);

  return (
    <div>
      <Setting.Card className="k-settings-card">
        <Setting.Header>
          <h3 className="b-h3">{i18n.t('User Info')}</h3>
        </Setting.Header>
        <Setting.Rows>
          <div className="b-form-grid">
            <p className="b-txt">{i18n.t('First name')}</p>

            <TextInput
              disabled={isDisabled}
              value={form.first_name}
              onChange={(e) => updateForm('first_name', e.target.value)}
              validator={requiredAndWithinCharLimit(25)}
              onInvalidate={onInvalidate(0)}
              compact
            />

            <p className="b-txt b-mt1">{i18n.t('Last name')}</p>

            <TextInput
              disabled={isDisabled}
              value={form.last_name}
              onChange={(e) => updateForm('last_name', e.target.value)}
              validator={requiredAndWithinCharLimit(25)}
              onInvalidate={onInvalidate(1)}
              compact
            />

            <p className="b-txt b-mt1">{i18n.t('Email')}</p>

            <TextInput
              disabled
              value={form.email}
              onChange={(e) => updateForm('email', e.target.value)}
              compact
            />

            {isEmailPasswordAuth0 && (
              <div className="b-form-grid password-field">
                <p className="b-h3 b-mt1 b-mb1">{i18n.t('Password')}</p>

                <Banner className="b-mb1" theme="info" icon="circle-info">
                  <p>{i18n.t('Password must be at least 8 characters.')}</p>
                </Banner>

                {page.isEditing ? (
                  <>
                    <p className="b-txt">{i18n.t('Current password')}</p>

                    <TextInput
                      data-testid="current-password"
                      // @ts-ignore
                      id="current-password"
                      name="current-password"
                      autoComplete="current-password"
                      type="password"
                      placeholder={i18n.t('Enter your existing password')}
                      value={passwords.current}
                      onChange={(e) =>
                        updatePassword('current', e.target.value)
                      }
                    />

                    <p className="b-txt b-mt1">{i18n.t('New password')}</p>

                    <TextInput
                      data-testid="new-password"
                      // @ts-ignore
                      id="new-password"
                      name="new-password"
                      autoComplete="new-password"
                      type="password"
                      placeholder={i18n.t('Password')}
                      value={passwords.new}
                      onChange={(e) => updatePassword('new', e.target.value)}
                      validator={newPassword(
                        8,
                        i18n.t('Your password'),
                        passwords.current,
                      )}
                      onInvalidate={onInvalidate(2)}
                    />

                    <p className="b-txt b-mt1">
                      {i18n.t('Confirm new password')}
                    </p>

                    <TextInput
                      data-testid="new-password-confirm"
                      // @ts-ignore
                      id="new-password-confirm"
                      name="new-password-confirm"
                      autoComplete="new-password"
                      type="password"
                      placeholder={i18n.t('Password')}
                      value={passwords.confirmNew}
                      onChange={(e) =>
                        updatePassword('confirmNew', e.target.value)
                      }
                      validator={passwordsMustMatch(passwords.new)}
                      onInvalidate={onInvalidate(3)}
                    />
                  </>
                ) : (
                  <>
                    <p className="b-txt">{i18n.t('Current password')}</p>
                    <TextInput type="password" value="*********" disabled />
                  </>
                )}
              </div>
            )}
          </div>
        </Setting.Rows>
      </Setting.Card>

      <Setting.Card>
        <Setting.Header>
          <h3 className="b-h3">{i18n.t('Preferences')}</h3>
        </Setting.Header>

        <Setting.Rows>
          <div className="b-form-grid">
            <p className="b-txt">{i18n.t('Time zone')}</p>

            <SelectSearch
              testId="timezone_pref"
              placeholder={i18n.t('Search or select from list')}
              searchPlaceholder={i18n.t('Search option')}
              compact
              disabled={isDisabled}
              value={timezoneOptions.find(
                (option) => option.value === form.settings.timezone,
              )}
              onChange={(selected) =>
                updateForm('timezone', selected.value, true)
              }
              options={timezoneOptions}
              className="k-user-profile-timezone-select"
            />

            {showUserLocale && (
              <>
                <p className="b-txt b-mt1">{i18n.t('Language')}</p>

                <Select
                  testId="language_pref"
                  placeholder={i18n.t('Select from list')}
                  compact
                  disabled={isDisabled}
                  value={optionsLocaleLanguages.find(
                    (option) => option.value === form.locale,
                  )}
                  onChange={(v) => updateForm('locale', v.value)}
                  options={optionsLocaleLanguages}
                />
              </>
            )}

            {!showUserLocale && (
              <>
                <p className="b-txt b-mt1">{i18n.t('Date format')}</p>

                <Select
                  testId="date_format_pref"
                  placeholder={i18n.t('Select from list')}
                  compact
                  disabled={isDisabled}
                  value={dateFormatOptions.find(
                    (option) =>
                      option.value === form.settings.preferred_date_format,
                  )}
                  onChange={(selected) =>
                    updateForm('preferred_date_format', selected.value, true)
                  }
                  options={dateFormatOptions}
                />
              </>
            )}

            <p className="b-txt b-mt1">{i18n.t('Display relative dates')}</p>

            <Select
              testId="relative_dates_pref"
              placeholder={i18n.t('Select from list')}
              compact
              disabled={isDisabled}
              value={relativeDatesOptions.find(
                (option) =>
                  option.value === form.settings.disable_relative_dates,
              )}
              onChange={(selected) =>
                updateForm('disable_relative_dates', selected.value, true)
              }
              options={relativeDatesOptions}
            />

            <p className="b-txt b-mt1">
              {i18n.t('Receive weekly status emails')}
            </p>

            <Select
              testId="send_weekly_emails_pref"
              placeholder={i18n.t('Select from list')}
              compact
              disabled={isDisabled}
              value={weeklyEmailOptions.find(
                (option) => option.value === form.settings.send_weekly_emails,
              )}
              onChange={(selected) =>
                updateForm('send_weekly_emails', selected.value, true)
              }
              options={weeklyEmailOptions}
            />
          </div>
        </Setting.Rows>
      </Setting.Card>

      <Actions
        pageState={page}
        onBack={onBack}
        onCancel={onCancel}
        onEdit={() => updatePageState('isEditing', true)}
        onSave={onSave}
      />
    </div>
  );
};

export default UserProfile;
