import { createColumnHelper } from '@tanstack/react-table';
import { i18n } from 'i18n';
/* istanbul ignore file */
import type { z } from 'zod';

import { getIconName } from '../../components/PrismNav/prismNavUtils';
import {
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
  getEnumFilterOptions,
} from '../column-utils';
import { getScharedColumns } from './getSharedColumns';

export const getLaunchAgentsDaemonsColumns = ({
  launchAgentsDaemonsSchema,
}): {
  columnDefs: any[];
  pinnedColumns: string[];
  alwaysHiddenColumns: string[];
} => {
  const { properties } = launchAgentsDaemonsSchema.schema;
  const launchAgentsDaemonsColumnHelper =
    createColumnHelper<z.infer<typeof properties>>();

  const columnDefs = [
    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.label`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.label`,
        meta: {
          displayName: properties.label.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.is_disabled`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.is_disabled`,
        cell: YesNoCell,
        meta: {
          displayName: properties.is_disabled.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.run_at_load`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.run_at_load`,
        cell: YesNoCell,
        meta: {
          displayName: properties.run_at_load.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.service_domain`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.service_domain`,
        meta: {
          displayName: properties.service_domain.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'enum',
          filterIcon: 'list-check',
          filterOptions: [
            { value: 'Daemon', label: i18n.t('Daemon') },
            { value: 'User Agent', label: i18n.t('User agent') },
            { value: 'System Agent', label: i18n.t('System agent') },
          ],
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.program`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.program`,
        cell: getCodeSnippetCell,
        meta: {
          displayName: properties.program.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.path`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.path`,
        cell: getCodeSnippetCell,
        meta: {
          defaultHidden: true,
          displayName: properties.path.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.file_name`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.file_name`,
        meta: {
          defaultHidden: true,
          displayName: properties.file_name.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.program_arguments`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.program_arguments`,
        cell: getCodeSnippetCell,
        meta: {
          defaultHidden: true,
          displayName: properties.program_arguments.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.keep_alive`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.keep_alive`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.keep_alive.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.disabled`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.disabled`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.disabled.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.user_name`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.user_name`,
        meta: {
          defaultHidden: true,
          displayName: properties.user_name.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.group_name`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.group_name`,
        meta: {
          defaultHidden: true,
          displayName: properties.group_name.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.standard_out_path`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.standard_out_path`,
        cell: getCodeSnippetCell,
        meta: {
          defaultHidden: true,
          displayName: properties.standard_out_path.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.standard_error_path`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.standard_error_path`,
        cell: getCodeSnippetCell,
        meta: {
          defaultHidden: true,
          displayName: properties.standard_error_path.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.start_interval`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.start_interval`,
        meta: {
          defaultHidden: true,
          displayName: properties.start_interval.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'number',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.watch_paths`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.watch_paths`,
        cell: getCodeSnippetCell,
        meta: {
          defaultHidden: true,
          displayName: properties.watch_paths.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.queue_directories`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.queue_directories`,
        cell: getCodeSnippetCell,
        meta: {
          defaultHidden: true,
          displayName: properties.queue_directories.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.start_on_mount`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.start_on_mount`,
        meta: {
          defaultHidden: true,
          displayName: properties.start_on_mount.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.root_directory`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.root_directory`,
        cell: getCodeSnippetCell,
        meta: {
          defaultHidden: true,
          displayName: properties.root_directory.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.working_directory`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.working_directory`,
        cell: getCodeSnippetCell,
        meta: {
          defaultHidden: true,
          displayName: properties.working_directory.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.process_type`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.process_type`,
        meta: {
          defaultHidden: true,
          displayName: properties.process_type.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'enum',
          filterOptions: getEnumFilterOptions(
            launchAgentsDaemonsSchema.schema?.definitions.ProcesssType.enum ||
              [],
          ),
          filterIcon: 'list-check',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.user_agent_local_user`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.user_agent_local_user`,
        meta: {
          defaultHidden: true,
          displayName: properties.user_agent_local_user.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.user_agent_local_user_id`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.user_agent_local_user_id`,
        meta: {
          defaultHidden: true,
          displayName: properties.user_agent_local_user_id.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'number',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.is_loaded`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.is_loaded`,
        cell: YesNoCell,
        meta: {
          defaultHidden: true,
          displayName: properties.is_loaded.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'boolean',
          filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
          filterIcon: 'circle-dot',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row[`${launchAgentsDaemonsSchema.uri}.pid`],
      {
        id: `${launchAgentsDaemonsSchema.uri}.pid`,
        meta: {
          defaultHidden: true,
          displayName: properties.pid.title,
          displayIcon: getIconName(launchAgentsDaemonsSchema.uri),
          filterType: 'number',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    ...getScharedColumns(launchAgentsDaemonsSchema),
  ];

  return { columnDefs, pinnedColumns: [], alwaysHiddenColumns: [] };
};
