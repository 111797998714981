/* istanbul ignore file */
import { i18n } from 'i18n';
import { ProfileStatusTypes } from './Profiles/ProfileStatus.types';

export type LibraryItemLogSerializer = (details: any) => string;
export interface LibraryItemStatusSerializerMap {
  Default: LibraryItemLogSerializer;
  [key: string]: LibraryItemLogSerializer;
}

export const LibraryItemStatuses = {
  ...ProfileStatusTypes,
} as const;

type LibraryItemStatusKeys = keyof typeof LibraryItemStatuses;
export type LibraryItemStatusTypes =
  (typeof LibraryItemStatuses)[LibraryItemStatusKeys];

export interface LibraryItemStatusContent {
  type: LibraryItemStatusTypes;
  version: number;
  details?: any;
}

export enum LogDetailKeys {
  CommandCompleted = 'command_completed',
  CommandIssued = 'command_issued',
  PayloadIdentifier = 'payload_identifier',
  PayloadUUID = 'payload_uuid',
  ProfileUUID = 'profile_uuid',
  ProfileName = 'profile_name',
  CommandFailed = 'command_failed',
  Reason = 'reason',
  ErrorChain = 'error_chain',
}

export const LogDetailLabels = {
  [LogDetailKeys.CommandCompleted]: () => i18n.t('Command Completed'),
  [LogDetailKeys.CommandIssued]: () => i18n.t('Command Issued'),
  [LogDetailKeys.CommandFailed]: () => i18n.t('Command Failed'),
  [LogDetailKeys.PayloadIdentifier]: () => i18n.t('Payload Identifier'),
  [LogDetailKeys.PayloadUUID]: () => i18n.t('Payload UUID'),
  [LogDetailKeys.ProfileUUID]: () => i18n.t('Profile UUID'),
  [LogDetailKeys.ProfileName]: () => i18n.t('Profile Name'),
  [LogDetailKeys.Reason]: () => i18n.t('Reason'),
  [LogDetailKeys.ErrorChain]: () => i18n.t('Error Chain'),
};
