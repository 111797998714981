import {
  Button,
  DropdownMenuPrimitives as DropdownMenu,
  Flex,
  Icon,
  Select,
  Text,
  TextArea,
  Tooltip,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

import { useEffect, useState } from 'react';
import { useSendMatchFeedback } from '../../hooks/use-send-match-feedback';
import type { ShowToast } from '../../hooks/use-vulnerability-toast';

import type { CVEFeedback, Vulnerability } from '../../vulnerability.types';

const RichLabel = /* istanbul ignore next */ (props: {
  label: string;
  isSelected: boolean;
}) => {
  const { label, isSelected } = props;
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Text variant={isSelected ? 'primary' : 'default'}>{label}</Text>
      {isSelected && (
        <Icon name="fa-check-14px" size="sm" color="var(--colors-blue50)" />
      )}
    </Flex>
  );
};

const FeedbackDropdown = (props: {
  vulnerability: Vulnerability;
  showToast: ShowToast;
}) => {
  const { vulnerability, showToast } = props;
  const { cve_id } = vulnerability;

  const DEFAULT_FEEDBACK = { issue: '', details: '' };
  const DETAILS_CHAR_LIMIT = 3000;
  const translatedCharLimit = i18n.format.number(DETAILS_CHAR_LIMIT);

  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [feedback, setFeedback] = useState<CVEFeedback>(DEFAULT_FEEDBACK);
  const { sendMatchFeedback, isPending, isSuccess, isError } =
    useSendMatchFeedback();

  const issueOptions = [
    {
      value: 'This vulnerability does NOT impact software that is listed',
      label: i18n.t(
        'This vulnerability does NOT impact software that is listed',
      ),
    },
    {
      value: 'The CVE record is incorrect',
      label: i18n.t('The CVE record is incorrect'),
    },
    {
      value: 'The vulnerability impacts software that is not listed',
      label: i18n.t('The vulnerability impacts software that is not listed'),
    },
    {
      value: "There is software listed that isn't installed",
      label: i18n.t("There is software listed that isn't installed"),
    },
    {
      value: 'Other',
      label: i18n.t('Other'),
    },
  ];

  const richIssueOptions = issueOptions.map((option) => ({
    ...option,
    richLabel: (
      <RichLabel
        label={option.label}
        isSelected={feedback.issue === option.value}
      />
    ),
  }));

  useEffect(() => {
    if (!isFeedbackOpen) {
      setFeedback(DEFAULT_FEEDBACK);
    }
  }, [isFeedbackOpen]);

  useEffect(
    /* istanbul ignore next */ () => {
      if (isPending) {
        showToast({
          title: i18n.t('Report being submitted'),
          variant: 'progress',
          content: i18n.t('Submitting the report to the Support team'),
        });
      } else if (isSuccess) {
        showToast({
          title: i18n.t('Report successfully submitted'),
          variant: 'success',
          content: i18n.t(
            'Submitted the report to the Support team successfully',
          ),
        });
      } else if (isError) {
        showToast({
          title: i18n.t('Error submitting report'),
          variant: 'error',
        });
      }
    },
    [isPending, isSuccess, isError],
  );

  const onReport = () => {
    setIsFeedbackOpen(false);
    sendMatchFeedback({ cveId: cve_id, feedback });
  };

  return (
    <DropdownMenu.Root open={isFeedbackOpen} onOpenChange={setIsFeedbackOpen}>
      <DropdownMenu.Trigger>
        <Tooltip
          side="bottom"
          theme="dark"
          content={i18n.t('Report inaccuracy')}
          css={{ zIndex: 2 }}
        >
          <Button
            icon={{ name: 'file-circle-info' }}
            variant="subtle"
            css={{
              backgroundColor: isFeedbackOpen
                ? '$button_subtle_background_active'
                : '',
            }}
          />
        </Tooltip>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content side="bottom" css={{ zIndex: 3 }} align="end">
        <Flex p3 flow="column" gap="md">
          <Select
            label={i18n.t('Choose an issue to report')}
            placeholder={i18n.t('Select an issue')}
            value={feedback.issue}
            options={richIssueOptions}
            onChange={(v) => setFeedback({ ...feedback, issue: v })}
          />
          <TextArea
            value={feedback.details}
            title="details"
            onChange={(e) =>
              setFeedback({ ...feedback, details: e.target.value })
            }
            placeholder={i18n.t(
              'Tell us about the issue including the application and device details (optional)',
            )}
            hint={
              feedback.details?.length > DETAILS_CHAR_LIMIT
                ? {
                    label: i18n.t('Max {limit} characters', {
                      limit: translatedCharLimit,
                    }),
                  }
                : {}
            }
            state={
              feedback.details.length > DETAILS_CHAR_LIMIT ? 'error' : 'default'
            }
            css={{ width: '320px', '& textarea': { minHeight: '120px' } }}
          />
          <Flex justifyContent="end" css={{ gap: '10px' }}>
            <Button
              compact
              variant="subtle"
              onClick={() => setIsFeedbackOpen(false)}
            >
              {i18n.t('Cancel')}
            </Button>
            <Button
              compact
              variant="primary"
              disabled={
                !feedback.issue || feedback.details.length > DETAILS_CHAR_LIMIT
              }
              onClick={onReport}
            >
              {i18n.t('Report')}
            </Button>
          </Flex>
        </Flex>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export { FeedbackDropdown };
