import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { InstallProfileSuccessDetails } from '../ProfileStatus.types';

function serializerV1(details: InstallProfileSuccessDetails) {
  const {
    command_completed,
    command_issued,
    payload_identifier,
    payload_uuid,
    profile_uuid,
    profile_name,
  } = details;

  return new LibraryItemStatusLog()
    .addRow(i18n.t('MDM Command Completed'), i18n.t('Install Profile'))
    .addRow(i18n.t('Profile Name'), profile_name)
    .addRow(i18n.t('Profile UUID'), profile_uuid)
    .addRow(i18n.t('Payload Identifier'), payload_identifier)
    .addRow(i18n.t('Payload UUID'), payload_uuid)
    .addRow(i18n.t('Command Issued'), command_issued)
    .addRow(i18n.t('Command Completed'), command_completed)
    .toString();
}

export const InstallProfileSuccessLogSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
