import React, { useCallback, useContext, useEffect } from 'react';
import { i18n } from 'src/i18n';

import { updateSetting as update } from '@kandji-inc/bumblebee';

import { AUTO_APP_DEFAULT_TIMEZONE } from 'app/common/constants';
import useUniqValue from 'components/common/hooks/use-uniq-value/use-uniq-value';
import { AccountContext } from 'contexts/account';
import ActivityTab from 'features/library-items/common/activity-tab';
import {
  InstallationCard,
  SSLink,
  useSsInstall,
} from 'features/library-items/template';
import { useFlags } from 'src/config/feature-flags';
import UpdatesCard from 'src/features/library-items/common/updates-card/UpdatesCard';
import useDefaultTimezone from '../../../../components/common/hooks/use-default-timezone';
import { useBlueprintConflicts } from '../../common/blueprint-conflicts';
import { useLibraryItem } from '../../common/hooks/use-library-item';
import LibraryItemPage from '../../common/library-item-page';
import NewStatusTab from '../../common/new-status-tab/new-status-tab';
import { getNewStatusTabProps } from '../../common/new-status-tab/util';
import SelfServiceCard from '../../common/self-service-card';
import WithOneDescriptionPreset from '../../common/self-service-card/drawer/presets/with-one-description-preset';
import {
  VERSION_ENFORCEMENT_OPTIONS,
  getTimezones,
} from '../../common/updates-card/updates-card-constants';
import config from '../../library/library-item-configurations/items/auto-apps';
import InfoBanners from './info-banners';
import initialState from './initial-state';
import OptionsCard from './options-card';
import Notifications from './sections/notifications';
import AutomaticAppService, {
  automaticAppService,
} from './service/automatic-app-service';
import { createTransformToApi, transformFromApi } from './service/transformers';
import useAutomaticAppService from './service/use-automatic-app-service';
import UpdateOnlyInstallationOption from './updateOnlyInstallationOption';

const getDeviceFamily = (runsOn) => {
  const keyLabelMap = {
    mac: 'Mac',
    ipad: 'iPad',
    iphone: 'iPhone',
  };

  return Object.keys(keyLabelMap)
    .filter((key) => runsOn[key])
    .map((key) => keyLabelMap[key]);
};

const AutomaticAppPage = (props) => {
  const { model, setModel, pageState } = props;
  const [validationDep, triggerValidation] = useUniqValue();
  const defaultTimezone = useDefaultTimezone();
  const { 'dc-auto-app-local-timezone': LDFF_autoAppLocalTimezone } =
    useFlags();
  const { currentCompany } = useContext(AccountContext);
  const isDevelopmentInstance = currentCompany.instance_type === 'development';

  const itemConfig = config['Auto Apps'];

  const notificationsUpdate = useCallback(
    update('notifications', setModel),
    [],
  );
  const selectedTimezone =
    model.updates?.enforcementTimezone ||
    (LDFF_autoAppLocalTimezone
      ? AUTO_APP_DEFAULT_TIMEZONE()
      : getTimezones(itemConfig.type).find(
          (tz) => tz.value === defaultTimezone,
        ));

  const breadcrumb = model.instanceName
    ? `${model.name} - ${model.instanceName}`
    : model.name;

  const updateInstallation = useCallback(update('installation', setModel), []);
  const updateSs = useCallback(update('selfService', setModel), []);
  const updateUpdates = useCallback(update('updates', setModel), []);
  const updateOptions = useCallback(update('options', setModel), []);
  const isSsForced = useSsInstall({
    installType: model.installation.type,
    ssType: AutomaticAppService.installationTypes.NO_ENFORCEMENT,
    updateSs,
  });

  const isUpdateOnly =
    model.installation.type ===
    AutomaticAppService.installationTypes.UPDATE_ONLY;

  const installationOptions = [
    {
      value: AutomaticAppService.installationTypes.CONTINUOUSLY_ENFORCE,
      label: i18n.t('Continuously enforce'),
    },
    {
      value: AutomaticAppService.installationTypes.NO_ENFORCEMENT,
      label: i18n.t('Install on-demand from Self Service'),
    },
    {
      value: AutomaticAppService.installationTypes.UPDATE_ONLY,
      label: i18n.t('Update only'),
    },
  ];

  const installationHelper = (
    <p className="b-txt-light">
      {i18n.t(
        `Determine how this Auto App should be installed. Choose to install and continuously enforce, install on-demand from Self Service, or update only. Update only enforces updates for this application if it's already installed, but does not install it on any new computers. It is also not made available in Self Service.`,
      )}{' '}
      <SSLink />
    </p>
  );

  useEffect(
    /* istanbul ignore next */ () => {
      if (
        isUpdateOnly &&
        model.updates.versionEnforcement?.value ===
          AutomaticAppService.updateTypes.ANY
      ) {
        updateUpdates(
          'versionEnforcement',
          VERSION_ENFORCEMENT_OPTIONS().find(
            (opt) => opt.value === AutomaticAppService.updateTypes.NEWEST,
          ),
        );
      }
    },
    [isUpdateOnly],
  );

  return (
    <LibraryItemPage
      {...props}
      type={itemConfig.type}
      identifier={itemConfig.identifier}
      triggerValidation={triggerValidation}
      transformToApi={createTransformToApi({ selectedTimezone })}
      transformFromApi={transformFromApi}
      service={automaticAppService}
      crumb={breadcrumb || i18n.t('Automatic App')}
      summaryInfoProps={{
        name: model.name,
        instanceName: model.instanceName,
        description: model.description,
        extra: (
          <InfoBanners
            requiresRosetta={model.requiresRosetta}
            warning={model.warning}
          />
        ),
        publisher: model.publisher,
        currentVersion: model.version,
        devices: getDeviceFamily(model.runsOn),
        requirements: model.osRequirements,
      }}
      isNameEditable={false}
      ActivityTab={ActivityTab}
      StatusTab={NewStatusTab}
      getStatusTabProps={getNewStatusTabProps}
      canHaveInstanceName
      supportsRules
      supportsDuplication
    >
      <InstallationCard
        setting={model.installation}
        update={updateInstallation}
        isDisabled={pageState.isDisabled}
        options={installationOptions}
        helper={installationHelper}
        updateValidation={updateInstallation}
        showError={pageState.isSubmitted}
        isRequired
      />
      {!isUpdateOnly && (
        <SelfServiceCard
          setting={model.selfService}
          update={updateSs}
          isDisabled={pageState.isDisabled}
          defaults={{
            icon: model.icon,
            name: model.name,
            shortDescription: model.subtitle,
            longDescription: model.description,
          }}
          canBeDisabled={!isSsForced}
          isSubmitted={pageState.isSubmitted}
          validationDep={validationDep}
          DrawerContent={WithOneDescriptionPreset}
        />
      )}
      <UpdatesCard
        setting={model.updates}
        installationType={model.installation.type}
        installationUpdateOnly={
          model.installation.updates_on_pre_installed_versions_only
        }
        update={updateUpdates}
        isDisabled={pageState.isDisabled}
        validationDep={validationDep}
        appName={model.name}
        selectedTimezone={selectedTimezone}
        isDevelopmentInstance={isDevelopmentInstance}
        type={itemConfig.type}
        osMinimumVersion={model._data.minimum_os_version}
        doNotManageUpdatesError={i18n.t(
          'This configuration is invalid. If you choose to continuously enforce the installation, you need to set an update schedule or specify a minimum version.',
        )}
      />
      {!isUpdateOnly && (
        <Notifications
          settings={model.notifications}
          isDisabled={pageState.isDisabled}
          update={notificationsUpdate}
        />
      )}
      {!isUpdateOnly && (
        <OptionsCard
          setting={model.options}
          update={updateOptions}
          isDisabled={pageState.isDisabled}
        />
      )}
    </LibraryItemPage>
  );
};

const AutomaticApp = () => {
  const { pageProps, PlaceHolder } = useLibraryItem({
    initialState: initialState(),
    useService: useAutomaticAppService,
  });

  const blueprintConflicts = useBlueprintConflicts();

  if (PlaceHolder) {
    return <PlaceHolder />;
  }

  return (
    <AutomaticAppPage
      {...pageProps}
      blueprintConflicts={blueprintConflicts}
      testId="automatic-app-v2"
    />
  );
};

export default AutomaticApp;
