import type { LibraryItemStatusContent } from '../CommonStatus.types';
import { buildLogFromStatusDetails } from './buildLogFromStatusDetails';
import { getStatusSerializer } from './getStatusSerializer';

export function formatStatusContent(
  content: Partial<LibraryItemStatusContent>,
  unformattedLog?: string | null | undefined,
): string | null | undefined {
  if (content == null) return unformattedLog;

  const { type, details, version } = content;

  const serialize = getStatusSerializer(type, version);

  if (serialize == null) {
    return buildLogFromStatusDetails(details);
  }

  const formattedLog = serialize(details);

  return formattedLog != null ? formattedLog : unformattedLog;
}
