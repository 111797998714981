import { Chip, Code, Flex, Td, Text, Tr, styled } from '@kandji-inc/nectar-ui';
import { links } from 'app/common/constants';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { i18n } from 'src/i18n';
import constants from '../../../common/constants';
import type { ThreatDetail } from '../../../threat.types';
import ThreatListTableArrowButton from './ThreatListTableArrowButton';

const StyledTr = styled(Tr, {
  marginTop: '-10px',
  '& td': {
    $$tdActiveBg: '$neutral0',
    verticalAlign: 'top',
  },
  '& td a': {
    color: '$blue50',
  },
});

const StyleCode = styled(Code, {
  borderRadius: '4px',
  border: '1px solid $neutral40',
  background: '$neutral5',
  color: '$neutral70',
  fontSize: '$1',
  lineHeight: '12px',
  padding: '0px 8px',
});

const TextPadded = styled(Text, {
  paddingLeft: 24,
});

const FlexLabels = styled(Flex, {
  span: { color: '$neutral70', lineHeight: '20px' },
});

const FlexValues = styled(Flex, {
  '& > span': { lineHeight: '20px', minHeight: '20px' },
});

const CapitalizedChip = styled(Chip, {
  '&:not(:disabled)': {
    textTransform: 'capitalize',
    backgroundColor: '$neutral0',
    border: '1px solid $neutral30',
    color: '$neutral60',
    padding: '1px 5px',
  },
});

const PostureFlex = styled(Flex, {
  width: 200,
  justifyContent: 'space-between',
  alignItems: 'center',
});

const {
  THREAT_STATUS: { NOT_QUARANTINED, RELEASED, RESOLVED },
} = constants;

type ThreatListTableExpandedRowProps = {
  threat: ThreatDetail;
  isOnDeviceRecord?: boolean | undefined;
};

const ThreatListTableExpandedRow = (props: ThreatListTableExpandedRowProps) => {
  const { threat, isOnDeviceRecord } = props;
  const {
    date_of_quarantine: dateOfQuarantine,
    date_of_release: dateOfRelease,
    released_by: releasedBy,
    release_note: releaseNote,
    resolved_at: resolvedAt,
    resolved_by: resolvedBy,
    blueprint_id: blueprintId,
    blueprint_name: blueprintName,
    library_item_id: libraryItemId,
    library_item_name: libraryItemName,
    malware_posture: malwarePosture,
    pup_posture: pupPosture,
    bundle_path: bundlePath,
    status,
    file_path: filePath,
    file_hash: fileHash,
    process_owner: processOwner,
    device_name: deviceName,
    device_serial_number: deviceSerialNumber,
    device_id: deviceId,
    detection_date: detectionDate,
  } = threat;

  const [isDeviceDetailsExpanded, setIsDeviceDetailsExpanded] =
    useState<boolean>(false);

  const handleExpandRowButtonClick = (isExpanded: boolean) => {
    setIsDeviceDetailsExpanded(isExpanded);
  };

  const isNotNotQuarantined = status !== NOT_QUARANTINED;
  const isReleased = status === RELEASED;
  const isResolved = status === RESOLVED;

  const dateOfQuarantineField = isNotNotQuarantined ? (
    <Text title={i18n.format.datetime(dateOfQuarantine)}>
      {i18n.format.datetime(dateOfQuarantine)}
    </Text>
  ) : null;

  const dateOfReleaseField = isReleased ? (
    <Text title={i18n.format.datetime(dateOfRelease)}>
      {i18n.format.datetime(dateOfRelease)}
    </Text>
  ) : null;

  const releasedByField = isReleased ? (
    <Text title={releasedBy}>{releasedBy}</Text>
  ) : null;

  const releaseNoteField =
    releaseNote && isReleased ? (
      <Text title={releaseNote}>{releaseNote}</Text>
    ) : null;

  const resolvedAtField = isResolved ? (
    <Text title={i18n.format.datetime(resolvedAt)}>
      {i18n.format.datetime(resolvedAt)}
    </Text>
  ) : null;

  const resolvedByField = isResolved ? (
    <Text title={resolvedBy}>{resolvedBy}</Text>
  ) : null;

  const bundlePathField = bundlePath ? (
    <Text title={bundlePath}>{bundlePath}</Text>
  ) : null;

  const deviceDetails = (
    <>
      <Text title={blueprintName || blueprintId}>
        {blueprintId ? (
          <Link to={`${links.blueprints}/${blueprintId}`}>
            {blueprintName || blueprintId}
          </Link>
        ) : (
          <Text variant="description">
            {i18n.t('No Blueprint currently assigned to this device.')}
          </Text>
        )}
      </Text>

      <Text title={libraryItemName || libraryItemId}>
        {libraryItemId ? (
          <Link to={`/library/avert/${libraryItemId}`}>
            {libraryItemName || libraryItemId}
          </Link>
        ) : (
          <Text variant="description">
            {i18n.t('No Library Item currently assigned to this device.')}
          </Text>
        )}
      </Text>

      {libraryItemId && malwarePosture && (
        <Text title={i18n.t('Malware Posture')}>
          <PostureFlex>
            {i18n.t('Malware Posture')} &nbsp;
            <CapitalizedChip
              dismissible={false}
              size="compact"
              color="neutral"
              label={malwarePosture}
            />
          </PostureFlex>
        </Text>
      )}

      {libraryItemId && pupPosture && (
        <Text title={i18n.t('PUP Posture')}>
          <PostureFlex>
            {i18n.t('PUP Posture')} &nbsp;
            <CapitalizedChip
              dismissible={false}
              size="compact"
              color="neutral"
              label={pupPosture}
            />
          </PostureFlex>
        </Text>
      )}
    </>
  );

  return (
    <StyledTr
      key={`exp${fileHash}${filePath}`}
      hoverBg="none"
      hoverAnchorUnderline={false}
    >
      <Td title="">&nbsp;</Td>

      <Td title="">
        <FlexLabels flow="column" gap="lg" pt1 pb3>
          <Text>{i18n.t('Detected on')}</Text>
          {dateOfQuarantineField && <Text>{i18n.t('Quarantined on')}</Text>}
          {dateOfReleaseField && <Text>{i18n.t('Released on')}</Text>}
          {releasedByField && <Text>{i18n.t('Released by')}</Text>}
          {releaseNoteField && <Text>{i18n.t('Release note')}</Text>}
          {resolvedAtField && <Text>{i18n.t('Resolved on')}</Text>}
          {resolvedByField && <Text>{i18n.t('Resolved by')}</Text>}
          <Text>{i18n.t('Path')}</Text>
          {bundlePathField && <Text>{i18n.t('Bundle Path')}</Text>}
          <Text>{i18n.t('Hash')}</Text>
          <Text>{i18n.t('User')}</Text>
          <Text>
            <Flex alignItems="center" gap="sm">
              <ThreatListTableArrowButton
                compact
                expanded={isDeviceDetailsExpanded}
                onToggle={handleExpandRowButtonClick}
                testId="expand-device-details-button"
              />
              {i18n.t('Device details')}
            </Flex>
          </Text>
          {isDeviceDetailsExpanded && (
            <>
              <TextPadded>{i18n.t('Blueprint')}</TextPadded>
              <TextPadded>{i18n.t('Avert Library Item')}</TextPadded>
            </>
          )}
        </FlexLabels>
      </Td>

      <Td title="" colSpan={isOnDeviceRecord ? 4 : 5}>
        <FlexValues flow="column" gap="lg" pt1 pb3>
          <Text title={i18n.format.datetime(detectionDate)}>
            {i18n.format.datetime(detectionDate)}
          </Text>
          {dateOfQuarantineField}
          {dateOfReleaseField}
          {releasedByField}
          {releaseNoteField}
          {resolvedAtField}
          {resolvedByField}
          <Text title={filePath}>
            <StyleCode>{filePath}</StyleCode>
          </Text>
          {bundlePathField}
          <Text title={fileHash}>
            <StyleCode>{fileHash}</StyleCode>
          </Text>
          <Text title={processOwner}>{processOwner}</Text>
          <Text title={`${deviceName} - ${deviceSerialNumber}`}>
            <Link to={`${links.devices}/${deviceId}`}>{deviceName}</Link>
            {` - ${deviceSerialNumber}`}
          </Text>
          {isDeviceDetailsExpanded && deviceDetails}
        </FlexValues>
      </Td>

      <Td title="">&nbsp;</Td>
    </StyledTr>
  );
};

export default ThreatListTableExpandedRow;
