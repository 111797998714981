import {
  Banner,
  Button,
  Checkbox,
  Select,
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import React, { useEffect } from 'react';
import { i18n } from 'src/i18n';
import './configuration.css';

import { Setting } from 'features/library-items/template';

import VpnService from '../../service/vpn.service';
import allKeys, {
  requiredValidator,
  numberValidator,
  passwordVerifyValidator,
  getDate,
} from './rows.config';

const InputField = (props) => {
  const {
    inputType,
    label,
    isDisabled,
    configuration,
    configKey,
    validators,
    onInvalidate,
    placeholder,
    updateConfiguration,
    refs,
  } = props;

  return (
    <div
      className="k-vpn-config-row__controls"
      ref={refs[configKey.validationKey]}
    >
      <TextInput
        compact
        type={inputType || 'text'}
        id={label}
        label=""
        disabled={isDisabled}
        value={configuration[configKey.key]}
        placeholder={placeholder}
        onChange={(e) => {
          const v = e.target.value;
          updateConfiguration(configKey.key, () => v);
        }}
        validator={validators}
        onInvalidate={
          configKey.validationKey ? onInvalidate(configKey.validationKey) : null
        }
      />
    </div>
  );
};

const Configuration = (props) => {
  const {
    update,
    updateConfiguration,
    isDisabled,
    isSaving,
    validationDep,
    configuration,
    onDelete,
  } = props;
  const configKeys = allKeys(configuration);

  const validationKeys = Object.values(configKeys)
    .map((c) => c.validationKey)
    .filter(Boolean);

  const { refs, onInvalidate } = useInputsValidators(validationKeys, update);
  useRemoveValidationOnUnmount(validationKeys, update);

  const inputProps = {
    updateConfiguration,
    configuration,
    isDisabled,
    refs,
    onInvalidate,
  };

  /* Manages updating the invalid states for the proxy setup nested fields */
  useEffect(() => {
    const manualKeys = [
      configKeys.Server.validationKey,
      configKeys.Port.validationKey,
      configKeys.ProxyPassword.validationKey,
      configKeys.ProxyPasswordVerify.validationKey,
    ];
    const automaticKeys = [configKeys.Url_to_autoconfig_file.validationKey];
    const proxySetupValue = configuration[configKeys.ProxySetup.key]?.value;

    if (proxySetupValue === VpnService.Proxys().AUTOMATIC.value) {
      manualKeys.forEach((key) => onInvalidate(key)(false));
    } else if (proxySetupValue === VpnService.Proxys().MANUAL.value) {
      automaticKeys.forEach((key) => onInvalidate(key)(false));
    } else {
      [...manualKeys, ...automaticKeys].forEach((key) =>
        onInvalidate(key)(false),
      );
    }
  }, [configuration[configKeys.ProxySetup.key]]);

  const onSelect = (key) => (v) => updateConfiguration(key, () => v);
  return (
    <Setting.Card>
      <Setting.Header>
        <div className="b-flex-btw b-flex1 b-flex-vc">
          <h3 className="b-h3">{i18n.t('VPN configuration')}</h3>
          {onDelete && (
            <Button
              onClick={onDelete}
              isDisabled={isDisabled}
              kind="link"
              theme="error"
              icon="xmark"
            >
              {i18n.t('Remove')}
            </Button>
          )}
        </div>
      </Setting.Header>
      <Setting.Rows>
        <Setting.Row>
          <Setting.Title className="b-txt">
            {i18n.t('Connection name')}
          </Setting.Title>
          <Setting.Helpers className="b-txt-light">
            {i18n.t('Display name for this VPN connection.')}
          </Setting.Helpers>
          <Setting.Controls>
            <InputField
              configKey={configKeys.UserDefinedName}
              label={i18n.t('Connection Name')}
              placeholder="VPN"
              validators={(v) => [requiredValidator(v, [validationDep])]}
              {...inputProps}
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title className="b-txt">
            {i18n.t('Connection type')}
          </Setting.Title>
          <Setting.Helpers className="b-txt-light">
            {i18n.t(
              'Supported VPN type. Other types may be configured with a Custom Profile.',
            )}
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              compact
              disabled={isDisabled}
              options={Object.values(VpnService.VPNTypes())}
              value={configuration[configKeys.VPNType.key]}
              onChange={onSelect(configKeys.VPNType.key)}
            />
          </Setting.Controls>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title className="b-txt">{i18n.t('Server')}</Setting.Title>
          <Setting.Helpers className="b-txt-light">
            {i18n.t('The hostname or IP address of the VPN gateway.')}
          </Setting.Helpers>
          <Setting.Controls>
            <InputField
              configKey={configKeys.Hostname}
              label={i18n.t('Server')}
              placeholder="vpn.waystar.com"
              validators={(v) => [requiredValidator(v, [validationDep])]}
              {...inputProps}
            />
          </Setting.Controls>
        </Setting.Row>

        {(!isDisabled ||
          isSaving ||
          (isDisabled && configuration[configKeys.SharedSecret.key])) && (
          <Setting.Row className="k-vpn-config-row">
            <div className="b-flex-vc b-flex-gmicro k-vpn-config-row__title">
              <p className="b-txt">{i18n.t('Shared secret')}</p>
              {!isDisabled && (
                <p className="b-txt-light">{i18n.t('(optional)')}</p>
              )}
            </div>
            <div className="b-flex-vg1  k-vpn-config-row__helper">
              <p className="b-txt-light">
                {i18n.t(
                  'The shared secret used to authenticate to the VPN gateway. Leave blank to prompt the user.',
                )}
              </p>
              {configuration.SHARED_SECRET_UPDATED_AT && (
                <Banner theme="info">
                  <p>
                    {i18n.t(`Set on {date}`, {
                      date: i18n.format.date(
                        new Date(
                          getDate(configuration.SHARED_SECRET_UPDATED_AT),
                        ),
                      ),
                    })}
                  </p>
                </Banner>
              )}
            </div>
            <InputField
              configKey={configKeys.SharedSecret}
              label="Shared Secret (optional)"
              placeholder=""
              inputType="password"
              validators={(v) => [
                passwordVerifyValidator(
                  v,
                  [
                    validationDep,
                    configuration[configKeys.SharedSecret.key],
                    configuration[configKeys.SharedSecretVerify.key],
                  ],
                  configuration[configKeys.SharedSecretVerify.key],
                ),
              ]}
              {...inputProps}
            />
          </Setting.Row>
        )}

        {(!isDisabled || isSaving) && (
          <Setting.Row>
            <Setting.Title className="b-txt">
              {i18n.t('Verify shared secret')}
            </Setting.Title>
            <Setting.Controls>
              <InputField
                configKey={configKeys.SharedSecretVerify}
                label="Verify Shared Secret"
                placeholder=""
                inputType="password"
                validators={(v) => [
                  passwordVerifyValidator(
                    v,
                    [
                      validationDep,
                      configuration[configKeys.SharedSecret.key],
                      configuration[configKeys.SharedSecretVerify.key],
                    ],
                    configuration[configKeys.SharedSecret.key],
                  ),
                ]}
                {...inputProps}
              />
            </Setting.Controls>
          </Setting.Row>
        )}

        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              isDisabled={isDisabled}
              label={i18n.t('Send all traffic through VPN')}
              checked={configuration[configKeys.SendAllThroughVPN.key]}
              onChange={() =>
                updateConfiguration(configKeys.SendAllThroughVPN.key, (p) => !p)
              }
              id={configKeys.SendAllThroughVPN.key}
              testId={configKeys.SendAllThroughVPN.key}
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Select to send all network traffic through the VPN connection (full tunnel).',
              )}
            </p>
          </Setting.Helpers>
        </Setting.Row>

        <Setting.Row>
          <Setting.Title className="b-txt">
            {i18n.t('Proxy setup')}
          </Setting.Title>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t('Configure proxy settings for the VPN connection.')}
            </p>
          </Setting.Helpers>
          <Setting.Controls>
            <Select
              compact
              disabled={isDisabled}
              options={Object.values(VpnService.Proxys())}
              value={configuration[configKeys.ProxySetup.key]}
              onChange={onSelect(configKeys.ProxySetup.key)}
            />
          </Setting.Controls>

          {configuration[configKeys.ProxySetup.key]?.value ===
            VpnService.Proxys().AUTOMATIC.value && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow className="k-vpn-config-row">
                <div className="b-txt k-vpn-config-row__title">
                  {i18n.t('Proxy URL')}
                </div>
                <div className="b-txt-light k-vpn-config-row__helper">
                  {i18n.t(
                    'Full URL to the Proxy Auto-Configuration (PAC) file.',
                  )}
                </div>
                <InputField
                  configKey={configKeys.Url_to_autoconfig_file}
                  label="Proxy URL"
                  placeholder="proxy.waystar.com/auto.pac"
                  validators={(v) => [requiredValidator(v, [validationDep])]}
                  {...inputProps}
                />
              </Setting.SecondaryControlsRow>
            </Setting.SecondaryControls>
          )}

          {configuration[configKeys.ProxySetup.key]?.value ===
            VpnService.Proxys().MANUAL.value && (
            <Setting.SecondaryControls>
              <Setting.SecondaryControlsRow className="k-vpn-config-row">
                <div className="b-txt k-vpn-config-row__title">
                  {i18n.t('Proxy server')}
                </div>
                <div className="b-txt-light k-vpn-config-row__helper">
                  {i18n.t('Hostname or IP address of the proxy server.')}
                </div>
                <InputField
                  configKey={configKeys.Server}
                  label="Proxy Server"
                  placeholder="proxy.waystar.com"
                  validators={(v) => [requiredValidator(v, [validationDep])]}
                  {...inputProps}
                />
              </Setting.SecondaryControlsRow>
              <Setting.SecondaryControlsRow className="k-vpn-config-row">
                <div className="b-txt k-vpn-config-row__title">
                  {i18n.t('Proxy port')}
                </div>
                <div className="b-txt-light k-vpn-config-row__helper">
                  {i18n.t('Port number of the proxy server.')}
                </div>
                <InputField
                  configKey={configKeys.Port}
                  label="Proxy Port"
                  placeholder="8080"
                  validators={(v) => [
                    requiredValidator(v, [validationDep]),
                    numberValidator(v, [validationDep]),
                  ]}
                  {...inputProps}
                />
              </Setting.SecondaryControlsRow>

              {(!isDisabled ||
                isSaving ||
                (isDisabled && configuration[configKeys.ProxyAccount.key])) && (
                <Setting.SecondaryControlsRow className="k-vpn-config-row">
                  <div className="b-mb1 b-flex-vc b-flex-gmicro k-vpn-config-row__title">
                    <p className="b-txt">{i18n.t('Proxy username')}</p>
                    {!isDisabled && (
                      <p className="b-txt-light">{i18n.t('(optional)')}</p>
                    )}
                  </div>
                  <div className="b-txt-light k-vpn-config-row__helper">
                    {i18n.t(
                      'The user name used to authenticate to the proxy server. Leave blank to prompt the user.',
                    )}
                  </div>
                  <InputField
                    configKey={configKeys.ProxyAccount}
                    label={i18n.t('Proxy Username (optional)')}
                    placeholder={i18n.t('Username')}
                    {...inputProps}
                  />
                </Setting.SecondaryControlsRow>
              )}
              {(!isDisabled ||
                isSaving ||
                (isDisabled &&
                  configuration[configKeys.ProxyPassword.key])) && (
                <Setting.SecondaryControlsRow className="k-vpn-config-row">
                  <div className="b-mb1 b-flex-vc b-flex-gmicro k-vpn-config-row__title">
                    <p className="b-txt">{i18n.t('Proxy password')}</p>
                    {!isDisabled && (
                      <p className="b-txt-light">{i18n.t('(optional)')}</p>
                    )}
                  </div>
                  <div className="b-flex-vg1 k-vpn-config-row__helper">
                    <p className="b-txt-light">
                      {i18n.t(
                        'The password used to authenticate to the proxy server. Leave blank to prompt the user.',
                      )}
                    </p>
                    {configuration.PROXY_PASSWORD_UPDATED_AT && (
                      <Banner theme="info">
                        <p>
                          {i18n.t(`Set on {date}`, {
                            date: i18n.format.date(
                              new Date(
                                getDate(
                                  configuration.PROXY_PASSWORD_UPDATED_AT,
                                ),
                              ),
                            ),
                          })}
                        </p>
                      </Banner>
                    )}
                  </div>
                  <InputField
                    configKey={configKeys.ProxyPassword}
                    label={i18n.t('Proxy Password (optional)')}
                    placeholder={i18n.t('Password')}
                    inputType="password"
                    validators={(v) => [
                      passwordVerifyValidator(
                        v,
                        [
                          validationDep,
                          configuration[configKeys.ProxyPassword.key],
                          configuration[configKeys.ProxyPasswordVerify.key],
                        ],
                        configuration[configKeys.ProxyPasswordVerify.key],
                      ),
                    ]}
                    {...inputProps}
                  />
                </Setting.SecondaryControlsRow>
              )}
              {(!isDisabled || isSaving) && (
                <Setting.SecondaryControlsRow className="k-vpn-config-row">
                  <div className="b-txt b-mb1 k-vpn-config-row__title">
                    {i18n.t('Verify proxy password')}
                  </div>
                  <InputField
                    configKey={configKeys.ProxyPasswordVerify}
                    label={i18n.t('Verify Proxy Password')}
                    placeholder={i18n.t('Verify Password')}
                    inputType="password"
                    validators={(v) => [
                      passwordVerifyValidator(
                        v,
                        [
                          validationDep,
                          configuration[configKeys.ProxyPassword.key],
                          configuration[configKeys.ProxyPasswordVerify.key],
                        ],
                        configuration[configKeys.ProxyPassword.key],
                      ),
                    ]}
                    {...inputProps}
                  />
                </Setting.SecondaryControlsRow>
              )}
            </Setting.SecondaryControls>
          )}
        </Setting.Row>

        {(!isDisabled ||
          isSaving ||
          (isDisabled && configuration[configKeys.Account.key])) && (
          <Setting.Row>
            <Setting.Title>
              <div className="b-flex-vc b-flex-gmicro">
                <p className="b-txt">{i18n.t('Account')}</p>
                {!isDisabled && (
                  <p className="b-txt-light">{i18n.t('(optional)')}</p>
                )}
              </div>
            </Setting.Title>
            <Setting.Helpers>
              <p className="b-txt-light">
                {i18n.t(
                  'The account name used to authenticate to the VPN gateway. Leave blank to prompt the user.',
                )}
              </p>
            </Setting.Helpers>
            <Setting.Controls>
              <InputField
                configKey={configKeys.Account}
                label={i18n.t('Account (optional)')}
                placeholder={i18n.t('Account')}
                {...inputProps}
              />
            </Setting.Controls>
          </Setting.Row>
        )}

        {(!isDisabled ||
          isSaving ||
          (isDisabled && configuration[configKeys.Password.key])) && (
          <Setting.Row className="k-vpn-config-row">
            <div className="b-flex-vc b-flex-gmicro k-vpn-config-row__title">
              <p className="b-txt">{i18n.t('Password')}</p>
              {!isDisabled && (
                <p className="b-txt-light">{i18n.t('(optional)')}</p>
              )}
            </div>
            <div className="b-flex-vg1 k-vpn-config-row__helper">
              <p className="b-txt-light">
                {i18n.t(
                  'The password used to authenticate to the VPN gateway. Leave blank to prompt the user.',
                )}
              </p>
              {configuration.PASSWORD_UPDATED_AT && (
                <Banner theme="info">
                  <p>
                    {i18n.t(`Set on {date}`, {
                      date: i18n.format.date(
                        new Date(getDate(configuration.PASSWORD_UPDATED_AT)),
                      ),
                    })}
                  </p>
                </Banner>
              )}
            </div>
            <InputField
              configKey={configKeys.Password}
              label={i18n.t('Password (optional)')}
              placeholder={i18n.t('Password')}
              inputType="password"
              validators={(v) => [
                passwordVerifyValidator(
                  v,
                  [
                    validationDep,
                    configuration[configKeys.Password.key],
                    configuration[configKeys.PasswordVerify.key],
                  ],
                  configuration[configKeys.PasswordVerify.key],
                ),
              ]}
              {...inputProps}
            />
          </Setting.Row>
        )}

        {(!isDisabled || isSaving) && (
          <Setting.Row>
            <Setting.Title>
              <div className="b-txt">{i18n.t('Verify password')}</div>
            </Setting.Title>
            <Setting.Controls>
              <InputField
                configKey={configKeys.PasswordVerify}
                label={i18n.t('Verify Password')}
                placeholder=""
                inputType="password"
                validators={(v) => [
                  passwordVerifyValidator(
                    v,
                    [
                      validationDep,
                      configuration[configKeys.Password.key],
                      configuration[configKeys.PasswordVerify.key],
                    ],
                    configuration[configKeys.Password.key],
                  ),
                ]}
                {...inputProps}
              />
            </Setting.Controls>
          </Setting.Row>
        )}
      </Setting.Rows>
    </Setting.Card>
  );
};

export default Configuration;
