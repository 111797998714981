import * as React from 'react';
import { ExportButton } from 'src/components/export';
import { i18n } from 'src/i18n';
import type { ExportPrismViewRequestSchema } from '../../prism/types/prism.types';

interface DevicesExportButtonProps {
  view?: {
    name: string | undefined;
    filters: Record<string, unknown> | undefined;
  };
  columns:
    | {
        category: string | undefined;
        name: string | undefined;
        visible: boolean | undefined;
        size: number | undefined;
      }[]
    | undefined;
  filter?: Record<string, unknown> | undefined;
  blueprintIds?: string[] | undefined;
  deviceFamilies?: string[] | undefined;
}

export const DevicesExportButton = ({
  view,
  columns,
  filter,
  blueprintIds,
  deviceFamilies,
}: DevicesExportButtonProps) => {
  const [exportType, setExportType] = React.useState<
    'current_view' | 'all_attributes'
  >('current_view');
  return (
    <ExportButton
      title={getTitle(view?.name)}
      exportTypeOptions={[
        { type: 'current_view', label: i18n.t('Current View') },
        { type: 'all_attributes', label: i18n.t('Saved View') },
      ]}
      helpText={getHelpText(exportType)}
      onExport={() =>
        handleExportPayload({
          exportType,
          viewName: view?.name,
          viewFilters: view?.filters,
          columns,
          filter,
          blueprintIds,
          deviceFamilies,
        })
      }
      exportType={exportType}
      setExportType={setExportType}
      exportDataType="views"
    />
  );
};

export const getTitle = (viewName: string | undefined) => {
  return `Export ${viewName ?? 'Devices'} to CSV`;
};

export const handleExportPayload = ({
  exportType,
  // sorting,
  columns,
  blueprintIds,
  deviceFamilies,
  filter,
  viewFilters,
  viewName,
}: {
  exportType: 'current_view' | 'all_attributes';
  // sorting: SortingState;
  columns:
    | {
        category: string | undefined;
        name: string | undefined;
        visible: boolean | undefined;
        size: number | undefined;
      }[]
    | undefined;
  blueprintIds: string[] | undefined;
  deviceFamilies: string[] | undefined;
  viewName?: string | undefined;
  filter: Record<string, unknown> | undefined;
  viewFilters: Record<string, unknown> | undefined;
}): ExportPrismViewRequestSchema => {
  const filterToApply = exportType === 'all_attributes' ? viewFilters : filter;
  const prefixedFilter = prefixFilterWithCategory(
    filterToApply,
    'device_information',
  );

  const payload: ExportPrismViewRequestSchema = {
    blueprint_ids: blueprintIds ?? [],
    device_families: deviceFamilies ?? [],
    filter: prefixedFilter,
    columns:
      exportType === 'all_attributes'
        ? columns
        : columns?.filter((col) => col.visible),
    view_name: viewName ?? 'Devices',
    // TODO: revisit once we have support for sorting in views export
    // sort_by: getSortString(sorting),
  };

  return payload;
};

export const prefixFilterWithCategory = (
  filter: Record<string, unknown> | undefined,
  category: string,
) => {
  if (!filter || Object.keys(filter).length === 0) {
    return filter;
  }

  return Object.entries(filter).reduce(
    (acc, [key, value]) => {
      if (key.split('.').length === 2) {
        acc[key] = value;
      } else {
        acc[`${category}.${key}`] = value;
      }
      return acc;
    },
    {} as Record<string, unknown>,
  );
};

export const getHelpText = (exportType: string) => {
  if (exportType === 'current_view') {
    return i18n.$t(
      'CSV will {only} include data that is currently visible in the table, and contain any applied filters.',
      { only: <strong>only</strong> },
    );
  }
  return i18n.$t(
    'CSV will include {all} attributes and filters in your saved view.',
    { all: <strong>all</strong> },
  );
};
