// istanbul ignore file
import { Button, Flex } from '@kandji-inc/nectar-ui';
import type { OnApply, OnClear } from 'src/features/edr/common/common.types';
import { i18n } from 'src/i18n';
import EDRDetectionDateFilter from '../../common/components/Filters/EDRDetectionDateFilter';
import EDRSearchFilter from '../../common/components/Filters/EDRSearchFilter';
import ThreatListExportButton from '../common/components/ThreatListExportButton';
import ClassificationFilterDropdown from '../common/components/ThreatListFilters/ClassificationFilterDropdown';
import DetectionTypeSelector from '../common/components/ThreatListFilters/DetectionTypeSelector';
import StatusFilter from '../common/components/ThreatListFilters/StatusFilter';
import constants from '../common/constants';
import type {
  DetectionTypeSelectorState,
  FiltersState,
  OnClearAll,
} from '../threat.types';

type ThreatListFiltersProps = {
  filters: FiltersState;
  onApply: OnApply;
  onDetectionTypeApply: (value: DetectionTypeSelectorState | undefined) => void;
  onClear: OnClear;
  onClearAll: OnClearAll;
  isFiltering: boolean;
  malwareCount: number | undefined;
  pupCount: number | undefined;
  isExportHidden?: boolean;
  onExport: () => void;
  isFileDetectionType?: boolean;
};

const enumOptions =
  constants.THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS.filter(
    (option) => option.value !== 'last_24_hours' && option.value !== 'all_time',
  );

const ThreatListFilters = (props: ThreatListFiltersProps) => {
  const {
    filters,
    onApply,
    onDetectionTypeApply,
    onClear,
    onClearAll,
    isFiltering,
    malwareCount,
    pupCount,
    isExportHidden = false,
    onExport,
    isFileDetectionType,
  } = props;

  return (
    <Flex flow="column" gap="md" flex={1}>
      <Flex justifyContent="space-between">
        <EDRSearchFilter
          name="query"
          value={filters.query}
          onChange={onApply}
          onClear={onClear}
          isValueSync={false}
        />
        <DetectionTypeSelector
          value={filters.detectionType}
          onChange={onDetectionTypeApply}
        />
      </Flex>
      <Flex justifyContent="space-between">
        <Flex wrap="wrap" gap="md">
          <EDRDetectionDateFilter
            filter={filters.detectionDate}
            onChange={onApply}
            onClear={onClear}
            options={enumOptions}
          />
          <StatusFilter
            values={filters.status}
            onChange={onApply}
            onClear={onClear}
            list={
              isFileDetectionType
                ? constants.THREAT_STATUS_LIST
                : constants.THREAT_BEHAVIORAL_STATUS_LIST
            }
          />
          <ClassificationFilterDropdown
            value={filters.classification}
            onChange={onApply}
            onClear={onClear}
            list={
              isFileDetectionType
                ? constants.THREAT_CLASSIFICATION_LIST
                : constants.THREAT_BEHAVIORAL_CLASSIFICATION_LIST
            }
            malwareCount={malwareCount}
            pupCount={pupCount}
            benignCount={0}
            unknownCount={0}
          />
          {isFiltering && (
            <Button variant="subtle" compact onClick={onClearAll}>
              {i18n.t('Clear all')}
            </Button>
          )}
        </Flex>
        {!isExportHidden && <ThreatListExportButton onClick={onExport} />}
      </Flex>
    </Flex>
  );
};

export default ThreatListFilters;
