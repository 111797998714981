import { Toaster as toaster } from '@kandji-inc/bumblebee';
import { Banner } from '@kandji-inc/nectar-ui';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { usePermissions } from 'contexts/account';
import { i18n } from 'i18n';
import KandjiSupportLink from '../../common/KandjiSupportLink';
import { isM1Device, regExpMapper } from '../../common/helpers';
import { getPin } from '../api';
import PINModal from './PINModal';

const ShowPINField = styled('span')`
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
`;

const LockedNotification = ({
  computerId,
  lockStatus,
  deviceModel,
  osVersion,
}) => {
  const [pin, setPin] = useState(null);
  const [showPin, setShowPin] = useState(false);
  const onShowClick = useCallback(() => {
    getPin(computerId)
      .then((res) => {
        setPin(res.pin);
        setShowPin((prev) => !prev);
      })
      .catch(() => {
        toaster(i18n.t('Failed to get the PIN code'));
      });
  }, []);
  const onClose = () => {
    setShowPin(false);
  };
  const permissions = usePermissions();
  const disabledLockPin =
    isM1Device(deviceModel) && regExpMapper['11.0-11.4'].test(osVersion);
  const disabledLockPinText = i18n.t(
    ' Lock device PINs are not supported on Mac computers with Apple silicon prior to macOS 11.5.',
  );
  const supportLink = (
    <KandjiSupportLink
      href="https://support.kandji.io/support/solutions/articles/72000560469"
      text={i18n.t(' Learn more.')}
    />
  );

  return (
    <Banner
      theme="danger"
      icon="lock"
      text={
        <>
          {lockStatus === 'yes' && (
            <span>
              <b>{i18n.t('This device is locked.')}</b>
              {!disabledLockPin
                ? i18n.t('  It can be unlocked using the PIN.')
                : disabledLockPinText}
              {supportLink}
            </span>
          )}
          {lockStatus === 'pending' && (
            <span>
              {!disabledLockPin ? (
                <>
                  <b>
                    {i18n.$t(
                      'This device will be locked with {showPINField} the next time it connects to MDM.',
                      {
                        showPINField: permissions.canViewSecrets ? (
                          <ShowPINField onClick={onShowClick}>
                            {i18n.t('this PIN')}
                          </ShowPINField>
                        ) : (
                          <>{i18n.t('a PIN')}</>
                        ),
                      },
                    )}
                  </b>
                </>
              ) : (
                <>
                  <b>
                    {i18n.t(
                      'This device will lock the next time it connects to MDM.',
                    )}
                  </b>
                  {disabledLockPinText}
                </>
              )}
              {supportLink}
            </span>
          )}
          {lockStatus === 'yes' &&
            !disabledLockPin &&
            permissions.canViewSecrets && (
              <>
                <ShowPINField onClick={onShowClick}>
                  {i18n.t('Show PIN')}
                </ShowPINField>
              </>
            )}
          {pin && <PINModal pin={pin} showPin={showPin} onClose={onClose} />}
        </>
      }
    />
  );
};

LockedNotification.propTypes = {
  computerId: PropTypes.string.isRequired,
  lockStatus: PropTypes.string.isRequired,
  deviceModel: PropTypes.string.isRequired,
  osVersion: PropTypes.string.isRequired,
};

export default LockedNotification;
