import { Body, Button, Dialog, Flex } from '@kandji-inc/nectar-ui';
import { useRulesContext } from 'features/rules-modal/RulesContext';
/* istanbul ignore file */
import React from 'react';
import { i18n } from 'src/i18n';

export const RulesDeleteModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}) => {
  const { deleteRules } = useRulesContext();
  return (
    <Dialog
      isOpen={isOpen}
      closeOnEscape
      closeOnOutsideClick
      onOpenChange={() => setIsOpen(false)}
      css={{
        width: '560px',
      }}
      title={i18n.t('Assignment Rules')}
      content={
        <>
          <Body>
            {i18n.t(`By removing these Rules, this Library Item will be included on all
            devices associated with the Blueprints this Library Item is assigned
            to.`)}
          </Body>
          <Body>
            {i18n.t(`Those changes will only go into effect once the Library Item itself
            is saved.`)}
          </Body>
          <Body>
            {i18n.t(`Are you sure that you want to remove these Rules?`)}
          </Body>
        </>
      }
      footer={
        <Flex justifyContent="end" gap="sm">
          <Button variant="subtle" onClick={() => setIsOpen(false)}>
            {i18n.t(`Cancel`)}
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              deleteRules();
              setIsOpen(false);
            }}
          >
            {i18n.t(`Remove`)}
          </Button>
        </Flex>
      }
    />
  );
};
