import {
  NFMultiSelect,
  NFMultiTextField,
  NFSelectV2,
  NFTextField,
  type ValidationFunction,
  ValidationTemplates,
  useFieldValue,
} from '@kandji-inc/nectar-form';
import { Text } from '@kandji-inc/nectar-ui';
import type { FacetData } from 'features/rules-modal/types';
import {
  convertFacetMapValidationToNectarFormValidation,
  operatorHasMultipleValues,
} from 'features/rules-modal/utils';
import {
  DATA_TYPES,
  LI_OS_VERSION_DEFAULT_OPERATOR,
  TEXT_TYPES,
} from 'features/rules/constants';
import { i18n } from 'src/i18n';
import { MultiSelectApi } from './MultiSelectApi';

const requiredNonEmptyArray =
  /* istanbul ignore next */
    (errorMsg = i18n.t('This field is required')): ValidationFunction =>
    (stringValue) => {
      const arrayValue = JSON.parse(stringValue || '[]');
      if (arrayValue.length === 0) {
        return errorMsg;
      }
    };

export const RuleValue = ({
  operatorFieldName,
  valueFieldPrefix,
  facetData,
  /* istanbul ignore next */
  disabled = false,
}: {
  operatorFieldName: string;
  valueFieldPrefix: string;
  facetData: FacetData;
  disabled?: boolean;
}) => {
  const operator = useFieldValue(operatorFieldName);
  const hasMultipleValues = operatorHasMultipleValues(
    facetData.data_type,
    operator,
  );
  const valueFieldName = hasMultipleValues
    ? `${valueFieldPrefix}.multi_value`
    : `${valueFieldPrefix}.value`;

  // no value for this operator
  if (
    operator === TEXT_TYPES.IS_BLANK ||
    operator === TEXT_TYPES.IS_NOT_BLANK ||
    operator === TEXT_TYPES.IS_ANY ||
    operator === LI_OS_VERSION_DEFAULT_OPERATOR
  ) {
    return null;
  }

  let validation = hasMultipleValues
    ? [
        requiredNonEmptyArray(
          i18n.t(`Enter {facetDataName}.`, { facetDataName: facetData.name }),
        ),
      ]
    : [
        ValidationTemplates.required(
          i18n.t(`Enter {facetDataName}.`, { facetDataName: facetData.name }),
        ),
      ];

  if (facetData.validation) {
    validation = [
      ...validation,
      ...convertFacetMapValidationToNectarFormValidation(facetData.validation),
    ];
  }

  switch (facetData.data_type) {
    case DATA_TYPES.SELECT:
      return (
        <NFSelectV2
          name={valueFieldName}
          disabled={disabled}
          options={facetData.options}
          validation={validation}
        />
      );
    case DATA_TYPES.TEXT:
      return operator === TEXT_TYPES.BETWEEN ? (
        <>
          <NFTextField
            key={`${valueFieldName}.0`}
            name={`${valueFieldName}.0`}
            disabled={disabled}
            placeholder={i18n.t('Value')}
            validation={validation}
          />
          <Text css={{ margin: '$2' }}>{i18n.t(`and`)}</Text>
          <NFTextField
            key={`${valueFieldName}.1`}
            name={`${valueFieldName}.1`}
            disabled={disabled}
            placeholder={i18n.t('Value')}
            validation={validation}
          />
        </>
      ) : (
        <NFTextField
          key={valueFieldName}
          name={valueFieldName}
          disabled={disabled}
          placeholder={i18n.t('Value')}
          validation={validation}
        />
      );
    case DATA_TYPES.MULTITEXT:
      return hasMultipleValues ? (
        <NFMultiTextField
          name={valueFieldName}
          disabled={disabled}
          validation={validation}
          placeholder={i18n.t('Type value and press enter or paste a list')}
        />
      ) : (
        <NFTextField
          name={valueFieldName}
          disabled={disabled}
          placeholder={i18n.t('Value')}
          validation={validation}
        />
      );
    case DATA_TYPES.MULTISELECT:
      return (
        <NFMultiSelect
          componentCss={{
            root: {
              width: '100%',
            },
            trigger: {
              width: '100%',
            },
            menu: {
              width: '100%',
            },
          }}
          name={valueFieldName}
          size="full"
          disabled={disabled}
          options={facetData.options}
          selectAllLabel={i18n.t('All')}
          validation={
            /* istanbul ignore next */ validation || [
              requiredNonEmptyArray(
                i18n.t(`Select {facetDataName}.`, {
                  facetDataName: facetData.name,
                }),
              ),
            ]
          }
        />
      );
    case DATA_TYPES.MULTISELECTAPI:
      return (
        <MultiSelectApi
          name={valueFieldName}
          validation={[
            requiredNonEmptyArray(
              i18n.t(`Select {facetDataName}.`, {
                facetDataName: facetData.name,
              }),
            ),
            ValidationTemplates.required(
              i18n.t(`Enter {facetDataName}.`, {
                facetDataName: facetData.name,
              }),
            ),
          ]}
          apiData={facetData.api}
        />
      );
    default:
      throw new Error(`Unsupported data type: ${facetData.data_type}`);
  }
};
