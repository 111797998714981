export const noop = () => {};

export const slugify = (text) => {
  const a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  const b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  const p = new RegExp(a.split('').join('|'), 'g');
  return (
    (text &&
      text
        .toString()
        .toLowerCase()
        // Replace spaces with -
        .replace(/\s+/g, '-')
        // Replace special chars
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        // Replace & with 'and'
        .replace(/&/g, '-and-')
        // Remove all non-word chars
        .replace(/[^\w-]+/g, '')
        // Replace multiple - with single -
        .replace(/--+/g, '-')
        // Replace something with single -
        .replace(/[\s_-]+/g, '-')
        // Trim - from start of text
        .replace(/^-+/, '')
        // Trim - from end of text
        .replace(/-+$/, '')
        // Trim spaces from start and end of text
        .trim()) ||
    null
  );
};

export const copyToClipboard = (str) => {
  navigator.clipboard.writeText(str);
};
