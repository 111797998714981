import {
  Badge,
  Box,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  DropdownMenu,
  Flex,
  Heading,
  Icon,
  Text,
  Tooltip,
  styled,
} from '@kandji-inc/nectar-ui';
import get from 'lodash/get';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { i18n } from 'i18n';
import { UserRoles } from 'src/app/common/constants';
import getDeviceImage from 'src/components/common/image-device';
import { getModelID } from 'src/components/common/image-device/utilities';
import useAccount, { usePermissions } from 'src/contexts/account';
import {
  ASSIGNMENT_MAP_DEVICE_LOOKUP_PARAM,
  paths,
} from 'src/features/blueprints/common';
import DeviceActions from 'src/features/device-actions';
import { useGetLibrary } from 'src/features/library-items/data-service/library/useGetLibraryItems';
import {
  apiTypes,
  deviceTypes,
} from 'src/features/library-items/library/common';
import { useBlueprints } from 'src/features/visibility/prism/hooks';
import { getLostModeStatus } from 'src/hooks/useLostMode';

import { useFlags } from 'src/config/feature-flags';
import { translatedSeverity } from 'src/features/edr/vulnerability/constants';
import { useGetDeviceVulnerabilitySummary } from 'src/features/edr/vulnerability/hooks/use-get-device-vulnerability-summary';
import { Severity } from 'src/features/edr/vulnerability/vulnerability.types';
import { getEnv } from 'src/util';
import { getStatusBadgeColor } from '../common/helpers';
import { Loader } from '../interface/Loader';
import ComputerChangeBlueprint from '../interface/modals/ComputerChangeBlueprint';
import ComputerNotifications from './ComputerNotifications';
import { AssignTagsModal, TagDisplay } from './assign-tags';
import { AssignUserModal } from './assign-user';
import {
  getAgentLastCheckIn,
  getDeviceModel,
  getSystemVersion,
} from './computerHelpers';
import { SetAssetTagModal } from './set-asset-tag';
import useChangeComputerBlueprint from './useChangeComputerBlueprint';
import useComputer from './useComputer';
import { useDeviceModals } from './useDeviceModals';
import useComputerLastCheckIn from './useMDMCheckin';
import usePatchComputer from './usePatchComputer';

const overflowTextCss = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const DetailColumn = styled(Box, {
  maxWidth: '276px',
});

const DetailTextLabel = styled(Text, {
  fontSize: '14px',
  padding: '$1 0',
  color: '$neutral70',
  whiteSpace: 'nowrap',
});

const DetailTextValue = styled(Text, {
  color: '$neutral90',
  padding: '$1 0',
  fontWeight: '$medium',
  fontSize: '14px',
  ...overflowTextCss,
});

const IconLink = styled(Link, {
  display: 'flex',
  marginLeft: '4px',
  position: 'relative',

  '&:hover': {
    color: 'inherit',
  },

  '& svg': {
    width: '16px',
    height: '16px',
    color: '#a5aab5',
  },
});

/* istanbul ignore next */
const TooltipOverflowText = (props) => {
  const { children, overflowText } = props;
  const ref = useRef(null);
  const childWithRef = React.cloneElement(children, { ref });
  const isOverflowing = ref.current?.scrollWidth > ref.current?.clientWidth;

  if (isOverflowing) {
    return (
      <Tooltip
        content={<Text>{overflowText}</Text>}
        maxWidth="unset"
        css={{ zIndex: 10 }}
      >
        {childWithRef}
      </Tooltip>
    );
  }
  return childWithRef;
};

const SingleDeviceHeader = (props) => {
  const { children } = props;
  const { id: computerId } = useParams<{ id: string }>();
  const {
    userRole,
    currentCompany: { feature_configuration },
  } = useAccount();
  const isThreatEnabled = feature_configuration?.edr?.enabled;
  const isUserHelpDesk = userRole === UserRoles.helpdesk;
  const permissions = usePermissions();
  const {
    'dc-05302024-tags': LDFF_tags,
    'vm_06252024_vuln-poc': LDFF_vuln,
    'edr_091324_process-monitoring-ui': LDFF_threat_bd,
  } = useFlags();
  const isVulnEnabled =
    LDFF_vuln && feature_configuration?.vulnerability_management?.enabled;

  const [isScrolling, setIsScrolling] = useState(false);
  const { data: computer, refetch: refetchComputer } = useComputer(computerId);
  const { mutateAsync: mutateComputer } = usePatchComputer(computerId);
  const { mutateAsync: mutateComputerBlueprint } = useChangeComputerBlueprint();
  const { data: computerCheckinData } = useComputerLastCheckIn(computerId);
  const { data: blueprints } = useBlueprints();
  const { data: vulnerabilities = {} } =
    useGetDeviceVulnerabilitySummary(computerId);
  const { data: threatRes, isLoading: isLoadingThreat } = useGetLibrary({
    loc: 'list',
    apiParams: {
      blueprint__in: computer?.blueprint_id,
      type__in: apiTypes.THREAT_SECURITY_POLICY,
    },
    options: {
      enabled: !!computer?.blueprint_id,
    },
  });
  const [modalKind, modalIsOpen, setModalOpen] = useDeviceModals();
  const hasThreatInstalled = Boolean((threatRes as any)?.data?.count);

  /* istanbul ignore next */
  const formatNumberText = (num, text) => {
    if (!num) {
      return '';
    }

    return `${i18n.format.number(num)} ${text}`;
  };

  const getStatusWithLostMode = (computer) => {
    const { isLostModePending, isLostModeEnabled } =
      getLostModeStatus(computer);

    return isLostModePending || isLostModeEnabled
      ? i18n.t('LOST')
      : computer.status;
  };

  const getFullStatus = (status, deferred) => {
    if (deferred) {
      return i18n.t('Awaiting First Run').toUpperCase();
    }

    const mapping = {
      PASS: i18n.t('ALL CLEAR'),
      REMEDIATED: i18n.t('ALL CLEAR'),
      ERROR: i18n.t('ALERT'),
      WARNING: i18n.t('ALERT'),
      INCOMPATIBLE: i18n.t('INCOMPATIBLE'),
      EMPTY: i18n.t('NO HISTORY'),
      STOPPED: i18n.t('STOPPED'),
      LOST: i18n.t('LOST'),
    };

    return (mapping[status] || mapping.EMPTY).toUpperCase();
  };

  const formatVulnerabilities = (vulnerabilities) => {
    const order = [
      i18n.t('critical'),
      i18n.t('high'),
      i18n.t('medium'),
      i18n.t('low'),
    ];

    const filteredAndFormattedVulnerabilities = order
      .filter((key) => vulnerabilities[key] > 0)
      .map(
        (key) =>
          `${translatedSeverity[Severity[key]]()} (${i18n.format.number(vulnerabilities[key])})`,
      )
      .join(', ');

    return filteredAndFormattedVulnerabilities || '-';
  };

  useEffect(() => {
    const onScroll = () => setIsScrolling(window.scrollY > 0);
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  if (!computer) {
    return <Loader />;
  }

  const {
    name,
    device_family: deviceFamily,
    blueprint_id,
    threat_counts,
    user,
    asset_tag,
    deferred_install,
  } = computer;
  const model = getDeviceModel(computer);
  const modelID = getModelID(computer);
  const imageDevice = getDeviceImage(deviceFamily, modelID);
  const serialNumber = computer.serial_number || '-';
  const status = getStatusWithLostMode(computer);
  const computerFullStatus = getFullStatus(status, deferred_install);
  const statusBadgeColor = getStatusBadgeColor(
    status,
    null,
    deferred_install,
    null,
  );
  const computerLastCheckins = { last_checkins: computerCheckinData || {} };
  const lastCheckIn = [
    getAgentLastCheckIn(computerLastCheckins),
    computerCheckinData?.['mdm-command'],
  ]
    .filter((el) => el != null)
    .sort()
    .reverse()[0];
  const lastNotification = get(
    computer,
    'device.last_apns_request.requested_at',
  );
  const lastAPNSStatus = get(computer, 'device.last_apns_request.status');
  const isAPNSFailed = lastAPNSStatus !== 200;
  const lastAPNSErrorDescription = get(
    computer,
    'device.last_apns_request.description',
  );
  const lastAPNSErrorReason = get(computer, 'device.last_apns_request.reason');
  const isShowingLastAPNsPush = getEnv() === 'dev' && computer.is_mdm;

  const assignedBlueprint = blueprints?.find((bp) => bp.id === blueprint_id);
  const osTypeMap = {
    Mac: i18n.t('macOS'),
    iPhone: i18n.t('iOS'),
    iPad: i18n.t('iPadOS'),
    AppleTV: i18n.t('tvOS'),
  };

  const osType = osTypeMap[deviceFamily] || i18n.t('OS');
  const os = computer.os_version
    ? getSystemVersion(computer, false, true, true, true, '-')
    : computer.info
      ? computer.info['System Version'].split('(')[0]
      : '-';
  const blueprintNames = blueprints?.reduce(
    (a, c) => ({
      ...a,
      [c.id]: c.name,
    }),
    {},
  );
  const editDeviceDetailsOptions = [
    {
      label: i18n.t('Edit user'),
      onClick: () => setModalOpen(true, 'assignUser'),
      disabled: !permissions.canManageDevices,
    },
    {
      label: i18n.t('Edit asset tag'),
      onClick: () => setModalOpen(true, 'assetTag'),
      disabled: !permissions.canManageDevices,
    },
    {
      label: i18n.t('Edit tags'),
      onClick: () => setModalOpen(true, 'tags'),
      disabled: !permissions.canManageDevices,
    },
    {
      label: i18n.t('Edit Blueprint'),
      onClick: () => setModalOpen(true, 'blueprint'),
      disabled: !permissions.canManageBlueprints && !isUserHelpDesk,
    },
  ];

  return (
    <Flex flow="column" css={{ margin: '0 -48px' }}>
      <Flex
        gap="md"
        flow="column"
        css={{
          position: 'sticky',
          top: 0,
          backgroundColor: '$neutral0',
          zIndex: 10,
          padding: '18px $5 0 $5',
        }}
      >
        <Breadcrumbs isNavigation css={{ marginBottom: '$1' }}>
          <BreadcrumbsItem
            item={{ title: i18n.t('Devices'), url: '/devices' }}
          />
          <BreadcrumbsItem
            item={{
              title: name,
            }}
          />
        </Breadcrumbs>

        <Flex
          justifyContent="space-between"
          css={{
            position: 'relative',
            '&::after': {
              content: '',
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              height: '30px',
              background: 'linear-gradient(white, rgba(255, 255, 255, 0))',
              pointerEvents: 'none',
              visibility: isScrolling ? 'visible' : 'hidden',
            },
          }}
        >
          <Flex alignItems="center" gap="sm">
            <img
              src={imageDevice}
              alt="device"
              style={{ width: '36px', height: '36px' }}
            />
            <Heading size="2" css={{ fontWeight: '$medium' }}>
              {name}
            </Heading>
            {['ERROR', 'WARNING', 'LOST'].includes(status) && (
              <Flex>
                <Badge color={statusBadgeColor as any}>
                  {computerFullStatus}
                </Badge>
              </Flex>
            )}
          </Flex>
          <Flex alignItems="center" gap="sm">
            <DropdownMenu
              css={{ width: '140px', zIndex: 99999999 }}
              options={editDeviceDetailsOptions}
              withArrow={false}
              contentProps={{ align: 'start' }}
            >
              <Button compact>{i18n.t('Edit device details')}</Button>
            </DropdownMenu>
            <DeviceActions computer={computer} />
          </Flex>
        </Flex>
      </Flex>

      <Flex flow="column" gap="md" pl5 pr5>
        <Flex css={{ gap: '60px' }}>
          <DetailColumn>
            <Flex gap="xs" alignItems="center">
              <DetailTextLabel>{i18n.t('Model')}</DetailTextLabel>
              <TooltipOverflowText overflowText={model}>
                <DetailTextValue>{model || '-'}</DetailTextValue>
              </TooltipOverflowText>
            </Flex>
            <Flex gap="xs" alignItems="center">
              <DetailTextLabel>{i18n.t('Serial')}</DetailTextLabel>
              <TooltipOverflowText overflowText={serialNumber}>
                <DetailTextValue>{serialNumber}</DetailTextValue>
              </TooltipOverflowText>
            </Flex>
            <Flex gap="xs" alignItems="center">
              <DetailTextLabel>
                {i18n.t('{osType} version', { osType })}{' '}
              </DetailTextLabel>
              <TooltipOverflowText overflowText={os}>
                <DetailTextValue>{os}</DetailTextValue>
              </TooltipOverflowText>
            </Flex>
          </DetailColumn>
          <DetailColumn>
            <Flex gap="xs" alignItems="center">
              <DetailTextLabel>{i18n.t('User')}</DetailTextLabel>
              <TooltipOverflowText overflowText={user?.name}>
                <DetailTextValue>
                  {user ? (
                    <Link to={`/users/all/${user.id}`}>{user.name}</Link>
                  ) : (
                    '-'
                  )}
                </DetailTextValue>
              </TooltipOverflowText>
            </Flex>
            <Flex gap="xs" alignItems="center">
              <DetailTextLabel>{i18n.t('Asset tag')}</DetailTextLabel>
              <TooltipOverflowText overflowText={asset_tag}>
                <DetailTextValue>{asset_tag || '-'}</DetailTextValue>
              </TooltipOverflowText>
            </Flex>
            {LDFF_tags && (
              <Flex gap="xs" alignItems="center">
                <DetailTextLabel>{i18n.t('Tags')}</DetailTextLabel>
                <DetailTextValue>
                  {computer.tags?.length ? (
                    <TagDisplay tags={computer.tags} />
                  ) : (
                    '-'
                  )}
                </DetailTextValue>
              </Flex>
            )}
          </DetailColumn>
          <DetailColumn>
            <Flex gap="xs" alignItems="center">
              <DetailTextLabel>{i18n.t('Blueprint')}</DetailTextLabel>
              <Flex alignItems="center" css={overflowTextCss}>
                <TooltipOverflowText overflowText={assignedBlueprint?.name}>
                  <DetailTextValue>
                    {assignedBlueprint?.name ? (
                      <Link
                        to={paths.getBlueprintRouteByType(assignedBlueprint)}
                      >
                        {assignedBlueprint.name}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </DetailTextValue>
                </TooltipOverflowText>
                {assignedBlueprint?.type === 'flow' && (
                  <Tooltip
                    content={
                      <Text data-testid="look-up-flow-tooltip">
                        {i18n.t('Look up device on Assignment Map')}
                      </Text>
                    }
                    maxWidth="unset"
                    css={{ zIndex: 99999 }}
                  >
                    <IconLink
                      data-testid="look-up-flow"
                      to={`${paths.flowBlueprint(
                        assignedBlueprint.id,
                      )}?${ASSIGNMENT_MAP_DEVICE_LOOKUP_PARAM}=${
                        computer.serial_number
                      }`}
                    >
                      <Icon name="magnifying-glass" />
                    </IconLink>
                  </Tooltip>
                )}
              </Flex>
            </Flex>
            <Flex gap="xs" alignItems="center">
              <DetailTextLabel>{i18n.t('Last check-in')}</DetailTextLabel>
              <Tooltip
                content={
                  <Text data-testid="last-checkin-tooltip">
                    {i18n.format.datetime(lastCheckIn)}
                  </Text>
                }
                onOpen={() => lastCheckIn}
                css={{ zIndex: 99999 }}
              >
                <DetailTextValue data-testid="last-checkin">
                  {lastCheckIn
                    ? i18n.format.datetime(lastCheckIn, { relative: true })
                    : '-'}
                </DetailTextValue>
              </Tooltip>
            </Flex>
            {isShowingLastAPNsPush && (
              <Flex gap="xs" alignItems="center">
                <DetailTextLabel>{i18n.t('Last APNS push')}</DetailTextLabel>
                <Tooltip
                  content={
                    <Text data-testid="apns-tooltip">
                      {isAPNSFailed
                        ? `${lastAPNSErrorReason}: ${lastAPNSErrorDescription}`
                        : i18n.format.datetime(lastNotification)}
                    </Text>
                  }
                  onOpen={() =>
                    (isAPNSFailed && lastAPNSErrorDescription) ||
                    lastNotification
                  }
                  css={{ zIndex: 99999 }}
                >
                  <DetailTextValue data-testid="apns">
                    {isAPNSFailed && 'Error'}
                    {!isAPNSFailed &&
                      (lastNotification
                        ? i18n.format.datetime(lastNotification, {
                            relative: true,
                          })
                        : '-')}
                  </DetailTextValue>
                </Tooltip>
              </Flex>
            )}
          </DetailColumn>
          <DetailColumn css={{ maxWidth: '359px' }}>
            {isThreatEnabled && deviceFamily === deviceTypes.MAC && (
              <Flex gap="xs" alignItems="center">
                {LDFF_threat_bd ? (
                  <>
                    <DetailTextLabel>
                      {i18n.t('Active threats')}
                    </DetailTextLabel>
                    {!isLoadingThreat && !hasThreatInstalled && (
                      <Tooltip
                        content={
                          <Text>
                            {i18n.t('Avert library item not assigned')}
                          </Text>
                        }
                        css={{ zIndex: 99999 }}
                      >
                        <DetailTextValue>{i18n.t('n/a')}</DetailTextValue>
                      </Tooltip>
                    )}
                    {!isLoadingThreat && hasThreatInstalled && (
                      <Flex flow="row" gap="md">
                        <Tooltip
                          side="top"
                          theme="dark"
                          content={i18n.t('File detection: Not Quarantined')}
                          css={{ zIndex: 10 }}
                        >
                          <Flex alignItems="center" gap="xs">
                            <Icon
                              name="shield-xmark"
                              size="sm"
                              color="var(--colors-red50)"
                            />
                            <DetailTextValue>
                              {threat_counts?.not_quarantined_total || 0}
                            </DetailTextValue>
                          </Flex>
                        </Tooltip>
                        <Tooltip
                          side="top"
                          theme="dark"
                          content={i18n.t('Behavioral detection: Detected')}
                          css={{ zIndex: 10 }}
                        >
                          <Flex alignItems="center" gap="xs">
                            <Icon
                              name="bell"
                              size="sm"
                              color="var(--colors-yellow60)"
                            />
                            <DetailTextValue>
                              {threat_counts?.malicious_detected_count || 0}
                            </DetailTextValue>
                          </Flex>
                        </Tooltip>
                      </Flex>
                    )}
                  </>
                ) : (
                  <>
                    <DetailTextLabel>
                      {i18n.t('Threats detected')}
                    </DetailTextLabel>
                    {!isLoadingThreat && !hasThreatInstalled && (
                      <Tooltip
                        content={
                          <Text>
                            {i18n.t('Avert library item not assigned')}
                          </Text>
                        }
                        css={{ zIndex: 99999 }}
                      >
                        <DetailTextValue>{i18n.t('n/a')}</DetailTextValue>
                      </Tooltip>
                    )}
                    {!isLoadingThreat &&
                      hasThreatInstalled &&
                      (threat_counts?.total ? (
                        <DetailTextValue>
                          <Link
                            to={`/devices/${computerId}/threats`}
                          >{`${formatNumberText(
                            threat_counts.malware_count,
                            'Malware',
                          )}`}</Link>
                          {threat_counts.malware_count > 0 &&
                            threat_counts.pup_count > 0 &&
                            ', '}
                          <Link
                            to={`/devices/${computerId}/threats`}
                          >{`${formatNumberText(
                            threat_counts.pup_count,
                            'PUP',
                          )}`}</Link>
                        </DetailTextValue>
                      ) : (
                        <Tooltip
                          content={<Text>{i18n.t('No threat events')}</Text>}
                          css={{ zIndex: 99999 }}
                        >
                          <DetailTextValue data-testid="no-threat-events">
                            0
                          </DetailTextValue>
                        </Tooltip>
                      ))}
                  </>
                )}
              </Flex>
            )}
            {isVulnEnabled && (
              <Flex gap="xs" alignItems="center">
                <DetailTextLabel>
                  {i18n.t('Active vulnerabilities')}
                </DetailTextLabel>
                <TooltipOverflowText
                  overflowText={formatVulnerabilities(vulnerabilities)}
                >
                  <DetailTextValue>
                    {formatVulnerabilities(vulnerabilities)}
                  </DetailTextValue>
                </TooltipOverflowText>
              </Flex>
            )}
          </DetailColumn>
        </Flex>
        <ComputerNotifications />
      </Flex>

      {children}

      <AssignUserModal
        isOpen={modalKind === 'assignUser' && modalIsOpen}
        setIsOpen={() => setModalOpen(false)}
        computerRecord={computer}
        onSave={refetchComputer}
      />
      <SetAssetTagModal
        isOpen={modalKind === 'assetTag' && modalIsOpen}
        setIsOpen={() => setModalOpen(false)}
        computer={computer}
        updateComputerFields={(_, asset_tag) =>
          mutateComputer(asset_tag).then(refetchComputer)
        }
      />
      <AssignTagsModal
        isOpen={modalKind === 'tags' && modalIsOpen}
        setIsOpen={() => setModalOpen(false)}
        computer={computer}
        updateComputerFields={(_, asset_tag) =>
          mutateComputer(asset_tag).then(refetchComputer)
        }
      />
      {modalKind === 'blueprint' && modalIsOpen && (
        <ComputerChangeBlueprint
          blueprintNames={blueprintNames}
          blueprintRecord={{ id: computer.blueprint_id }}
          changeBlueprint={(_, blueprintId) =>
            mutateComputerBlueprint({
              computers: [computerId],
              blueprintId,
            }).then(refetchComputer)
          }
          info={{
            computerId,
            blackListOfBlueprint: [computer.blueprint_id].filter(Boolean),
          }}
          onHide={() => setModalOpen(false)}
          blueprints={blueprints}
        />
      )}
    </Flex>
  );
};

export default SingleDeviceHeader;
