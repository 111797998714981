import { i18n } from 'src/i18n';
import ThreatEmptyFilterIcon from '../../../assets/threat-no-filtered-results.svg';
import ThreatEmptyIconV3 from '../../../assets/threat-no-threats-v3.png';
import ThreatListEmptyTable from '../ThreatListEmptyTable';

export type ThreatListEmptyStatesProps = {
  isFiltering: boolean;
  displayDetectionPeriod?: string;
  displayDetectionSubject?: string;
};

const ThreatListEmptyStates = ({
  isFiltering,
  displayDetectionPeriod,
  displayDetectionSubject,
}: ThreatListEmptyStatesProps) => (
  <>
    {isFiltering ? (
      <ThreatListEmptyTable
        icon={ThreatEmptyFilterIcon}
        header={i18n.t('No results found')}
        body={i18n.t(
          'Try changing the filter or search with different keywords.',
        )}
        compact
      />
    ) : (
      <ThreatListEmptyTable
        icon={ThreatEmptyIconV3}
        iconWidth={50}
        header={i18n.t('No threats detected')}
        body={
          displayDetectionPeriod
            ? i18n.t(
                '{displayDetectionPeriod}, no {displayDetectionSubject} have occurred in your Mac fleet.',
                { displayDetectionPeriod, displayDetectionSubject },
              )
            : i18n.t(
                'No {displayDetectionSubject} have occurred in your Mac fleet within the specified detection period.',
                { displayDetectionSubject },
              )
        }
        compact
      />
    )}
  </>
);

export default ThreatListEmptyStates;
