import { i18n } from 'src/i18n';
import {
  AM_DEVICE_SPECIFIC_OS_VERSION_FACETS,
  AM_OS_VERSION_PARENT_KEY,
  FACET_GROUPS,
  LI_DEVICE_SPECIFIC_OS_VERSION_FACETS,
  OS_VERSION_PARENT_KEY,
} from '../constants';

import { getOptionsFromObject } from '../utilities';

const PARENT_OS_VERSION_FACET = () => ({
  value: OS_VERSION_PARENT_KEY,
  label: i18n.t('OS Version'),
  subject: 'computer',
  group: i18n.t('Device criteria'),
  deviceFamilies: ['Mac', 'iPhone', 'iPad', 'Apple TV'],
});

const PARENT_OS_VERSION_FACET_AM = () => ({
  value: AM_OS_VERSION_PARENT_KEY,
  label: i18n.t('OS Version'),
  subject: 'computer',
  group: i18n.t('Device criteria'),
  deviceFamilies: ['Mac', 'iPhone', 'iPad', 'Apple TV'],
});

/**
 * Formats all possible rule input options given the facet map to be used by the Rule Input dropdown
 * @param {Object} facetMap - a mapping of facet keys to respective facet data
 * @returns an array of input options in { value, label, subject, deviceFamilies } format
 */
const getAllInputOptions = (facetMap) => {
  const facetOptions = getOptionsFromObject(facetMap);
  const formattedFacetOptions = [];

  let addedParentOSVersionFacet = false;
  let addedParentOSVersionFacetAM = false;

  // Format each facet to be readable by the Rule Input dropdown
  facetOptions?.forEach((facetOption) => {
    const {
      value,
      data: { name: label, subject, device_families },
    } = facetOption;

    // Add the parent OS version facet in lieu of the device-specific OS version
    // facets if device-specific OS version facets are present in the facet map
    // and the parent OS version facet has not been added already
    if (LI_DEVICE_SPECIFIC_OS_VERSION_FACETS.includes(value)) {
      if (!addedParentOSVersionFacet) {
        addedParentOSVersionFacet = true;
        formattedFacetOptions.push(PARENT_OS_VERSION_FACET());
      }
    } else if (AM_DEVICE_SPECIFIC_OS_VERSION_FACETS.includes(value)) {
      if (!addedParentOSVersionFacetAM) {
        addedParentOSVersionFacetAM = true;
        formattedFacetOptions.push(PARENT_OS_VERSION_FACET_AM());
      }
    } else {
      formattedFacetOptions.push({
        value,
        label,
        subject,
        group: FACET_GROUPS()[subject]?.label, // used by nectar-ui SelectV2 to group options into sections
        deviceFamilies: device_families,
      });
    }
  });

  return formattedFacetOptions;
};

/**
 * Gets all unused rule input options given the current list of rules and the facet map
 * @param {Object} facetMap - a mapping of facet keys to respective facet data
 * @param {Array} ruleList - a list of currently built rules
 * @param {Array} supportedDeviceFamilies - a list of device families the library item supports
 * @returns an array of available input options in { value, label, subject, deviceFamilies } format
 */
const getAvailableInputOptions = (
  facetMap,
  ruleList,
  supportedDeviceFamilies,
) => {
  const allInputOptions = getAllInputOptions(facetMap);
  const compatibleInputOptions = allInputOptions.filter((option) =>
    option.deviceFamilies.some((family) =>
      supportedDeviceFamilies.includes(family),
    ),
  );
  const inUseInputOptions = ruleList?.map((rule) => rule?.input);

  const availableInputOptions = compatibleInputOptions.filter(
    (option) => !inUseInputOptions.includes(option?.value),
  );

  return availableInputOptions;
};

export { getAllInputOptions, getAvailableInputOptions };
