import type { DetectionDateFilterFields } from 'src/features/edr/common/common.types';
import { i18n } from 'src/i18n';
import { constants } from '../../common';

const FALLBACK_DETECTION_PERIOD_MESSAGE = 'During the selected period';

const getDisplayPeriod = (detectionDate: DetectionDateFilterFields): string => {
  const { value } = detectionDate;

  if (value === 'custom_date_range') {
    return '';
  }

  if (value === 'all_time') {
    return i18n.t('Across all time');
  }

  if (value?.startsWith('last_')) {
    const option =
      constants.THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS.find(
        (option) => option.value === value,
      );

    // istanbul ignore next
    if (option?.getInlineLabel) {
      const optionLabel = option.getInlineLabel();
      return i18n.t('In the {optionLabel}', { optionLabel });
    }
  }

  return FALLBACK_DETECTION_PERIOD_MESSAGE;
};

const getDisplayDetectionPeriod = (
  detectionDate: DetectionDateFilterFields | undefined,
): string => {
  try {
    if (!detectionDate) {
      throw new Error('Detection date filter fields are required');
    }

    const period = getDisplayPeriod(detectionDate);

    return period;
  } catch {
    return FALLBACK_DETECTION_PERIOD_MESSAGE;
  }
};

export default getDisplayDetectionPeriod;
