import configs from 'features/library-items/library/library-item-configurations/items';
import { getAllBlueprintOptions } from '../../../data-service/blueprint/use-blueprint-service';
import type {
  AppBlockingModel,
  AppBlockingModelSettings,
} from '../app-blocking.types';
import type {
  AppBlockingApiPayloadSettings,
  AppBlockingApiRequestPayload,
  AppBlockingApiResponsePayload,
} from './transformers.types';

import { v4 as uuid } from 'uuid';

function transformSettings(
  settings: AppBlockingApiPayloadSettings,
): AppBlockingModelSettings {
  return {
    general: {
      by_process: settings.by_process.map((item) => ({
        ...item,
        id: uuid(),
      })),
      by_path: settings.by_path.map((item) => ({
        ...item,
        id: uuid(),
      })),
      by_developer_id: settings.by_developer_id.map((item) => ({
        ...item,
        id: uuid(),
      })),
      by_bundle_id: settings.by_bundle_id.map((item) => ({
        ...item,
        id: uuid(),
      })),
      message_customization: settings.message_customization,
    },
  };
}

async function transformFromApi(apiData: AppBlockingApiResponsePayload) {
  const selectedBlueprints = await getAllBlueprintOptions(apiData.blueprints);
  const excludedBlueprints = await getAllBlueprintOptions(
    apiData.excluded_blueprints,
  );

  const commonData = {
    id: apiData.id,
    name: apiData.name,
    selectedBlueprints,
    excludedBlueprints,
    isAllBlueprints: apiData.is_all_blueprints,
  };

  const result: AppBlockingModel = {
    ...commonData,
    isActive: apiData.active,
    ...transformSettings(apiData.data),
  };

  return {
    ...apiData,
    data: result,
  };
}

function transformToApi(model: AppBlockingModel): AppBlockingApiRequestPayload {
  const toSend: AppBlockingApiRequestPayload = {
    name: model.name,
    active: model.isActive,
    blueprints: model.selectedBlueprints.map((b) => b.value),
    is_all_blueprints: model.isAllBlueprints,
    data: {
      by_process: model.general.by_process.map(({ id, ...item }) => ({
        ...item,
      })),
      by_path: model.general.by_path.map(({ id, ...item }) => ({
        ...item,
      })),
      by_developer_id: model.general.by_developer_id.map(({ id, ...item }) => ({
        ...item,
      })),
      by_bundle_id: model.general.by_bundle_id.map(({ id, ...item }) => ({
        ...item,
      })),
      message_customization: model.general.message_customization,
    },
  };

  if (model.skip_blueprint_conflict) {
    toSend.skip_blueprint_conflict = model.skip_blueprint_conflict;
  }

  if (!model.id) {
    const itemConfig = configs['App Blocking'];

    toSend.type = itemConfig.type;
  }

  return toSend;
}

export { transformToApi, transformFromApi };
