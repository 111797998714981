import {
  BasePagination,
  Flex,
  PaginationPrimitives,
  type PaginationProps,
  SelectV2 as Select,
  Separator,
} from '@kandji-inc/nectar-ui';
import * as React from 'react';
import { i18n } from 'src/i18n';

export const Pagination = ({
  currentPage,
  onPageChange,
  totalItems,
  itemsPerPage,
  itemsPerPageOptions,
  onItemsPerPageChange,
  bulkActions,
}: Omit<PaginationProps, 'totalPages'> & {
  itemsPerPageOptions?: number[];
  onItemsPerPageChange?: (itemsPerPage: number) => void;
  bulkActions?: React.ReactNode;
}) => {
  const totalPages = React.useMemo(
    () =>
      totalItems > 0 && !Number.isNaN(Math.ceil(totalItems / itemsPerPage))
        ? Math.ceil(totalItems / itemsPerPage)
        : 1,
    [totalItems, itemsPerPage],
  );

  const isLastPage = currentPage === totalPages;
  const isFirstPage = currentPage === 1;

  const pageOptions = React.useMemo(
    () => getPageOptions(currentPage, totalPages),
    [currentPage, totalPages],
  );

  const summary = React.useMemo(() => {
    if (bulkActions != null) {
      return bulkActions;
    }
    return (
      <>
        {onItemsPerPageChange !== undefined && (
          <>
            <PaginationPrimitives.PageSizer
              totalItems={totalItems}
              itemsPerPageOptions={itemsPerPageOptions ?? undefined}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={onItemsPerPageChange}
            />
            <Separator
              orientation="vertical"
              css={{ alignSelf: 'stretch', width: 1, minHeight: 36 }}
            />
          </>
        )}
        <PaginationPrimitives.Summary
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
        />
      </>
    );
  }, [
    bulkActions,
    onItemsPerPageChange,
    itemsPerPage,
    totalItems,
    currentPage,
  ]);

  return (
    <BasePagination>
      <Flex alignItems="center" css={{ gap: '10px' }}>
        {summary}
      </Flex>
      <Flex gap="xs">
        <PaginationPrimitives.IconButton
          aria-label="select first page"
          type="button"
          onClick={() => onPageChange(1)}
          disabled={isFirstPage}
          icon="arrow-left-to-line"
        />
        <PaginationPrimitives.IconButton
          aria-label="select previous page"
          type="button"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={isFirstPage}
          icon="fa-arrow-left-to-line-control"
        />
        <Select.Default
          value={currentPage.toString()}
          onValueChange={(e) => onPageChange(parseInt(e, 10))}
          triggerProps={{
            value: PaginationPrimitives.getPageString(currentPage, totalPages),
            disabled: totalPages === 1,
          }}
          options={pageOptions}
        />
        <PaginationPrimitives.IconButton
          aria-label="select next page"
          type="button"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={isLastPage}
          icon="fa-arrow-right-to-line-control"
        />
        <PaginationPrimitives.IconButton
          aria-label="select last page"
          type="button"
          onClick={() => onPageChange(totalPages)}
          disabled={isLastPage}
          icon="arrow-right-to-line"
        />
      </Flex>
    </BasePagination>
  );
};

export const getPageOptions = (
  currentPage: number,
  totalPages: number,
): { value: string; label: string }[] => {
  const visiblePages = 50; // Number of pages to show in the dropdown
  const halfVisible = Math.floor(visiblePages / 2);

  let start = Math.max(1, currentPage - halfVisible);
  let end = Math.min(totalPages, start + visiblePages - 1);

  // Adjust start if end is less than visiblePages
  if (end - start + 1 < visiblePages) {
    start = Math.max(1, end - visiblePages + 1);
  }

  // Adjust end if start is 1 and totalPages is less than visiblePages
  if (start === 1 && totalPages < visiblePages) {
    end = totalPages;
  }

  const options = [];
  for (let i = start; i <= end; i++) {
    options.push({ value: i.toString(), label: i18n.format.number(i) });
  }

  return options;
};
