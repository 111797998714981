import { Card, Flex, Icon, Separator, Text } from '@kandji-inc/nectar-ui';
import { i18n } from 'src/i18n';
import { Chrome, Crowdstrike, Notion, Zoom } from '../components/icons';

const i18nAppDescriptionKeys = {
  GOOGLE_CHROME: 'Google Chrome',
  CROWDSTRIKE: 'Crowdstrike',
};

const appDescriptionMap = i18n.createMap({
  [i18nAppDescriptionKeys.CROWDSTRIKE]: () =>
    i18n.t('Cybersecurity technology'),
  [i18nAppDescriptionKeys.GOOGLE_CHROME]: () => i18n.t('Web browser'),
});

const apps = [
  {
    name: 'XCode CLI Tools',
    status: 'Completed',
    icon: 'code-simple',
    color: '#fff',
    bgColor: '#5457F6',
  },
  {
    name: 'Konica Minolta Printer',
    status: 'Completed',
    icon: 'print',
    color: '#fff',
    bgColor: '#A5D167',
  },
  {
    name: 'Crowdstrike',
    description: i18nAppDescriptionKeys.CROWDSTRIKE,
    status: 'Completed',
    icon: 'kandji-blueprint',
    SvgComponent: Crowdstrike,
  },
  {
    name: 'Google Chrome',
    description: i18nAppDescriptionKeys.GOOGLE_CHROME,
    status: 'Installing',
    icon: 'chrome-extensions',
    color: 'var(--colors-blue60)',
    SvgComponent: Chrome,
  },
  {
    name: 'Zoom',
    status: 'Pending',
    icon: 'kandji-print',
    color: 'var(--colors)',
    SvgComponent: Zoom,
  },
  {
    name: 'Notion',
    status: 'Pending',
    icon: 'kandji-print',
    SvgComponent: Notion,
  },
];

const statusMap = i18n.createMap({
  Completed: () => i18n.t('Completed'),
  Installing: () => i18n.t('Installing'),
  Pending: () => i18n.t('Pending'),
});

export const AppList = ({ isDarkMode }) => {
  return (
    <Card
      testId="applist:card"
      css={{
        backgroundColor: isDarkMode ? '#1E1E1E' : '#FFF',
        padding: '0',
        maxHeight: '356px',
        overflow: 'hidden',
        width: '100%',
        borderRadius: '10px',
        border: 'none',
      }}
    >
      {apps.map((app, i) => {
        return (
          <AppListItem
            isDarkMode={isDarkMode}
            key={app.name}
            app={app}
            order={i + 1}
          />
        );
      })}
    </Card>
  );
};

export const AppListFooter = ({ isDarkMode }) => {
  return (
    <>
      <AppleQuestionIcon isDarkMode={isDarkMode} />
      <Flex
        justifyContent="start"
        alignItems="center"
        css={{ gap: 8, paddingLeft: 40 }}
      >
        <Chrome size={20} />
        <Text
          data-testid="applistfooter:text"
          css={{
            fontFamily: 'SF Pro Text',
            fontSize: '13px',
            color: isDarkMode
              ? 'rgba(255,255,255, 0.85)'
              : 'rgba(0, 0, 0, 0.85)',
          }}
        >
          {i18n.$t('{appName} is installing', {
            appName: <span style={{ fontWeight: 700 }}>Google Chrome</span>,
          })}
        </Text>
      </Flex>
    </>
  );
};

const AppListItem = ({ app, isDarkMode, order }) => {
  return (
    <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
      <Flex alignItems="center" css={{ height: '65px' }}>
        <Flex
          css={{ width: '100%', height: '40px' }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center" css={{ gap: '10px' }}>
            <Text
              css={{
                fontWeight: '400',
                fontSize: '13px',
                textAlign: 'right',
                fontFamily: 'SF Pro Text',
                color: isDarkMode
                  ? 'rgba(255,255,255, 0.85)'
                  : 'rgba(0, 0, 0, 0.85)',
              }}
            >
              {order}
            </Text>
            <Flex
              justifyContent="center"
              alignItems="center"
              css={{
                backgroundColor: app.bgColor ?? 'transparent',
                borderRadius: '$round',
                height: '40px',
                width: '40px',
                overflow: app.bgColor ?? 'hidden',
              }}
            >
              {app.SvgComponent ? (
                <app.SvgComponent />
              ) : (
                <Icon name={app.icon} color={app.color} size="md" />
              )}
            </Flex>
            <Flex flow="column" justifyContent="start" alignItems="start">
              <Text
                css={{
                  fontWeight: '700',
                  fontSize: '13px',
                  fontFamily: 'SF Pro Text',
                  color: isDarkMode
                    ? 'rgba(255,255,255, 0.85)'
                    : 'rgba(0, 0, 0, 0.85)',
                }}
              >
                {app.name}
              </Text>
              {app?.description ? (
                <Text
                  css={{
                    fontWeight: '400',
                    fontSize: '11px',
                    lineHeight: '14px',
                    fontFamily: 'SF Pro Text',
                    color: isDarkMode
                      ? 'rgba(255,255,255, 0.85)'
                      : 'rgba(0, 0, 0, 0.85)',
                  }}
                >
                  {appDescriptionMap(app?.description)}
                </Text>
              ) : null}
            </Flex>
          </Flex>
          <Flex css={{ width: '150px' }} justifyContent="end">
            <Flex css={{ width: '100%', gap: '10px', alignSelf: 'end' }}>
              {app.status === 'Completed' ? (
                <Flex
                  css={{
                    backgroundColor: '#34C759',
                    height: '16px',
                    width: '16px',
                    borderRadius: '$round',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Icon name="check" size="xs" color="white" />
                </Flex>
              ) : app.status === 'Installing' ? (
                isDarkMode ? (
                  <DarkModeSpinner />
                ) : (
                  <Spinner />
                )
              ) : (
                <Flex css={{ height: '16px', width: '16px' }} />
              )}

              <Text
                css={{
                  fontFamily: 'SF Pro Text',
                  textAlign: 'left',
                  color: isDarkMode
                    ? 'rgba(255,255,255, 0.85)'
                    : 'rgba(0, 0, 0, 0.85)',
                }}
              >
                {statusMap(app.status)}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Separator
        css={{
          backgroundColor: isDarkMode ? '#323232' : '#E8E8E8',
          transitionProperty: 'background-color',
          transitionDuration: '0.5s',
        }}
      />
    </div>
  );
};

const AppleQuestionIcon = ({ isDarkMode }) => {
  return (
    <Flex
      css={{
        height: 20,
        width: 20,
        backgroundColor: isDarkMode ? '#48484A' : '#FFF',
        boxShadow: '0px 0.5px 1px 0.5px rgba(0, 0, 0, 0.10)',
        borderRadius: '$round',
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Text
        css={{
          fontFamily: 'SF Pro Text',
          mixBlendMode: 'luminosity',
          fontSize: '12.05px',
          color: isDarkMode ? '#E5E5E5' : '#232526',
        }}
      >
        ?
      </Text>
    </Flex>
  );
};

// TODO become methodical and actually go in and add conditional for inverting darknode
const Spinner = () => {
  return (
    <div style={{ width: '16px', height: '16px', position: 'relative' }}>
      <div
        style={{
          width: 16,
          height: 16,
          left: -0,
          top: 0,
          position: 'absolute',
        }}
      >
        <div
          style={{
            width: 2,
            height: 5,
            left: 7,
            top: 0,
            position: 'absolute',
            background: 'rgba(0, 0, 0, 0.80)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 12.95,
            top: 1.64,
            position: 'absolute',
            transform: 'rotate(45deg)',
            transformOrigin: '0 0',
            background: 'rgba(0, 0, 0, 0.10)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 16,
            top: 7,
            position: 'absolute',
            transform: 'rotate(90deg)',
            transformOrigin: '0 0',
            background: 'rgba(0, 0, 0, 0.20)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 14.36,
            top: 12.95,
            position: 'absolute',
            transform: 'rotate(135deg)',
            transformOrigin: '0 0',
            background: 'rgba(0, 0, 0, 0.30)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 9,
            top: 16,
            position: 'absolute',
            transform: 'rotate(180deg)',
            transformOrigin: '0 0',
            background: 'rgba(0, 0, 0, 0.40)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 3.05,
            top: 14.36,
            position: 'absolute',
            transform: 'rotate(-135deg)',
            transformOrigin: '0 0',
            background: 'rgba(0, 0, 0, 0.50)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 0,
            top: 9,
            position: 'absolute',
            transform: 'rotate(-90deg)',
            transformOrigin: '0 0',
            background: 'rgba(0, 0, 0, 0.60)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 1.64,
            top: 3.05,
            position: 'absolute',
            transform: 'rotate(-45deg)',
            transformOrigin: '0 0',
            background: 'rgba(0, 0, 0, 0.70)',
            borderRadius: 1,
          }}
        />
      </div>
    </div>
  );
};

const DarkModeSpinner = () => {
  return (
    <div style={{ width: '16px', height: '16px', position: 'relative' }}>
      <div
        style={{
          width: 16,
          height: 16,
          left: -0,
          top: 0,
          position: 'absolute',
        }}
      >
        <div
          style={{
            width: 2,
            height: 5,
            left: 7,
            top: 0,
            position: 'absolute',
            background: 'rgba(255, 255, 255, 0.80)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 12.95,
            top: 1.64,
            position: 'absolute',
            transform: 'rotate(45deg)',
            transformOrigin: '0 0',
            background: 'rgba(255, 255, 255, 0.10)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 16,
            top: 7,
            position: 'absolute',
            transform: 'rotate(90deg)',
            transformOrigin: '0 0',
            background: 'rgba(255, 255, 255, 0.20)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 14.36,
            top: 12.95,
            position: 'absolute',
            transform: 'rotate(135deg)',
            transformOrigin: '0 0',
            background: 'rgba(255, 255, 255, 0.30)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 9,
            top: 16,
            position: 'absolute',
            transform: 'rotate(180deg)',
            transformOrigin: '0 0',
            background: 'rgba(255, 255, 255, 0.40)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 3.05,
            top: 14.36,
            position: 'absolute',
            transform: 'rotate(-135deg)',
            transformOrigin: '0 0',
            background: 'rgba(255, 255, 255, 0.50)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 0,
            top: 9,
            position: 'absolute',
            transform: 'rotate(-90deg)',
            transformOrigin: '0 0',
            background: 'rgba(255, 255, 255, 0.60)',
            borderRadius: 1,
          }}
        />
        <div
          style={{
            width: 2,
            height: 5,
            left: 1.64,
            top: 3.05,
            position: 'absolute',
            transform: 'rotate(-45deg)',
            transformOrigin: '0 0',
            background: 'rgba(255, 255, 255, 0.70)',
            borderRadius: 1,
          }}
        />
      </div>
    </div>
  );
};
