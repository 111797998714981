import { type LogDetailKeys, LogDetailLabels } from '../CommonStatus.types';
import { LibraryItemStatusLog } from './LibraryItemStatusLog';

export function getLogDetailLabel(key: LogDetailKeys) {
  const getLabel = LogDetailLabels[key];
  return getLabel?.() ?? key;
}

export function buildLogFromStatusDetails(
  details: Record<LogDetailKeys, string>,
) {
  const statusLog = new LibraryItemStatusLog();
  const entries = Object.entries(details) as [LogDetailKeys, string][];

  for (const [key, value] of entries) {
    if (value == null) continue;

    const formattedValue: string = value;

    statusLog.addRow(getLogDetailLabel(key), formattedValue);
  }

  return statusLog.toString();
}
