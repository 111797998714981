import { DropdownMenu, FilterButton, Flex } from '@kandji-inc/nectar-ui';
import type { OnApply, OnClear } from 'src/features/edr/common/common.types';
import { i18n } from 'src/i18n';
import constants from '../../../common/constants';
import type {
  BehavioralThreatClassification,
  FilterFields,
  ThreatClassification,
} from '../../../threat.types';

const {
  THREAT_CLASSIFICATION: { MALWARE, PUP, BENIGN, UNKNOWN },
} = constants;

type ThreatClassificationFilter =
  | ThreatClassification
  | BehavioralThreatClassification;

type ClassificationFilterOption = {
  key?: ThreatClassificationFilter;
  value: ThreatClassificationFilter;
  label: React.ReactNode;
  checked?: boolean;
};

const getClassificationLabel = (classification: ThreatClassificationFilter) => {
  if (classification === 'PUP') {
    return i18n.t('PUP');
  }
  return constants.THREAT_CLASSIFICATION_LABELS(classification);
};

const getClassifactionIcon = (classification: ThreatClassificationFilter) =>
  constants.THREAT_CLASSIFICATION_ICON_MAP[classification];

type ClassificationMenuItemProps = {
  label: string;
  count: number;
};

const ClassificationMenuItem = (props: ClassificationMenuItemProps) => {
  const { label, count } = props;

  return (
    <Flex flex={1} css={{ justifyContent: 'space-between', minWidth: 120 }}>
      <Flex>{label}</Flex>
      {/* <Flex alignItems="center">
        <Text variant="disabled" size="1">
          {count.toLocaleString()} threats
        </Text>
      </Flex> */}
    </Flex>
  );
};

type ClassificationFilterDropdownProps = {
  value: ThreatClassificationFilter | undefined;
  onChange: OnApply;
  onClear: OnClear;
  list: ThreatClassificationFilter[];
  malwareCount: number | undefined;
  pupCount: number | undefined;
  benignCount: number | undefined;
  unknownCount: number | undefined;
};

const ClassificationFilterDropdown = (
  props: ClassificationFilterDropdownProps,
) => {
  const {
    value,
    onChange,
    onClear,
    list,
    malwareCount = 0,
    pupCount = 0,
    benignCount = 0,
    unknownCount = 0,
  } = props;

  const values = {
    [MALWARE]: malwareCount,
    [PUP]: pupCount,
    [BENIGN]: benignCount,
    [UNKNOWN]: unknownCount,
  };

  const filterName: FilterFields = 'classification';
  const filterValue: string | undefined = value;
  const filterOptions: ClassificationFilterOption[] = list.map(
    (classification) => {
      const label = getClassificationLabel(classification);
      const count = values[classification];

      return {
        key: classification,
        label: <ClassificationMenuItem label={label} count={count} />,
        icon: getClassifactionIcon(classification),
        value: classification,
        onClick: () => onChange(filterName, classification),
        selected: classification === filterValue,
      };
    },
  );

  const label = i18n.t('Classification');

  return (
    <DropdownMenu
      footer={{
        type: 'filter',
        showClear: true,
        clearLabel: i18n.t('Clear'),
        clearDisabled: Boolean(!filterValue),
        handleClear: () => onClear(filterName),
        showApply: false,
        showCancel: false,
      }}
      withArrow={false}
      css={{
        zIndex: 1,
        '& svg': {
          height: 16,
          width: 16,
          flexShrink: 0,
        },
        '& > div': { gap: 6 },
      }}
      options={filterOptions}
      contentProps={{ align: 'start' }}
    >
      <FilterButton
        filtersSelected={Boolean(filterValue)}
        showRemove={false}
        css={{
          '&[type="button"]': { height: 28 },
          strong: { fontWeight: '$medium' },
        }}
        data-testid="classification-filter"
      >
        {() => (
          <>
            {label}
            {filterValue ? ': ' : ''}
            {filterValue ? (
              <strong>{getClassificationLabel(filterValue)}</strong>
            ) : null}
          </>
        )}
      </FilterButton>
    </DropdownMenu>
  );
};

export default ClassificationFilterDropdown;
