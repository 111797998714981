export const ProgressBar = ({ isDarkMode }) => {
  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div
        style={{ width: 250, height: 6, left: 0, top: 0, position: 'absolute' }}
      >
        <div
          data-testid="progress-bar:background-bar"
          style={{
            width: 250,
            height: 6,
            left: 0,
            top: 0,
            position: 'absolute',
            background: isDarkMode
              ? 'rgba(255, 255, 255, 0.05)'
              : 'rgba(0, 0, 0, 0.05)',
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04) inset',
            borderRadius: 6,
          }}
        />
        <div
          style={{
            width: 250,
            height: 6,
            left: 0,
            top: 0,
            position: 'absolute',
            background: 'rgba(0, 0, 0, 0.05)',
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04) inset',
            borderRadius: 6,
          }}
        />
        <div
          style={{
            width: 112.5,
            height: 6,
            left: 0,
            top: 0,
            position: 'absolute',
          }}
        >
          <div
            style={{
              width: 45,
              height: 6,
              left: 0,
              top: 0,
              position: 'absolute',
              background: '#007AFF',
              borderRadius: 3,
            }}
          />
        </div>
      </div>
    </div>
  );
};
