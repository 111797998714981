import { Button, Flex } from '@kandji-inc/nectar-ui';
import type { OnApply, OnClear } from 'src/features/edr/common/common.types';
import EDRDetectionDateFilter from 'src/features/edr/common/components/Filters/EDRDetectionDateFilter';
import { i18n } from 'src/i18n';
import EDRSearchFilter from '../../common/components/Filters/EDRSearchFilter';
import ThreatListExportButton from '../common/components/ThreatListExportButton';
import ClassificationFilterDropdown from '../common/components/ThreatListFilters/ClassificationFilterDropdown';
import StatusFilter from '../common/components/ThreatListFilters/StatusFilter';
import constants from '../common/constants';
import type { FiltersState, OnClearAll } from '../threat.types';

type ThreatListFiltersProps = {
  filters: FiltersState;
  onApply: OnApply;
  onClear: OnClear;
  onClearAll: OnClearAll;
  isFiltering: boolean;
  isExportHidden: boolean;
  onExport: () => void;
  isExportDisabled?: boolean;
  malwareCount: number | undefined;
  pupCount: number | undefined;
  benignCount: number | undefined;
  unknownCount: number | undefined;
};

const ThreatListFilters = (props: ThreatListFiltersProps) => {
  const {
    filters,
    onApply,
    onClear,
    malwareCount,
    pupCount,
    benignCount,
    unknownCount,
    onClearAll,
    isFiltering,
    isExportHidden,
    onExport,
    isExportDisabled = false,
  } = props;

  return (
    <Flex flow="row" gap="md" justifyContent="space-between" py3 px5>
      <Flex flow="row" gap="md">
        <EDRSearchFilter
          name="query"
          value={filters.query}
          onChange={onApply}
          onClear={onClear}
          isValueSync={false}
        />
        <EDRDetectionDateFilter
          filter={filters.detectionDate}
          onChange={onApply}
          onClear={onClear}
          options={constants.THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS}
        />
        <StatusFilter
          values={filters.status}
          onChange={onApply}
          onClear={onClear}
          list={constants.THREAT_STATUS_LIST}
        />
        <ClassificationFilterDropdown
          value={filters.classification}
          onChange={onApply}
          onClear={onClear}
          list={constants.THREAT_CLASSIFICATION_LIST}
          malwareCount={malwareCount}
          pupCount={pupCount}
          benignCount={benignCount}
          unknownCount={unknownCount}
        />
        {isFiltering && (
          <Button variant="subtle" compact onClick={onClearAll}>
            {i18n.t('Clear all')}
          </Button>
        )}
      </Flex>
      {!isExportHidden && (
        <ThreatListExportButton
          onClick={onExport}
          disabled={isExportDisabled}
        />
      )}
    </Flex>
  );
};

export default ThreatListFilters;
