/* istanbul ignore file */
import {
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import { Button, Flex, SelectV2, Text, TextField } from '@kandji-inc/nectar-ui';
import React, { useEffect } from 'react';
import { i18n } from 'src/i18n';
import AppBlockingService from '../../service/app-blocking-service';

const SingleEntry = ({
  title,
  dataKey,
  entry,
  withMatchType = true,
  updateEntry,
  updateValidation,
  isDisabled,
  isSubmitted,
  onDelete,
  validator,
}) => {
  const fieldsToValidate = [`${entry.id}_value`];
  const { refs, onInvalidate, invalidations } = useInputsValidators(
    fieldsToValidate,
    updateValidation,
  );
  useRemoveValidationOnUnmount(fieldsToValidate, updateValidation);

  useEffect(() => {
    if (validator) {
      for (const v of validator) {
        const validation = v(entry[dataKey]);
        onInvalidate(0)(validation.invalid && validation.message);

        if (validation.invalid) {
          break;
        }
      }
    }
  }, [entry]);

  const valueHasError = isSubmitted && invalidations[0];

  return (
    <Flex gap="md" alignItems="center" ref={refs[0]}>
      <TextField
        label={title}
        value={entry[dataKey]}
        onChange={(e) => updateEntry(dataKey, e.target.value)}
        css={{ width: '350px' }}
        state={valueHasError ? 'error' : 'default'}
        hint={{
          ...(valueHasError && {
            label: valueHasError,
          }),
        }}
        disabled={isDisabled}
        autoFocus
      />
      {!withMatchType && (
        <Button
          css={{
            alignSelf: valueHasError ? 'center' : 'end',
            marginBottom: '2px',
          }}
          variant="subtleDanger"
          icon={{ name: 'trash-can' }}
          onClick={onDelete}
          disabled={isDisabled}
        />
      )}
      {withMatchType && (
        <Flex
          flow="column"
          gap="xs"
          css={{ alignSelf: valueHasError ? 'start' : 'unset' }}
        >
          <Text
            css={{
              fontWeight: 500,
              color: isDisabled ? '$neutral50' : '$neutral70',
            }}
          >
            {i18n.t('Match type:')}
          </Text>
          <Flex alignItems="center" gap="sm">
            <SelectV2.Default
              options={AppBlockingService.getMatchKinds()}
              onValueChange={(value) => updateEntry('type', value)}
              value={entry.type}
              triggerProps={{
                value: AppBlockingService.getMatchKinds().find(
                  ({ value }) => value === entry.type,
                ).label,
                // @ts-expect-error - Not typed correctly
                variant: 'input',
                css: { width: '250px', height: '40px', alignSelf: 'end' },
                disabled: isDisabled,
                compact: true,
                size: 'sm',
              }}
            />
            <Button
              css={{ alignSelf: 'center' }}
              variant="subtleDanger"
              icon={{ name: 'trash-can' }}
              onClick={onDelete}
              disabled={isDisabled}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default SingleEntry;
