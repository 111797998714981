import { Accordion, Icon } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';

import { useState } from 'react';
import AnalyzeThreatEventsImage from './assets/analyze-threat-events.png';
import OneAgentImage from './assets/one-agent.png';
import ReleaseFilesImage from './assets/release-files.png';
import {
  AccordionContentStyled,
  AccordionItemStyled,
  AccordionTriggerStyled,
  ColumnLeft,
  ColumnRight,
  SectionContent,
  SectionHeading,
  Wrapper,
  oneAgentImageCss,
} from './one-agent-styles';

const AngleDownIcon = () => (
  <Icon name="fa-angle-down-small" style={{ width: 20, height: 20 }} />
);

const OneAgentSection = () => {
  const [oneAgentImage, setOneAgentImage] = useState<string>('item-1');

  const oneAgentImageClass = oneAgentImageCss().className;

  return (
    <Wrapper>
      <SectionHeading as="h2" size="2">
        {i18n.t('One agent. The most powerful protection for Mac.')}
      </SectionHeading>
      <SectionContent>
        <ColumnLeft>
          <Accordion
            collapsible
            css={{ width: '100%' }}
            defaultValue="item-1"
            onValueChange={
              /* istanbul ignore next */ (value: string) => {
                setOneAgentImage(value || 'item-1');
              }
            }
          >
            <AccordionItemStyled value="item-1">
              <AccordionTriggerStyled customIcon={<AngleDownIcon />}>
                {i18n.t('Detect and stop any threat')}
              </AccordionTriggerStyled>
              <AccordionContentStyled>
                {i18n.t(
                  'EDR detects and mitigates threats via behavioral detections and file analysis. Process monitoring recognizes deviations from expected behavior and can kill malicious processes instantly. This delivers real-time protection against known and unknown threats.',
                )}
              </AccordionContentStyled>
            </AccordionItemStyled>
            <AccordionItemStyled value="item-2">
              <AccordionTriggerStyled customIcon={<AngleDownIcon />}>
                {i18n.t('Analyze threat events')}
              </AccordionTriggerStyled>
              <AccordionContentStyled>
                {i18n.t(
                  'See information such as the threat name, classification, along with any relevant actions, affected Mac computers, and time stamps of key events.',
                )}
              </AccordionContentStyled>
            </AccordionItemStyled>
            <AccordionItemStyled value="item-3">
              <AccordionTriggerStyled customIcon={<AngleDownIcon />}>
                {i18n.t('Release files from quarantine')}
              </AccordionTriggerStyled>
              <AccordionContentStyled>
                {i18n.t(
                  'If a quarantined file needs to be released, use a streamlined workflow to add the file to an allow list and release it across all devices.',
                )}
              </AccordionContentStyled>
            </AccordionItemStyled>
          </Accordion>
        </ColumnLeft>
        <ColumnRight>
          {oneAgentImage === 'item-1' && (
            <img
              src={OneAgentImage}
              style={{ width: 669, marginLeft: -24 }}
              alt="detect-and-stop-any-threat"
            />
          )}
          {oneAgentImage === 'item-2' && (
            <img
              src={AnalyzeThreatEventsImage}
              className={oneAgentImageClass}
              alt="analyze-threat-events"
            />
          )}
          {oneAgentImage === 'item-3' && (
            <img
              src={ReleaseFilesImage}
              className={oneAgentImageClass}
              alt="release-files"
            />
          )}
        </ColumnRight>
      </SectionContent>
    </Wrapper>
  );
};

export default OneAgentSection;
