import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { ProfileCommonStatusDetails } from '../ProfileStatus.types';

function serializerV1(_details: ProfileCommonStatusDetails) {
  return new LibraryItemStatusLog()
    .addRow(i18n.t('The profile is pending installation'))
    .toString();
}

export const PendingInstallAuditSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
