/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import { Eclipse } from '../../images/loader';

const Simple = () => (
  <div className="loader-with-text2">
    <div className="loader-with-text-data">
      <i className="fas fa-spinner fa-spin" />
      <span>Loading...</span>
    </div>
  </div>
);

const Line = () => (
  <div className="loader-with-text2">
    <div className="loader-with-text2-line" />
    <div className="loader-with-text-data">
      <i className="fas fa-spinner fa-spin" />
      <span>Loading...</span>
    </div>
    <div className="loader-with-text2-line" />
  </div>
);

const types = {
  DEFAULT: 'default',
  SPLASH: 'splash',
  SIMPLE: 'simple',
  LINE: 'line',
};

const loaders = {
  [types.DEFAULT]: <Eclipse />,
  [types.SPLASH]: <Eclipse />,
  [types.SIMPLE]: <Simple />,
  [types.LINE]: <Line />,
};

const Loader = ({ type = 'default' }) => (
  <aside className={`loader loader-${type}`}>{loaders[type]}</aside>
);

Loader.types = types;

Loader.propTypes = {
  type: PropTypes.oneOf(Object.values(Loader.types)),
};

export default Loader;
