import {
  Checkbox,
  TextInput,
  useInputsValidators,
  useRemoveValidationOnUnmount,
} from '@kandji-inc/bumblebee';
import { Box, Flex } from '@kandji-inc/nectar-ui';
import { Setting } from 'features/library-items/template';
import { i18n } from 'i18n';
/* istanbul ignore file */
import {
  type ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import KandjiSupportLink from 'src/app/components/common/KandjiSupportLink';
import type { GeneralWebClip } from 'src/types/common.types';
import { WebClipIconUploader } from '../uploader/uploader';
import { ActionType, WebClipContext } from './WebClipContext';

const SMALLEST_ICON_SIZE_PX = 144;
const RECOMMENDED_ICON_SIZE_PX = 400;
const fieldsToValidate = ['Label', 'URL'];

const GeneralConfig = ({
  update,
  setting,
  isDisabled,
  validationDep,
}: GeneralConfigProps) => {
  const handleUpdate = (field: string, value: any) => {
    update(field, value);
    dispatch({
      type: ActionType.UpdateWebclip,
      payload: { key: field as keyof GeneralWebClip, value },
    });
  };
  const { state: webclipItem, dispatch } = useContext(WebClipContext);

  const supportLink = (
    <KandjiSupportLink
      href="https://support.kandji.io/support/solutions/articles/72000633951"
      text={i18n.t('Learn more')}
      className="b-alink"
    />
  );

  const [status, setStatus] = useState('');
  const { refs, onInvalidate } = useInputsValidators(fieldsToValidate, update);
  useRemoveValidationOnUnmount(fieldsToValidate, update);
  const isValidating = status === 'validating';
  const { current: invalidMap } = useRef(new Map());

  useEffect(() => {
    handleUpdate('webClipImgUrl', setting?.webClipImgUrl);
  }, [setting?.webClipImgUrl]);

  useEffect(() => {
    if (isValidating) {
      let firstInvalidRef: HTMLInputElement | null = null;

      invalidMap.forEach(({ isInvalid, ref }) => {
        if (typeof isInvalid === 'function') {
          const invalidRes = isInvalid(webclipItem);

          /* istanbul ignore next */
          if (invalidRes && !firstInvalidRef) {
            firstInvalidRef = ref;
          }
        } else if (isInvalid && !firstInvalidRef) {
          firstInvalidRef = ref;
        }
      });
    }
  }, [isValidating, invalidMap, update, setStatus]);

  const requiredValidator = (v) => ({
    message: i18n.t('Required'),
    invalid: () => !v,
    trigger: [validationDep, 'onBlur'],
  });

  const urlValidator = (v) => ({
    message: i18n.t('Must be a valid URL'),
    invalid: () => {
      try {
        new URL(v);
      } catch (e) {
        return true;
      }
    },
    trigger: [validationDep, 'onBlur'],
  });
  const validateImageDimensions = (image) => {
    if (!image) {
      return null;
    }

    if (
      image.width < SMALLEST_ICON_SIZE_PX ||
      image.height < SMALLEST_ICON_SIZE_PX
    ) {
      invalidMap.set('custom_icon', {
        ref: invalidMap.get('custom_icon').ref,
        isInvalid: true,
      });

      return i18n.t('Image should be at least {size} x {size} pixels', {
        size: SMALLEST_ICON_SIZE_PX,
      });
    }

    if (
      image.width > RECOMMENDED_ICON_SIZE_PX ||
      image.height > RECOMMENDED_ICON_SIZE_PX
    ) {
      invalidMap.set('custom_icon', {
        ref: invalidMap.get('custom_icon').ref,
        isInvalid: true,
      });

      return i18n.t('Image should not be larger than {size} x {size} pixels', {
        size: RECOMMENDED_ICON_SIZE_PX,
      });
    }

    invalidMap.set('custom_icon', {
      ref: invalidMap.get('custom_icon').ref,
      isInvalid: false,
    });

    return null;
  };

  const validateFileType = (file) => {
    if (!file || file.name === '') {
      return null;
    }

    if (!file?.type?.includes('png')) {
      invalidMap.set('custom_icon', {
        ref: invalidMap.get('custom_icon').ref,
        isInvalid: true,
      });

      return i18n.t('Image needs to be in PNG format');
    }

    invalidMap.set('custom_icon', {
      ref: invalidMap.get('custom_icon').ref,
      isInvalid: false,
    });

    return null;
  };

  const handleChange =
    (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
      handleUpdate(field, e.target.value);
      setStatus('validating');
    };

  return (
    <Setting.Card>
      <Setting.Header>
        <h3 className="b-h3">{i18n.t('General')}</h3>
      </Setting.Header>
      <Setting.Rows>
        <Box>
          <Flex flow="column" alignItems="start" gap="xl">
            <div className="top-description">
              <p className="b-txt the-recommended">
                {i18n.t('Name your Web Clip, add the URL and icon below.')}{' '}
                {supportLink}
              </p>
            </div>

            <div>
              <p ref={refs[0]} className="b-txt b-mb-micro">
                {i18n.t('Label')}
              </p>
              <TextInput
                className="wide-text-input"
                data-testid="webclip-label-field"
                value={setting.Label}
                placeholder={i18n.t('Enter a label for the Web Clip')}
                onChange={handleChange('Label')}
                validator={(v) => [requiredValidator(v)]}
                disabled={isDisabled}
                onInvalidate={onInvalidate('Label')}
              />
            </div>

            <div>
              <p ref={refs[1]} className="b-txt b-mb-micro">
                {i18n.t('URL')}
              </p>
              <TextInput
                className="wide-text-input"
                data-testid="webclip-url-field"
                value={setting.URL}
                placeholder={i18n.t('Enter a URL for the Web Clip')}
                disabled={isDisabled}
                onChange={handleChange('URL')}
                validator={(v) => [requiredValidator(v), urlValidator(v)]}
                onInvalidate={onInvalidate('URL')}
              />
            </div>

            <div
              className="b-mb2"
              ref={(r) =>
                invalidMap.set('custom_icon', {
                  ref: r,
                  isInvalid: invalidMap.get('custom_icon')?.isInvalid || false,
                })
              }
            >
              <p className="b-txt b-mb-micro">{i18n.t('Icon')}</p>

              <WebClipIconUploader
                disabled={isDisabled}
                smallPreview
                onImage={(img) => {
                  handleUpdate('webClipImgUrl', img.dataUrl);
                  handleUpdate('webClipImgS3Key', '');
                  handleUpdate('webClipImg', img);
                }}
                webClipImgS3Key={setting.webClipImgS3Key}
                existingImgUrl={setting.webClipImgUrl} // app_icon
                validators={[validateImageDimensions, validateFileType]}
                runValidatorsOn={[setting.webClipImgUrl]}
              />
            </div>
          </Flex>
        </Box>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting.IsRemovable}
              onChange={() => handleUpdate('IsRemovable', (p) => !p)}
              disabled={isDisabled}
              label={i18n.t('Removable')}
              id="removable-checkbox"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t('Allows user to remove the Web Clip.')}
            </p>
          </Setting.Helpers>
        </Setting.Row>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting.FullScreen}
              onChange={() => handleUpdate('FullScreen', (p) => !p)}
              disabled={isDisabled}
              label={i18n.t('Fullscreen')}
              id="fullscreen-checkbox"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Removes the Safari UI controls and prevents the user from navigating away from the Web Clip.',
              )}
            </p>
          </Setting.Helpers>
        </Setting.Row>
        <Setting.Row>
          <Setting.Controls>
            <Checkbox
              checked={setting.IgnoreManifestScope}
              onChange={() => handleUpdate('IgnoreManifestScope', (p) => !p)}
              disabled={isDisabled}
              label={i18n.t('Always hide UI controls')}
              id="ignore-manifest-scope-checkbox"
            />
          </Setting.Controls>
          <Setting.Helpers>
            <p className="b-txt-light">
              {i18n.t(
                'Allows full screen Web Clip to navigate to an external web site without showing Safari UI.',
              )}
            </p>
            {/* <Chip
              kind={'tertiary-light'}
              text="iOS"
              style={{ float: 'left', marginRight: '4px' }}
            />
            <Chip kind={'tertiary-light'} text="iPadOS" /> */}
          </Setting.Helpers>
        </Setting.Row>
      </Setting.Rows>
    </Setting.Card>
  );
};

export interface GeneralConfigProps {
  update: (field: string, value: any) => void;
  setting: GeneralWebClip;
  isDisabled: boolean;
  validationDep: number;
}

export default GeneralConfig;
