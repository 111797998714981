/**
 * A Library Item status log is a single
 * string separated by \n to create key: value
 * rows.
 */
export class LibraryItemStatusLog {
  private rows: string[];

  constructor() {
    this.rows = [];
  }

  private formatRow(label: string, value?: string): string {
    return value == null ? label : `${label}: ${value}`;
  }

  public addRow(label: string, value?: string) {
    this.rows.push(this.formatRow(label, value));
    return this;
  }

  public toString() {
    return this.rows.join('\n');
  }
}
