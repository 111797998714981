/* istanbul ignore file */
import type { UniqueIdentifier } from '@dnd-kit/core';
import {
  Box,
  Button,
  Flex,
  Heading,
  Loader,
  MultiSelect,
  Text,
  TextField,
  styled,
} from '@kandji-inc/nectar-ui';
import type { ReactEventHandler } from 'react';
import { i18n } from 'src/i18n';

import FeatureFlags from 'src/config/feature-flags';
import AppIcon from '../AppIcon';
import { DraggableApp } from '../DraggableApp';
import FullPreviewMessage from '../FullPreviewMessage';
import noResultsSvg from '../assets/no-results.svg';
import { APP_ICON_CONFIG, LAYOUT_CONFIG, PREVIEW_CONFIG } from '../common';

import type {
  App,
  AppTypeKind,
  HomeScreenLayoutDeviceKind,
  HomeScreenLayoutDeviceModelSettings,
  HomeScreenLayoutItem,
} from '../../../home-screen-layout.types';

const { height: heightCss } = LAYOUT_CONFIG;
const { ipad, iphone } = PREVIEW_CONFIG;

const maxTotalItems = {
  ipad: ipad.maxPerPage + ipad.maxDock,
  iphone: iphone.maxPerPage + iphone.maxDock,
};

export const ApplicationListSection = styled(Box, {
  display: 'grid',
  gridTemplateColumns: `repeat(4, ${APP_ICON_CONFIG.containerSize}px)`,
  gridTemplateRows: APP_ICON_CONFIG.containerSize,
  rowGap: '$2',
  justifyContent: 'space-between',
});

interface ApplicationListProps {
  readonly kind: HomeScreenLayoutDeviceKind;
  readonly apps: {
    appStore: ReadonlyArray<App>;
    inHouse: ReadonlyArray<App>;
    system: ReadonlyArray<App>;
    webclip: ReadonlyArray<App>;
  };
  readonly pages: HomeScreenLayoutDeviceModelSettings['Pages'];
  readonly dock: HomeScreenLayoutDeviceModelSettings['Dock'];
  readonly currentPage: number;
  readonly currentFolderId: string | null;
  readonly activeId: UniqueIdentifier;
  readonly searchTerm: string;
  readonly selectedType: ReadonlyArray<AppTypeKind>;
  readonly handleSearchChange: ReactEventHandler;
  readonly handleClearSearch: () => void;
  readonly handleClearAllFilters: () => void;
  readonly handleSelectedTypeChange: (val: ReadonlyArray<AppTypeKind>) => void;
  readonly handleSelectedBlueprintChange: (val: ReadonlyArray<string>) => void;
  readonly isDisabled: boolean;
  readonly blueprintOptions: ReadonlyArray<{ label: string; value: string }>;
  readonly selectedBlueprints: ReadonlyArray<string>;
  readonly onSelect: (
    item: HomeScreenLayoutItem | HomeScreenLayoutItem[],
  ) => void;
  readonly selectedItems: readonly HomeScreenLayoutItem[];
}

const ApplicationList = (props: ApplicationListProps) => {
  const {
    kind,
    apps,
    pages,
    dock,
    currentPage,
    currentFolderId,
    activeId,
    searchTerm,
    selectedType,
    handleSearchChange,
    handleClearSearch,
    handleClearAllFilters,
    handleSelectedTypeChange,
    handleSelectedBlueprintChange,
    isDisabled,
    blueprintOptions,
    selectedBlueprints,
    onSelect,
    selectedItems,
  } = props;

  const currPageItems = pages[currentPage];
  const allPageItems = pages.flatMap((page) => page);
  const totalItemsCount = (currPageItems?.length || 0) + dock.length;

  const hasNoResults =
    apps?.appStore?.length < 1 &&
    apps?.inHouse?.length < 1 &&
    apps?.system?.length < 1 &&
    apps?.webclip?.length < 1;

  const selectOptions = () => {
    const options = [
      {
        value: 'appStore',
        label: i18n.t('App Store'),
      },
      {
        value: 'system',
        label: i18n.t('System Apps'),
      },
    ];

    if (FeatureFlags.getFlag('core-08182024-ipa-apps-v2')) {
      options.splice(1, 0, {
        value: 'inHouse',
        label: i18n.t('In-House Apps'),
      });
    }

    if (FeatureFlags.getFlag('ios-web-clips')) {
      options.splice(1, 0, {
        value: 'profile',
        label: i18n.t('Web Clips'),
      });
    }

    return options;
  };

  return (
    <Flex
      as="section"
      aria-label={`${kind === 'ipad' ? 'iPad' : 'iPhone'} Application List`}
      gap="md"
      flow="column"
    >
      <TextField
        wFull
        icon="magnifying-glass"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder={i18n.t('Search for items')}
        showClearButton
        onClear={handleClearSearch}
        disabled={isDisabled}
      />
      <Flex gap="sm" justifyContent="space-between">
        <Flex gap="xs">
          <MultiSelect
            data-testid="application-type-select"
            size="xs"
            value={selectedBlueprints}
            onChange={handleSelectedBlueprintChange}
            placeholder={i18n.t('Blueprint')}
            options={blueprintOptions}
            disabled={isDisabled}
            searchable
            componentCss={{
              menu: {
                width: '400px',
              },
            }}
          />
          <MultiSelect
            data-testid="application-type-select"
            size="xs"
            value={selectedType}
            onChange={handleSelectedTypeChange}
            placeholder={i18n.t('Item type')}
            disabled={isDisabled}
            options={selectOptions()}
          />
        </Flex>
        <Button
          compact
          variant="subtle"
          disabled={isDisabled}
          onClick={handleClearAllFilters}
        >
          {i18n.t('Clear')}
        </Button>
      </Flex>

      {totalItemsCount >= maxTotalItems[kind] && <FullPreviewMessage />}

      <Flex
        flow="column"
        gap="xl"
        css={{
          height: heightCss.styledVar,
          padding: '$3',
          border: '1px solid $neutral20',
          borderRadius: '$1',
          scrollBehavior: 'smooth',
          overflowY: activeId ? 'hidden' : 'auto',
        }}
      >
        {!apps && (
          <Loader
            data-testid="hsl-app-list-loader"
            aria-busy="true"
            aria-label="Loading..."
            size="lg"
            css={{
              marginTop: '$4',
            }}
          />
        )}

        {apps?.appStore?.length > 0 && (
          <Flex flow="column" gap="lg">
            <Text
              size="2"
              css={{
                fontWeight: '$medium',
                color: '$neutral60',
              }}
            >
              {i18n.t('App Store Apps')}
            </Text>
            <ApplicationListSection
              data-testid={`hsl-app-list-app-store-apps-section-${kind}`}
            >
              {apps.appStore.map((app) => {
                const [disabled, selectedIndex] = getMappedAppProps({
                  app,
                  activeId,
                  totalItemsCount,
                  kind,
                  dockItems: dock,
                  allPageItems,
                  selectedItems,
                  isDisabled,
                  currentFolderId,
                });

                return (
                  <DraggableApp
                    key={app.id}
                    id={`appStore_${app.id}`}
                    app={app}
                    disabled={disabled}
                    onSelect={onSelect}
                  >
                    <AppIcon
                      app={app}
                      disabled={disabled}
                      count={selectedIndex + 1}
                    />
                  </DraggableApp>
                );
              })}
            </ApplicationListSection>
          </Flex>
        )}

        {apps?.inHouse?.length > 0 && (
          <Flex flow="column" gap="lg">
            <Text
              size="2"
              css={{
                fontWeight: '$medium',
                color: '$neutral60',
              }}
            >
              {i18n.t('In-house Apps')}
            </Text>
            <ApplicationListSection
              data-testid={`hsl-app-list-ipa-apps-section-${kind}`}
            >
              {apps.inHouse.map((app) => {
                const [disabled, selectedIndex] = getMappedAppProps({
                  app,
                  activeId,
                  totalItemsCount,
                  kind,
                  dockItems: dock,
                  allPageItems,
                  selectedItems,
                  isDisabled,
                  currentFolderId,
                });

                return (
                  <DraggableApp
                    key={app.id}
                    id={`inHouse_${app.id}`}
                    app={app}
                    disabled={disabled}
                    onSelect={onSelect}
                  >
                    <AppIcon
                      app={app}
                      disabled={disabled}
                      count={selectedIndex + 1}
                    />
                  </DraggableApp>
                );
              })}
            </ApplicationListSection>
          </Flex>
        )}

        {FeatureFlags.getFlag('ios-web-clips') && apps?.webclip?.length > 0 && (
          <Flex flow="column" gap="lg">
            <Text
              size="2"
              css={{
                fontWeight: '$medium',
                color: '$neutral60',
              }}
            >
              {i18n.t('Web Clips')}
            </Text>
            <ApplicationListSection
              data-testid={`hsl-app-list-webclip-apps-section-${kind}`}
            >
              {apps.webclip.map((app) => {
                const [disabled, selectedIndex] = getMappedAppProps({
                  app,
                  activeId,
                  totalItemsCount,
                  kind,
                  dockItems: dock,
                  allPageItems,
                  selectedItems,
                  isDisabled,
                  currentFolderId,
                });

                // hide apps that are always in preview in the application list
                // since they will always live in the preview area
                return app.alwaysInPreview === true ? null : (
                  <DraggableApp
                    key={app.id}
                    id={`system_${app.id}`}
                    app={app}
                    disabled={disabled}
                    onSelect={onSelect}
                  >
                    <AppIcon
                      app={app}
                      disabled={disabled}
                      count={selectedIndex + 1}
                    />
                  </DraggableApp>
                );
              })}
            </ApplicationListSection>
          </Flex>
        )}

        {apps?.system?.length > 0 && (
          <Flex flow="column" gap="lg">
            <Text
              size="2"
              css={{
                fontWeight: '$medium',
                color: '$neutral60',
              }}
            >
              {i18n.t('System Apps')}
            </Text>
            <ApplicationListSection
              data-testid={`hsl-app-list-system-apps-section-${kind}`}
            >
              {apps.system.map((app) => {
                const [disabled, selectedIndex] = getMappedAppProps({
                  app,
                  activeId,
                  totalItemsCount,
                  kind,
                  dockItems: dock,
                  allPageItems,
                  selectedItems,
                  isDisabled,
                  currentFolderId,
                });

                // hide apps that are always in preview in the application list
                // since they will always live in the preview area
                return app.alwaysInPreview === true ? null : (
                  <DraggableApp
                    key={app.id}
                    id={`system_${app.id}`}
                    app={app}
                    disabled={disabled}
                    onSelect={onSelect}
                  >
                    <AppIcon
                      app={app}
                      disabled={disabled}
                      count={selectedIndex + 1}
                    />
                  </DraggableApp>
                );
              })}
            </ApplicationListSection>
          </Flex>
        )}

        {hasNoResults && (
          <Box pt8 px5 css={{ textAlign: 'center' }}>
            <img src={noResultsSvg} alt="No results found" />
            <Heading size="3" css={{ marginTop: '$5' }}>
              No Results Found
            </Heading>
            <Text size="2" variant="description">
              To arrange this item on the Home Screen, first add it to your
              Apple Business Manager account.
            </Text>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default ApplicationList;

function getMappedAppProps({
  app,
  activeId,
  totalItemsCount,
  kind,
  dockItems,
  allPageItems,
  selectedItems,
  isDisabled,
  currentFolderId,
}: {
  app: App;
  activeId: UniqueIdentifier;
  totalItemsCount: number;
  kind: HomeScreenLayoutDeviceKind;
  dockItems: ReadonlyArray<HomeScreenLayoutItem>;
  allPageItems: ReadonlyArray<HomeScreenLayoutItem>;
  selectedItems: ReadonlyArray<HomeScreenLayoutItem>;
  isDisabled: boolean;
  currentFolderId: string;
}) {
  const selectedIndex = selectedItems.findIndex((item) => item.id === app.id);
  const { maxPerFolder } = PREVIEW_CONFIG[kind];

  const disabled =
    isDisabled ||
    app.id === activeId ||
    totalItemsCount >= maxTotalItems[kind] ||
    Boolean(dockItems.find(({ id }) => id === app.id)) ||
    Boolean(
      allPageItems?.find((pageItem) => {
        /* istanbul ignore if */
        // @ts-expect-error -- don't understand why Pages isn't on this type because Folder is included in the `HomeScreenLayoutItem` type
        if (pageItem?.Pages) {
          if (
            pageItem.id === currentFolderId &&
            // @ts-expect-error -- don't understand why Pages isn't on this type because Folder is included in the `HomeScreenLayoutItem` type
            pageItem?.Pages[0].length >= maxPerFolder
          ) {
            // @todo handle all pages, not just the first
            return true;
          }

          // @ts-expect-error -- don't understand why Pages isn't on this type because Folder is included in the `HomeScreenLayoutItem` type
          return pageItem.Pages?.some((page) =>
            page.some((item) => item.id === app.id),
          );
        }

        return pageItem.id === app.id;
      }),
    ) ||
    (activeId && selectedIndex > -1);

  return [disabled, selectedIndex] as const;
}
