/* istanbul ignore file */
import { useQuery } from '@tanstack/react-query';
import { i18n } from 'i18n';
import { useDynamicImport } from 'src/hooks/useDynamicImport';
import { getGlobalVars } from '../GlobalVars';

interface LockScreenLexicalProps {
  handleEditorChange: (newEditorState: string) => void;
  isDisabled?: boolean;
  lockScreenFootnote: string;
}

function LexicalEditorComponent({
  Lexical,
  handleEditorChange,
  isDisabled,
  lockScreenFootnote,
  globalVariables,
}) {
  const {
    LexicalEditor,
    LexicalMentionsTriggers,
    LexicalMentionNode,
    LexicalMentionsPlugin,
    LexicalDisabledPlugin,
    LexicalInitialStatePlugin,
  } = Lexical;

  const initialConfig = {
    namespace: 'LockScreenLexical',
    nodes: [LexicalMentionNode],
    editable: !isDisabled,
  };

  const mentionItems = {
    [LexicalMentionsTriggers.DOLLAR]: globalVariables,
  };

  return (
    <LexicalEditor
      initialConfig={initialConfig}
      placeholder={i18n.t(
        'Enter lock screen message. Type “$” to access variables.',
      )}
      editorProps={{
        minHeight: '60px',
        resize: 'vertical',
      }}
      onEditorChange={handleEditorChange}
    >
      <LexicalMentionsPlugin
        mentionItems={mentionItems}
        menuCss={{ maxHeight: '200px' }}
      />
      <LexicalDisabledPlugin isDisabled={isDisabled} />
      <LexicalInitialStatePlugin value={lockScreenFootnote} />
    </LexicalEditor>
  );
}

export function LockScreenLexical({
  handleEditorChange,
  lockScreenFootnote,
  isDisabled,
}: LockScreenLexicalProps) {
  const [Lexical, isLoading, hasError] = useDynamicImport(
    () => import('@kandji-inc/nectar-ui/lexical'),
  );

  const {
    data,
    error,
    isLoading: isQueryLoading,
    isFetching,
  } = useQuery({
    queryKey: ['globalVars'],
    queryFn: getGlobalVars,
  });

  if (isLoading || isQueryLoading || isFetching || hasError || error || !data) {
    return null;
  }

  const globalVariables: string[] = data.map((item) => item.name);

  return (
    <LexicalEditorComponent
      Lexical={Lexical}
      handleEditorChange={handleEditorChange}
      globalVariables={globalVariables}
      lockScreenFootnote={lockScreenFootnote}
      isDisabled={isDisabled}
    />
  );
}
