import { Flex, Grid, Heading, Icon, Text, styled } from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { constants } from '../../common';
import ThreatListHighlightedText from '../../common/components/ThreatListHighlightedText';
import ThreatListSquareButton from '../../common/components/ThreatListSquareButton';
import ThreatListStatusBadge from '../../common/components/ThreatListStatusBadge';
import ThreatListTextCopyButton from '../../common/components/ThreatListTextCopyButton';
// TODO: Move file to common components
import ThreatListButtonPopover from '../../threat-groups/ThreatListByThreats/SidePanels/ThreatListButtonPopover';
import type {
  ThreatClassification,
  ThreatDetail,
  ThreatStatus,
} from '../../threat.types';

const SplitViewClassificationIcon = styled(Flex, {
  width: 40,
  height: 40,
  padding: 6,
  borderRadius: '50%',
  svg: { width: 26, height: 26 },
  justifyContent: 'center',
  alignItems: 'center',
});

const ThreatNameHeading = styled(Heading, {
  fontSize: 20,
  lineHeight: '32px',
  letterSpacing: '-0.8px',
  fontWeight: '$medium',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 220,
});

const SummaryDetails = styled(Flex, {
  gap: '$3',
  alignItems: 'start',
});

const TextBold = styled(Text, { fontWeight: '$medium' });

const StyledGrid = styled(Grid, {
  fontSize: '$1',
  color: '$neutral70',
  gap: 6,
  gridTemplateColumns: '126px 1fr',
  flex: 1,
  alignItems: 'stretch',
  gridAutoRows: '18px',
});

const GridCell = styled(Flex, {
  gap: '$1',
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

const getClassificationLabel = (classification: ThreatClassification) =>
  constants.THREAT_CLASSIFICATION_LABELS(classification);

const getClassifactionIcon = (classification: ThreatClassification) =>
  constants.THREAT_CLASSIFICATION_ICON_MAP[classification];

const getClassifactionColor = (classification: ThreatClassification) =>
  constants.THREAT_CLASSIFICATION_COLOR[classification];

const getStatusLabel = (status: ThreatStatus) =>
  constants.THREAT_STATUS_DISPLAY_NAME_MAP(status);

const getStatusIcon = (status: ThreatStatus) =>
  constants.THREAT_STATUS_ICON_MAP[status];

const getStatusColor = (status: ThreatStatus) =>
  constants.THREAT_STATUS_COLOR_MAP[status];

const {
  THREAT_STATUS: { NOT_QUARANTINED, RELEASED, QUARANTINED, RESOLVED },
} = constants;

type ThreatListSplitViewProps = {
  threat: ThreatDetail;
  tableSearchQuery: string | undefined;
  onReleaseThreat: () => void;
  onRecheckStatus: (threat: ThreatDetail) => void;
  isThreatBeingChecked: boolean;
};

const ThreatListSplitViewHeader = (props: ThreatListSplitViewProps) => {
  const {
    threat,
    tableSearchQuery,
    onReleaseThreat,
    onRecheckStatus,
    isThreatBeingChecked,
  } = props;
  const { status } = threat;
  const color = getClassifactionColor(threat.classification);
  const isNotQuarantined = status === NOT_QUARANTINED;
  const isQuarantined = status === QUARANTINED;
  const isResolved = status === RESOLVED;
  const isReleased = status === RELEASED;
  const hasReleaseNote = Boolean(threat.release_note);

  return (
    <Flex flow="column" gap="xl" wFull>
      <Flex
        flow="row"
        css={{
          alignItems: 'center',
          gap: '$2',
          justifyContent: 'space-between',
        }}
      >
        <SplitViewClassificationIcon css={{ bg: `$${color}10` }}>
          <Icon
            color={`var(--color-${color}-60)`}
            name={getClassifactionIcon(threat.classification)}
          />
        </SplitViewClassificationIcon>
        <Flex flex={1} flow="column" justifyContent="start" alignItems="start">
          <ThreatNameHeading as="h3">
            <ThreatListTextCopyButton
              text={threat.file_hash}
              tooltip={threat.file_hash}
            />
          </ThreatNameHeading>
          <Text variant="description">
            {getClassificationLabel(threat.classification)}
          </Text>
        </Flex>
        <Flex css={{ alignSelf: 'flex-start', mt: 2 }}>
          {isNotQuarantined && (
            <ThreatListSquareButton
              tooltip={i18n.t('Recheck status')}
              icon="arrows-rotate"
              variant="primary"
              onClick={/* istanbul ignore next */ () => onRecheckStatus(threat)}
              disabled={isThreatBeingChecked}
              testId="split-view-recheck-status-button"
            />
          )}
          {isQuarantined && (
            <ThreatListSquareButton
              tooltip={i18n.t('Release threat')}
              icon="file-shield"
              variant="primary"
              onClick={onReleaseThreat}
              testId="split-view-release-threat-button"
            />
          )}
        </Flex>
      </Flex>
      <SummaryDetails>
        <StyledGrid>
          <GridCell>
            <Text variant="description">{i18n.t('Threat status')}</Text>
          </GridCell>
          <GridCell>
            <ThreatListStatusBadge
              color={getStatusColor(threat.status)}
              icon={getStatusIcon(threat.status)}
            >
              {getStatusLabel(threat.status)}
            </ThreatListStatusBadge>
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('File name')}</Text>
          </GridCell>
          <GridCell>
            <ThreatListHighlightedText search={tableSearchQuery}>
              {threat.threat_name}
            </ThreatListHighlightedText>
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Detected on')}</Text>
          </GridCell>
          <GridCell>{i18n.format.datetime(threat.detection_date)}</GridCell>

          {isQuarantined && (
            <>
              <GridCell>
                <Text variant="description">{i18n.t('Quarantined on')}</Text>
              </GridCell>
              <GridCell css={{ alignItems: 'stretch' }}>
                {i18n.format.datetime(threat.date_of_quarantine)}
              </GridCell>
            </>
          )}

          {isResolved && (
            <>
              <GridCell>
                <Text variant="description">{i18n.t('Resolved on')}</Text>
              </GridCell>
              <GridCell css={{ alignItems: 'stretch' }}>
                {i18n.format.datetime(threat.resolved_at)}
                <Flex css={{ pt: 2, gap: 2 }}>
                  <ThreatListButtonPopover
                    tooltip={i18n.t('Resolved by')}
                    icon="user-large"
                    testId="side-panel-release-notes-button"
                  >
                    <TextBold>{i18n.t('Resolved by')}</TextBold>
                    <Text>{threat.resolved_by}</Text>
                  </ThreatListButtonPopover>
                </Flex>
              </GridCell>
            </>
          )}

          {isReleased && (
            <>
              <GridCell>
                <Text variant="description">{i18n.t('Released on')}</Text>
              </GridCell>
              <GridCell css={{ alignItems: 'stretch' }}>
                {i18n.format.datetime(threat.date_of_release)}
                <Flex css={{ pt: 2, gap: 2 }}>
                  <ThreatListButtonPopover
                    tooltip={i18n.t('Released by')}
                    icon="user-large"
                    testId="side-panel-release-notes-button"
                  >
                    <TextBold>{i18n.t('Released by')}</TextBold>
                    <Text>{threat.released_by}</Text>
                  </ThreatListButtonPopover>
                  {hasReleaseNote && (
                    <ThreatListButtonPopover
                      tooltip={i18n.t('Release notes')}
                      icon="file"
                      testId="side-panel-release-notes-button"
                    >
                      <TextBold>{i18n.t('Notes')}</TextBold>
                      <Text>{threat.release_note}</Text>
                    </ThreatListButtonPopover>
                  )}
                </Flex>
              </GridCell>
            </>
          )}
        </StyledGrid>
      </SummaryDetails>
    </Flex>
  );
};

export default ThreatListSplitViewHeader;
