import React, { useState } from 'react';
import { components } from 'react-select';

import { array } from 'prop-types';

import {
  Banner,
  Select as BumblebeeSelect,
  Flex,
  Icon,
  setClass,
} from '@kandji-inc/bumblebee';

import { FACET_GROUPS } from '../../../constants';
import { getIcons } from '../../icons/helpers';
import useBuilder from '../../use-builder';

import { useOption } from '.';

const styles = {
  groupHeading: (provided) => ({
    ...provided,
    marginBottom: '8px',
    marginTop: '8px',
    textTransform: 'none',
  }),
  option: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled && 'var(--color-neutral-50)',
    cursor: isDisabled && 'not-allowed',
  }),
};

const DropdownIndicator = (props) => {
  const {
    selectProps: { menuIsOpen },
  } = props;

  return (
    <components.DropdownIndicator {...props}>
      <Icon
        name="angle-down"
        size="sm"
        className={setClass([
          'b-select__icon',
          menuIsOpen ? '' : 'b-select__icon--closed',
        ])}
      />
    </components.DropdownIndicator>
  );
};

const GroupHeading = (props) => {
  const {
    data: { subject, label, banner, hasDisabled },
  } = props;

  return (
    <components.GroupHeading {...props}>
      <div className="b-select__group-heading-label">{label}</div>
      {subject === 'user_directory_integration' && hasDisabled && banner && (
        <Banner
          className="b-select__group-heading-banner b-mb1 b-mt1"
          theme="info"
          kind="block"
        >
          <p>{banner}</p>
        </Banner>
      )}
    </components.GroupHeading>
  );
};

// A custom value component that displays supported device family icons
const IconValue = (props) => {
  const {
    isDisabled,
    data: { label, deviceFamilies: facetSupportedDeviceFamilies },
  } = props;

  const {
    supportedDeviceFamilies: libraryItemSupportedDeviceFamilies,
    installOnDeviceFamilies,
  } = useBuilder();

  return (
    <components.SingleValue className="b-select__single-value" {...props}>
      <span className="b-select__option-label">{label}</span>
      <span className="b-select__option-icons">
        {getIcons(
          facetSupportedDeviceFamilies,
          libraryItemSupportedDeviceFamilies,
          installOnDeviceFamilies,
          isDisabled,
        )}
      </span>
    </components.SingleValue>
  );
};

// A custom option component that displays supported device family icons
const OptionWithIcon = (props) => {
  const {
    data: { label, deviceFamilies: facetSupportedDeviceFamilies },
  } = props;

  const [isHovering, setIsHovering] = useState(false);
  const {
    supportedDeviceFamilies: libraryItemSupportedDeviceFamilies,
    installOnDeviceFamilies,
  } = useBuilder();

  return (
    <span
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <components.Option {...props}>
        <Flex align="center">
          <span className="b-select__option-label">{label}</span>
          {isHovering && (
            <span>
              {getIcons(
                facetSupportedDeviceFamilies,
                libraryItemSupportedDeviceFamilies,
                installOnDeviceFamilies,
              )}
            </span>
          )}
        </Flex>
      </components.Option>
    </span>
  );
};

const Select = (props) => {
  const { options, ...rest } = props;

  const { getIsDisabled } = useOption();

  // Adds the 'isDisabled' key to each option with values determined by external conditions
  const optionsWithIsDisabled = options.map((option) => ({
    ...option,
    isDisabled: getIsDisabled(option),
  }));

  // Returns a new list of options filtered by the specified "subject" value
  const filterOptionsBySubject = (value) =>
    optionsWithIsDisabled.filter(({ subject }) => subject === value);

  // Groups the options by their "subject" value and includes a group heading (label)
  const optionsGrouped = Object.keys(FACET_GROUPS()).map((subject) => {
    const { label, banner } = FACET_GROUPS()[subject];

    const optionsBySubject = filterOptionsBySubject(subject);

    const hasDisabled =
      optionsBySubject.find(({ isDisabled }) => isDisabled) !== undefined;

    return {
      options: optionsBySubject,
      subject,
      label,
      banner,
      hasDisabled,
    };
  });

  return (
    <BumblebeeSelect
      {...rest}
      styles={styles}
      options={optionsGrouped}
      components={{
        DropdownIndicator,
        GroupHeading,
        Option: OptionWithIcon,
        SingleValue: IconValue,
      }}
    />
  );
};

Select.propTypes = {
  options: array.isRequired,
};

export default Select;
