import { createColumnHelper } from '@tanstack/react-table';
import { i18n } from 'i18n';
/* istanbul ignore file */
import type { z } from 'zod';

import {
  DateCell,
  YES_NO_CELL_FILTER_OPTIONS,
  YesNoCell,
  extractFilterableColumns,
  getFilteredDisabled as filterDisabled,
  getCodeSnippetCell,
  getEnumFilterOptions,
} from '../column-utils';
import { getGlobalColumns } from './globalColumns';

export const getLaunchAgentsDaemonsColumns = (schemas) => {
  const globalSchema = schemas.find((schema) => schema.uri === 'globals');
  const launchAgentsDaemonsSchema = schemas.find(
    (schema) => schema.uri === 'launch_agents_and_daemons',
  );

  if (!launchAgentsDaemonsSchema || !globalSchema) {
    return { columnDefs: [], filterableColumns: [] };
  }

  const combinedProperties = {
    ...globalSchema.schema.properties,
    ...launchAgentsDaemonsSchema.schema.properties,
  };

  const launchAgentsDaemonsColumnHelper =
    createColumnHelper<z.infer<typeof combinedProperties>>();

  const globalColumns = getGlobalColumns(
    launchAgentsDaemonsColumnHelper,
    combinedProperties,
  );

  const columnDefs = [
    ...globalColumns.startColumnDefs,

    launchAgentsDaemonsColumnHelper.accessor((row) => row.label, {
      id: 'label',
      meta: {
        displayName: combinedProperties.label.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.is_disabled, {
      id: 'is_disabled',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.is_disabled.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.run_at_load, {
      id: 'run_at_load',
      cell: YesNoCell,
      meta: {
        displayName: combinedProperties.run_at_load.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.service_domain, {
      id: 'service_domain',
      meta: {
        displayName: combinedProperties.service_domain.title,
        filterType: 'enum',
        filterIcon: 'list-check',
        filterOptions: [
          { value: 'Daemon', label: i18n.t('Daemon') },
          { value: 'User Agent', label: i18n.t('User agent') },
          { value: 'System Agent', label: i18n.t('System agent') },
        ],
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.program, {
      id: 'program',
      cell: getCodeSnippetCell,
      meta: {
        displayName: combinedProperties.program.title,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.created_at, {
      id: 'created_at',
      cell: DateCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.created_at.title,
        filterType: 'date-time',
        filterIcon: 'calendar',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.path, {
      id: 'path',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.path.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.file_name, {
      id: 'file_name',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.file_name.title,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.program_arguments, {
      id: 'program_arguments',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.program_arguments.title,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.keep_alive, {
      id: 'keep_alive',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.keep_alive.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.disabled, {
      id: 'disabled',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.disabled.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.user_name, {
      id: 'user_name',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.user_name.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.group_name, {
      id: 'group_name',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.group_name.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.standard_out_path, {
      id: 'standard_out_path',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.standard_out_path.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.standard_error_path, {
      id: 'standard_error_path',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.standard_error_path.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.start_interval, {
      id: 'start_interval',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.start_interval.title,
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.watch_paths, {
      id: 'watch_paths',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.watch_paths.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.queue_directories, {
      id: 'queue_directories',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.queue_directories.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.start_on_mount, {
      id: 'start_on_mount',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.start_on_mount.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.root_directory, {
      id: 'root_directory',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.root_directory.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.working_directory, {
      id: 'working_directory',
      cell: getCodeSnippetCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.working_directory.title,
        filterType: 'string',
        filterIcon: 'text-size',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.process_type, {
      id: 'process_type',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.process_type.title,
        filterType: 'enum',
        filterOptions: getEnumFilterOptions(
          launchAgentsDaemonsSchema.schema?.definitions.ProcesssType.enum || [],
        ),
        filterIcon: 'list-check',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row.user_agent_local_user,
      {
        id: 'user_agent_local_user',
        meta: {
          defaultHidden: true,
          displayName: combinedProperties.user_agent_local_user.title,
          filterType: 'string',
          filterIcon: 'text-size',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor(
      (row) => row.user_agent_local_user_id,
      {
        id: 'user_agent_local_user_id',
        meta: {
          defaultHidden: true,
          displayName: combinedProperties.user_agent_local_user_id.title,
          filterType: 'number',
          filterIcon: 'hashtag',
          filterDisabled,
        },
      },
    ),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.is_loaded, {
      id: 'is_loaded',
      cell: YesNoCell,
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.is_loaded.title,
        filterType: 'boolean',
        filterOptions: [...YES_NO_CELL_FILTER_OPTIONS()],
        filterIcon: 'circle-dot',
        filterDisabled,
      },
    }),

    launchAgentsDaemonsColumnHelper.accessor((row) => row.pid, {
      id: 'pid',
      meta: {
        defaultHidden: true,
        displayName: combinedProperties.pid.title,
        filterType: 'number',
        filterIcon: 'hashtag',
        filterDisabled,
      },
    }),

    ...globalColumns.endColumnDefs,
  ];

  const filterableColumns = extractFilterableColumns(columnDefs);

  return { columnDefs, filterableColumns };
};
