import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import AwesomeTableRow from '../AwesomeTableRow';

const AwesomeTableBody = ({
  data,
  headers,
  tableWidth,
  expandComponent,
  loadingDataView,
  clickableCellsToExpand,
  isExpandableRow,
}) => {
  if (isEmpty(data)) {
    return loadingDataView;
  }
  return (
    <div className="awesome-table-body-wrapper">
      {data.map((row, i) => (
        <AwesomeTableRow
          key={`AwesomeTableTr-${i}`}
          headers={headers}
          tableWidth={tableWidth}
          row={row}
          index={i}
          expandComponent={expandComponent}
          clickableCellsToExpand={clickableCellsToExpand}
          isExpandableRow={isExpandableRow}
          isLastRow={data.length === i + 1}
        />
      ))}
    </div>
  );
};

AwesomeTableBody.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  tableWidth: PropTypes.number.isRequired,
  expandComponent: PropTypes.func.isRequired,
  clickableCellsToExpand: PropTypes.arrayOf(PropTypes.string),
  isExpandableRow: PropTypes.func,
};

AwesomeTableBody.defaultProps = {
  data: [],
  clickableCellsToExpand: null,
  isExpandableRow: null,
};

export default AwesomeTableBody;
