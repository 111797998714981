import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { InstallProfileFailureDetails } from '../ProfileStatus.types';

function serializerV1(details: InstallProfileFailureDetails) {
  const {
    command_failed,
    command_issued,
    error_chain,
    payload_identifier,
    payload_uuid,
    profile_uuid,
    profile_name,
  } = details;

  const log = new LibraryItemStatusLog()
    .addRow(i18n.t('MDM Command Failed'), i18n.t('Install Profile'))
    .addRow(i18n.t('Profile Name'), profile_name)
    .addRow(i18n.t('Profile UUID'), profile_uuid)
    .addRow(i18n.t('Payload Identifier'), payload_identifier)
    .addRow(i18n.t('Payload UUID'), payload_uuid)
    .addRow(i18n.t('Command Issued'), command_issued)
    .addRow(i18n.t('Command Failed'), command_failed);

  error_chain.forEach((error) => {
    const {
      error_domain,
      error_code,
      localized_description,
      us_english_description,
    } = error;
    const description = localized_description ?? us_english_description;

    log.addRow(`${description} (${error_domain}): ${error_code}`);
  });

  return log.toString();
}

export const InstallProfileFailureLogSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
