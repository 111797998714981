import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { ProfileCommonStatusDetails } from '../ProfileStatus.types';

function serializerV1(details: ProfileCommonStatusDetails) {
  const { payload_identifier, profile_uuid, profile_name } = details;

  return new LibraryItemStatusLog()
    .addRow(i18n.t('Profile is installed'))
    .addRow(i18n.t('Profile Name'), profile_name)
    .addRow(i18n.t('Profile UUID'), profile_uuid)
    .addRow(i18n.t('Payload Identifier'), payload_identifier)
    .toString();
}

export const InstallProfileSuccessAuditSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
