import { i18n } from 'i18n';
import type { PrismCategoryUri } from 'src/features/visibility/prism/types/prism.types';

export const getIconByDeviceFamily = (deviceFamily: string) => {
  switch (deviceFamily) {
    case 'mac':
      return 'sf-desktop-computer';
    case 'iphone':
      return 'sf-iphone';
    case 'ipad':
      return 'sf-ipad-landscape';
    case 'apple_tv':
      return 'sf-tv';
    default:
      return 'sf-desktop-computer';
  }
};

export const getSupportedDeviceFamiliesByPrismCategory = (
  prismCategory: PrismCategoryUri | undefined,
) => {
  switch (prismCategory) {
    case 'device_information':
      return ['mac', 'iphone', 'ipad', 'apple_tv'];
    case 'activation_lock':
      return ['mac', 'iphone', 'ipad'];
    case 'filevault':
      return ['mac'];
    case 'startup_settings':
      return ['mac'];
    case 'launch_agents_and_daemons':
      return ['mac'];
    case 'system_extensions':
      return ['mac'];
    case 'gatekeeper_and_xprotect':
      return ['mac'];
    case 'transparency_database':
      return ['mac'];
    case 'kernel_extensions':
      return ['mac'];
    case 'local_users':
      return ['mac'];
    case 'installed_profiles':
      return ['mac', 'iphone', 'ipad', 'apple_tv'];
    case 'application_firewall':
      return ['mac'];
    case 'apps':
      return ['mac', 'iphone', 'ipad', 'apple_tv'];
    case 'desktop_and_screensaver':
      return ['mac'];
    case 'certificates':
      return ['mac', 'iphone', 'ipad', 'apple_tv'];
    default:
      return [];
  }
};

export const getCopyByPrismCategory = (prismCategory: PrismCategoryUri) => {
  switch (prismCategory) {
    case 'device_information':
      return i18n.t('General information about enrolled devices.');
    case 'filevault':
      return i18n.t('The FileVault and Recovery Key state for macOS devices.');
    case 'startup_settings':
      return i18n.t('Startup security settings for macOS devices.');
    case 'launch_agents_and_daemons':
      return i18n.t(
        'The installed Launch Agents and Daemons on macOS devices.',
      );
    case 'activation_lock':
      return i18n.t(
        'Activation Lock details for macOS, iOS, and iPadOS devices.',
      );
    case 'system_extensions':
      return i18n.t('System Extensions details for macOS devices.');
    case 'gatekeeper_and_xprotect':
      return i18n.t('Gatekeeper and XProtect details for macOS devices.');
    case 'transparency_database':
      return i18n.t(
        'Transparency, Consent, and Control (TCC) details for macOS devices running macOS 11 or later.',
      );
    case 'kernel_extensions':
      return i18n.t('Kernel Extensions details for macOS devices.');
    case 'local_users':
      return i18n.t('Local Users details for macOS devices.');
    case 'installed_profiles':
      return i18n.t(
        'Installed Profiles details for macOS, iOS, iPadOS, and tvOS devices.',
      );
    case 'application_firewall':
      return i18n.t(
        'The application layer firewall configuration for a macOS device.',
      );
    case 'apps':
      return i18n.t(
        'The applications installed on macOS, iOS, iPadOS, and tvOS devices.',
      );
    case 'desktop_and_screensaver':
      return i18n.t('Desktop and Screensaver details for macOS devices.');
    case 'certificates':
      return i18n.t(
        'Certificates installed on macOS, iOS, iPadOS, and tvOS devices.',
      );
    default:
      return i18n.t('Unknown');
  }
};

export const SUPPORT_URL =
  'https://support.kandji.io/support/solutions/articles/72000611921';
