import { i18n } from 'i18n';
import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { ProfileCommonStatusDetails } from '../ProfileStatus.types';

function serializerV1(details: ProfileCommonStatusDetails) {
  const { payload_identifier, payload_uuid, profile_uuid, profile_name } =
    details;

  return new LibraryItemStatusLog()
    .addRow(i18n.t('Profile appears to be missing:'))
    .addRow(i18n.t('Profile Name'), profile_name)
    .addRow(i18n.t('Profile UUID'), profile_uuid)
    .addRow(i18n.t('Payload Identifier'), payload_identifier)
    .addRow(i18n.t('Payload UUID'), payload_uuid)
    .addRow(i18n.t('Attempting to re-install the profile...'))
    .toString();
}

export const MissingProfileAuditSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
