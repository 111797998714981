import { Button, setClass } from '@kandji-inc/bumblebee';
import { i18n } from 'i18n';
import { bool, func, object, string } from 'prop-types';

import HubSpotHandler from 'components/common/hubspot-handler';

const Actions = (props) => {
  const {
    className,
    isEditable,
    onBack,
    onCancel,
    onEdit,
    onSave,
    pageState,
    style,
  } = props;

  return (
    <>
      <HubSpotHandler />
      <div style={style} className={setClass('b-library__actions', className)}>
        <div className="b-library__actions-inner">
          <div className="b-library__actions-primary b-grid-ctas">
            {pageState.isEditing ? (
              <Button
                isDisabled={pageState.isSaving}
                kind="outline"
                onClick={onCancel}
              >
                {i18n.t('Cancel')}
              </Button>
            ) : (
              <Button kind="outline" onClick={onBack}>
                {i18n.t('Back')}
              </Button>
            )}
            {isEditable &&
              (pageState.isEditing ? (
                <Button onClick={onSave} disabled={!pageState.isValid}>
                  {i18n.t('Save')}
                </Button>
              ) : (
                <Button onClick={onEdit}>{i18n.t('Edit')}</Button>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

Actions.propTypes = {
  className: string,
  isEditable: bool,
  onBack: func.isRequired,
  onCancel: func.isRequired,
  onEdit: func.isRequired,
  onSave: func.isRequired,
  pageState: object.isRequired,
  style: object,
};

Actions.defaultProps = {
  style: {},
  className: null,
  isEditable: true,
};

export default Actions;
