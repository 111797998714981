export const Zoom = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3259_218)">
        <rect width="40" height="40" rx="20" fill="#3D85FB" />
        <rect width="40.1226" height="40.1226" rx="10" fill="#3D85FB" />
        <path
          d="M24.2364 25.203C24.2364 25.3877 24.0867 25.5374 23.9021 25.5374H12.0464C10.6998 25.5374 9.6084 24.4459 9.6084 23.0994V14.4944C9.6084 14.3097 9.75809 14.16 9.94275 14.16H21.7984C23.145 14.16 24.2364 15.2514 24.2364 16.598V25.203Z"
          fill="white"
        />
        <path
          d="M30.7374 24.9126C30.7374 25.1797 30.4398 25.3389 30.2176 25.1908L26.0103 22.3859C25.9173 22.3239 25.8614 22.2195 25.8614 22.1077V17.5896C25.8614 17.4778 25.9173 17.3734 26.0103 17.3114L30.2176 14.5066C30.4398 14.3584 30.7374 14.5177 30.7374 14.7848V24.9126Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3259_218">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ZoomSmall = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1814_1734)">
        <rect width="24" height="24" rx="12" fill="#3D85FB" />
        <rect width="24.0736" height="24.0736" rx="12" fill="#3D85FB" />
        <path
          d="M14.5415 15.1219C14.5415 15.2327 14.4516 15.3225 14.3409 15.3225H7.22745C6.4195 15.3225 5.76465 14.6677 5.76465 13.8597V8.69671C5.76465 8.58591 5.85447 8.49609 5.96526 8.49609H13.0787C13.8866 8.49609 14.5415 9.15094 14.5415 9.9589V15.1219Z"
          fill="white"
        />
        <path
          d="M18.4421 14.9477C18.4421 15.1079 18.2635 15.2035 18.1302 15.1146L15.6058 13.4317C15.55 13.3945 15.5164 13.3318 15.5164 13.2647V10.5539C15.5164 10.4868 15.55 10.4241 15.6058 10.3869L18.1302 8.70402C18.2635 8.61514 18.4421 8.71071 18.4421 8.87094V14.9477Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1814_1734">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
