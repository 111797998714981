import { i18n } from 'i18n';

import SafariDownload_de from './1/1/SafariDownload_de.webp';
import SafariDownload_en_US from './1/1/SafariDownload_en_US.webp';
import SafariDownload_es_419 from './1/1/SafariDownload_es_419.webp';
import SafariDownload_ja_JP from './1/1/SafariDownload_ja_JP.webp';

import FinderDownloadsFolder_de from './1/2/FinderDownloadsFolder_de.webp';
import FinderDownloadsFolder_en_US from './1/2/FinderDownloadsFolder_en_US.webp';
import FinderDownloadsFolder_es_419 from './1/2/FinderDownloadsFolder_es_419.webp';
import FinderDownloadsFolder_ja_JP from './1/2/FinderDownloadsFolder_ja_JP.webp';

import ProfileDownloadedPopup_de from './1/3/ProfileDownloadedPopup_de.webp';
import ProfileDownloadedPopup_en_US from './1/3/ProfileDownloadedPopup_en_US.webp';
import ProfileDownloadedPopup_es_419 from './1/3/ProfileDownloadedPopup_es_419.webp';
import ProfileDownloadedPopup_ja_JP from './1/3/ProfileDownloadedPopup_ja_JP.webp';

import SystemSettingsProfileNotInstalled_de from './2/1/SystemSettingsProfileNotInstalled_de.webp';
import SystemSettingsProfileNotInstalled_en_US from './2/1/SystemSettingsProfileNotInstalled_en_US.webp';
import SystemSettingsProfileNotInstalled_es_419 from './2/1/SystemSettingsProfileNotInstalled_es_419.webp';
import SystemSettingsProfileNotInstalled_ja_JP from './2/1/SystemSettingsProfileNotInstalled_ja_JP.webp';

import SystemSettingsReviewProfile_de from './2/2/SystemSettingsReviewProfile_de.webp';
import SystemSettingsReviewProfile_en_US from './2/2/SystemSettingsReviewProfile_en_US.webp';
import SystemSettingsReviewProfile_es_419 from './2/2/SystemSettingsReviewProfile_es_419.webp';
import SystemSettingsReviewProfile_ja_JP from './2/2/SystemSettingsReviewProfile_ja_JP.webp';

import SystemSettingsProfileInstalled_de from './2/3/SystemSettingsProfileInstalled_de.webp';
import SystemSettingsProfileInstalled_en_US from './2/3/SystemSettingsProfileInstalled_en_US.webp';
import SystemSettingsProfileInstalled_es_419 from './2/3/SystemSettingsProfileInstalled_es_419.webp';
import SystemSettingsProfileInstalled_ja_JP from './2/3/SystemSettingsProfileInstalled_ja_JP.webp';

import AgentMenuBar_de from './3/1/AgentMenuBar_de.webp';
import AgentMenuBar_en_US from './3/1/AgentMenuBar_en_US.webp';
import AgentMenuBar_es_419 from './3/1/AgentMenuBar_es_419.webp';
import AgentMenuBar_ja_JP from './3/1/AgentMenuBar_ja_JP.webp';

export const INSTRUCTION_STEPS = {
  1: {
    1: {
      [i18n.LOCALES.en_US]: SafariDownload_en_US,
      [i18n.LOCALES.de]: SafariDownload_de,
      [i18n.LOCALES.ja_JP]: SafariDownload_ja_JP,
      [i18n.LOCALES.es_419]: SafariDownload_es_419,
    },
    2: {
      [i18n.LOCALES.en_US]: FinderDownloadsFolder_en_US,
      [i18n.LOCALES.de]: FinderDownloadsFolder_de,
      [i18n.LOCALES.ja_JP]: FinderDownloadsFolder_ja_JP,
      [i18n.LOCALES.es_419]: FinderDownloadsFolder_es_419,
    },
    3: {
      [i18n.LOCALES.en_US]: ProfileDownloadedPopup_en_US,
      [i18n.LOCALES.de]: ProfileDownloadedPopup_de,
      [i18n.LOCALES.ja_JP]: ProfileDownloadedPopup_ja_JP,
      [i18n.LOCALES.es_419]: ProfileDownloadedPopup_es_419,
    },
  },
  2: {
    1: {
      [i18n.LOCALES.en_US]: SystemSettingsProfileNotInstalled_en_US,
      [i18n.LOCALES.de]: SystemSettingsProfileNotInstalled_de,
      [i18n.LOCALES.ja_JP]: SystemSettingsProfileNotInstalled_ja_JP,
      [i18n.LOCALES.es_419]: SystemSettingsProfileNotInstalled_es_419,
    },
    2: {
      [i18n.LOCALES.en_US]: SystemSettingsReviewProfile_en_US,
      [i18n.LOCALES.de]: SystemSettingsReviewProfile_de,
      [i18n.LOCALES.ja_JP]: SystemSettingsReviewProfile_ja_JP,
      [i18n.LOCALES.es_419]: SystemSettingsReviewProfile_es_419,
    },
    3: {
      [i18n.LOCALES.en_US]: SystemSettingsProfileInstalled_en_US,
      [i18n.LOCALES.de]: SystemSettingsProfileInstalled_de,
      [i18n.LOCALES.ja_JP]: SystemSettingsProfileInstalled_ja_JP,
      [i18n.LOCALES.es_419]: SystemSettingsProfileInstalled_es_419,
    },
  },
  3: {
    1: {
      [i18n.LOCALES.en_US]: AgentMenuBar_en_US,
      [i18n.LOCALES.de]: AgentMenuBar_de,
      [i18n.LOCALES.ja_JP]: AgentMenuBar_ja_JP,
      [i18n.LOCALES.es_419]: AgentMenuBar_es_419,
    },
  },
};

const validLocales = [
  i18n.LOCALES.en_US,
  i18n.LOCALES.de,
  i18n.LOCALES.ja_JP,
  i18n.LOCALES.es_419,
];

export function getInstructionStepImage(step, substep, locale) {
  let localeToUse = locale;

  if (!validLocales.includes(locale)) {
    localeToUse = i18n.LOCALES.en_US;
  }

  return INSTRUCTION_STEPS[step][substep][localeToUse];
}
