import {
  type CSS,
  Flex,
  Icon,
  Link,
  Text,
  Tooltip,
  styled,
} from '@kandji-inc/nectar-ui';
import { links } from 'src/app/common/constants';
import type { ApplicationVersion } from 'src/features/edr/vulnerability/vulnerability.types';
import { i18n } from 'src/i18n';

type ApplicationVersionItemProps = {
  applicationVersion: ApplicationVersion;
  css?: CSS;
};

const DetailText = styled(Text, {
  fontSize: '$1',
  color: '$neutral70',
});

const ApplicationVersionItem = (props: ApplicationVersionItemProps) => {
  const { applicationVersion, css = {} } = props;
  const {
    latest_detection_date: latestDetectionDate,
    blueprints,
    version,
    devices_impacted: devicesImpacted,
  } = applicationVersion;

  return (
    <Flex flow="column" css={{ gap: '10px', paddingRight: '40px', ...css }}>
      <Flex gap="lg">
        <Flex alignItems="center" gap="xs" css={{ width: '37%' }}>
          <DetailText>{i18n.t('Last detected:')}</DetailText>
          <Text size="1">
            {i18n.format.datetime(new Date(latestDetectionDate))}
          </Text>
        </Flex>

        <Flex alignItems="center" gap="xs" css={{ width: '37%' }}>
          <DetailText>{i18n.t('Blueprint:')}</DetailText>
          <Flex alignItems="center">
            <Link
              href={`${links.blueprints}/${blueprints[0].id}`}
              target="_blank"
              rel="noopener noreferrer"
              variant="subtle"
            >
              <Text
                css={{
                  fontSize: '$1',
                  fontWeight: '$medium',
                  paddingLeft: '2px',
                  maxWidth: blueprints?.length > 1 ? '150px' : '171px', // to account for the "+X"
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {blueprints[0].name}
              </Text>
            </Link>
            {blueprints.length > 1 && (
              <>
                <Text css={{ fontSize: '$1' }}>,</Text>
                <Tooltip
                  side="bottom"
                  theme="light"
                  interactive
                  css={{ zIndex: 2, padding: '$3 $4', maxHeight: '190px' }}
                  content={
                    <Flex
                      flow="column"
                      css={{
                        width: '156px',
                        gap: '6px',
                      }}
                    >
                      {blueprints.slice(1).map((bp) => (
                        <Flex alignItems="center">
                          <Icon
                            name="kandji-blueprint"
                            size="xs"
                            color="var(--colors-neutral80)"
                          />
                          <Link
                            href={`${links.blueprints}/${bp.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="subtle"
                          >
                            <Text
                              css={{
                                fontWeight: '$medium',
                                paddingLeft: '2px',
                              }}
                            >
                              {bp.name}
                            </Text>
                          </Link>
                        </Flex>
                      ))}
                    </Flex>
                  }
                >
                  <Text
                    css={{ fontSize: '$1', paddingLeft: '$1' }}
                  >{`+${i18n.format.number(blueprints.length - 1)}`}</Text>
                </Tooltip>
              </>
            )}
          </Flex>
        </Flex>

        <Flex alignItems="center" gap="xs">
          <DetailText>{i18n.t('Version:')}</DetailText>
          <Text size="1">{version}</Text>
        </Flex>
      </Flex>

      <Flex>
        <Flex alignItems="center" gap="xs" css={{ width: '37%' }}>
          <DetailText>{i18n.t('Devices impacted:')}</DetailText>
          <Text size="1">{i18n.format.number(devicesImpacted)}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export { ApplicationVersionItem };
