import {
  type ForwardRefExoticComponent,
  createContext,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

export const InfiniteScrollContext = createContext({
  scrollCallback: () => null,
  lastItemRef: null,
});

interface InfiniteScrollProviderProps {
  children: React.ReactNode;
  scrollCallback: () => void;
}

export const InfiniteScrollProvider: ForwardRefExoticComponent<InfiniteScrollProviderProps> =
  forwardRef(({ children, scrollCallback }, ref) => {
    const lastItemRef = useRef();

    useImperativeHandle(ref, () => lastItemRef.current);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.map((entry) => {
            if (entry.isIntersecting) {
              scrollCallback();
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.5 },
      );

      if (lastItemRef?.current) {
        observer.observe(lastItemRef.current);
      }

      return () => {
        if (lastItemRef?.current) {
          observer.unobserve(lastItemRef.current);
        }
      };
    }, [scrollCallback]);

    return (
      <InfiniteScrollContext.Provider value={{ scrollCallback, lastItemRef }}>
        {children}
      </InfiniteScrollContext.Provider>
    );
  });
