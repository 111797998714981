/* istanbul ignore file */
import PropTypes from 'prop-types';
import React from 'react';
import {
  Activity,
  AppStore,
  AzureActiveDirectory,
  Bug,
  BugCrossed,
  CalendarArrow,
  Chat,
  Check,
  ChevronDoubleDown,
  ChevronDoubleUp,
  ClipboardListCheck,
  CustomSAML,
  Desktop,
  Ellipsis,
  EmptyBeehive,
  Envelope,
  ErrorCircle,
  ExclamationCircle,
  Eye,
  EyeSlash,
  FileCopy,
  FileVault,
  Files,
  Firewall,
  Folder,
  Gatekeeper,
  GoogleWorkspace,
  Help,
  Information,
  Integrations,
  KandjiCircle,
  KandjiHive,
  KandjiSelfService,
  LightBulb,
  LockWithKey,
  MagnifyingGlass,
  MediaAccess,
  Notifications,
  Pencil,
  Pin,
  PlusCircle,
  PlusCircleHollow,
  Printer,
  ScreenSaver,
  Script,
  Settings,
  Shield,
  SoftwareUpdates,
  Squares,
  Star,
  Sync,
  SystemPreferences,
  Times,
  TimesCircleHollow,
  Tools,
  Trash,
  Users,
  VulnBug,
} from '../../images/icon';

const icons = {
  'app-store': <AppStore />,
  'calendar-arrow': <CalendarArrow />,
  'empty-beehive': <EmptyBeehive />,
  'eye-slash': <EyeSlash />,
  'error-circle': <ErrorCircle />,
  'file-copy': <FileCopy />,
  'exclamation-circle': <ExclamationCircle />,
  'light-bulb': <LightBulb />,
  'magnifying-glass': <MagnifyingGlass />,
  'media-access': <MediaAccess />,
  'plus-circle': <PlusCircle />,
  'plus-circle-hollow': <PlusCircleHollow />,
  'screen-saver': <ScreenSaver />,
  'software-updates': <SoftwareUpdates />,
  'system-preferences': <SystemPreferences />,
  'times-circle-hollow': <TimesCircleHollow />,
  'kandji-circle': <KandjiCircle />,
  'kandji-hive': <KandjiHive />,
  'kandji-self-service': <KandjiSelfService />,
  'azure-active-directory': <AzureActiveDirectory />,
  'custom-saml': <CustomSAML />,
  'google-workspace': <GoogleWorkspace />,
  activity: <Activity />,
  chat: <Chat />,
  check: <Check />,
  desktop: <Desktop />,
  envelope: <Envelope />,
  ellipsis: <Ellipsis />,
  eye: <Eye />,
  files: <Files />,
  filevault: <FileVault />,
  firewall: <Firewall />,
  folder: <Folder />,
  gatekeeper: <Gatekeeper />,
  help: <Help />,
  information: <Information />,
  notifications: <Notifications />,
  pencil: <Pencil />,
  pin: <Pin />,
  printer: <Printer />,
  script: <Script />,
  settings: <Settings />,
  squares: <Squares />,
  star: <Star />,
  sync: <Sync />,
  times: <Times />,
  tools: <Tools />,
  trash: <Trash />,
  users: <Users />,
  'lock-with-key': <LockWithKey />,
  'chevron-double-down': <ChevronDoubleDown />,
  'chevron-double-up': <ChevronDoubleUp />,
  integrations: <Integrations />,
  bug: <Bug />,
  'bug-crossed': <BugCrossed />,
  'vuln-bug': <VulnBug />,
  shield: <Shield />,
  'clipboard-list-check': <ClipboardListCheck />,
};

const Icon = ({ icon = 'default', className = '', ...props }) => (
  <i className={`icon icon-${icon} ${className}`} {...props}>
    {icons[icon]}
  </i>
);

Icon.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
