import { Banner } from '@kandji-inc/bumblebee';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { setSnackbar } from 'src/app/_actions/ui';
import { links } from 'src/app/common/constants';
import useLostMode from 'src/hooks/useLostMode';

import { i18n } from 'i18n';
import refreshLostModeData from './refreshLostModeData';

function LostModeDisableStatus(props) {
  const { computer } = props;
  const dispatch = useDispatch();
  const {
    isLostModeDisablePending,
    isLostModeDisableErrored,
    lostModeDisableDatetime,
  } = useLostMode(computer);
  const match = useRouteMatch();

  const handleRefresh = (e) => {
    e.preventDefault();
    refreshLostModeData({ computer }, dispatch);
    dispatch(setSnackbar(i18n.t('Page refreshed')));
  };

  if (isLostModeDisablePending && lostModeDisableDatetime) {
    return (
      <Banner kind="block" theme="warning" className="lost-mode__status-banner">
        <div>
          Disable Lost Mode command initiated at{' '}
          {i18n.format.datetime(lostModeDisableDatetime)}.{' '}
          <button
            onClick={handleRefresh}
            className="lost-mode__warning-refresh"
            type="button"
          >
            {' '}
            Refresh
          </button>{' '}
          to see change.
        </div>
      </Banner>
    );
  }

  if (isLostModeDisableErrored) {
    return (
      <Banner kind="block" theme="error" className="lost-mode__status-banner">
        <div>
          Lost Mode could not be disabled. See
          <Link
            className="lost-mode__activity-link"
            to={`${links.devices}/${match.params.id}/activity`}
          >
            Activity
          </Link>
          for details
        </div>
      </Banner>
    );
  }
  return null;
}

export default LostModeDisableStatus;
