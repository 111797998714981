import { Chip, Flex, MultiSelect, Text } from '@kandji-inc/nectar-ui';
import React from 'react';

import { useGetTagsInfinite } from 'src/features/tags';
import { useIntersectionObserver } from 'src/hooks/useIntersection';
import { i18n } from 'src/i18n';

type TagsDropdownProps = {
  tagsLimit?: number;
  onTagsSelect: (tags: Array<string>) => void;
  tags: Array<string>;
  label?: string;
  placeholder?: string;
};

const TagsDropdown = (props: TagsDropdownProps) => {
  const {
    tagsLimit = 50,
    onTagsSelect,
    tags,
    label = i18n.t('Tags'),
    placeholder = i18n.t('Enter tags'),
  } = props;
  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
  });

  const [searchTerm, setSearchTerm] = React.useState('');
  const [options, setOptions] = React.useState(null);

  const {
    data: paginatedTags,
    fetchNextPage,
    isFetching,
    hasNextPage,
  } = useGetTagsInfinite(searchTerm, tagsLimit);
  const data = React.useMemo(
    () => paginatedTags?.pages.map((page) => page.data.results).flat(),
    [paginatedTags],
  );

  const [anyTagsInSystem, setAnyTagsInSystem] = React.useState(
    Boolean(tags?.length > 0),
  );

  const onCreateTag = (tag: string) => {
    onTagsSelect([...tags, tag.trim()]);
    setSearchTerm('');
  };

  React.useEffect(() => {
    if (data) {
      setOptions(
        data?.map(({ name }, idx) => ({
          label: name,
          value: name,
          richLabel: (
            <Chip
              ref={idx === data.length - 1 ? ref : undefined}
              label={name}
            />
          ),
        })) || [],
      );

      if (!searchTerm) {
        setAnyTagsInSystem(Boolean(data?.length > 0));
      }
    }
  }, [data]);

  React.useEffect(() => {
    /* istanbul ignore next */
    if (hasNextPage && isIntersecting && !isFetching) {
      fetchNextPage();
    }
  }, [isIntersecting]);

  return (
    <MultiSelect
      label={label}
      value={tags}
      onChange={onTagsSelect}
      options={options}
      placeholder={placeholder}
      searchable
      searchFn={setSearchTerm}
      hideNoOptionsFoundMessage={true}
      creatable={{
        // `active` is false to prevent default creatable behavior (create on Tab, etc)
        active: false,
        onCreate: onCreateTag,
        showMenuMessage: searchTerm?.trim() !== '',
        maxLength: 50,
        ignoredKeys: [','],
        customMenuMessage: (searchInput) => (
          <Flex alignItems="center" css={{ gap: '$1' }}>
            <Text>{i18n.t('Create')}</Text>
            <Chip label={searchInput} />
          </Flex>
        ),
      }}
      customHeader={
        <Text variant="description" size="1" css={{ padding: '6px 12px' }}>
          {anyTagsInSystem
            ? i18n.t('Select a tag or create new')
            : i18n.t('Type to create a tag')}
        </Text>
      }
      componentCss={{
        label: { fontSize: '14px' },
        trigger: { width: '500px' },
        menu: { width: '500px' },
        valueContainer: { maxWidth: '500px' },
      }}
    />
  );
};

export default TagsDropdown;
