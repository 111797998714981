// istanbul ignore file
import {
  Flex,
  Grid,
  Heading,
  Icon,
  Text,
  Tooltip,
  TooltipProvider,
  styled,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import { useEffect, useRef, useState } from 'react';
import { constants } from '../../common';
import { OverflowingChipList } from '../../common/components/OverflowingChipList';
import ThreatListStatusBadge from '../../common/components/ThreatListStatusBadge';
import ThreatListTextCopyButton from '../../common/components/ThreatListTextCopyButton';
import type {
  BehavioralThreatClassification,
  BehavioralThreatDetail,
  BehavioralThreatStatus,
} from '../../threat.types';

const SplitViewClassificationIcon = styled(Flex, {
  width: 40,
  height: 40,
  padding: 6,
  borderRadius: '50%',
  svg: { width: 26, height: 26 },
  justifyContent: 'center',
  alignItems: 'center',
});

const ThreatNameHeading = styled(Heading, {
  fontSize: 20,
  lineHeight: '32px',
  letterSpacing: '-0.8px',
  fontWeight: '$medium',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 220,
});

const SummaryDetails = styled(Flex, {
  gap: '$3',
  alignItems: 'start',
  width: '112%',
});

const StyledGrid = styled(Grid, {
  fontSize: '$1',
  color: '$neutral70',
  gap: 6,
  gridTemplateColumns: '126px 1fr',
  flex: 1,
  alignItems: 'stretch',
  gridAutoRows: '18px',
});

const GridCell = styled(Flex, {
  gap: '$1',
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

const getClassificationLabel = (
  classification: BehavioralThreatClassification,
) => constants.THREAT_CLASSIFICATION_LABELS(classification);

const getClassifactionIcon = (classification: BehavioralThreatClassification) =>
  constants.THREAT_CLASSIFICATION_ICON_MAP[classification];

const getClassifactionColor = (
  classification: BehavioralThreatClassification,
) => constants.THREAT_CLASSIFICATION_COLOR[classification];

const getStatusLabel = (status: BehavioralThreatStatus) =>
  constants.THREAT_STATUS_DISPLAY_NAME_MAP(status);

const getStatusIcon = (status: BehavioralThreatStatus) =>
  constants.THREAT_STATUS_ICON_MAP[status];

const getStatusColor = (status: BehavioralThreatStatus) =>
  constants.THREAT_STATUS_COLOR_MAP[status];

type ThreatListSplitViewProps = {
  threat: BehavioralThreatDetail;
  tableSearchQuery: string | undefined;
  onReleaseThreat: () => void;
  onRecheckStatus: (threat: BehavioralThreatDetail) => void;
  isThreatBeingChecked: boolean;
};

const ThreatListSplitViewHeader = (props: ThreatListSplitViewProps) => {
  const { threat, tableSearchQuery } = props;
  const color = getClassifactionColor(threat.classification);

  const [isDescriptionOverflowing, setIsDescriptionOverflowing] =
    useState(false);
  const descriptionTextRef = useRef<HTMLDivElement>(null);

  useEffect(
    /* istanbul ignore next */ () => {
      if (descriptionTextRef.current) {
        setIsDescriptionOverflowing(
          descriptionTextRef.current.scrollWidth >
            descriptionTextRef.current.clientWidth,
        );
      }
    },
    [],
  );

  return (
    <Flex flow="column" gap="xl" wFull>
      <Flex
        flow="row"
        css={{
          alignItems: 'center',
          gap: '$2',
          justifyContent: 'space-between',
        }}
      >
        <SplitViewClassificationIcon css={{ bg: `$${color}10` }}>
          <Icon
            color={`var(--color-${color}-60)`}
            name={getClassifactionIcon(threat.classification)}
          />
        </SplitViewClassificationIcon>
        <Flex flex={1} flow="column" justifyContent="start" alignItems="start">
          <ThreatNameHeading as="h3">
            <ThreatListTextCopyButton
              text={threat.threat_id}
              tooltip={threat.threat_id}
            />
          </ThreatNameHeading>
          <Text variant="description">
            {getClassificationLabel(threat.classification)}
          </Text>
        </Flex>
      </Flex>
      <SummaryDetails>
        <StyledGrid>
          <GridCell>
            <Text variant="description">{i18n.t('Threat status')}</Text>
          </GridCell>
          <GridCell>
            <ThreatListStatusBadge
              color={getStatusColor(threat.threat_status)}
              icon={getStatusIcon(threat.threat_status)}
            >
              {getStatusLabel(threat.threat_status)}
            </ThreatListStatusBadge>
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Detected on')}</Text>
          </GridCell>
          <GridCell>{i18n.format.datetime(threat.detection_date)}</GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Description')}</Text>
          </GridCell>
          <GridCell>
            <Text
              ref={descriptionTextRef}
              css={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: '224px',
              }}
            >
              {threat.description?.length ? threat.description : '-'}
            </Text>

            {/* istanbul ignore next */}
            {isDescriptionOverflowing && (
              <TooltipProvider>
                <Tooltip
                  side="bottom"
                  theme="light"
                  align="end"
                  maxWidth="316px"
                  content={
                    <Flex flow="column" gap="xs">
                      <Text variant="description">{i18n.t('Description')}</Text>
                      <Text>{threat.description}</Text>
                    </Flex>
                  }
                  css={{ zIndex: 100, padding: '$3 $4' }}
                >
                  <Text variant="primary">{i18n.t('see more')}</Text>
                </Tooltip>
              </TooltipProvider>
            )}
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Rule version')}</Text>
          </GridCell>
          <GridCell>{threat.rule_version}</GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Malware family')}</Text>
          </GridCell>
          <GridCell>
            {threat.malware_families?.length ? (
              <OverflowingChipList
                chips={threat?.malware_families}
                containerWidth={266}
                searchTerm={tableSearchQuery}
              />
            ) : (
              '-'
            )}
          </GridCell>

          <GridCell>
            <Text variant="description">{i18n.t('Informational tags')}</Text>
          </GridCell>
          <GridCell>
            {threat.informational_tags?.length ? (
              <OverflowingChipList
                chips={threat.informational_tags}
                containerWidth={266}
              />
            ) : (
              '-'
            )}
          </GridCell>
        </StyledGrid>
      </SummaryDetails>
    </Flex>
  );
};

export default ThreatListSplitViewHeader;
