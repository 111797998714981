import { ProfileStatusTypes } from '../ProfileStatus.types';
import { BlockedProfileAuditSerializers } from './BlockedProfileAuditLogSerializers';
import { ExcludedProfileAuditLogSerializers } from './ExcludedProfileAuditLogSerializers';
import { InstallProfileFailureLogSerializers } from './InstallProfileFailureLogSerializer';
import { InstallProfileSuccessAuditSerializers } from './InstallProfileSuccessAuditSerializers';
import { InstallProfileSuccessLogSerializers } from './InstallProfileSuccessLogSerializer';
import { MissingProfileAuditSerializers } from './MissingProfileAuditLogSerializers';
import { PendingInstallAuditSerializers } from './PendingInstallProfileAuditLogSerializers';

export const ProfileStatusSerializers = {
  [ProfileStatusTypes.INSTALL_PROFILE_SUCCESS_AUDIT]:
    InstallProfileSuccessAuditSerializers,
  [ProfileStatusTypes.INSTALL_PROFILE_SUCCESS]:
    InstallProfileSuccessLogSerializers,
  [ProfileStatusTypes.INSTALL_PROFILE_FAILURE]:
    InstallProfileFailureLogSerializers,
  [ProfileStatusTypes.PROFILE_BLOCKED_AUDIT]: BlockedProfileAuditSerializers,
  [ProfileStatusTypes.PROFILE_EXCLUDED_AUDIT]:
    ExcludedProfileAuditLogSerializers,
  [ProfileStatusTypes.PROFILE_MISSING_AUDIT]: MissingProfileAuditSerializers,
  [ProfileStatusTypes.INSTALL_PROFILE_PENDING_AUDIT]:
    PendingInstallAuditSerializers,
};
