/* istanbul ignore file */
import { i18n } from 'src/i18n';
import NewLibraryItemService from '../../../data-service/library-item/new-library-item-service';

class AppBlockingService extends NewLibraryItemService {
  static getMatchKinds = () => [
    { label: i18n.t('Contains'), value: 'contains' },
    { label: i18n.t('Exact'), value: 'exact' },
    { label: i18n.t('Regex'), value: 'regex' },
  ];
}

export const appBlockingService = new AppBlockingService();

export default AppBlockingService;
