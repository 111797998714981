import {
  Button,
  ButtonGroup,
  Chip,
  Toggle,
  setClass,
} from '@kandji-inc/bumblebee';
import { KandjiSetupCtx } from 'features/library-items/items/kandji-setup/kandji-setup.context';
import React, { useState, useContext } from 'react';

import { i18n } from 'src/i18n';
import useCompanyLogo from '../hooks/use-company-logo.hook';
import KandjiLogo from '../kandji-logo';
import setPreviewImage, {
  previewPaths,
  success as successIconsPath,
} from '../set-preview-image';
import ToggleButton from '../toggle-button';
import LinkPreviews from './link-previews';
import './success-screen.css';
import { Flex, Text } from '@kandji-inc/nectar-ui';
import { Screen } from '../components/mac-screen';
import SuccessSidePanel from './success-side-panel';

const SuccessScreen = (props) => {
  const {
    isDisabled,
    isDarkMode,
    setIsDarkMode,
    setGlobalValidations,
    itemId,
  } = useContext(KandjiSetupCtx);
  const { model, setModel, initialStateCopy, beforeEditModel } = props;
  const [isExpanded, setIsExpanded] = useState(true);
  const isEnabled = model.successScreen.isActive;

  const setIsEnabled = () => {
    setModel((p) => ({
      ...p,
      successScreen: {
        ...p.successScreen,
        isActive: !isEnabled,
      },
    }));
  };

  const onValidations = (v) => {
    setGlobalValidations((p) => ({ ...p, [v.name]: v.isInvalid }));
  };

  const companyLogo = useCompanyLogo({ model, isDarkMode });

  return (
    <div className="b-library__setting-card" style={{ marginBottom: '97px' }}>
      <div className="b-library__setting-card--title b-flex-btw b-flex-vc">
        <h3 className="b-h3">{i18n.t(`Complete screen`)}</h3>
        <div className="b-flex">
          <Chip
            className={setClass([
              'b-txt-ctrl8',
              isDisabled ? 'b-chip-active-md--disabled' : '',
            ])}
            kind={isEnabled ? 'active' : 'inactive'}
            text={isEnabled ? i18n.t('Enabled') : i18n.t('Disabled')}
          />{' '}
          <Toggle
            disabled={isDisabled}
            style={{ marginLeft: 'var(--b-gap-tiny)' }}
            checked={isEnabled}
            onToggle={setIsEnabled}
          />
        </div>
      </div>
      <p className="b-txt b-library__setting-card--subtitle">
        {i18n.t(`Customize the header and subheader on the Complete screen. Add links and
        resources to help your users get started on their new devices.`)}
      </p>
      <div
        className={setClass([
          'b-library__setting-rows',
          !isEnabled ? 'k-library__setting-rows--collapsed' : '',
        ])}
      >
        <div className="b-library__setting-row--single-col">
          <div className="b-flex-vc b-flex-btw">
            <div className="b-flex-g b-flex-vc">
              <SuccessSidePanel
                itemId={itemId}
                disabled={isDisabled}
                model={model}
                beforeEditModel={beforeEditModel}
                setModel={setModel}
                initialStateCopy={initialStateCopy}
                isDarkMode={isDarkMode}
                onValidations={onValidations}
              />
              <Button
                onClick={() => setIsExpanded((p) => !p)}
                icon={isExpanded ? 'angle-up' : 'angle-down'}
                kind="link"
                size="small"
              >
                {isExpanded
                  ? i18n.t('Collapse preview')
                  : i18n.t('Expand preview')}
              </Button>
            </div>
            <div className={setClass('k-ks-toggle', !isExpanded && '--hidden')}>
              <ButtonGroup
                initialSelected={isDarkMode ? 1 : 0}
                activeWatch={isDarkMode ? 1 : 0}
                onToggle={(index) => setIsDarkMode(index !== 0)}
              >
                <ToggleButton
                  className="b-txt k-ks-dark-blue"
                  onClick={() => setIsDarkMode(false)}
                >
                  {i18n.t(`Light Mode`)}
                </ToggleButton>
                <ToggleButton
                  className="b-txt k-ks-dark-blue"
                  onClick={() => setIsDarkMode(true)}
                >
                  {i18n.t(`Dark Mode`)}
                </ToggleButton>
              </ButtonGroup>
            </div>
          </div>
          <div
            className={setClass([
              'k-library-preview',
              !isExpanded ? 'k-library-preview--collapsed' : '',
            ])}
          >
            <div className="k-library-preview__bg">
              <img
                draggable="false"
                src={setPreviewImage({
                  isDarkMode,
                  displayMode: model.general.displayMode,
                })}
                alt={i18n.t('Preview')}
              />
              <Screen.Root
                isDarkMode={isDarkMode}
                model={model}
                companyLogo={companyLogo}
              >
                <Screen.Header>
                  <img
                    draggable="false"
                    src={companyLogo.src}
                    alt={i18n.t('Company Logo')}
                  />
                </Screen.Header>
                <Screen.Body>
                  {({ isDarkMode }) => {
                    return (
                      <Flex flow="column" alignItems="center">
                        <img
                          draggable="false"
                          className="k-ks-success-icons"
                          src={
                            isDarkMode
                              ? successIconsPath.successIconsDark
                              : successIconsPath.successIcons
                          }
                          alt={i18n.t('Success')}
                        />
                        <Text
                          css={{
                            fontFamily: 'SF Pro Display',
                            fontWeight: 700,
                            fontSize: '26px',
                            lineHeight: '32px',
                            color: isDarkMode
                              ? 'rgba(255,255,255, 0.85)'
                              : 'rgba(0, 0, 0, 0.85)',
                          }}
                        >
                          {model.successScreen.header}
                        </Text>
                        <Text
                          css={{
                            whiteSpace: 'pre-wrap',
                            fontSize: '13px',
                            lineHeight: '16px',
                            fontFamily: 'SF Pro Text',
                            padding: '0px 145px',
                            color: isDarkMode
                              ? 'rgba(255,255,255, 0.85)'
                              : 'rgba(0, 0, 0, 0.85)',
                          }}
                        >
                          {model.successScreen.subHeader}
                        </Text>
                        <LinkPreviews
                          isEditing={false}
                          isDarkMode={isDarkMode}
                          model={model}
                          setModel={setModel}
                        />
                      </Flex>
                    );
                  }}
                </Screen.Body>
                <Screen.Footer>
                  <Flex css={{ width: '100%' }} justifyContent="end">
                    <Screen.FooterButton>{i18n.t('Quit')}</Screen.FooterButton>
                  </Flex>
                </Screen.Footer>
              </Screen.Root>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessScreen;
