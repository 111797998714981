import { Chip } from '@kandji-inc/nectar-ui';
import { i18n } from 'src/i18n';

const chipStyledCss = {
  padding: '1px 5px',
  display: 'flex',
};

const ThreatListDevicesCountChip = ({
  label,
  compact,
}: { label: string; compact?: boolean }) => {
  return (
    <Chip
      label={label}
      color="unfilled"
      iconLeft={{ icon: 'sf-desktop-computer', size: 'sm' }}
      size={compact ? 'compact' : undefined}
      css={chipStyledCss}
      title={i18n.t('Devices impacted')}
    />
  );
};

export default ThreatListDevicesCountChip;
