import {
  Button,
  Toaster_UNSTABLE as Toaster,
  useToast_UNSTABLE as useToast,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';
import { InterfaceContext } from '../interface';
import { exportDataConfigs } from './configs';
import { downloadFile } from './utils';

export type ExportDataType = keyof typeof exportDataConfigs;
type Schema<T extends ExportDataType> =
  (typeof exportDataConfigs)[T]['schemaType'];

export const DataExportContext = React.createContext<{
  isExporting: boolean;
  onCreateExport: (data: Schema<ExportDataType>) => void;
  setExportDataType: (type: ExportDataType) => void;
}>({
  isExporting: false,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: ok
  onCreateExport: /* istanbul ignore next */ () => {},
  // biome-ignore lint/suspicious/noEmptyBlockStatements: ok
  setExportDataType: /* istanbul ignore next */ () => {},
});

export const DataExportProvider = ({
  children,
}: { children: React.ReactNode }) => {
  const [exportId, setExportId] = React.useState<string | null>(null);
  const [exportDataType, setExportDataType] =
    React.useState<ExportDataType>('prism');

  const { sidebarOpened } = React.useContext(InterfaceContext);

  // istanbul ignore next
  const sidebarOffset = sidebarOpened ? 264 : 100;
  const { toast } = useToast();

  if (!exportDataConfigs[exportDataType]) {
    throw new Error(`Unsupported export data type: ${exportDataType}`);
  }

  const {
    mutateAsync: createExport,
    isPending: createExportPending,
    variables,
    isError,
  } = exportDataConfigs[exportDataType]?.useCreateExportMutation();

  const { data: exportData, fetchStatus: exportFetchStatus } =
    exportDataConfigs[exportDataType].useExportQuery(exportId);

  const isExporting =
    createExportPending ||
    exportFetchStatus === 'fetching' ||
    exportId !== null;

  const handleCreateExport = React.useCallback(
    async (data: Schema<ExportDataType>) => {
      const createdExport = await createExport(data);
      setExportId(createdExport.id);
    },
    [createExport],
  );

  React.useEffect(() => {
    // istanbul ignore next
    const handleBeforeUnload = (event) => {
      if (exportData?.status === 'pending') {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [exportData?.status]);

  React.useEffect(() => {
    // istanbul ignore next
    if (
      exportData?.status === 'success' &&
      exportData?.signed_url &&
      exportData?.path
    ) {
      toast({
        open: true,
        duration: 8000,
        title: getToastTitle(exportDataType, variables),
        variant: 'success',
        content: i18n.t('Export complete!'),
        style: {
          left: sidebarOffset,
          position: 'absolute',
          bottom: '12px',
        },
      });
      downloadFile(exportData.signed_url, exportData.path);
      setExportId(null);
    }

    // istanbul ignore next
    if (exportData?.status === 'pending') {
      toast({
        title: getToastTitle(exportDataType, variables),
        open: true,
        duration: Infinity,
        variant: 'default',
        content: i18n.t('Preparing file for download...'),
        style: {
          left: sidebarOffset,
          position: 'absolute',
          bottom: '12px',
        },
      });
    }
  }, [exportData, exportDataType, variables, sidebarOffset, toast]);

  React.useEffect(() => {
    // istanbul ignore next
    if (exportData?.status === 'failed' || isError) {
      const title = getToastTitle(exportDataType, variables);
      toast({
        open: true,
        duration: 8000,
        title,
        variant: 'error',
        content: i18n.t('Export failed!'),
        action: (
          <Button
            variant="primary"
            onClick={() => {
              const payload = getExportPayload(exportDataType, variables);
              handleCreateExport(payload);
              setExportId(null);
            }}
          >
            Retry
          </Button>
        ),
        style: {
          left: sidebarOffset,
          position: 'absolute',
          bottom: '12px',
        },
      });
    }
  }, [
    exportData,
    handleCreateExport,
    variables,
    exportDataType,
    isError,
    sidebarOffset,
    toast,
  ]);

  return (
    <DataExportContext.Provider
      value={{
        isExporting,
        onCreateExport: handleCreateExport,
        setExportDataType,
      }}
    >
      {children}
      <Toaster />
    </DataExportContext.Provider>
  );
};

export const useDataExportContext = () => React.useContext(DataExportContext);

export const getToastTitle = (
  exportDataType: ExportDataType,
  variables: Schema<ExportDataType>,
) => {
  if (!exportDataConfigs[exportDataType]) {
    throw new Error(`Unsupported export data type: ${exportDataType}`);
  }

  return exportDataConfigs[exportDataType].getToastTitle(variables);
};

export const getExportPayload = (
  exportDataType: ExportDataType,
  variables: Schema<ExportDataType>,
): Schema<ExportDataType> => {
  if (!exportDataConfigs[exportDataType]) {
    throw new Error(`Unsupported export data type: ${exportDataType}`);
  }

  return exportDataConfigs[exportDataType].getExportPayload(variables);
};
