import { i18n } from 'src/i18n';

export const dateFormatOptions = [
  { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
  { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
  { label: 'YYYY/MM/DD', value: 'YYYY/MM/DD' },
] as const;

export const displayRelativeDatesOptions = [
  { label: 'No', value: 'disable' },
  { label: 'Yes', value: 'enable' },
] as const;

export const displayWeeklyEmailOptions = [
  { label: 'No', value: false },
  { label: 'Yes', value: true },
] as const;

/* istanbul ignore next */
export const i18nDateFormatOptionMap = i18n.createMap({
  'MM/DD/YYYY': () => i18n.t('MM/DD/YYYY'),
  'DD/MM/YYYY': () => i18n.t('DD/MM/YYYY'),
  'YYYY/MM/DD': () => i18n.t('YYYY/MM/DD'),
});

/* istanbul ignore next */
export const i18nRelativeDatesOptionMap = i18n.createMap({
  No: () => i18n.t('No'),
  Yes: () => i18n.t('Yes'),
});

/* istanbul ignore next */
export const i18nWeeklyEmailOptionMap = i18n.createMap({
  No: () => i18n.t('No'),
  Yes: () => i18n.t('Yes'),
});
