import { Button } from '@kandji-inc/bumblebee';
import { API_BASE } from 'app/api/domains';
import { i18n } from 'i18n';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import IPadImage from '../../../assets/img/enrollment_portal/ipad_kandji.svg';
import { downloadFromUrl } from '../common/helpers';
import { LineLoader } from '../interface/LineLoader';
import HeaderWithLogo from './HeaderWithLogo';
import { downloadFromObjectUrl } from './utils/downloadFromObjectUrl';
import { validateAndDownloadConfig } from './utils/validateAndDownloadConfig';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${document.documentElement.clientWidth};
`;

const ComputerImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const Header = styled.div`
  margin-top: 10px;
  font-family: var(--font-family-primary);
  font-size: 30px;
  font-weight: 500;
  line-height: 39px;
  text-align: center;
  color: #1a1d25;
`;

const HelperText = styled.div`
  margin-top: 10px;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #1a1d25;
`;

const ButtonWrapper = styled.div`
  margin: 24px auto 74px;
`;

class EnrollTabletPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isAccessCodeValid: false,
      selectedBlueprint: null,
      validatedAccessCode: null,
      downloadUrl: null,
    };
    this.buttonRef = React.createRef();
    this.onDownload = this.onDownload.bind(this);
  }

  componentDidMount() {
    const { history, match } = this.props;
    const accessCode = get(match, 'params.accessCode');

    const init = async () => {
      /* istanbul ignore next */
      if (accessCode) {
        let redirected = true;
        try {
          const { downloadUrl, blueprintId, redirect } =
            await validateAndDownloadConfig(accessCode);

          if (redirect) return;

          redirected = false;

          this.setState({
            isAccessCodeValid: true,
            selectedBlueprint: blueprintId,
            validatedAccessCode: accessCode,
            downloadUrl,
          });
        } catch {
          history.push('/enroll');
        } finally {
          if (!redirected) {
            this.setState({ isLoading: false });
          }
        }
      }
    };

    init();
  }

  componentWillUnmount(): void {
    const { downloadUrl } = this.state;
    URL.revokeObjectURL(downloadUrl);
  }

  onDownload() {
    const { selectedBlueprint, validatedAccessCode, downloadUrl } = this.state;
    if (!selectedBlueprint) {
      return;
    }

    if (downloadUrl != null) {
      downloadFromObjectUrl(downloadUrl);
      return;
    }

    downloadFromUrl(
      `${API_BASE}mdm/enroll/${selectedBlueprint}?code=${validatedAccessCode}`,
    );
  }

  scrollToRef() {
    window.scrollTo(0, this.buttonRef.current.offsetTop);
  }

  render() {
    const { isLoading, isAccessCodeValid } = this.state;
    if (isLoading) {
      return <LineLoader />;
    }
    if (!isAccessCodeValid) {
      /* istanbul ignore next */
      return <h1>{i18n.t('Access code is invalid')}</h1>;
    }
    return (
      <Container id="enroll">
        <HeaderWithLogo pageName={i18n.t('Enrollment Portal')} large />
        <ComputerImageWrapper>
          <img role="presentation" alt={i18n.t('computer')} src={IPadImage} />
        </ComputerImageWrapper>
        <Header>{i18n.t('Enroll your iPad with Kandji')}</Header>
        <HelperText>
          {i18n.t(
            `To enroll, click the button below to download and install Kandji`,
          )}
        </HelperText>
        <ButtonWrapper>
          <Button
            type="button"
            innerRef={this.buttonRef}
            onClick={() => {
              this.onDownload();
              this.scrollToRef();
            }}
            style={{ width: '210px', background: '#FFCF01', color: '#1A1D25' }}
          >
            {i18n.t(`Download Kandji`)}
          </Button>
        </ButtonWrapper>
      </Container>
    );
  }
}

export default EnrollTabletPage;
