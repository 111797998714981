import React from 'react';

import { Badge } from '@kandji-inc/nectar-ui';

import { STATUS_MAP } from './constants';
import { mapStatusToAttributes, translateStatusLabel } from './helpers';

type StatusBadgeProps = {
  status: string;
};

const StatusBadge = (props: StatusBadgeProps) => {
  const { status } = props;

  const { label, color } = mapStatusToAttributes(status);
  const transltedLabel = label ? translateStatusLabel(label) : '';

  return <Badge color={color}>{transltedLabel}</Badge>;
};

export default StatusBadge;
