import {
  BoolFilterType,
  DateFilterType,
  EnumFilterType,
  FilterSearchButton,
  Flex,
  Icon,
  NumFilterType,
  StringFilterType,
  Text,
  TextField,
  TimeFilterType,
  VersionFilterType,
  isNullOrNotNullOperator,
} from '@kandji-inc/nectar-ui';
import { i18n } from 'i18n';
import * as React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useFlags } from 'src/config/feature-flags';
import { getIconName } from '../../prism/components/PrismNav/prismNavUtils';
import {
  createApplyFilter,
  createClearFilter,
  createRemoveFilter,
  getInitialFilterOptions,
} from '../../prism/components/PrismTable/utils/prismFiltersUtils';
import { useSyncUrlWithTableState } from '../../prism/hooks/use-sync-url-with-table-state';
import type { PrismCategoryFilterProperties as ColFilters } from '../../prism/types/filter.types';
import { usePrismViewsContext } from '../contexts/PrismViewsContext';

const PrismViewFilters = ({
  search,
  setSearch,
  selectedCategories,
}: {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  selectedCategories: Set<string>;
}) => {
  const { columns, prismCategories } = usePrismViewsContext();
  const { filter, removeFilter, setFilter } =
    useSyncUrlWithTableState('/devices');
  const { 'visibility-cross-category-views': ffCrossCategoryViews } = useFlags([
    'visibility-cross-category-views',
  ]);

  const filterOptions = React.useMemo(() => {
    return parseFilterOptionMetaFromColumnDefs({
      columns: (columns?.columnDefs || []).map((def) => ({ columnDef: def })),
      columnFilters: filter,
      selectedCategories,
    });
  }, [columns, filter, selectedCategories]);

  const columnFilters = React.useMemo(() => {
    if (filter == null) {
      return [];
    }
    return Object.entries(filter)
      .map(getInitialFilterOptions(filterOptions))
      .filter(Boolean);
  }, [filter, filterOptions]);

  const handleFilterSelect = React.useCallback(
    (filterKey: string) => {
      setFilter({ [filterKey]: '' });
    },
    [setFilter],
  );

  return (
    <Flex
      key={JSON.stringify(columnFilters)}
      flow="row"
      wrap="wrap"
      gap="sm"
      flex="1"
    >
      <TextField
        iconLeft
        compact
        showClearButton={search.length > 0}
        onClear={() => setSearch('')}
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        icon="magnifying-glass"
        type="input"
        placeholder={i18n.t('Search')}
        css={{ width: '240px', flex: 'none' }}
      />
      {prismCategories &&
        columnFilters?.map((filter: ColFilters['unionOf']['details']) => {
          const handleApply = createApplyFilter(
            'device_views',
            filter,
            setFilter,
          );

          const handleRemove = createRemoveFilter(
            'device_views',
            filter,
            removeFilter,
          );

          const handleClear = createClearFilter(
            'device_views',
            filter,
            removeFilter,
          );

          const handleCancel = ({ isFilterEmpty }) => {
            if (isFilterEmpty) {
              handleRemove();
            }
          };

          const dropdownProps = {
            css: {
              zIndex: 10,
            },
            defaultOpen: !!(
              !isNullOrNotNullOperator(filter.operator) &&
              (filter.value == null || filter.value.length === 0)
            ),
            contentProps: {
              align: 'start' as const,
              preventOutsideInteraction: true,
            },
          };

          let [category, name] = filter.key.split('.');
          if (name == null) {
            category = 'device_information';
          }
          const categoryDisplayName = prismCategories.find(
            (c) => c.uri === category,
          )?.display_name;
          // istanbul ignore next
          const buttonProps = ffCrossCategoryViews
            ? {
                iconLeft: getIconName(category as string),
                tooltip: (
                  <Flex flow="column">
                    <Text css={{ fontWeight: 500 }}>{filter.label}</Text>
                    <Flex gap="xs">
                      <Icon name={getIconName(category as string)} size="sm" />
                      <Text>{categoryDisplayName}</Text>
                    </Flex>
                  </Flex>
                ),
              }
            : {};

          const filterProps = {
            showRemove: true,
            key: filter.key,
            dropdownProps,
            buttonProps,
            handleApply,
            handleCancel,
            handleClear,
            handleRemove,
          };

          // istanbul ignore next
          switch (filter.type) {
            case 'string': {
              return (
                <StringFilterType
                  hideNullOperators={filter.key === 'blueprint_name'}
                  filter={filter}
                  {...filterProps}
                />
              );
            }

            case 'enum':
              return (
                <EnumFilterType
                  multi
                  filter={filter}
                  enumOptions={filter.enumOptions}
                  {...filterProps}
                />
              );

            case 'date-time':
              return <DateFilterType filter={filter} {...filterProps} />;

            case 'boolean': {
              return (
                <BoolFilterType
                  filter={filter}
                  boolOptions={filter.boolOptions}
                  {...filterProps}
                />
              );
            }

            case 'number': {
              return <NumFilterType filter={filter} {...filterProps} />;
            }

            case 'time': {
              return <TimeFilterType filter={filter} {...filterProps} />;
            }

            case 'version': {
              return <VersionFilterType filter={filter} {...filterProps} />;
            }

            default:
              return null;
          }
        })}

      <FilterSearchButton
        text={columnFilters.length ? '' : i18n.t('Add Filter')}
        asButton={columnFilters.length ? 'button' : 'filter-button'}
        options={
          ffCrossCategoryViews
            ? splitIntoSections({
                filterOptions,
                columnSchemas: prismCategories,
              })
            : filterOptions
        }
        onFilterSelect={handleFilterSelect}
        componentCss={{
          menu: {
            zIndex: 11,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '$1',
            },
            '&:hover': {
              '&::-webkit-scrollbar-track': {
                background: 'rgba(243, 247, 250)',
                borderRadius: '$rounded',
              },
              '&::-webkit-scrollbar-thumb': {
                background: 'rgba(80, 94, 113, 0.24)',
                borderRadius: '$rounded',
                height: '50px',
              },
            },
          },
        }}
      />
    </Flex>
  );
};

export default PrismViewFilters;

export function splitIntoSections({ filterOptions, columnSchemas }) {
  return filterOptions
    .reduce((sections, option) => {
      let [category, name] = option.value.split('.');
      if (name == null) {
        category = 'device_information';
      }
      option.richLabel = (
        <Flex flex="1" alignItems="center" mr1>
          {option.label}
          <Flex flex="1" />
          <Icon
            name={getIconName(category)}
            size="sm"
            style={{ color: '#7B8BA3' }}
          />
        </Flex>
      );
      const columnSchema = columnSchemas.find(
        (schema) => schema.uri === category,
      );
      if (columnSchema) {
        const section = sections.find(
          (section) => section.section === columnSchema.display_name,
        );
        if (section) {
          section.options.push(option);
        } else {
          sections.push({
            section: columnSchema.display_name,
            showSectionLabel: true,
            options: [option],
          });
        }
      }
      return sections;
    }, [])
    .sort((a, b) => {
      return a.section === 'Devices'
        ? -1
        : b.section === 'Devices'
          ? 1
          : a.section.localeCompare(b.section);
    });
}

export function parseFilterOptionMetaFromColumnDefs({
  columns,
  columnFilters,
  selectedCategories,
}: {
  columns: any[];
  columnFilters: {};
  selectedCategories: Set<string>;
}) {
  return columns
    ?.map((col) => {
      const colMeta = col.columnDef?.meta;

      if (!colMeta?.filterType) {
        return undefined;
      }

      const disabled = columnFilters[col.columnDef.id] != null;

      return {
        label: col.columnDef.meta.displayName,
        value: col.columnDef.id,
        icon: colMeta.filterIcon,
        disabled,
        tooltip: disabled ? i18n.t('Already an active filter') : null,
        meta: {
          ...colMeta,
        },
      };
    })
    .filter((option) => {
      if (!option) {
        return false;
      }
      if (selectedCategories.size === 0) {
        return true;
      }
      let [category, name] = option.value.split('.');
      if (name == null) {
        category = 'device_information';
      }

      return selectedCategories.has(category);
    })
    .sort((a, b) =>
      a?.label.localeCompare(b?.label, undefined, {
        sensitivity: 'base',
        ignorePunctuation: true,
      }),
    );
}
