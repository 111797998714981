import { i18n } from 'src/i18n';
import ThreatListSquareButton from './ThreatListSquareButton';

type ThreatListExportButtonProps = {
  disabled?: boolean;
  onClick: () => void;
};

const ThreatListExportButton = ({
  onClick,
  disabled = false,
}: ThreatListExportButtonProps) => (
  <ThreatListSquareButton
    tooltip={i18n.t('Export CSV')}
    icon="file-arrow-down"
    onClick={onClick}
    disabled={disabled}
    testId="threat-export-button"
  />
);

export default ThreatListExportButton;
