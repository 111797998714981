import { i18n } from 'src/i18n';
import type {
  BehavioralThreatClassification,
  BehavioralThreatStatus,
  SortColumnDirection,
  SortColumnName,
  ThreatClassification,
  ThreatPosture,
  ThreatStatus,
} from '../threat.types';

const THREAT_CLASSIFICATION: Readonly<{
  [Prop in ThreatClassification]: Extract<ThreatClassification, Prop>;
}> = {
  MALWARE: 'MALWARE',
  PUP: 'PUP',
  BENIGN: 'BENIGN',
  UNKNOWN: 'UNKNOWN',
};

const THREAT_BEHAVIORAL_CLASSIFICATION: Readonly<{
  [Prop in BehavioralThreatClassification]: Extract<
    BehavioralThreatClassification,
    Prop
  >;
}> = {
  MAL: 'MAL',
  SUS: 'SUS',
};

// Subset of all threat classifications to enable as filters
const THREAT_CLASSIFICATION_FILTERS = Object.fromEntries(
  Object.entries(THREAT_CLASSIFICATION),
);

const THREAT_BEHAVIORAL_CLASSIFICATION_FILTERS = Object.fromEntries(
  Object.entries(THREAT_BEHAVIORAL_CLASSIFICATION),
);

const THREAT_CLASSIFICATION_LABELS = i18n.createMap({
  [THREAT_CLASSIFICATION.PUP]: () =>
    i18n.t('Potentially Unwanted Program (PUP)'),
  [THREAT_CLASSIFICATION.MALWARE]: () => i18n.t('Malware'),
  [THREAT_CLASSIFICATION.BENIGN]: () => i18n.t('Benign'),
  [THREAT_CLASSIFICATION.UNKNOWN]: () => i18n.t('Unknown'),
  [THREAT_BEHAVIORAL_CLASSIFICATION.MAL]: () => i18n.t('Malicious'),
  [THREAT_BEHAVIORAL_CLASSIFICATION.SUS]: () => i18n.t('Suspicious'),
});

const THREAT_CLASSIFICATION_ICON_MAP = {
  [THREAT_CLASSIFICATION.MALWARE]: 'bug',
  [THREAT_CLASSIFICATION.PUP]: 'layer-group',
  [THREAT_CLASSIFICATION.BENIGN]: 'circle-check',
  [THREAT_CLASSIFICATION.UNKNOWN]: 'circle-question',
  [THREAT_BEHAVIORAL_CLASSIFICATION.MAL]: 'bug',
  [THREAT_BEHAVIORAL_CLASSIFICATION.SUS]: 'layer-group',
} as const;

const THREAT_CLASSIFICATION_COLOR = {
  [THREAT_CLASSIFICATION.MALWARE]: 'red',
  [THREAT_CLASSIFICATION.PUP]: 'yellow',
  [THREAT_CLASSIFICATION.BENIGN]: 'green',
  [THREAT_CLASSIFICATION.UNKNOWN]: 'neutral',
  [THREAT_BEHAVIORAL_CLASSIFICATION.MAL]: 'red',
  [THREAT_BEHAVIORAL_CLASSIFICATION.SUS]: 'yellow',
} as const;

const THREAT_STATUS: Readonly<{
  [Prop in ThreatStatus]: Extract<ThreatStatus, Prop>;
}> = {
  QUARANTINED: 'QUARANTINED',
  NOT_QUARANTINED: 'NOT_QUARANTINED',
  RESOLVED: 'RESOLVED',
  RELEASED: 'RELEASED',
} as const;

const THREAT_BEHAVIORAL_STATUS: Readonly<{
  [Prop in BehavioralThreatStatus]: Extract<BehavioralThreatStatus, Prop>;
}> = {
  DETECTED: 'DETECTED',
  BLOCKED: 'BLOCKED',
  BLOCKED_PARENT: 'BLOCKED_PARENT',
  INFORMATIONAL: 'INFORMATIONAL',
} as const;

const THREAT_POSTURE: Readonly<{
  [Prop in ThreatPosture]: Extract<ThreatPosture, Prop>;
}> = {
  PROTECT: 'PROTECT',
  DETECT: 'DETECT',
} as const;

const THREAT_CLASSIFICATION_LIST = Object.values(THREAT_CLASSIFICATION_FILTERS);
const THREAT_STATUS_LIST = Object.values(THREAT_STATUS);
const THREAT_STATUS_JOINT_LIST = THREAT_STATUS_LIST.sort()
  .map((value) => value.toLowerCase())
  .join(',');
const THREAT_BEHAVIORAL_STATUS_LIST = Object.values(
  THREAT_BEHAVIORAL_STATUS,
).filter((status) => status !== THREAT_BEHAVIORAL_STATUS.BLOCKED_PARENT);
const THREAT_BEHAVIORAL_STATUS_JOINT_LIST = THREAT_BEHAVIORAL_STATUS_LIST.sort()
  .map((value) => value.toLowerCase())
  .join(',');

const THREAT_BEHAVIORAL_CLASSIFICATION_LIST = Object.values(
  THREAT_BEHAVIORAL_CLASSIFICATION_FILTERS,
);

const THREAT_POSTURE_LIST = Object.values(THREAT_POSTURE);

const THREAT_STATUS_DISPLAY_NAME_MAP = i18n.createMap({
  [THREAT_STATUS.QUARANTINED]: () => i18n.t('Quarantined'),
  [THREAT_STATUS.NOT_QUARANTINED]: () => i18n.t('Not quarantined'),
  [THREAT_STATUS.RESOLVED]: () => i18n.t('Resolved'),
  [THREAT_STATUS.RELEASED]: () => i18n.t('Released'),
  [THREAT_BEHAVIORAL_STATUS.DETECTED]: () => i18n.t('Detected'),
  [THREAT_BEHAVIORAL_STATUS.BLOCKED]: () => i18n.t('Blocked'),
  [THREAT_BEHAVIORAL_STATUS.BLOCKED_PARENT]: () =>
    i18n.t('Blocked (killed parent)'),
  [THREAT_BEHAVIORAL_STATUS.INFORMATIONAL]: () => i18n.t('Informational'),
});

const THREAT_STATUS_COLOR_MAP = {
  [THREAT_STATUS.QUARANTINED]: 'blue',
  [THREAT_STATUS.NOT_QUARANTINED]: 'red',
  [THREAT_STATUS.RESOLVED]: 'green',
  [THREAT_STATUS.RELEASED]: 'neutral',
  [THREAT_BEHAVIORAL_STATUS.DETECTED]: 'yellow',
  [THREAT_BEHAVIORAL_STATUS.BLOCKED]: 'blue',
  [THREAT_BEHAVIORAL_STATUS.BLOCKED_PARENT]: 'blue',
  [THREAT_BEHAVIORAL_STATUS.INFORMATIONAL]: 'neutral',
} as const;

const THREAT_STATUS_ICON_MAP = {
  [THREAT_STATUS.NOT_QUARANTINED]: 'shield-xmark',
  [THREAT_STATUS.QUARANTINED]: 'shield-check',
  [THREAT_STATUS.RESOLVED]: 'octagon-check',
  [THREAT_STATUS.RELEASED]: 'shield-minus',
  [THREAT_BEHAVIORAL_STATUS.DETECTED]: 'bell',
  [THREAT_BEHAVIORAL_STATUS.BLOCKED]: 'do-not-enter',
  [THREAT_BEHAVIORAL_STATUS.BLOCKED_PARENT]: 'do-not-enter',
  [THREAT_BEHAVIORAL_STATUS.INFORMATIONAL]: 'circle-info',
} as const;

const THREAT_TABLE_SORTABLE_FIELDS: SortColumnName[] = [
  'threat_id',
  'threat_name',
  'associated_item',
  'classification',
  'device_name',
  'process_name',
  'process',
  'detection_date',
  'status',
  'device_count',
] as const;

const THREAT_TABLE_ROWS_PER_PAGE_OPTIONS = [50, 100, 300] as const;

const THREAT_SIDE_PANEL_ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100] as const;

const THREAT_TABLE_DATE_RANGE_OPTIONS = [7, 30, 60, 90] as const;

const THREAT_SORT_ORDER: SortColumnDirection[] = [
  'none',
  'asc',
  'desc',
] as const;

const THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS: {
  value: string;
  getInlineLabel?: () => string;
  getLabel: () => string;
}[] = [
  {
    getLabel: () => i18n.t('Last 24 hours'),
    getInlineLabel: () => i18n.t('last 24 hours'),
    value: 'last_24_hours',
  },
  {
    getLabel: () => i18n.t('Last 7 days'),
    getInlineLabel: () => i18n.t('last 7 days'),
    value: 'last_7_days',
  },
  {
    getLabel: () => i18n.t('Last 30 days'),
    getInlineLabel: () => i18n.t('last 30 days'),
    value: 'last_30_days',
  },
  {
    getLabel: () => i18n.t('Last 60 days'),
    getInlineLabel: () => i18n.t('last 60 days'),
    value: 'last_60_days',
  },
  {
    getLabel: () => i18n.t('Last 90 days'),
    getInlineLabel: () => i18n.t('last 90 days'),
    value: 'last_90_days',
  },
  {
    getLabel: () => i18n.t('All time'),
    value: 'all_time',
  },
  {
    getLabel: () => i18n.t('Custom date range'),
    value: 'custom_date_range',
  },
] as const;

const THREAT_VIEW = {
  THREATS: 'by-threats',
  DEVICES: 'by-devices',
} as const;

const THREAT_VIEW_LIST = Object.values(THREAT_VIEW);

const THREAT_DETECTION_TYPE = {
  FILE: 'FILE',
  BEHAVIORAL: 'BEHAVIORAL',
} as const;

const THREAT_DETECTION_TYPE_LIST = Object.values(THREAT_DETECTION_TYPE);

const VIRUS_TOTAL_URL = 'https://www.virustotal.com/gui/file/';

const constants = {
  THREAT_CLASSIFICATION,
  THREAT_STATUS,
  THREAT_BEHAVIORAL_STATUS,
  THREAT_CLASSIFICATION_LIST,
  THREAT_BEHAVIORAL_CLASSIFICATION_LIST,
  THREAT_BEHAVIORAL_CLASSIFICATION,
  THREAT_CLASSIFICATION_LABELS,
  THREAT_CLASSIFICATION_ICON_MAP,
  THREAT_CLASSIFICATION_COLOR,
  THREAT_STATUS_LIST,
  THREAT_STATUS_JOINT_LIST,
  THREAT_STATUS_DISPLAY_NAME_MAP,
  THREAT_STATUS_COLOR_MAP,
  THREAT_STATUS_ICON_MAP,
  THREAT_TABLE_SORTABLE_FIELDS,
  THREAT_TABLE_ROWS_PER_PAGE_OPTIONS,
  THREAT_SIDE_PANEL_ROWS_PER_PAGE_OPTIONS,
  THREAT_TABLE_DATE_RANGE_OPTIONS,
  THREAT_SORT_ORDER,
  THREAT_DETECTION_DATE_SELECT_CUSTOM_RANGE_OPTIONS,
  THREAT_VIEW,
  THREAT_DETECTION_TYPE,
  THREAT_DETECTION_TYPE_LIST,
  THREAT_VIEW_LIST,
  THREAT_POSTURE,
  THREAT_POSTURE_LIST,
  VIRUS_TOTAL_URL,
  THREAT_BEHAVIORAL_STATUS_LIST,
  THREAT_BEHAVIORAL_STATUS_JOINT_LIST,
};

export default constants;
