/* istanbul ignore file */

import { i18n } from 'i18n';
import React from 'react';

const entry = ({ details }) => {
  const { 'certificate subject': certificate_subject } = details;

  return {
    icon: 'fas fa-file-certificate',
    iconText: i18n.t('AD CS certificate issued'),
    text: i18n.t('AD CS certificate issued'),
    description: certificate_subject,
  };
};

const detail = ({ action_type, details }) => {
  const {
    time,
    library_item,
    library_item_name,
    'certificate subject': certificate_subject,
  } = details;

  return (
    <div className={`activity-entry-details ${action_type}`}>
      <p>
        {i18n.t('Issued on:')} {i18n.format.datetime(time)}
      </p>
      <p>
        {i18n.t('Library Item:')} {library_item}
      </p>
      <p>
        {i18n.t('Library Item name:')} {library_item_name}
      </p>
      <p>
        {i18n.t('Subject:')} {certificate_subject}
      </p>
    </div>
  );
};

export default { entry, detail };
