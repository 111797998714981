import type { AriaAttributes, JSXElementConstructor } from 'react';

import { ActionsMenuCol, Col } from './components/col';
import type { ColSortDirection } from './table.types';

type ValidColType = (typeof VALID_COL_TYPES)[number];

const VALID_COL_TYPES = [Col, ActionsMenuCol] as const;
export const COL_TYPE_MAP = new WeakMap(
  VALID_COL_TYPES.map((type) => [type, type.displayName?.toLowerCase()]),
);
export const ARIA_SORT_MAP: Record<
  ColSortDirection,
  AriaAttributes['aria-sort']
> = {
  asc: 'ascending',
  desc: 'descending',
  none: 'none',
} as const;

export function getIsSortDescendOrNone(
  direction: ColSortDirection | undefined,
) {
  return direction === 'desc' || direction === 'none' || !direction;
}

export function getColumnSortDirection(
  currentSort: string,
  columnName: string,
) {
  if (currentSort && currentSort.includes(columnName)) {
    return currentSort.startsWith('-') ? 'desc' : 'asc';
  }

  return undefined;
}

export function formatNextSort<ColName extends string>(
  colName: ColName,
  sortDirection: 'asc' | 'desc',
): `${ColName}` | `-${ColName}`;
export function formatNextSort<ColName extends string>(
  colName: ColName,
  sortDirection: 'asc' | 'desc',
) {
  const sortDirLabelMap = {
    asc: '',
    desc: '-',
  } as const;

  return `${sortDirLabelMap[sortDirection]}${colName}`;
}

export function getHasWidthOverflow(element: Element) {
  return element.scrollWidth > element.clientWidth;
}

export function isValidTableColType(
  type: string | JSXElementConstructor<unknown> | ValidColType,
): type is ValidColType {
  return typeof type !== 'string' && (type === Col || type === ActionsMenuCol);
}
