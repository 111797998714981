import { LibraryItemStatusLog } from '../../lib/LibraryItemStatusLog';
import type { BlockedProfileDetails } from '../ProfileStatus.types';

function serializerV1(details: BlockedProfileDetails) {
  const { reason } = details;

  return new LibraryItemStatusLog().addRow(reason).toString();
}

export const BlockedProfileAuditSerializers = {
  Default: serializerV1,
  1: serializerV1,
};
