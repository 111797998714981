import './scss/select-search.scss';

import {
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, { useState, useRef, useCallback, useEffect } from 'react';

import Select, { components } from 'react-select';

import { setClass, useOutsideClick } from '@kandji-inc/bumblebee';

import { i18n } from 'src/i18n';
import DropdownIndicator from './dropdown-indicator';
import ValueContainer from './value-container';

const Menu = (props: any) => {
  const { children } = props;
  return <div className="b-select-multi__menu">{children}</div>;
};

Menu.propTypes = {
  children: node,
};

Menu.defaultProps = {
  children: <></>,
};

const Blanket = (props: any) => {
  const { onClick } = props;
  return <div tabIndex={0} onClick={onClick} />;
};

Blanket.propTypes = {
  onClick: func.isRequired,
};

const Dropdown = (props: any) => {
  const {
    children,
    disabled,
    isOpen,
    onDropdownClose,
    placeholder,
    toggleOpen,
    value,
  } = props;

  return (
    <>
      <ValueContainer
        disabled={disabled}
        isOpen={isOpen}
        placeholder={placeholder}
        toggleOpen={toggleOpen}
        value={value}
      />

      {isOpen && !disabled ? <Menu>{children}</Menu> : null}

      {isOpen && !disabled ? <Blanket onClick={onDropdownClose} /> : null}
    </>
  );
};

Dropdown.propTypes = {
  children: node,
  disabled: bool,
  isOpen: bool,
  onDropdownClose: func.isRequired,
  placeholder: string,
  toggleOpen: func.isRequired,
  value: shape({ label: string, value: oneOfType([string, number]) }),
};

Dropdown.defaultProps = {
  children: <></>,
  disabled: false,
  isOpen: false,
  placeholder: 'Select Option',
  value: null,
};

const Option = (props: any) => <components.Option {...props} />;

const MenuList = (props: any) => {
  const { children } = props;
  return (
    <div className="select-multi-search__menu-list">
      {}

      <components.MenuList {...props}>{children}</components.MenuList>
    </div>
  );
};

MenuList.propTypes = {
  children: node,
};

MenuList.defaultProps = {
  children: <></>,
};

const AsyncSelectSearch = (props: any) => {
  const {
    className,
    compact,
    auto,
    disabled,
    extra,
    isInitialOpen,
    onChange,
    onClose,
    loadOptions,
    placeholder,
    searchPlaceholder = i18n.t('Search Option'),
    customOption,
    style,
    testId,
    value,
  } = props;
  const [isOpen, setIsOpen] = useState(isInitialOpen);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const wrapperRef = useRef(null);

  const fetchOptions = useCallback(async () => {
    if (inputValue === '') {
      setOptions([]);
    } else {
      const newOptions = await loadOptions(inputValue).catch(() => []);
      setOptions(newOptions);
    }

    setIsLoading(false);
  }, [inputValue]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      fetchOptions();
    }, 300);

    return () => clearTimeout(debounce);
  }, [fetchOptions]);

  const handleOnClose = async (isSelectOpen: any) => {
    if (isSelectOpen) {
      await new Promise((resolve) => {
        setInputValue('');
        onClose();
        // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
        resolve();
      });
      return setIsOpen(false);
    }
  };

  useOutsideClick(
    wrapperRef,
    (e: any) => {
      if (!e.composedPath().includes(wrapperRef?.current)) {
        handleOnClose(isOpen);
      }
    },
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
    [isOpen],
  );

  const toggleOpen = () => {
    if (!disabled) {
      handleOnClose(isOpen);
      setIsOpen((prevToggleVal: any) => !prevToggleVal);
    }
  };

  const onInputChange = async (input: string, { action }) => {
    if (action === 'input-change') {
      setIsLoading(true);
      setOptions([]);
      setInputValue(input);
    }
  };

  const onSelectChange = (selectedValues: any, actionObj: any) => {
    onChange(selectedValues, actionObj);
    handleOnClose(isOpen);
  };

  return (
    <div
      className={setClass([
        'b-select-multi__wrapper',
        'custom-select-search',
        className,
        compact && 'b-select--max-width',
        auto && '--auto',
      ])}
      ref={wrapperRef}
      data-testid={testId}
    >
      <Dropdown
        disabled={disabled}
        isOpen={isOpen}
        onDropdownClose={toggleOpen}
        placeholder={placeholder}
        toggleOpen={toggleOpen}
        value={value}
      >
        <Select
          className="b-select-multi-menu"
          classNamePrefix="b-select"
          components={{
            DropdownIndicator,
            MenuList,
            Option: customOption || Option,
            ClearIndicator: null,
            LoadingIndicator: null,
          }}
          clearSearchValue={() => setInputValue('')}
          inputValue={inputValue}
          options={options}
          placeholder={searchPlaceholder}
          onChange={onSelectChange}
          onInputChange={onInputChange}
          value={value}
          noOptionsMessage={() => (
            <p className="b-select-multi-search__no-results-msg b-mb1">
              {i18n.t('No results found')}
            </p>
          )}
          styles={style}
          isDisabled={disabled}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          menuIsOpen
          autoFocus
          isLoading={isLoading}
          {...extra}
        />
      </Dropdown>
    </div>
  );
};

AsyncSelectSearch.propTypes = {
  className: string,
  compact: bool,
  auto: bool,
  disabled: bool,
  extra: object,
  isInitialOpen: bool,
  onChange: func.isRequired,
  onClose: func,
  loadOptions: func.isRequired,
  placeholder: string,
  searchPlaceholder: string,
  customOption: node,
  style: object,
  testId: string,
  value: shape({ label: string, value: oneOfType([string, number]) }),
};

AsyncSelectSearch.defaultProps = {
  className: null,
  compact: false,
  auto: false,
  disabled: false,
  extra: {},
  isInitialOpen: false,
  onClose: () => {},
  placeholder: 'Select Option',
  searchPlaceholder: undefined,
  customOption: null,
  style: {},
  testId: undefined,
  value: null,
};

export default AsyncSelectSearch;
