import { AccountContext, type UserSettings } from 'contexts/account';
import { useContext } from 'react';

export interface UserFormBase {
  first_name: string;
  last_name: string;
  email: string;
  id: string;
  locale: string;
  settings: UserSettings;
}

export interface UserFormWithPassword extends UserFormBase {
  old_password?: string;
  password?: string;
}

export const useInitialForm = (): UserFormWithPassword => {
  const {
    userId,
    userFirstName,
    userLastName,
    userEmail,
    userLocale,
    userSettings,
  } = useContext(AccountContext);
  return {
    first_name: userFirstName,
    last_name: userLastName,
    email: userEmail,
    id: userId,
    locale: userLocale,
    settings: {
      ...userSettings,
      timezone: userSettings?.timezone || null,
      preferred_date_format:
        userSettings?.preferred_date_format || 'MM/DD/YYYY',
      disable_relative_dates: userSettings?.disable_relative_dates || 'enable',
      send_weekly_emails:
        userSettings?.send_weekly_emails !== undefined
          ? userSettings.send_weekly_emails
          : true,
    },
  };
};

export const initialPasswords = {
  current: '',
  new: '',
  confirmNew: '',
};

export const useInitialPageState = () => ({
  isEditing: false,
  isAdding: false,
  isSaving: false,
  isValid: false,
});
